import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  GetPreviousGroupOrdersListAPI,
  ReActivateGroupOrdersAPI,
  SetGroupOrderIdAPI,
  fetchOrderHistoryId,
} from "../../actions/Orders/Order";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import OrderDetail from "./ViewOrderDetail";
import { CircularProgress } from "@material-ui/core";
import { ORDER_STATUSES } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { createEmptyCart } from "../Menu/MenuFunctions";
import { RefreshCartItems } from "../Cart/cartFunctions";
import MessagesList from "../../config/messages.json";
import DecisionDialog from "../DecisionDialog";
import { cancelGroupOrderAPI } from "../../actions/Cart/Cart";

function OrderGroupHistory({ searchId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const locationsList = useSelector((state) => state.location.pickupLocations);

  // CREATE LOCAL STATE
  const [ordersList, setOrdersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [historyDate, setHistoryDate] = useState(null);
  const [viewedOrderId, setViewedOrderId] = useState(searchId ? searchId : -1);
  const [viewedGroupOrderId, setViewedGroupOrderId] = useState("");
  const [cancelOrderIds, setCancelOrderIds] = useState([]);
  const [updateRecordId, setUpdateRecordId] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [yesButtonText, setYesButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [locationsListData, setLocationsListData] = useState({});

  useEffect(() => {
    let locationObj = {};
    locationsList.map((singleLocation) => {
      locationObj[singleLocation.idMixtLocation] = singleLocation.timezone;
    });
    setLocationsListData(locationObj);
  }, []);

  const getUserOrdersHistory = async () => {
    setLoader(true);
    const ordersListData = await GetPreviousGroupOrdersListAPI({
      date: historyDate,
    });
    if (!ordersListData.generalError) {
      //   console.log(ordersListData, ordersListData.data.orders);
      let newOrdersList = ordersList.concat(ordersListData.data.orders);
      setOrdersList(newOrdersList);
      if (ordersListData.data.length === ordersListData.data.limit)
        setHistoryDate(newOrdersList[newOrdersList.length - 1].createdAt);
      else setHistoryDate("");
      // UPDATE TOTAL PAGE COUNT
    }
    setLoader(false);
  };
  const ReactivateGroupOrder = (id) => {
    setUpdateRecordId(id);
    setLoader(true);
    let message = "";
    if (orderData && Object.keys(orderData).length > 1) {
      if (orderData.bOrderAsParticipant)
        message = "Regenerate will clear current cart";
      else message = "Regenerate will clear current group cart";

      if (orderData.isGroupOrder) {
        setYesButtonText("Cancel and Create New Group Order");
        setCancelButtonText("Do Not Cancel");
      } else {
        setYesButtonText("Cancel Order");
        setCancelButtonText("Do Not Cancel");
      }

      setErrorTitle(MessagesList[message + " Title"]);
      setErrorDescription(MessagesList[message]);
    } else {
      reCreateOrder(id);
    }
  };
  const reCreateOrder = async (id = null) => {
    setErrorTitle("");
    setErrorDescription("");
    if (
      orderData &&
      Object.keys(orderData).length > 1 &&
      orderData.isGroupOrder &&
      !orderData.bOrderAsParticipant
    )
      cancelGroupOrderAPI(orderData.groupOrderId);
    const ordersListData = await ReActivateGroupOrdersAPI({
      idGroupOrder: id ? id : updateRecordId,
    });
    if (!ordersListData.generalError) {
      await createEmptyCart(1, null, 0, id ? id : updateRecordId);
      dispatch(
        SetGroupOrderIdAPI({
          group_order_id: "",
          group_order_user_name: "",
        })
      );
      await RefreshCartItems(dispatch);

      history.push("cart");
      //   getUserOrdersHistory();
    }
    setLoader(false);
    setUpdateRecordId("");
  };

  useEffect(async () => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    // GET PREVIOUS ORDERS LIST WITH PAGE
    getUserOrdersHistory();
  }, []);
  const handleContainerOnBottom = () => {
    // GET NEXT LIST IF USER REACHED AT BOTTOM OF DOCUMENT
    if (historyDate !== "") getUserOrdersHistory();
  };

  const noOptionSelected = () => {
    setUpdateRecordId("");
    setLoader(false);
    setErrorTitle("");
    setErrorDescription("");
  };

  const fetchGroupOrderHistoryId = async (groupOrderId) => {
    setViewedGroupOrderId(groupOrderId);
    setLoader(true);
    let result = await fetchOrderHistoryId(groupOrderId);
    if (!result.generalError) {
      console.log(result.data.idOrderHistory);
      setViewedOrderId(result.data.idOrderHistory);
    }
    setLoader(false);
    setViewedGroupOrderId("");
  };
  return (
    <>
      {viewedOrderId === -1 ? (
        <>
          <BottomScrollListener onBottom={handleContainerOnBottom} />
          <div className="pb-4 mb-4 pt-4 pt-md-0">
            {ordersList.map((orderDetail) => {
              let itemName = [];
              let totalAmount = 0;
              orderDetail.groupItems.map((singleItem) => {
                singleItem.items.map((singleItemDetail) => {
                  itemName.push(singleItemDetail.itemName);
                  totalAmount += singleItemDetail.itemTotal;
                });
              });
              let locationTimeZone = "";
              locationTimeZone = orderDetail.general_info.doordashLocationId
                ? locationsListData[orderDetail.general_info.doordashLocationId]
                : locationsListData[orderDetail.general_info.mixtLocationId];
              return (
                <div
                  className="border mb-4 cp-19 bg-white6"
                  key={orderDetail.idGroupOrder}
                  id={orderDetail.idGroupOrder}
                >
                  <p className="BrandenGrotesqueBold font-size-0_9 text-grey1 text-uppercase mb-1">
                    {orderDetail.orderType === "pickup"
                      ? orderDetail.orderType +
                        " - " +
                        orderDetail.pickup_location_info.locationName +
                        " - group order"
                      : orderDetail.orderType + " - group order"}
                  </p>
                  <p className="BrownSTDRegular font-size-0_9 text-grey5">
                    {orderDetail.fullAddress}
                  </p>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      Order #
                    </span>
                    <span className="w-78 w-78 w-sm-82 w-md-85 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {orderDetail.idGroupOrder}
                    </span>
                  </div>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      {orderDetail.orderType}
                    </span>
                    {orderDetail.orderType === "pickup" ? (
                      <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                        {moment
                          .tz(orderDetail.orderDate * 1000, locationTimeZone)
                          .format("dddd, MMMM D, YYYY hh:mm A")}{" "}
                        -{" "}
                        {moment
                          .tz(orderDetail.orderDate * 1000, locationTimeZone)
                          .add(15, "minutes")
                          .format("hh:mm A")}{" "}
                      </span>
                    ) : (
                      <div className="d-flex flex-column flex-md-row w-78 w-sm-82 w-md-85 ">
                        <span className="BrownSTDRegular font-size-0_9 text-grey5 me-0 me-sm-5">
                          {moment
                            .tz(orderDetail.orderDate * 1000, locationTimeZone)
                            .format("dddd, MMMM D, YYYY")}
                        </span>
                        <span className="BrownSTDRegular font-size-0_9 text-grey5">
                          {"Estimated Delivery Time: " +
                            moment
                              .tz(
                                orderDetail.orderDate * 1000,
                                locationTimeZone
                              )
                              .format("hh:mm A")}{" "}
                          -{" "}
                          {moment
                            .tz(orderDetail.orderDate * 1000, locationTimeZone)
                            .add(15, "minutes")
                            .format("hh:mm A")}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      your order
                    </span>
                    <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {itemName.join(", ")}
                    </span>
                  </div>
                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      total
                    </span>
                    <span className="w-78 w-78 w-sm-82 w-md-85 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      ${totalAmount.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      status
                    </span>
                    <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {cancelOrderIds.indexOf(orderDetail.idGroupOrder) === -1
                        ? ORDER_STATUSES[orderDetail.orderStatus]
                          ? ORDER_STATUSES[orderDetail.orderStatus]
                          : orderDetail.orderStatus
                        : ORDER_STATUSES["cancelled"]}
                    </span>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full px-4 height-30 border-0"
                      onClick={() => {
                        if (orderDetail.orderStatus === "open") {
                          history.push("cart");
                        } else if (orderDetail.orderStatus === "cancel")
                          ReactivateGroupOrder(orderDetail.idGroupOrder);
                        else fetchGroupOrderHistoryId(orderDetail.idGroupOrder);
                      }}
                    >
                      {(updateRecordId === orderDetail.idGroupOrder &&
                        loader) ||
                      (viewedGroupOrderId === orderDetail.idGroupOrder &&
                        loader) ? (
                        <CircularProgress size={24} color="white" />
                      ) : orderDetail.orderStatus === "open" ||
                        orderDetail.orderStatus === "cancel" ? (
                        "Continue"
                      ) : (
                        "View"
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
            {loader ? (
              <div className="circular-progress-bar">
                <CircularProgress size={24} />
              </div>
            ) : (
              ordersList.length === 0 && (
                <div className="d-flex flex-column align-items-center cmt-50">
                  <h2 className="BrandenGrotesqueBold font-size-1_5 text-brown1 text-uppercase">
                    no orders yet
                  </h2>
                  <p className="BrownSTDRegular font-size-1_1 text-grey5 mb-4 text-center mb-4 cmt-25">
                    You must be hungry!
                  </p>
                  <button
                    className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full width-168 height-30 border-0 cmt-50"
                    onClick={() =>
                      orderData.hasOwnProperty("orderType")
                        ? history.push("/menu")
                        : history.push("/locations")
                    }
                  >
                    order now
                  </button>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        <OrderDetail
          orderId={viewedOrderId}
          cancelOrderIds={cancelOrderIds}
          setCancelOrderIds={setCancelOrderIds}
          setActionType={setViewedOrderId}
        />
      )}

      <DecisionDialog
        showError={errorTitle ? true : false}
        text={errorDescription}
        noOption={noOptionSelected}
        yesOption={reCreateOrder}
        heading={errorTitle}
        cancelButtonText={cancelButtonText}
        yesButtonText={yesButtonText}
      />
    </>
  );
}

export default OrderGroupHistory;
