import React, { useEffect, useState } from "react";
import DeliveryLocationForm from "./DeliveryLocationForm";
import { useSelector } from "react-redux";
import LocationItem from "./DeliveryLocationItem.js";
import { CircularProgress } from "@material-ui/core";
import { DecryptReduxAuth } from "../../actions/General/General";

function DeliveryLocations({
  setActionType,
  setSelectedLocation,
  showLocations,
  setShowLocations,
}) {
  // GET ALL DELIVERY UP LOCATIONS FROM REDUX STATE
  const auth = useSelector((state) => state.auth.data);
  let authData = DecryptReduxAuth(auth);
  const locations = useSelector((state) => state.location.deliveryLocations);
  const locationsLoader = useSelector(
    (state) => state.location.deliveryLocationsLoader
  );

  // CREATE LOCAL STATE
  const [addNewLocation, setAddNewLocation] = useState(
    locations.length === 0 ? true : false
  );

  useEffect(() => {
    if (!locationsLoader) {
      setAddNewLocation(locations.length === 0 ? true : false);
    }
  }, [locationsLoader]);
  return (
    <div>
      {!addNewLocation ? (
        <>
          <div
            className="d-flex align-items-end justify-content-between-2 mx-4 search-delivery-div"
            style={{ height: "60px" }}
          >
            <p
              className="BrandenGrotesqueRegular font-size-0_9 text-grey1 cursor-pointer w-100 mb-0 cpb-3 cmb-md-0"
              onClick={() => setAddNewLocation(true)}
            >
              Add a new delivery address
            </p>
            <p
              className="view_map_button BrownSTDBold font-size-0_8 text-grey1 w-25 mb-0 line-height-1 cmb-6"
              onClick={() => setShowLocations(!showLocations)}
            >
              {showLocations ? "View Map" : "View List"}
            </p>
          </div>

          {locationsLoader ? (
            <div className="circular-progress-bar">
              <CircularProgress />
            </div>
          ) : locations.length === 0 ? (
            <p className="BrownSTDBold font-size-1">No Locations Found</p>
          ) : (
            locations.map((location) => {
              return (
                <LocationItem
                  key={location.idUserDeliveryAddress}
                  location={location}
                  setSelectedLocation={setSelectedLocation}
                  setActionType={setActionType}
                  showLocations={showLocations}
                />
              );
            })
          )}
        </>
      ) : (
        !locationsLoader && (
          <DeliveryLocationForm
            setAddNewLocation={
              authData.isGuest ? setSelectedLocation : setAddNewLocation
            }
            setActionType={setActionType}
            setShowLocations={setShowLocations}
          />
        )
      )}
    </div>
  );
}

export default DeliveryLocations;
