import React, { useEffect, useState } from "react";
import InstructionsIcon from "../../assets/img/InstructionsIcon.png";
import LocationIcon from "../../assets/img/LocationIcon.png";
import RightArrow from "../../assets/img/RightArrow.jpg";
import TimeIcon from "../../assets/img/TimeIcon.png";
import DoorDashIcon from "../../assets/img/DoorDashIcon.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SelectTime from "../Locations/SelectTime";
import UpdateDeliveryInstructions from "./UpdateDeliveryInstructions";
import { useHistory } from "react-router-dom";
import { ORDER_STATUSES } from "../../config/config";
import { ResetCartDetailAPI } from "../../actions/Cart/Cart";
import DecisionDialog from "../DecisionDialog";
import { GetCartLocationDetail } from "./cartFunctions";
import { GetDeliveryLocationsAPI, GetPickUpLocationsAPI } from "../../actions/Locations/Locations";

const RightArrowComponent = () => {
  return <img src={RightArrow} className={"right-icon"} alt={"Icon"} />;
};

function LocationDetail({
  orderLocation,
  timezone = "America/Los_Angeles",
  orderDate,
  orderType,
  flow,
  otherData = {},
  setIsCalculateAPIRequiredToCall = null,
  updateTime = false,
  setUpdateTime = null,
  isGroupOrder = false,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState("");
  const [showLocations, setShowLocations] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const locations = useSelector((state) => state.location);

  useEffect(() => {
    if (
      !locations.pickupLocations ||
      locations.pickupLocations.length === 0
    )
      dispatch(GetPickUpLocationsAPI());
    if (
      !locations.deliveryLocations ||
      locations.deliveryLocations.length === 0
    )
      dispatch(GetDeliveryLocationsAPI());
  }, []);
  useEffect(() => {
    // setShowTime(updateTime);
    if (updateTime) {
      updateOrderTime();
    }
  }, [updateTime]);

  const emptyCart = async () => {
    await ResetCartDetailAPI(dispatch);
    history.push("/locations");
  };
  const timeUpdated = () => {
    setIsCalculateAPIRequiredToCall(true);
    setShowTime(false);
    setUpdateTime(false);
  };
  const updateOrderTime = () => {
    let selectedLocation = GetCartLocationDetail();
    setSelectedLocation(selectedLocation[0]);
    setShowTime(!showTime);
  };
  return (
    <div className="px-2 px-md-4 py-3 location_info">
      {/* DELIVERY/PICKUP INFORMATION DIV */}

      <h2 className="text-capitalize BrandenGrotesqueBold font-size-1_3 text-grey1 border-bottom-1 pb-2">
        {orderType === "pickup" ? "pick Up " : "delivery "}
        information
      </h2>
      {flow === "Reorder" &&
        (otherData.destinationEnum === 2 ||
          otherData.destinationEnum === 0) && (
          <div
            className={`d-flex align-items-center border-bottom-1 py-3 ${
              otherData.destinationEnum === 2 ? "cursor-pointer" : ""
            } `}
          >
            <img
              src={DoorDashIcon}
              className={"delivery-info-icon"}
              alt={"InstructionsIcon"}
            />
            <div
              className="d-flex flex-column flex-1 px-2"
              onClick={() => {
                if (otherData.destinationEnum === 2) {
                  history.push("/track/" + otherData.deliveryId);
                }
              }}
            >
              <h3 className="BrownSTDBold font-size-0_9 text-grey1 mb-0 text-capitalize">
                Order Status
              </h3>
              <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1">
                {ORDER_STATUSES[otherData.status]}
              </p>
            </div>
            {orderType === "delivery" &&
              ORDER_STATUSES[otherData.status] !== ORDER_STATUSES.cancelled && (
                <p
                  onClick={() => {
                    if (otherData.destinationEnum === 2) {
                      history.push("/track/" + otherData.deliveryId);
                    }
                  }}
                  className="BrownSTDBold font-size-0_9 text-grey1 mb-1 mx-2"
                >
                  Track
                </p>
              )}
            {otherData.destinationEnum === 2 && <RightArrowComponent />}
          </div>
        )}

      <div
        onClick={() => {
          if (flow !== "Reorder" && !isGroupOrder) setShowLocations(true);
        }}
        className={`d-flex align-items-center border-bottom-1 py-3  ${
          flow !== "Reorder" ? "cursor-pointer" : ""
        } `}
      >
        <img
          src={LocationIcon}
          className={"delivery-info-icon"}
          alt={"InstructionsIcon"}
        />
        <div className="d-flex flex-column flex-1 px-2">
          <h3 className="BrownSTDBold font-size-0_9 text-grey1 mb-0 text-capitalize">
            {orderType === "pickup"
              ? orderLocation.locationName
              : orderLocation.locationName}
          </h3>
          <div className="d-flex justify-content-between align-items-center m-0 p-0">
            <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1">
              {orderType === "pickup"
                ? orderLocation.fullAddress
                : [
                    orderLocation.fullAddress,
                    orderLocation.floorAddress,
                    orderLocation.city,
                    orderLocation.state + " " + orderLocation.zipCode,
                  ]
                    .filter(Boolean)
                    .join(", ")}
            </p>
          </div>
        </div>
        {flow !== "Reorder" && !isGroupOrder && (
          <div className="d-flex align-items-center">
            <p className="m-0 p-0 mx-2 BrownSTDRegular font-size-0_9 text-grey1">
              Change
            </p>
            <RightArrowComponent />
          </div>
        )}
      </div>
      <div
        className="d-flex align-items-center border-bottom-1 py-3"
        onClick={() => {
          if (flow !== "Reorder") updateOrderTime();
        }}
      >
        <img
          src={TimeIcon}
          className={"delivery-info-icon"}
          alt={"InstructionsIcon"}
        />
        <div
          className={`d-flex flex-column flex-1 px-2 ${
            flow !== "Reorder" ? "cursor-pointer" : ""
          }`}
        >
          <h3 className="BrownSTDBold font-size-0_9 text-grey1 mb-0 text-capitalize">
            {orderType === "pickup" ? "Pick Up " : "Delivery "} date & time
          </h3>
          {orderDate ? (
            <>
              <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1">
                {moment
                  .tz(orderDate * 1000, timezone)
                  .format("dddd, MMMM DD, YYYY")}
              </p>
              <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1 mt-0">
                Estimated Time:{" "}
                {moment.tz(orderDate * 1000, timezone).format("LT")} -{" "}
                {moment
                  .tz(orderDate * 1000, timezone)
                  .add(15, "minutes")
                  .format("LT")}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
        {flow !== "Reorder" && <RightArrowComponent />}
      </div>
      {orderType === "delivery" && (
        <div
          className={`d-flex align-items-center border-bottom-1 py-3 ${
            flow !== "Reorder" ? "cursor-pointer" : ""
          }`}
          onClick={() => {
            if (flow !== "Reorder") setShowInstructions(true);
          }}
        >
          <img
            src={InstructionsIcon}
            className={"delivery-info-icon"}
            alt={"InstructionsIcon"}
          />
          <div className="d-flex flex-column flex-1 px-2">
            <h3 className="BrownSTDBold font-size-0_9 text-grey1 mb-0 text-capitalize">
              delivery instructions
            </h3>
            {orderLocation.instructions && (
              <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1">
                {orderLocation.instructions}
              </p>
            )}
          </div>
          {flow !== "Reorder" && <RightArrowComponent />}
        </div>
      )}

      {/* DELIVERY/PICKUP INFORMATION DIV */}
      {/* SHOW TIME DIV */}
      {showTime && (
        <div className="locations_div cart_locations_div">
          <div className={`pb-4 px-2`}>
            <SelectTime
              setActionType={timeUpdated}
              locationType={orderType}
              selectedLocation={selectedLocation}
              parentPage={"cart"}
              addPrepTimeMinutes={
                otherData.addPrepTimeMinutes ? otherData.addPrepTimeMinutes : 0
              }
            />
          </div>
        </div>
      )}
      {/* SHOW TIME DIV */}
      {/* SHOW LOCATIONS DIV */}
      {showLocations && (
        <DecisionDialog
          showError={showLocations}
          text={
            "Changing Pick Up or Delivery locations will restart your order."
          }
          noOption={setShowLocations}
          yesOption={emptyCart}
          heading={"Alert"}
        />
      )}
      {/* SHOW TIME DIV */}
      {/* SHOW INSTRUCTIONS DIV */}
      {showInstructions && (
        <UpdateDeliveryInstructions
          instructions={orderLocation.instructions}
          setActionType={setShowInstructions}
        />
      )}
      {/* SHOW INSTRUCTIONS DIV */}
    </div>
  );
}

export default LocationDetail;
