import React from "react";

function OrderType({ displayType, setDisplayType }) {
  return (
    <div className="bg-white order_type w-100">
      <div className="d-flex border-grey4 width-390 rounded-full mx-auto height-44 border">
        <span
          className={`BrandenGrotesqueBold font-size-0_9 ${
            displayType === 1 ? "text-white active" : "text-white2"
          } h-100 cursor-pointer w-33 d-flex align-items-center justify-content-center border-right border-grey4 border border-top-0 border-bottom-0 border-start-0 text-uppercase`}
          onClick={() => setDisplayType(1)}
        >
          Mixt Orders
        </span>
        <span
          className={`BrandenGrotesqueBold font-size-0_9 ${
            displayType === 3 ? "text-white active" : "text-white2"
          } h-100 cursor-pointer w-34 d-flex align-items-center justify-content-center border-right border-grey4 border border-top-0 border-bottom-0 border-start-0 text-uppercase`}
          onClick={() => setDisplayType(3)}
        >
          Group Orders
        </span>
        <span
          className={`BrandenGrotesqueBold font-size-0_9 ${
            displayType === 2 ? "text-white active" : "text-white2"
          } h-100 cursor-pointer w-33 d-flex align-items-center justify-content-center text-uppercase`}
          onClick={() => setDisplayType(2)}
        >
          Favorites
        </span>
      </div>
    </div>
  );
}

export default OrderType;
