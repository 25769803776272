import React, { useState } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import DoorDashIcon from "../../assets/img/DoorDashIcon.svg";
import MixtLocationIcon from "../../assets/img/MixtLocationIcon.svg";
import DeliveryLocationIcon from "../../assets/img/DropOffLocation.svg";

function TrackingMap(props) {
  const [zoom] = useState(12);
  const [currentLatLng, setCurrentLatLng] = useState({
    lat: props.trackDetail.deliveryInfo.latitude
      ? props.trackDetail.deliveryInfo.latitude
      : 37.79055703923744,
    lng: props.trackDetail.deliveryInfo.longitude
      ? props.trackDetail.deliveryInfo.longitude
      : -122.40186705390119,
  });
  return (
    <Map
      center={currentLatLng}
      className={"tracking_map"}
      zoom={zoom}
      google={props.google}
    >
      <Marker
        className={"DoorDashIcon"}
        position={{
          lat: props.trackDetail.latitude,
          lng: props.trackDetail.longitude,
        }}
        icon={{
          url: DoorDashIcon,
          anchor: new props.google.maps.Point(39, 43),
          scaledSize: new props.google.maps.Size(39, 43),
        }}
      />
      {props.trackDetail.pickupInfo && (
        <Marker
          className={"userMarker"}
          position={{
            lat: props.trackDetail.pickupInfo.latitude,
            lng: props.trackDetail.pickupInfo.longitude,
          }}
          icon={{
            url: MixtLocationIcon,
            anchor: new props.google.maps.Point(39, 43),
            scaledSize: new props.google.maps.Size(39, 43),
          }}
        />
      )}
      <Marker
        className={"userMarker"}
        position={{
          lat: props.trackDetail.deliveryInfo.latitude,
          lng: props.trackDetail.deliveryInfo.longitude,
        }}
        icon={{
          url: DeliveryLocationIcon,
          anchor: new props.google.maps.Point(39, 43),
          scaledSize: new props.google.maps.Size(39, 43),
        }}
      />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(TrackingMap);
