import React, { useState } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Logo from "../../components/GeneralComponents/Logo";
import { useHistory } from "react-router-dom";
import { RewardAPI } from "../../actions/Auth/Auth";
import { CircularProgress } from "@material-ui/core";

function RewardForm() {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE LOCAL STATE
  const [rewardCode, setRewardCode] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    rewardCode: "",
  });
  const [generalError, setGeneralError] = useState("");
  const [loader, setLoader] = useState(false);

  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);

    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (rewardCode === "") {
      errors.rewardCode = "Reward Code is required";
    }
    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (errors.rewardCode === "") {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");

      // CALL API
      let data = RewardAPI({ code: rewardCode });
      data = await Promise.resolve(data);

      // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
      if (data && data.generalError) {
        setGeneralError(data.generalError);

        // HIDE LOADER
        setLoader(false);
      } else {
        // REDIRECT TO DASHBOARD
        openOtherForm("/dashboard?reward_earned=true");
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  // OPEN OTHER FORM
  const openOtherForm = (page) => {
    history.push(page);
  };

  return (
    <Row className="mb-5">
      <Col className="width-326 px-0">
        <Logo />
        <h3
          className={
            "text-center text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_7 cmt-50 cmb-25"
          }
          as="h3"
        >
          Digital Reward
        </h3>
        {generalError && <Alert variant={"danger"}>{generalError}</Alert>}
        <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-4 text-center">
          Have a Digital Reward code? Enter it below to earn your reward.
        </p>
        <Form>
          <Row className={"flex-column"}>
            <Col className="px-0">
              <Text
                name={"rewardCode"}
                label={"Digital Reward Code (Optional)"}
                placeholder={"Enter Reward Code"}
                required={true}
                value={rewardCode}
                setValue={setRewardCode}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
              />
            </Col>
            <Button
              className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto"
              type="button"
              variant=""
              onClick={submitForm}
            >
              {loader ? (
                <CircularProgress size={24} color={"inherit"} />
              ) : (
                "Submit"
              )}
            </Button>
          </Row>
        </Form>
        <Row className={"flex-column width-326"}>
          <Col className="px-0 text-center">
            <Button
              onClick={() => openOtherForm("/dashboard")}
              variant=""
              className={`BrownSTDRegular bg-white border-0 font-size-0_9 text-grey1 cmt-30`}
            >
              Skip
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default RewardForm;
