import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Logo from "../../components/GeneralComponents/Logo";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { GuestUser, VerifyStartUpPassword } from "../../actions/Auth/Auth";
import { DecryptReduxAuth } from "../../actions/General/General";
import { CustomFunc } from "../../actions/RSA/Rsa";

const PasswordPrompt = ({ children }) => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [password, setPassword] = useState("");

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  const createGuestLogin = async () => {
    if (auth.serverPublicKey === "") {
      await CustomFunc();
      await GuestUser(dispatch);
    }
  };
  useEffect(() => {
    createGuestLogin();
  }, []);
  // Get environment-specific session key based on URL
  const getEnvKey = () => {
    const currentUrl = window.location.href;
    return currentUrl.includes("staging-order.mixt.com")
      ? "authenticated_staging"
      : currentUrl.includes("mixt-web.inv8mixtdev.com")
      ? "authenticated_developing"
      : currentUrl.includes("localhost:")
      ? "authenticated_local"
      : null;
  };

  // Check for stored session and set prompt visibility
  useEffect(() => {
    const envKey = getEnvKey();
    if (envKey && !sessionStorage.getItem(envKey)) {
      setShowPrompt(true);
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  // Validate password
  const submitForm = async () => {
    setLoader(true);
    const result = await VerifyStartUpPassword(password);
    if (result.generalError) {
      setLoader(false);
      setErrorMessages({ ...errorMessages, password: "Password is incorrect" });
    } else {
      setLoader(false);
      const envKey = getEnvKey();
      if (envKey) sessionStorage.setItem(envKey, "true");
      setIsAuthenticated(true);
      setShowPrompt(false);
    }
  };

  return (
    <>
      {showPrompt && (
        <Container>
          <Row>
            <Col className="px-0 py-5 px-5">
              <Logo />
              <h1 className="text-center text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_7 cmt-50 cmb-80">
                Making sure it’s you
              </h1>
              <Form
                className="width-340 mx-auto"
                onSubmit={(e) => e.preventDefault()}
              >
                <Row className="flex-column">
                  <Col className="px-0">
                    <Text
                      name="password"
                      label="Password"
                      placeholder="Password"
                      value={password}
                      type="password"
                      required={true}
                      setValue={setPassword}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Button
                    className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto"
                    type="submit"
                    variant=""
                    onClick={submitForm}
                    disabled={password === ""}
                  >
                    {loader ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {isAuthenticated && children}
    </>
  );
};

export default PasswordPrompt;
