import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// AUTH PAGES
import Login from "../views/Auth/Login";
import Decider from "../views/Auth/Decider";
import Signup from "../views/Auth/Signup";
import ResetPassword from "../views/Auth/ResetPassword";
import Reward from "../views/Auth/Reward";

// CAMPAIGN PAGES
import Dashboard from "../views/Dashboard";

// PROFILE PAGES
import Profile from "../views/Auth/Profile";

// LOCATION PAGES
import Locations from "../views/Locations/Map.js";

// Menu PAGES
import Menu from "../views/Menu/Menu.js";

// Cart PAGES
import Cart from "../views/Cart/Cart.js";
import GroupLink from "../views/Cart/GroupLink.js";

// ORDER PAGES
import Orders from "../views/Orders/Orders";
import TrackOrder from "../views/Orders/TrackOrder";

// PAYMENT SETTING PAGES
import Payment from "../views/Cart/Payment";

// LOGOUT PAGE
import Logout from "../views/Logout";

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "nc-icon nc-bell-55",
    component: Locations,
    authRequired: 0,
    layout: "/",
  },
  {
    path: "go",
    name: "Group Link",
    icon: "nc-icon nc-bell-55",
    component: GroupLink,
    authRequired: 2,
    layout: "/",
  },
  {
    path: "signin",
    name: "Login",
    icon: "nc-icon nc-bell-55",
    component: Login,
    authRequired: 0,
    layout: "/",
  },
  {
    path: "signup",
    name: "Register",
    icon: "nc-icon nc-bell-55",
    component: Signup,
    authRequired: 0,
    layout: "/",
  },
  {
    path: "reset_password",
    name: "Reset Password",
    icon: "nc-icon nc-bell-55",
    component: ResetPassword,
    authRequired: 0,
    layout: "/",
  },
  {
    path: "reward",
    name: "Reward",
    icon: "nc-icon nc-bell-55",
    component: Reward,
    authRequired: 1,
    layout: "/",
  },
  {
    path: "logout",
    name: "Log out",
    icon: <ExitToAppIcon />,
    component: Logout,
    authRequired: 1,
    layout: "/",
  },
  // LOCATIONS ROUTES
  {
    path: "locations",
    name: "Locations",
    component: Locations,
    authRequired: 2,
    layout: "/",
  },
  // Menu ROUTES
  {
    path: "menu",
    name: "Menu",
    component: Menu,
    authRequired: 2,
    layout: "/",
  },
  // Decider Page
  {
    path: "decider",
    name: "Decider",
    component: Decider,
    authRequired: 2,
    layout: "/",
  },
  // CART ROUTES
  {
    path: "cart",
    name: "Cart",
    component: Cart,
    authRequired: 1,
    layout: "/",
  },
  {
    path: "cards",
    name: "Payment",
    component: Payment,
    authRequired: 1,
    layout: "/",
  },
  // ORDERS ROUTES
  {
    path: "orders",
    name: "Orders",
    icon: "nc-icon nc-bell-55",
    component: Orders,
    authRequired: 1,
    layout: "/",
  },
  {
    path: "track",
    name: "Track Order",
    icon: "nc-icon nc-bell-55",
    component: TrackOrder,
    authRequired: 1,
    layout: "/",
  },
  // CAMPAIGN ROUTES
  {
    path: "dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bell-55",
    component: Dashboard,
    authRequired: 1,
    layout: "/",
  },
  // PROFILE ROUTES
  {
    path: "profile",
    name: "Profile",
    icon: "nc-icon nc-bell-55",
    component: Profile,
    authRequired: 1,
    layout: "/",
  },
];

export default routes;
