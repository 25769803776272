import React from "react";

function ImgComponent() {
  return (
    <div
      className="authImage"
    ></div>
  );
}

export default ImgComponent;
