import React, { useEffect, useState } from "react";
import {
  ResetCartDetailAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/cart.css";
import { CircularProgress } from "@material-ui/core";
import OrderDetail from "./OrderDetail";
import OrderPrice from "./OrderPrice";
import Suggestions from "./Suggestions";
import LocationDetail from "./LocationDetail";
import Rewards from "./Rewards";
import { useHistory } from "react-router-dom";
import MenuItemDetail from "../Menu/MenuItemDetail";
import MessageDialog from "../MessageDialog";
import {
  UpdateUserGroupOrderItems,
} from "../../actions/Cart/Cart";
import {
  CalculateTotalAmountOfOrder,
  CreateGoogleAnalyticsAPI,
} from "../../actions/General/General";
import { Button } from "react-bootstrap";
import {
  CalculateOrder,
  GetOrderType,
  RefreshCartItems,
  UpdateGroupItem,
} from "./cartFunctions";
import CheckOutDetail from "./CheckOutDetail";
import PaymentModel from "./PaymentModel";
import {
  GetPaymentCardAPI,
  UpdatePaymentCardsList,
} from "../../actions/Card/Card";
import GuestCartCreated from "./GuestCartCreated";
import { ProfileReducer } from "../../redux/reducers/ProfileReducer";
const ProfileActions = ProfileReducer.actions;

function Cart(props) {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE
  const history = useHistory();

  const dispatch = useDispatch();

  // CREATE LOCAL STATE VARIABLE
  const [orderFlow, setOrderFlow] = useState("");

  const [orderDetailData, setOrderDetailData] = useState({});
  const [loader, setLoader] = useState(true);
  const [editId, setEditId] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [unavailableGroupOrderData, setUnavailableGroupOrderData] = useState({});
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [updateTime, setUpdateTime] = useState(false);
  const [displayContentType, setDisplayContentType] = useState("");
  const [isCalculateAPIRequiredToCall, setIsCalculateAPIRequiredToCall] =
    useState(false);
  const [notAvailableItemId, setNotAvailableItemId] = useState("");
  // GET ORDER DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const calculatedOrderData = useSelector(
    (state) => state.order.calculated_order
  );
  const profile = useSelector((state) => state.profile);
  const groupOrderDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const location = useSelector((state) => state.menu.location_info);

  useEffect(async () => {
    CreateGoogleAnalyticsAPI("CartView", {});
    let orderType = GetOrderType(orderData);
    setOrderFlow(orderType);
    if (
      orderType === "GroupParticipantNoItems" ||
      orderType === "NormalNoItems"
    )
      history.push("dashboard");
    else {
      let order_location_id = null;
      if (orderData.mixtLocationId) {
        order_location_id = orderData.mixtLocationId;
      }
      if (
        orderData.doordashLocationId &&
        (order_location_id === null ||
          orderData.doordashLocationId < orderData.mixtLocationId)
      ) {
        order_location_id = orderData.doordashLocationId;
      }
      setLocationId(order_location_id);
      if (orderData && orderData.items.length > 0)
        await CalculateOrder(
          orderData,
          location.timezone,
          setOrderDetailData,
          setErrorTitle,
          setErrorMessage,
          setErrorType,
          setNotAvailableItemId,
          dispatch,
          setUnavailableGroupOrderData
        );
      if (
        orderData &&
        orderData.isGroupOrder &&
        !orderData.bOrderAsParticipant
      ) {
        callRefreshAfterEach30Sec();
      }
      setLoader(false);
    }
  }, []);
  const callRefreshAfterEach30Sec = async () => {
    if (orderData && orderData.isGroupOrder && !orderData.bOrderAsParticipant)
      if (
        displayContentType === "" &&
        editId === "" &&
        props.location.pathname === "/cart"
      ) {
        let isCallAPI = await RefreshCartItems(dispatch);
        if (isCallAPI) setIsCalculateAPIRequiredToCall(true);
      }
  };

  useEffect(async () => {
    // CALL SUGGESTIONS, REWARDS, CALCULATE API
    let responses = await GetPaymentCardAPI();

    // UPDATE CARDS LIST IN REDUX
    dispatch(
      UpdatePaymentCardsList(responses && responses.data ? responses.data : [])
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      callRefreshAfterEach30Sec();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const orderItemUpdated = async (val) => {
    await UpdateGroupItem(editId, dispatch);
    setEditId(val);
    // setIsCalculateAPIRequiredToCall(true);
    // setLoader(true);
    // await CalculateOrder(
    //   orderData,
    //   location.timezone,
    //   setOrderDetailData,
    //   setErrorTitle,
    //   setErrorMessage,
    //   setErrorType,
    //   setNotAvailableItemId,
    //   dispatch
    // );

    setTimeout(() => {
      setLoader(false);
      setIsCalculateAPIRequiredToCall(true);
    }, 1000);
  };

  useEffect(async () => {
    if (
      calculatedOrderData &&
      orderData.discounts &&
      orderData.discounts.length > 0 &&
      parseFloat(orderData.discounts[0].amount).toFixed(2) !==
        parseFloat(calculatedOrderData.discount).toFixed(2) &&
      !loader
    ) {
      setLoader(true);
      await CalculateOrder(
        orderData,
        location.timezone,
        setOrderDetailData,
        setErrorTitle,
        setErrorMessage,
        setErrorType,
        setNotAvailableItemId,
        dispatch,
        setUnavailableGroupOrderData
      );
      setLoader(false);
      setIsCalculateAPIRequiredToCall(false);
    }
  }, [orderData, calculatedOrderData, loader]);

  useEffect(async () => {
    if (
      Object.keys(orderData).length > 0 &&
      orderData.items.length > 0 &&
      !loader &&
      errorType === "" &&
      isCalculateAPIRequiredToCall
    ) {
      setLoader(true);
      await CalculateOrder(
        orderData,
        location.timezone,
        setOrderDetailData,
        setErrorTitle,
        setErrorMessage,
        setErrorType,
        setNotAvailableItemId,
        dispatch,
        setUnavailableGroupOrderData
      );
      setLoader(false);
      setIsCalculateAPIRequiredToCall(false);
    }
  }, [orderData, isCalculateAPIRequiredToCall, errorType]);

  
  useEffect(async () => {
    if ( isCalculateAPIRequiredToCall &&
      Object.keys(orderData).length > 0 && unavailableGroupOrderData.length===0
    ) {
      setLoader(true);
      await CalculateOrder(
        orderData,
        location.timezone,
        setOrderDetailData,
        setErrorTitle,
        setErrorMessage,
        setErrorType,
        setNotAvailableItemId,
        dispatch,
        setUnavailableGroupOrderData
      );
      setLoader(false);
      setIsCalculateAPIRequiredToCall(false);
    }
  }, [unavailableGroupOrderData,isCalculateAPIRequiredToCall]);

  const closeErrorMessage = async () => {
    if (errorType === "time") {
      let orderItem = _.cloneDeep(orderData);
      orderItem.orderDate = orderDetailData.orderDate;

      // UPDATE DATA IN REDUX STATE
      dispatch(UpdateOrderDetailAPI(orderItem));
    } else if (errorType === "changeTime") {
      setIsCalculateAPIRequiredToCall(false);
      setUpdateTime(true);
    } else if (errorType === "itemAvailability") {
      // call the api here to remove ids
      
      let orderItem = _.cloneDeep(orderData);
      let items = orderItem.items;
      items = items.filter(
        (singleItem) => singleItem.itemIdBrink !== parseInt(notAvailableItemId)
      );
      items.map(
        (singleItem) =>
          (singleItem.modifierItems = singleItem.modifierItems.filter(
            (singleModifierItem) =>
              singleModifierItem.itemIdBrink !== parseInt(notAvailableItemId)
          ))
      );
      orderItem.items = items;

      if (items.length === 0 && !orderItem.isGroupOrder) {
        await ResetCartDetailAPI(dispatch);
        history.push("/menu");
      } else dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
      setNotAvailableItemId("");
    
      if (unavailableGroupOrderData) {
      const excludedIdGroupOrderItems = unavailableGroupOrderData?.excludedIdGroupOrderItem || [];
      const excludedUserIds = unavailableGroupOrderData?.excludedUserIds || [];
      if (excludedIdGroupOrderItems.length === excludedUserIds.length) {
        for (let i = 0; i < excludedIdGroupOrderItems.length; i++) {
          // Filter items based on the condition
          const filteredItems = unavailableGroupOrderData?.updatedOrderData?.items?.filter(
            (item) => item.idGroupOrderItems === excludedIdGroupOrderItems[i]
          );
      
        let data = {
            userId: excludedUserIds[i],
            items: filteredItems,
        };
          await UpdateUserGroupOrderItems(data, excludedIdGroupOrderItems[i]);
        }
      }
      
    
      // Reset state after processing
      setUnavailableGroupOrderData({});
        setIsCalculateAPIRequiredToCall(true);
    }
    
    } else if (errorType === "more items added") {
      setIsCalculateAPIRequiredToCall(true);
    } else if (errorType === "phone") {
      let userProfile_local = _.cloneDeep(profile);
      userProfile_local.isFirstLogin = true;
      dispatch(ProfileActions.updateUser(userProfile_local));
      history.push("/profile");
    } else if (errorType === "order cancelled") {
      await ResetCartDetailAPI(dispatch);
      history.push("/dashboard");
    }
    setErrorType("");
    setErrorMessage("");
    setErrorTitle("");
  };

  const updatePaymentInfo = (percentage, amount, card_id = "") => {
    // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
    let orderItem = _.cloneDeep(orderData);
    // SET TIP PERCENTAGE
    if (percentage !== "" && percentage !== undefined && percentage !== null) {
      orderItem.payment_info = {
        ...orderItem.payment_info,
        tipName: percentage.toString(),
        tipAmount: amount,
        idPaymentCard: card_id
          ? parseInt(card_id)
          : orderItem.payment_info.idPaymentCard,
      };
    }
    orderItem.payment_info = CalculateTotalAmountOfOrder(orderItem);
    // UPDATE DATA IN REDUX STATE
    dispatch(UpdateOrderDetailAPI(orderItem));
    setIsCalculateAPIRequiredToCall(true);
  };
  return (
    <>
      {editId !== "" &&
      orderData &&
      orderData.items &&
      orderData.items.length >= editId ? (
        <MenuItemDetail
          itemId={orderData.items[editId].itemIdBrink}
          itemDetailData={{
            name: orderData.items[editId].itemName,
            priceCal: "",
            seasonal: false,
            itemUniqueId: orderData.items[editId].itemUniqueId,
          }}
          setSelectedItem={orderItemUpdated}
          isEdit={true}
          itemNo={editId}
        />
      ) : (
        orderData.orderType && (
          <div>
            {loader && (
              <div className="circular-progress-bar cart-circular-progress-bar">
                <CircularProgress />
              </div>
            )}
            <CheckOutDetail
              logged_in_user_id={profile.user_id}
              order_user_id={groupOrderDetail.user_id}
              isGroupOrder={orderData.isGroupOrder}
              orderFlow={orderFlow}
            />
            <div className="d-flex flex-column flex-md-row justify-content-center">
              <div
                className={`${
                  orderFlow === "GroupAdmin" || orderFlow === "Normal"
                    ? "width-390"
                    : "w-0"
                } mx-auto mx-md-0 cart_sections`}
              >
                {orderFlow !== "GroupParticipant" && (
                  <LocationDetail
                    orderLocation={
                      orderData.orderType === "pickup"
                        ? orderData.pickup_location_info
                        : orderData.delivery_info
                    }
                    orderType={orderData.orderType}
                    flow={orderFlow}
                    orderDate={orderData.orderDate}
                    timezone={location.timezone}
                    otherData={{
                      addPrepTimeMinutes: orderData.addPrepTimeMinutes,
                    }}
                    setIsCalculateAPIRequiredToCall={
                      setIsCalculateAPIRequiredToCall
                    }
                    updateTime={updateTime}
                    setUpdateTime={setUpdateTime}
                    isGroupOrder={orderData.isGroupOrder}
                  />
                )}
                {orderFlow !== "GroupParticipant" &&
                  orderFlow !== "Reorder" && (
                    <div className="d-none d-lg-block">
                      <Rewards
                        setErrorMessage={setErrorMessage}
                        setErrorType={setErrorType}
                        setIsCalculateAPIRequiredToCall={
                          setIsCalculateAPIRequiredToCall
                        }
                      />
                    </div>
                  )}
              </div>
              <div
                className={`${
                  orderFlow === "GroupAdmin" || orderFlow === "Normal"
                    ? "width-390"
                    : "cart_order_detail"
                }  mx-auto mx-md-0 cart_sections`}
              >
                <OrderDetail
                  hasUtensils={orderData.hasUtensils}
                  itemsList={orderData.items}
                  locationId={locationId}
                  orderId={orderData.orderId}
                  flow={orderFlow}
                  setEditId={setEditId}
                  setIsCalculateAPIRequiredToCall={
                    setIsCalculateAPIRequiredToCall
                  }
                />
                <div>
                  <Suggestions
                    locationId={locationId}
                    flow={orderFlow}
                    setIsCalculateAPIRequiredToCall={
                      setIsCalculateAPIRequiredToCall
                    }
                  />
                  {orderFlow !== "GroupParticipant" &&
                    orderFlow !== "Reorder" && (
                      <div className="d-block d-lg-none">
                        <Rewards
                          setErrorMessage={setErrorMessage}
                          setErrorType={setErrorType}
                          setIsCalculateAPIRequiredToCall={
                            setIsCalculateAPIRequiredToCall
                          }
                        />
                      </div>
                    )}

                  {orderDetailData &&
                    Object.keys(orderDetailData).length > 0 && (
                      <OrderPrice
                        pricingData={orderDetailData}
                        discounts={orderData.discounts}
                        orderData={orderData}
                        flow={orderFlow}
                        setDisplayContentType={setDisplayContentType}
                        setErrorMessage={setErrorMessage}
                        setErrorTitle={setErrorTitle}
                        setErrorType={setErrorType}
                        setIsCalculateAPIRequiredToCall={
                          setIsCalculateAPIRequiredToCall
                        }
                      />
                    )}
                  <div className="text-center">
                    <Button
                      onClick={() => history.push("/menu")}
                      variant=""
                      className={`text-capitalize BrownSTDRegular bg-white border-0 font-size-1_1 text-grey1 ${
                        orderFlow === "GroupParticipant" ? "mb-3 mt-2" : "my-3"
                      }`}
                    >
                      {orderFlow === "GroupParticipant"
                        ? "Add More Items"
                        : "view menu"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <MessageDialog
        showError={errorType ? true : false}
        text={errorMessage}
        closeDialog={closeErrorMessage}
        heading={errorTitle}
      />
      {displayContentType === "payment" && orderData.orderType && (
        <PaymentModel
          updatePaymentInfo={updatePaymentInfo}
          totalPrice={orderDetailData.displayTotalAmount}
          orderDestination={orderDetailData.destinationEnum}
          setDisplayContentType={setDisplayContentType}
          pricingData={orderDetailData}
          setIsCalculateAPIRequiredToCall={setIsCalculateAPIRequiredToCall}
        />
      )}
      {displayContentType === "orderCreatedSuccessFully" && (
        <GuestCartCreated flow={orderFlow} />
      )}
    </>
  );
}

export default Cart;
