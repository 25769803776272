import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";

function InviteLink() {
  const [showToolTip, setShowToolTip] = useState(false);
  const group_info = useSelector((state) => state.order.group_basic_detail);
  const profile = useSelector((state) => state.profile);
  return (
    <>
      {group_info.hasOwnProperty("user_id") &&
        group_info.user_id === profile.user_id && (
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
            <p className="BrownSTDRegular font-size-0_9 text-center mb-0">
              Group Order Invite Link:
              <span className="text-orange1 cursor-pointer ps-1">
                {group_info.link}
              </span>
            </p>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => {
                setShowToolTip(false);
              }}
              open={showToolTip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link Copied"
            >
              <span
                className="BrownSTDRegular font-size-0_9 text-grey3 cursor-pointer ps-sm-3"
                onClick={() => {
                  setShowToolTip(true);
                  navigator.clipboard.writeText(group_info.link);
                  setTimeout(() => {
                    setShowToolTip(false);
                  }, 2000);
                }}
              >
                Copy Link
              </span>
            </Tooltip>
          </div>
        )}
    </>
  );
}

export default InviteLink;
