import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDeliveryLocationsAPI,
  GetPickUpLocationsAPI,
} from "../../actions/Locations/Locations";
import MessageDialog from "../MessageDialog";
import MessagesList from "../../config/messages.json";
import DecisionDialog from "../DecisionDialog";
import SelectTime from "../Locations/SelectTime";
import {
  ResetCartDetailAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import {
  CalculateTotalAmountOfOrder,
  CreateNewOrderDataAPI,
} from "../../actions/General/General";
import { useHistory } from "react-router-dom";

function ReorderPreviousOrder({
  setDisplayContentType,
  items,
  orderType,
  locationId,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showEmptyCartOrder, setShowEmptyCartOrder] = useState(false);
  const [locationsFetched, setLocationsFetched] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorText, setErrorText] = useState("");
  const [updateOrderItems, setUpdateOrderItems] = useState(false);
  const [isItemNotAvailableError, setIsItemNotAvailableError] = useState("");

  const locationsList = useSelector((state) => state.location);
  const cartOrderDetail = useSelector(
    (state) => state.order.calculate_order_data
  );

  useEffect(() => {
    let haveAnyCurrentOrder = false;
    if (cartOrderDetail.orderType) {
      if (!cartOrderDetail.isGroupOrder && cartOrderDetail.items.length > 0) {
        haveAnyCurrentOrder = true;
      }
      if (
        cartOrderDetail.isGroupOrder &&
        cartOrderDetail.bOrderAsParticipant &&
        cartOrderDetail.items.length > 0
      ) {
        haveAnyCurrentOrder = true;
      }

      if (
        cartOrderDetail.isGroupOrder &&
        !cartOrderDetail.bOrderAsParticipant
      ) {
        haveAnyCurrentOrder = true;
      }
    }
    if (haveAnyCurrentOrder) {
      setShowEmptyCartOrder(true);
    } else {
      getLocationForReorder();
    }
  }, []);

  const getLocationForReorder = async () => {
    setShowEmptyCartOrder(false);
    await ResetCartDetailAPI(dispatch);
    if (locationsList.pickupLocations.length > 0) {
      let userSelectedLocation =
        orderType === "pickup"
          ? locationsList.pickupLocations.filter(
              (loc) => loc.idMixtLocation === locationId
            )
          : locationsList.deliveryLocations.filter(
              (loc) => locationId === loc.idUserDeliveryAddress
            );
      if (userSelectedLocation.length === 0) {
        setErrorTitle(MessagesList["location error Title"]);
        setErrorText(MessagesList[orderType + " location error"]);
      } else {
        setSelectedLocation(userSelectedLocation[0]);
        setShowTime(true);
      }
    } else {
      dispatch(GetDeliveryLocationsAPI());
      dispatch(GetPickUpLocationsAPI());
      setLocationsFetched(true);
    }
  };
  useEffect(() => {
    if (locationsFetched) getLocationForReorder();
  }, [locationsList]);

  const updateReorderTime = async (addItem, message) => {
    if (addItem) {
      setShowTime(false);
      setUpdateOrderItems(true);
    } else {
      setDisplayContentType("", message);
    }
  };

  const addItemsToCart = async () => {
    setShowTime(false);
    setUpdateOrderItems(true);
    let newOrderDetail = await CreateNewOrderDataAPI(
      items,
      cartOrderDetail,
      "reorder"
    );
    let orderDetail = _.cloneDeep(cartOrderDetail);
    if (!newOrderDetail.hasOwnProperty("message")) {
      orderDetail.items = newOrderDetail.items;
      orderDetail.payment_info = CalculateTotalAmountOfOrder(orderDetail);
      dispatch(UpdateOrderDetailAPI(orderDetail));
      setDisplayContentType("");
      history.push("/cart");
    } else {
      setErrorText(
        newOrderDetail.items.length > 0
          ? MessagesList["Failed to get some category menu items"]
          : MessagesList[newOrderDetail.message]
      );
      setErrorTitle(
        newOrderDetail.items.length > 0
          ? MessagesList["Failed to get some category menu items Title"]
          : MessagesList[newOrderDetail.message + " Title"]
      );
      if (newOrderDetail.items.length > 0) {
        setIsItemNotAvailableError("alertWithItem");
        orderDetail.items = newOrderDetail.items;
        orderDetail.payment_info = CalculateTotalAmountOfOrder(orderDetail);
        dispatch(UpdateOrderDetailAPI(orderDetail));
      } else {
        setIsItemNotAvailableError("alert");
      }
    }
  };

  useEffect(() => {
    if (!errorTitle && isItemNotAvailableError === "alertWithItem") {
      setIsItemNotAvailableError("");
      history.push("/cart");
    } else if (!errorTitle && isItemNotAvailableError === "alert") {
      setIsItemNotAvailableError("");
      setDisplayContentType("");
    }
  }, [errorTitle]);

  useEffect(() => {
    if (updateOrderItems) addItemsToCart();
  }, [updateOrderItems]);
  return (
    <div>
      {showTime && (
        <div className="locations_div location_reorder_div reorder_time_slots">
          <div className={`pb-4 px-2`}>
            <SelectTime
              setActionType={updateReorderTime}
              locationType={orderType}
              selectedLocation={selectedLocation}
              parentPage={"reorder"}
            />
          </div>
        </div>
      )}

      <MessageDialog
        showError={errorTitle ? true : false}
        text={errorText}
        closeDialog={setErrorTitle}
        heading={errorTitle}
      />
      <DecisionDialog
        showError={showEmptyCartOrder}
        text={
          cartOrderDetail.isGroupOrder && !cartOrderDetail.bOrderAsParticipant
            ? "Reordering will clear your group order. This cannot be undone."
            : "Reordering will clear your current cart"
        }
        noOption={setDisplayContentType}
        yesOption={getLocationForReorder}
        yesButtonText={
          cartOrderDetail.isGroupOrder && !cartOrderDetail.bOrderAsParticipant
            ? "Cancel Group Order"
            : ""
        }
        cancelButtonText={
          cartOrderDetail.isGroupOrder && !cartOrderDetail.bOrderAsParticipant
            ? "Do Not Cancel"
            : ""
        }
        heading={"Reorder"}
      />
    </div>
  );
}

export default ReorderPreviousOrder;
