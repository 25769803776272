import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function GroupMenu({ show, setGroupOrderMenuClicked }) {
  const history = useHistory();
  // OPEN PAGE
  const openPage = (page) => {
    history.push(page);
    setGroupOrderMenuClicked(false);
  };

  return (
    <>
      {show ? (
        <ClickAwayListener
          onClickAway={() => {
            setTimeout(() => {
              setGroupOrderMenuClicked(false);
            }, 100);
          }}
        >
          <div className="user-menu group-menu py-3 px-4">
            <div className="menuItems">
              <section>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => openPage("/locations?is_group=true")}
                >
                  Create Group Order
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => openPage("/orders?is_group=true")}
                >
                  View Group Orders
                </p>
              </section>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </>
  );
}
