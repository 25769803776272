export const CUSTOM_VALIDATIONS = { email: "EMAIL", password: "PASSWORD" };
export const MENU_ALLERGEN_FREE_TEXT =
  "*We can't guarantee items are allergen free";
export const MICRO_SERVICES = {
  USERS: "/users",
  LOCATIONS: "/locations",
  MENUS: "/menus",
  ORDERS: "/orders",
  DOORDASH: "/doordash",
};

// export const PUNCHH_URL = "https://sandbox.punchh.com/api2/mobile/";
// export const PUNCHH_PLATFORM_URL = "https://sandbox.punchh.com/api2/dashboard/";
// export const PUNCHH_ORDERING_URL = "https://sandbox.punchh.com/api/auth/";
export const DEFAULT_PROTEIN_KEY = "DefaultProtein";
export const MENU_ITEM_TYPE = {
  dressing: 1,
  included_protein: 2,
  container: 3,
  other: 4,
};
export const MENU_ITEM_MODIFIER_CODES = {
  EXTRA: 3,
  ADD: 14,
};
export const DEFAULT_TIP_PERCENTAGE = 15;
export const TIP_PERCENTAGES_LIST = [
  { key: "None", value: 0 },
  { key: "15%", value: 15 },
  { key: "20%", value: 20 },
  { key: "25%", value: 25 },
  { key: "Custom", value: -1 },
];
export const ORDER_STATUSES = {
  confirmed: "Confirmed",
  scheduled: "Confirmed",
  assigned: "Confirmed",
  picked_up: "Picked Up",
  enroute_to_dropoff: "Driver on the way",
  arrived_at_consumer: "Arrived at Dropoff",
  delivered: "Delivered",
  cancelled: "Cancelled",
  could_not_deliver: "Cancelled",
  placed: "Placed",
  submit: "Confirmed",
  open: "In Progress",
  cancel: "Cancelled",
};
