import { v4 as uuidv4 } from "uuid";

import {
  CalculateTotalAmountOfOrder,
  CreateGoogleAnalyticsAPI,
} from "../../actions/General/General";
import {
  DEFAULT_PROTEIN_KEY,
  MENU_ITEM_MODIFIER_CODES,
  MENU_ITEM_TYPE,
} from "../../config/config";
import store from "../../redux/store";
import MessagesList from "../../config/messages.json";
import {
  UpdateGroupOrderBasicDetailAPI,
  UpdateOrderDetailAPI,
  createGroupOrderDetailAPI,
  getGroupOrderDetailAPI,
} from "../../actions/Cart/Cart";
import { SetLocationForMenuAPI } from "../../actions/Menu/Menu";

// GET MODIFIER OR ITEM PRICE
const getModifierOrItemPrice = (priceMethod, modifierPrice, menuPrice) => {
  if (priceMethod === 1) {
    return modifierPrice;
  } else {
    return menuPrice;
  }
};

// CREATE INCLUDED PROTEIN ITEM DATA
const populateDefaultList = (
  defaultIngredients,
  modifiersList,
  defaultProteinId,
  ingredientsArray = {},
  isEdit = 0
) => {
  let brinkItemIdsArray = defaultIngredients.map((defaultItem) => {
    return {
      itemIdBrink: defaultItem.itemIdBrink,
      position: defaultItem.position,
    };
  });
  //LOOP OVER ALL MODIFIERS
  modifiersList.map((modifier) => {
    // CHECK MODIFIER ITEMS IF MODIFIER IS NOT BREAD/CONTAINER
    if (!modifier.showItemsAsBoolean) {
      // LOOP OVER ALL MODIFIER ITEMS
      modifier.modifierGroupItems.map((singleGroupItem) => {
        let groupItem = _.cloneDeep(singleGroupItem);
        // IF MODIFIER ITEM INCLUDED IN DEFAULT INGREDIENTS, THEN GET MODIFIER DETAIL

        let ingredientIndexInDefaultArray = brinkItemIdsArray.filter(
          (singleDefaultItem) =>
            singleDefaultItem.itemIdBrink === groupItem.menuItem.itemIdBrink
        );
        if (
          ingredientIndexInDefaultArray.length > 0 ||
          groupItem.menuItem.itemIdBrink === defaultProteinId
        ) {
          if (
            ingredientIndexInDefaultArray[0] !== undefined &&
            ingredientIndexInDefaultArray[0].position
          )
            groupItem.position = ingredientIndexInDefaultArray[0].position;
          let isIncludedProtein = false;
          let isDefault = false;

          // IF INGREDIENT EXIST IN MORE INGREDIENTS MODIFIER, THEN IT IS DEFAULT INGREDIENT
          if (modifier.isMoreIngredient) isDefault = true;

          // ben 2:01 AM 28 July 2022
          // Hi @Usman Nisar @Muhammad Kokab Waqas @Irfan Ali Zaidi @Mazhar Iqbal
          //  I hope you're all doing well. Following up, an update to Select Included Protein that I discussed with @Irfan Ali Zaidi yesterday:
          // We will now automatically select the included protein for the user based on the ingredients of the item. The user can then change the included protein using the dropdown.
          // See attached for apps and web (@Mazhar Iqbal)- this is what a user should see when viewing a menu item detail page that has an included protein without changing anything.

          // Sir Irfan 4:34 PM 2 Aug 2022
          // 1. Default Protein selection per item in Admin - adding a column in Admin Items Page, with dropdown picker per item (that has Select Included Protein modifier) to select
          // the default included selected protein that will appear in the apps and websites as default selection.
          if (groupItem.menuItem.itemIdBrink === defaultProteinId) {
            isIncludedProtein = true;
          }

          let itemType = isIncludedProtein
            ? "included_protein"
            : modifier.isDressing
            ? "dressing"
            : "other";

          if (isEdit === 0 || itemType === "other") {
            prepareItemDetailAndAdd(groupItem, modifier, ingredientsArray, {
              isDefault,
              itemType,
              isAddedFromDefaultList: 1,
            });
          }
          // delete brinkItemIdsArray[ingredientIndexInDefaultArray[0]];
        }
      });
    }
  });
  return ingredientsArray;
};

const makeIngredientItem = (item, modifierData) => {
  let ingredientItem = {
    idModifierGroupItems: item.idModifierGroupItems,
    itemIdBrink: item.itemIdBrink,
    itemphoto: item.itemphoto,
    idMenuItem: item.idMenuItem,
    name: item.name,
    calories: item.calories,
    modifierId: modifierData.idItemModifiers,
    isDressing: modifierData.isDressing,
    modifierGroupIdBrink: modifierData.modifierGroupIdBrink,
    modifierGroupName: modifierData.modifierGroupName,
    minimum: modifierData.minimum,
    noOfFree: modifierData.free,
    itemType: item.itemType,
    position: item.position,
    isIncludedProtein: item.isIncludedProtein,
    isDefault: item.isDefault,
    isAddedFromDefaultList: item.isAddedFromDefaultList,
    price: getModifierOrItemPrice(
      //PRICE CALCULATING METHOD IS CALLED TO GET PRICE ACCORDING TO PRICE METHOD
      item.priceMethod,
      item.price,
      item.menuItemPrice
    ),
  };
  return ingredientItem;
};

const AddItemToIngredientsList = (currentIngredientsList, addedItem) => {
  // TASK DISCUSSIONS
  // In customize view, if a default ingredient with item id x is removed from the item, do not charge for it if it is re-added.
  // If default ingredient with item id x is added again as extra, charge for it.

  // Usman, 12:30 PM 1 AUG 2022 SKYPE
  // for the case in document, client mentioned that an item can be only associated with only one modifier group in a menu. if found any item that has id same
  // but modifier group different then please do mentioned / share with client.
  // on more point was discussed. free count per modifier group. item added as new or extra will be charged only if its count is greater than free count

  // Syed, 4:58 PM 2 AUG 2022 SKYPE
  // 2. Secondly, I forgot to mention yesterday: A salad or bowl has the ingredient, say carrots. If a user selects more carrots in customization,
  // we would add the mod code EXTRA to carrots. However, if user selects another ingredient, say broccoli (not in default ingredients) we would use mod code ADD.
  // These mod codes need to appear correctly in the order summary before the item name (Brink formats like this by default). Let me know if this is clear.

  // LOGIC FLOW
  // CHECK IF ADDED ITEM HAS KEY ISINCLUDEDPROTEIN, THEN ADD/UPDATE THIS ITEM AS INCLUDED PROTEIN
  // CHECK IF ADDED ITEM HAS KEY iSDEFAULT, THEN ADD AS DEFAULT
  // CHECK ADDED ITEM WILL BE ADDED AS FREE OR PAID ITEM
  // ADD ITEM ACCORDING TO PRICE LOGIC

  // ADD FEW KEYS IS ADDED ITEM
  addedItem.count = 1;
  // addedItem.pricedCount = 0;
  addedItem.parentKey = addedItem.itemIdBrink;

  // ADDED ITEM MODIFIER DETAIL, ITS ADDED AS FREE OR PAID
  let addedItemModifierDetail = {
    modifierId: addedItem.modifierId,
    modifierGroupIdBrink: addedItem.modifierGroupIdBrink,
    modifierGroupName: addedItem.modifierGroupName,
    isPaidItem: addedItem.isDefault ? false : true,
    price: addedItem.price,
  };

  // STEP 1: CHECK IF ADDED ITEM HAS KEY ISINCLUDEDPROTEIN, THEN ADD/UPDATE THIS ITEM AS INCLUDED PROTEIN

  if (addedItem.isIncludedProtein) {
    // if (addedItem.noOfFree === 0) addedItem.pricedCount = 1;
    addedItem.parentKey = DEFAULT_PROTEIN_KEY;
    addedItem.modifierDataList = [addedItemModifierDetail];
    currentIngredientsList[DEFAULT_PROTEIN_KEY] = addedItem;
  } else {
    // STEP 2: CHECK IF ADDED ITEM HAS KEY isDefault, THEN ADD AS DEFAULT

    if (addedItem.isDefault) {
      addedItem.modifierDataList = [addedItemModifierDetail];
      currentIngredientsList[addedItem.itemIdBrink] = addedItem;
    } else {
      // STEP 3: CHECK ADDED ITEM WILL BE ADDED AS FREE OR PAID ITEM

      // CHECK WHETHER ITEM IS ADDED AS FREE ITEM OR NOT
      // IF MODIFIER KEY (FREE) VALUE IS GREATER THAN 0
      // AND
      // COUNT SELECTED INGREDIENT ITEM MODIFIER ID FROM SELECTED INGREDIENTS LIST BY LOOP OVER ALL IS LESS THAN MODIFIER FREE VALUE
      // IF BOTH ABOVE CONDITIONS ARE TRUE THEN ADD ITEM AS FREE ITEM

      let isMoreFreeIngredientsAllow = false;
      if (addedItem.noOfFree > 0) {
        isMoreFreeIngredientsAllow = true;
        let addedItemModifierId = addedItem.modifierId;
        let modifierSelectedItems = 0;

        // LOOP OVER ALL CURRENT INGREDIENTS TO COUNT TOTAL NUMBER OF MODIFIER ITEMS
        Object.values(currentIngredientsList).map((currentIngredient) => {
          // if (currentIngredient.modifierId === addedItemModifierId)
          //   modifierSelectedItems += currentIngredient.count;
          currentIngredient.modifierDataList &&
            currentIngredient.modifierDataList.length > 0 &&
            currentIngredient.modifierDataList.map((modifierItem) => {
              if (addedItemModifierId === modifierItem.modifierId)
                modifierSelectedItems += 1;
            });
        });

        // IF COUNT OF MODIFIER ITEMS IS EQUAL OR GREATER THAN  MODIFIER FREE ITEMS
        // MAKE ADD ITEM AS NOT A FREE ITEM
        if (modifierSelectedItems >= addedItem.noOfFree)
          isMoreFreeIngredientsAllow = false;
      }

      // UPDATE IS PAID ITEM KEY TO FALSE IF ITS ADDED AS FREE ITEM
      if (isMoreFreeIngredientsAllow) {
        addedItemModifierDetail.isPaidItem = false;
      }

      // GET SELECTED ITEM DETAIL FROM PREVIOUSLY SELECTED ITEMS LIST
      let selectedItemDetail = currentIngredientsList[addedItem.itemIdBrink];

      // STEP4: ADD ITEM ACCORDING TO PRICE LOGIC
      // IF SELECTED ITEM NOT EXIST IN CURRENTLY SELECTED ITEMS LIST THEN ASSIGN ADDED ITEM TO IT
      if (!selectedItemDetail) {
        addedItem.modifierDataList = [addedItemModifierDetail];
        selectedItemDetail = addedItem;
      } else {
        let modifiersDetailList = _.cloneDeep(
          selectedItemDetail.modifierDataList
        );

        modifiersDetailList.push(addedItemModifierDetail);

        selectedItemDetail.modifierDataList = _.cloneDeep(modifiersDetailList);
        // UPDATE DIFFERENT KEY IN SELECTED INGREDIENTS LIST
        selectedItemDetail.price = addedItem.price;
        selectedItemDetail.count = selectedItemDetail.count + 1;
      }
      // ADD ITEM IN SELECTED INGREDIENTS LIST
      currentIngredientsList[addedItem.itemIdBrink] = selectedItemDetail;
    }
  }

  // UPDATE COUNTER FOR EACH MODIFIER
  currentIngredientsList = updateModifiersCounter(currentIngredientsList);
  // RETURN UPDATED CURRENT INGREDIENTS LIST
  return currentIngredientsList;
};

const updateModifiersCounter = (currentIngredients, modifiers = null) => {
  let modifiersList =
    modifiers === null
      ? store.getState().menu.item_detail.modifiers
      : modifiers;
  let idsListOfModifier = [];

  // LOOP OVER ALL MODIFIERS TO GET SINGLE MODIFIER MULTIPLE IDS
  modifiersList &&
    modifiersList.map((modifierItem) => {
      let singleModifierIds = [];

      // ADD MODIFIER ID IN LIST
      singleModifierIds.push(modifierItem.brinkId);

      // IF ITS CUSTOM GROUP THEN ADD MERGED MODIFIERS IDS AS WELL
      if (
        modifierItem.hasOwnProperty("isCustomGroup") &&
        modifierItem.isCustomGroup
      ) {
        if (modifierItem.customGroupTypeOf === 1) {
          singleModifierIds.length = 0;
          singleModifierIds.push(modifierItem.brinkIdProtein);
        } else if (modifierItem.customGroupTypeOf === 2)
          singleModifierIds.push(modifierItem.brinkIdSubstitution);
      }

      // ADD SINGLE MODIFIER IDS IN MAIN IDS LIST
      idsListOfModifier.push(singleModifierIds);
    });

  let modifiersCounter = {};
  // LOOP OVER ALL INGREDIENT ITEMS TO GET COUNT OF EACH MODIFIER
  for (const item in currentIngredients) {
    // GET SINGLE SELECTED INGREDIENT DETAIL
    let ingredientItem = _.cloneDeep(currentIngredients[item]);

    if (item !== "modifiersCounter" && item == ingredientItem.itemIdBrink) {
      let modifierIdsArray = [];

      // GET SELECTED INGREDIENT FIRST MODIFIER ID
      let ingredientFirstSelectedItemModifier =
        ingredientItem.modifierDataList[0].modifierId;

      // GET MODIFIER PREVIOUS COUNTER
      let modifierCounterVal = modifiersCounter.hasOwnProperty(
        ingredientFirstSelectedItemModifier
      )
        ? modifiersCounter[ingredientFirstSelectedItemModifier]
        : 0;

      // ADD COUNT OF OTHER SELECTED INGREDIENTS
      modifierCounterVal += ingredientItem.count - ingredientItem.isDefault;

      // LOOP OVER ALL MODIFIER IDS TO CHECK THE OTHER MODIFIER IDS OF THAT INGREDIENT
      idsListOfModifier.map((singleModifierIdsList) => {
        if (
          singleModifierIdsList.indexOf(ingredientFirstSelectedItemModifier) >
          -1
        ) {
          modifierIdsArray = singleModifierIdsList;
        }
      });
      // LOOP OVER ALL MODIFIER ITEMS ARRAY AND UPDATE COUNTER
      modifierIdsArray.map((modifierIdsArrayItem) => {
        modifiersCounter[modifierIdsArrayItem] = modifierCounterVal;
      });
    }
  }
  // UPDATE COUNTERS OBJECT IN INGREDIENTS ARRAY
  currentIngredients.modifiersCounter = modifiersCounter;
  return currentIngredients;
};

const calculateOrderPrice = (ingredientsList, basicPrice) => {
  let price = basicPrice;
  for (const item in ingredientsList) {
    if (ingredientsList[item].hasOwnProperty("price")) {
      // GET MODIFIERS LIST OF SINGLE SELECTED ITEM
      let modifierDataList = ingredientsList[item].modifierDataList;

      modifierDataList.map((modifierItem) => {
        if (modifierItem.isPaidItem) price += modifierItem.price;
      });
    }
  }
  return price;
};

const calculateOrderCalories = (
  ingredientsList,
  defaultCalories,
  defaultIngredientsList
) => {
  let calories = 0;
  for (const item in ingredientsList) {
    if (ingredientsList[item].hasOwnProperty("calories")) {
      // GET MODIFIERS LIST OF SINGLE SELECTED ITEM

      let singleItemCalories = 0;
      if (ingredientsList[item].isIncludedProtein) {
        singleItemCalories = ingredientsList[item].calories;
      } else {
        singleItemCalories =
          ingredientsList[item].calories *
          (ingredientsList[item].count -
            ingredientsList[item].isAddedFromDefaultList);
      }
      calories += singleItemCalories;
    }
  }

  defaultIngredientsList &&
    defaultIngredientsList.map((defaultIngredient) => {
      if (
        !ingredientsList.hasOwnProperty(defaultIngredient.itemIdBrink) ||
        ingredientsList[defaultIngredient.itemIdBrink]
          .isAddedFromDefaultList === 0
      ) {
        calories -= defaultIngredient.calories;
      }
    });

  calories += defaultCalories;
  return calories;
};

const prepareItemDetailAndAdd = (
  modifierItem,
  modifier,
  ingredients,
  otherData
) => {
  // SET THE ITEM TYPE
  let type = otherData.hasOwnProperty("itemType")
    ? otherData.itemType
    : "other";

  // CHECK MODIFIER IS CUSTOM MODIFIER OR NOT
  let isCustomGroupModifier =
    modifier.hasOwnProperty("isCustomGroup") && modifier.isCustomGroup;

  // Syed Irfan Ali Zaidi, 30 AUGUST at 3:54 PM ON SKYPE
  // customGroupTypeOf: 2, // 1==Proteins, 2==Ingredients/Substitution
  let customGroupTypeOf = modifier.hasOwnProperty("customGroupTypeOf")
    ? modifier.customGroupTypeOf
    : "";

  let proteinModifierText = "";
  // IF MODIFIER IS CUSTOM MODIFIER AND ADDED ITEM IS NOT INCLUDED PROTEIN ITEM THEN SET PROTEIN MODIFIER TEXT
  if (
    isCustomGroupModifier &&
    customGroupTypeOf === 1 &&
    (!otherData.hasOwnProperty("itemType") ||
      otherData.itemType !== "included_protein")
  ) {
    proteinModifierText = "Protein";
  }

  if (
    isCustomGroupModifier &&
    customGroupTypeOf === 2 &&
    otherData.hasOwnProperty("isRemovedModifier") &&
    otherData.isRemovedModifier > -1
  ) {
    proteinModifierText = "Substitution";
  }

  let item = {
    isDefault:
      otherData.hasOwnProperty("isDefault") && otherData.isDefault ? 1 : 0,
    idModifierGroupItems:
      modifierItem["idModifierGroupItems" + proteinModifierText],
    itemIdBrink: modifierItem.menuItem.itemIdBrink,
    idMenuItem: modifierItem.menuItem.idMenuItem,
    name: modifierItem.menuItem.name,
    priceMethod: modifierItem["priceMethod" + proteinModifierText],
    price: modifierItem["price" + proteinModifierText],
    calories: modifierItem.menuItem.calories,
    menuItemPrice: modifierItem.menuItem.price,
    position: modifierItem.position,
    itemphoto: modifierItem.menuItem.itemphoto,
    itemType: MENU_ITEM_TYPE[type],
    isAddedFromDefaultList: otherData.hasOwnProperty("isAddedFromDefaultList")
      ? otherData.isAddedFromDefaultList
      : 0,
    isIncludedProtein:
      otherData.hasOwnProperty("itemType") &&
      otherData.itemType === "included_protein"
        ? 1
        : 0,
  };
  let modifierData = {
    idItemModifiers:
      isCustomGroupModifier && customGroupTypeOf === 1
        ? modifier.brinkIdProtein
        : modifier["brinkId" + proteinModifierText],
    isDressing: modifier.isDressing,
    modifierGroupIdBrink: modifier["brinkId" + proteinModifierText],
    modifierGroupName: modifier["displayName" + proteinModifierText],
    minimum: modifier["minimum" + proteinModifierText],
    free: modifier["free" + proteinModifierText],
  };

  let selectedMenuItem = makeIngredientItem(item, modifierData);

  let ingredientsArray = AddItemToIngredientsList(
    ingredients,
    selectedMenuItem
  );
  return ingredientsArray;
};

const RemoveItemFromIngredientsList = (
  currentIngredients,
  brinkId,
  isIncludedProtein
) => {
  // CHECK IF DELETED ITEM IS INCLUDED PROTEIN OR SOME ONE ELSE
  // IF ITS INCLUDED PROTEIN THE GET MODIFIER ID
  let removedItemModifierId = 0;
  if (isIncludedProtein) {
    // GET REMOVED INCLUDED
    removedItemModifierId =
      currentIngredients[DEFAULT_PROTEIN_KEY].modifierDataList[0].modifierId;

    // DELETE INCLUDED PROTEIN
    delete currentIngredients[DEFAULT_PROTEIN_KEY];

    brinkId = null;
    // LOOP OVER ALL SELECTED INGREDIENTS TO GET THE SELECTED PROTEIN ITEM IF ANY SELECTED
    for (const item in currentIngredients) {
      // GET SINGLE SELECTED INGREDIENT DETAIL
      let ingredientItem = _.cloneDeep(currentIngredients[item]);

      // IF INGREDIENT AND REMOVED INGREDIENT MODIFIER IS SAME THEN GET MODIFIER SELECTED PROTEIN ITEM ID
      if (
        ingredientItem.hasOwnProperty("modifierDataList") &&
        ingredientItem.modifierDataList.length > 0 &&
        ingredientItem.modifierDataList[0].modifierId === removedItemModifierId
      ) {
        // IF SELECTED INCLUDED PROTEIN IS REMOVED THEN GET OTHER PROTEIN ITEM BRINK ID TO ADD AS INCLUDED PROTEIN
        brinkId = ingredientItem.itemIdBrink;
        break;
      }
    }
  }

  // IF INGREDIENT ID IS NOT NULL THEN REMOVE THE INGREDIENT
  // LOGIC FLOW

  // TAKE ONE VARIABLE ISPRICECOUNTDECREASE
  // GET DELETED INGREDIENT MODIFIER ID
  // IF DEFAULT ITEM DELETED THEN ONLY REMOVED AND NO NEED TO CHECK FOR ANY PRICED ITEM OF THAT MODIFIER
  // UPDATE VARIABLES ACCORDING TO INGREDIENT COUNT AND PRICED COUNT
  // IF INGREDIENT COUNT IS ONE, THEN REMOVE THE INGREDIENT
  // IF PRICED COUNT VARIABLE IS FALSE, MEANS THAT FREE ITEM IS DELETED, WE WILL HAVE TO HAVE TO GET SAME MODIFIER PRICED ITEM AND MARK ONE ITEM OF THAT WILL BE FREE

  let defaultDeletedItemModifierId = null;
  if (brinkId !== null) {
    // TAKE ONE VARIABLE ISPRICECOUNTDECREASE
    let isPriceCountDecreased = false;

    // GET DELETED ITEM FROM INGREDIENTS LIST
    let currentIngredientsList = _.cloneDeep(currentIngredients);
    let deletedIngredient = currentIngredientsList[brinkId];

    // GET MODIFIER ID OF DELETED ITEM AS WE REMOVE LAST ADDED ITEM
    removedItemModifierId =
      deletedIngredient.modifierDataList[deletedIngredient.count - 1]
        .modifierId;

    if (deletedIngredient.isDefault && deletedIngredient.count === 1) {
      defaultDeletedItemModifierId =
        deletedIngredient.modifierDataList[0].modifierId;
      delete currentIngredients[brinkId];
      isPriceCountDecreased = true;
    } else {
      // IF PRICEDCOUNT > 0 THEN UPDATE PRICED COUNTER VARIABLE
      // if (deletedIngredient.pricedCount > 0) {
      //   deletedIngredient.pricedCount -= 1;
      //   currentIngredients[brinkId] = deletedIngredient;
      //   isPriceCountDecreased = true;
      // }

      // IF DELETED ITEM COUNT IS 1 THEN REMOVE THAT ITEM, OTHERWISE DECREASE COUNT OF DELETED ITEM
      if (deletedIngredient.count === 1) {
        // SET ISPRICECOUNTDECREASED AS TRUE IF REMOVED ITEM IS PAID ITEM OTHERWISE ITS FALSE, AND WE WILL MAKE ANY OTHER PAID ITEM OF THIS MODIFIER TO FREE
        isPriceCountDecreased =
          deletedIngredient.modifierDataList[0].isPaidItem;

        delete currentIngredients[brinkId];
      } else {
        // SET ISPRICECOUNTDECREASED AS TRUE IF REMOVED ITEM IS PAID ITEM OTHERWISE ITS FALSE, AND WE WILL MAKE ANY OTHER PAID ITEM OF THIS MODIFIER TO FREE
        isPriceCountDecreased =
          deletedIngredient.modifierDataList[deletedIngredient.count - 1]
            .isPaidItem;

        // GET MODIFIER LIST OF REMOVED ITEM
        let modifiersDetailList = _.cloneDeep(
          deletedIngredient.modifierDataList
        );

        // REMOVE LAST ITEM
        modifiersDetailList.pop();

        // UPDATE MODIFIERS LIST
        deletedIngredient.modifierDataList = _.cloneDeep(modifiersDetailList);

        // IF DELETED INGREDIENT IS DRESSING, THEN REMOVE ITS LAST DRESSING TYPE
        if (deletedIngredient.itemType === 1) {
          // GET MODIFIER LIST OF REMOVED ITEM
          let modifierCodesList = _.cloneDeep(deletedIngredient.modifierCodes);

          // REMOVE LAST ITEM
          if (modifierCodesList) modifierCodesList.pop();

          // UPDATE MODIFIERS LIST
          deletedIngredient.modifierCodes = _.cloneDeep(modifierCodesList);
        }
        // DECREASE COUNT AND UPDATE INGREDIENTS LIST
        deletedIngredient.count -= 1;
        currentIngredients[brinkId] = deletedIngredient;
      }
    }

    // IF PRICED COUNT IS NOT DECREASED, THEN GET SAME MODIFIER PRICED ITEM AND DECREASE PRICED COUNT
    if (!isPriceCountDecreased) {
      // LOOP OVER ALL INGREDIENTS
      for (const item in currentIngredientsList) {
        // GET SINGLE SELECTED INGREDIENT DETAIL
        let ingredientItem = _.cloneDeep(currentIngredientsList[item]);

        // LOOP OVER ALL MODIFIERS LIST TO MATCH WITH REMOVED INGREDIENT MODIFIER
        ingredientItem.modifierDataList &&
          ingredientItem.modifierDataList.map((ingredientModifierItem) => {
            // if (ingredientItem.modifierGroupIdBrink === 37166)
              if (
                ingredientModifierItem.modifierId === removedItemModifierId &&
                ingredientModifierItem.isPaidItem &&
                !isPriceCountDecreased
              ) {
                // MAKE FREE ITEM WHEN
                // REMOVED MODIFIER ID IS SAME AS MODIFIER LIST ITEM MODIFIER ID
                // MODIFIER LIST ITEM IS PAID ITEM
                // ANY PAID ITEM WILL NOT MAKE FREE BEFORE IN THIS FLOW
                ingredientModifierItem.isPaidItem = false;
                currentIngredients[item] = ingredientItem;
                isPriceCountDecreased = true;
              }
          });
      }
    }
  }

  currentIngredients = updateModifiersCounter(currentIngredients);
  // RETURN UPDATED CURRENT INGREDIENTS LIST
  return {
    currentIngredientsList: currentIngredients,
    includedProteinItemId: isIncludedProtein ? brinkId : null,
    defaultDeletedItemModifierId,
  };
};

const getIncludedProteinModifierId = (brinkId, modifiers) => {
  // GET MODIFIERS OF MENU AND LOOP OVER ALL MODIFIER TO GET SELECTED PROTEIN MODIFIER ID
  let modifierId = 0;
  let modifiersListOfMenu = modifiers;
  modifiersListOfMenu.map((modifierItem) => {
    if (modifierItem.minimum > 0)
      modifierItem.modifierGroupItems.map((ingredientItem) => {
        if (ingredientItem.menuItem.itemIdBrink === brinkId) {
          modifierId = modifierItem.brinkId;
        }
      });
  });
  return modifierId;
};

const getAddedAndRemovedItemsList = (
  defaultIngredients,
  currentIngredients
) => {
  // ADD DEFAULT INGREDIENTS LIST BRINK IDS TO ARRAY
  let brinkItemIdsArray = defaultIngredients.map(
    (defaultItem) => defaultItem.itemIdBrink
  );

  let addedList = [];
  let removedList = [];
  // LOOP OVER ALL CURRENT INGREDIENTS LIST TO GET THE ADDED INGREDIENTS LIST
  for (const item in currentIngredients) {
    // GET SINGLE SELECTED INGREDIENT DETAIL
    let ingredientItem = _.cloneDeep(currentIngredients[item]);

    // IF ADDED ITEM IS NORMAL ITEM ADDED
    if (ingredientItem.itemType === 4) {
      // IF INGREDIENT ID DOESN'T EXISTS IN DEFAULT INGREDIENT IDS ARRAY THEN ADD IN ADDED ARRAY
      // IF INGREDIENT ID EXISTS IN DEFAULT INGREDIENT IDS ARRAY AND COUNT > 1 THEN ADD IN ADDED ARRAY

      if (
        brinkItemIdsArray.indexOf(ingredientItem.itemIdBrink) === -1 ||
        (brinkItemIdsArray.indexOf(ingredientItem.itemIdBrink) > -1 &&
          ingredientItem.count > 1)
      ) {
        let text = ingredientItem.name;
        if (ingredientItem.count - ingredientItem.isDefault > 1)
          text +=
            " (" + (ingredientItem.count - ingredientItem.isDefault) + ")";
        addedList.push(text);
      }
    }
  }

  // LOOP OVER ALL DEFAULT INGREDIENTS LIST, MATCH WITH CURRENT INGREDIENTS LIST
  // IF ITEM NOT FOUND, THEN THAT ITEM IS DELETED

  defaultIngredients.map((defaultItem) => {
    if (!currentIngredients.hasOwnProperty(defaultItem.itemIdBrink)) {
      removedList.push(defaultItem.name);
    }
  });

  return {
    addedList: addedList.join(", "),
    removedList: removedList.join(", "),
  };
};

const checkModifierMinimumLimitAchieved = (
  currentIngredients,
  modifiersList
) => {
  let isMinimumLimitReached = true;
  let minimumLimitMessage = "";
  // LOOP OVER ALL MODIFIER TO CHECK INGREDIENT LIMIT IN SELECTED INGREDIENTS LIST
  modifiersList.map((modifier) => {
    // IF MODIFIER MINIMUM IS GREATER THEN 0
    if (modifier.minimum > 0 && minimumLimitMessage === "") {
      isMinimumLimitReached = false;
      let counter = 0;
      modifier.modifierGroupItems.map((ingredientItem) => {
        if (
          currentIngredients.hasOwnProperty(
            ingredientItem.menuItem.itemIdBrink
          ) ||
          (currentIngredients.hasOwnProperty(DEFAULT_PROTEIN_KEY) &&
            currentIngredients[DEFAULT_PROTEIN_KEY].itemIdBrink ===
              ingredientItem.menuItem.itemIdBrink)
        ) {
          if (
            currentIngredients.hasOwnProperty(
              ingredientItem.menuItem.itemIdBrink
            )
          )
            counter =
              counter +
              (currentIngredients[ingredientItem.menuItem.itemIdBrink].count -
                currentIngredients[ingredientItem.menuItem.itemIdBrink]
                  .isDefault);
          else
            counter =
              counter +
              (currentIngredients[DEFAULT_PROTEIN_KEY].count -
                currentIngredients[DEFAULT_PROTEIN_KEY].isDefault);
        }
      });
      if (counter >= modifier.minimum) {
        isMinimumLimitReached = true;
      } else {
        minimumLimitMessage =
          MessagesList["Select the minimum required item quantity"] +
          " " +
          modifier.minimum +
          " item from " +
          modifier.displayName;
      }
    }
  });

  return { status: isMinimumLimitReached, minimumLimitMessage };
};

const createOrderItemsData = (
  ingredients,
  defaultIngredients,
  itemDetail,
  modifiersList,
  previousOrderItemsArray,
  otherData,
  addToOrderItems = true
) => {
  // CREATE ORDER ITEMS OBJECT
  let orderItemObj = {
    modifierMethodType: otherData.modifierMethodType,
    menuItemIdBrink: otherData.categoryMenuItemIdBrink,
    itemName: itemDetail.name,
    itemIdBrink: otherData.itemIdBrink,
    price: otherData.basicPrice,
    quantity: otherData.orderQuantity,
    itemphoto: otherData.itemphoto,
    notes: otherData.name,
    itemTotal: parseFloat(otherData.totalPrice),
    isSuggestionItem: otherData.hasOwnProperty("isSuggestionItem")
      ? otherData.isSuggestionItem
      : false,
    categoryCustomType: otherData.categoryCustomType,
    itemCustomType: otherData.itemCustomType,
  };

  if (itemDetail.userId) {
    orderItemObj.idGroupOrderItems = itemDetail.idGroupOrderItems;
    orderItemObj.userId = itemDetail.userId;
  }
  let orderItemsArray = [];

  // LOOP OVER ALL SELECTED INGREDIENTS TO GET THE SELECTED PROTEIN ITEM IF ANY SELECTED
  for (const item in ingredients) {
    // GET SINGLE SELECTED INGREDIENT DETAIL
    let ingredientItem = _.cloneDeep(ingredients[item]);

    // IF SELECTED INGREDIENT IS INCLUDED PROTEIN, THEN GET ITS MODIFIER ID
    if (ingredientItem.isIncludedProtein) {
      ingredientItem.modifierGroupIdBrink = getIncludedProteinModifierId(
        ingredientItem.itemIdBrink,
        modifiersList
      );
    }

    // IF SELECTED INGREDIENT ITEM TYPE IS NOT 4 MEANS IT'S DRESSING, INCLUDED PROTEIN, BREAD OR CONTAINER THEN ADD IN ORDER ITEMS
    // IF SELECTED INGREDIENT IS NOT ADDED FROM DEFAULT INGREDIENT LIST THEN ADD IN ORDER ITEMS
    // IF SELECTED INGREDIENT IS ADDED FROM DEFAULT INGREDIENTS LIST AND COUNT IS GREATER THAN 1
    if (
      ingredientItem.hasOwnProperty("count") &&
      (ingredientItem.itemType !== 4 ||
        ingredientItem.isDefault === 0 ||
        (ingredientItem.isDefault === 1 && ingredientItem.count > 1))
    ) {
      // GET INGREDIENT COUNT
      // let addedItemsCount = ingredientItem.count;

      // IF ADDED ITEM IN NORMAL ITEM DECREASE ITS COUNT IF ITS EXIST IN DEFAULT INGREDIENTS LIST
      // if (ingredientItem.itemType === 4) {
      //   addedItemsCount -= ingredientItem.isAddedFromDefaultList;
      // }

      let modifiersDataList = ingredientItem.modifierDataList;

      for (
        let i = ingredientItem.isDefault;
        i < modifiersDataList.length;
        i++
      ) {
        // CREATE SINGLE ITEM OBJECT FOR ORDER
        let orderSingleItem = {
          itemIdBrink: ingredientItem.itemIdBrink,
          itemName: ingredientItem.name,
          itemPrice: modifiersDataList[i].isPaidItem
            ? modifiersDataList[i].price
            : 0,
          modifierCodeId: null,
          modifierGroupIdBrink: modifiersDataList[i].modifierGroupIdBrink,
          modifierGroupName: modifiersDataList[i].modifierGroupName,
          isRemoved: false,
          modifierOfType: ingredientItem.itemType,
          isFreeItem: !modifiersDataList[i].isPaidItem,
        };

        // SET MODIFIER TYPE
        if (ingredientItem.itemType === MENU_ITEM_TYPE["dressing"]) {
          orderSingleItem.modifierCodeId =
            ingredientItem.modifierCodes[i].brinkId;
          orderSingleItem.modifierCodeName =
            ingredientItem.modifierCodes[i].name;
        } else if (
          ingredientItem.itemType === MENU_ITEM_TYPE["included_protein"]
        ) {
          orderSingleItem.modifierCodeId = null;
        } else {
          if (ingredientItem.isDefault)
            orderSingleItem.modifierCodeId = MENU_ITEM_MODIFIER_CODES["EXTRA"];
          else orderSingleItem.modifierCodeId = MENU_ITEM_MODIFIER_CODES["ADD"];
        }

        // ADD INGREDIENT ITEM IN ORDER ITEMS
        orderItemsArray.push(orderSingleItem);
      }
    }
  }

  // ADD REMOVED ITEMS IN ORDER DETAIL
  // LOOP OVER ALL DEFAULT INGREDIENTS
  if (modifiersList.length > 0)
    defaultIngredients.map((ingredient) => {
      // IF DEFAULT INGREDIENT ITEM IS NOT EXISTS IN CURRENT INGREDIENTS LIST THEN ADD IN ORDER ITEMS LIST
      // IF DEFAULT INGREDIENT ITEM IS EXISTS IN CURRENT INGREDIENTS LIST AND THAT ITEM IS ADDED AGAIN THEN ADD IN ORDER ITEMS LIST
      if (
        !ingredients.hasOwnProperty(ingredient.itemIdBrink) ||
        (ingredients.hasOwnProperty(ingredient.itemIdBrink) &&
          ingredients[ingredient.itemIdBrink].isAddedFromDefaultList === 0 &&
          !ingredients[ingredient.itemIdBrink].isDressing)
      ) {
        let modifierDetail = getIngredientModifierDetail(
          ingredient.itemIdBrink,
          modifiersList
        );

        // CREATE SINGLE ITEM OBJECT FOR ORDER TO DELETE
        let orderSingleItem = {
          itemIdBrink: ingredient.itemIdBrink,
          itemName: ingredient.name,
          itemPrice: 0,
          modifierCodeId: 2,
          modifierGroupIdBrink: modifierDetail.modifierGroupIdBrink,
          modifierGroupName: modifierDetail.modifierGroupName,
          isRemoved: true,
          modifierOfType: 0,
        };

        // ADD INGREDIENT ITEM IN ORDER ITEMS
        orderItemsArray.push(orderSingleItem);
      }
    });

  orderItemObj.modifierItems = orderItemsArray;
  orderItemObj.itemUniqueId = itemDetail.itemUniqueId
    ? itemDetail.itemUniqueId
    : uuidv4();

  if (!previousOrderItemsArray) previousOrderItemsArray = [];
  if (otherData.isEdit)
    previousOrderItemsArray[otherData.itemNo] = orderItemObj;
  else previousOrderItemsArray.unshift(orderItemObj);
  previousOrderItemsArray;

  return addToOrderItems ? previousOrderItemsArray : orderItemObj;
};

const getIngredientModifierDetail = (brinkId, modifiersList) => {
  let updateItem = {};

  // LOOP OVER ALL MENU MODIFIER
  modifiersList.map((modifier) => {
    modifier.modifierGroupItems.map((modifierItem) => {
      // IF BRINK ID IS MATCHED THEN UPDATE ITEM
      if (modifierItem.menuItem.itemIdBrink === brinkId) {
        updateItem = {
          modifierGroupIdBrink: modifier.brinkId,
          modifierGroupName: modifier.name,
        };
      }
    });
  });
  return updateItem;
};

const rePopulateIngredientsFromOrderData = (
  previousSelectedIngredientsList,
  ingredientsList,
  modifiersList,
  defaultIngredientsList = []
) => {
  // LOOP OVER ALL INGREDIENT TO REPOPULATE
  previousSelectedIngredientsList.map((previousSelectedIngredientItem) => {
    let calories = 0;
    let itemPhoto = null;
    modifiersList.map((modifierItem) => {
      if (
        modifierItem.brinkId ===
          previousSelectedIngredientItem.modifierGroupIdBrink ||
        modifierItem.brinkIdSubstitution ===
          previousSelectedIngredientItem.modifierGroupIdBrink ||
        modifierItem.brinkIdProtein ===
          previousSelectedIngredientItem.modifierGroupIdBrink
      ) {
        modifierItem.modifierGroupItems.map((modifierGroupItem) => {
          if (
            modifierGroupItem.menuItem.itemIdBrink ===
            previousSelectedIngredientItem.itemIdBrink
          ) {
            calories = modifierGroupItem.menuItem.calories;
            itemPhoto = modifierGroupItem.menuItem.itemphoto;
          }
        });
      }
    });
    if (!previousSelectedIngredientItem.isRemoved) {
      let ingredientName =
        previousSelectedIngredientItem.modifierOfType === 2
          ? "DefaultProtein"
          : previousSelectedIngredientItem.itemIdBrink;
      // GET ITEM DETAIL

      let position = defaultIngredientsList.filter((item) => {
        if (item.itemIdBrink === ingredientName) return item.position;
      });
      if (Array.isArray(position) && position.length === 0) {
        position = 99999999;
      }
      if (Array.isArray(position) && position.length > 0) {
        position = position[0].position;
      }
      let itemDetail = ingredientsList.hasOwnProperty(ingredientName)
        ? ingredientsList[ingredientName]
        : {
            count: 1,
            parentKey: ingredientName,
            price: previousSelectedIngredientItem.itemPrice,
            isDefault: 0,
            isAddedFromDefaultList:
              defaultIngredientsList.filter(
                (item) => item.itemIdBrink === ingredientName
              ).length === 1
                ? 1
                : 0,
            position: position,
            calories: calories,
            itemphoto: itemPhoto,
            modifierId: previousSelectedIngredientItem.modifierGroupIdBrink,
            itemIdBrink: previousSelectedIngredientItem.itemIdBrink,
            name: previousSelectedIngredientItem.itemName,
            isDressing: previousSelectedIngredientItem.modifierOfType === 1,
            isIncludedProtein:
              previousSelectedIngredientItem.modifierOfType === 2 ? 1 : 0,
            itemType: previousSelectedIngredientItem.modifierOfType,
            modifierDataList: [],
            modifierGroupIdBrink:
              previousSelectedIngredientItem.modifierGroupIdBrink,
            modifierGroupName: previousSelectedIngredientItem.modifierGroupName,
          };

      // GET ADDED MODIFIERS LIST OF INGREDIENT
      let modifierDataCodes = itemDetail.modifierDataList;

      // ADD INGREDIENT MODIFIER ITEM
      modifierDataCodes.push({
        modifierId: previousSelectedIngredientItem.modifierGroupIdBrink,
        modifierGroupIdBrink:
          previousSelectedIngredientItem.modifierGroupIdBrink,
        modifierGroupName: previousSelectedIngredientItem.modifierGroupName,
        isPaidItem: !previousSelectedIngredientItem.isFreeItem,
        price: previousSelectedIngredientItem.itemPrice,
      });

      // IF ITS DRESSING THEN ADD ITS SELECTED DRESSING TYPE
      if (previousSelectedIngredientItem.modifierOfType === 1) {
        let dressingCode = itemDetail.hasOwnProperty("modifierCodes")
          ? itemDetail.modifierCodes
          : [];
        dressingCode.push({
          name: previousSelectedIngredientItem.modifierCodeName,
          brinkId: previousSelectedIngredientItem.modifierCodeId,
        });
        itemDetail.modifierCodes = dressingCode;
      }

      // UPDATE INGREDIENT MODIFIER CODES LIST
      itemDetail.modifierDataList = modifierDataCodes;

      itemDetail.count = modifierDataCodes.length;

      // UPDATE IN MAIN INGREDIENTS LIST
      ingredientsList[ingredientName] = itemDetail;
    } else {
      let ingredientName = previousSelectedIngredientItem.itemIdBrink;
      // GET ITEM DETAIL
      let itemDetail = ingredientsList[ingredientName];

      if (itemDetail) {
        if (itemDetail.count === 1) {
          delete ingredientsList[ingredientName];
        } else {
          let modifierDataCodes = itemDetail.modifierDataList;
          modifierDataCodes.shift();
          // UPDATE INGREDIENT MODIFIER CODES LIST
          itemDetail.modifierDataList = modifierDataCodes;

          itemDetail.count = modifierDataCodes.length;
        }
      }
    }
  });
  ingredientsList = updateModifiersCounter(ingredientsList);
  return ingredientsList;
};

const createEmptyCart = async (
  isGroupOrder = 0,
  locationObject = null,
  selected_time = 0,
  groupId = "",
  discounts = ""
) => {
  // let is_link_displayed = groupId === "" ? false : true;
  let is_link_displayed = false;
  if (locationObject === null)
    locationObject = { isGroupOrder: isGroupOrder === 1 ? true : false };
  let dispatch = store.dispatch;
  let profile = store.getState().profile;

  let bOrderAsParticipant = false;
  let orderLocation = {};
  if (locationObject && locationObject.hasOwnProperty("orderType")) {
    if (locationObject.orderType === "delivery") {
      orderLocation.delivery_info = {
        locationName: locationObject.name,
        fullAddress: locationObject.locationAddress,
        companyName: locationObject.companyName,
        idUserDeliveryAddress: parseInt(locationObject.idUserDeliveryAddress),
        floorAddress: locationObject.floorAddress,
        city: locationObject.city,
        zipCode: locationObject.zipCode,
        state: locationObject.state,
        latitude: locationObject.latitude,
        longitude: locationObject.longitude,
        instructions: locationObject.instructions,
      };
      if (locationObject.guestFullAddress) {
        orderLocation.delivery_info["guestFullAddress"] =
          locationObject.guestFullAddress;
      }
    } else {
      orderLocation.pickup_location_info = {
        locationName: locationObject.name,
        fullAddress: locationObject.locationAddress,
      };
    }
  }

  if (isGroupOrder) {
    let groupLink = "";
    if (groupId === "") {
      let groupOrderDetail = {
        orderType: locationObject.orderType,
        orderDate: selected_time,
        platform: "web",
        userId: profile.user_id,
        userName: profile.first_name + " " + profile.last_name,
        general_info: {
          doordashLocationId: locationObject.locationId2,
          mixtLocationId: locationObject.locationId1,
          hasUtensils: false,
          has_favorites: false,
          appVersion: "",
        },
        user_info: {
          userId: profile.user_id,
          userFirstName: profile.first_name,
          userLastName: profile.last_name,
          emailAddress: profile.email,
          phoneNumber: profile.phone,
          punchhAccessToken: profile.access_token,
        },
        ...orderLocation,
      };
      let createGroupResponse = await createGroupOrderDetailAPI(
        groupOrderDetail
      );
      if (createGroupResponse.generalError) {
        return createGroupResponse.generalError;
      }

      await CreateGoogleAnalyticsAPI("GroupOrderCreated", {
        unixTime: selected_time,
        orderTime: new Date(selected_time),
        creationTime: new Date(),
      });

      groupId = createGroupResponse.data.idGroupOrder;
      groupLink = createGroupResponse.data.linkUrl;
    } else {
      bOrderAsParticipant = true;
    }
    let groupOrderDetail = await getGroupOrderDetailAPI(groupId);

    if (groupOrderDetail.generalError) {
      return groupOrderDetail.generalError;
    }
    groupLink = groupOrderDetail.data.linkUrl;
    if (groupOrderDetail.data.orderType === "delivery") {
      orderLocation.delivery_info = groupOrderDetail.data.delivery_info;
      locationObject.menuShowAddress =
        groupOrderDetail.data.delivery_info.fullAddress;
    } else {
      orderLocation.pickup_location_info =
        groupOrderDetail.data.pickup_location_info;
      let address =
        groupOrderDetail.data.pickup_location_info.fullAddress.split(",");
      locationObject.menuShowAddress = address[0];
    }
    locationObject.selected_time = groupOrderDetail.data.orderDate;
    locationObject.orderType = groupOrderDetail.data.orderType;
    locationObject.locationId1 =
      groupOrderDetail.data.general_info.mixtLocationId;
    locationObject.locationId2 =
      groupOrderDetail.data.general_info.doordashLocationId;

    // Create/Fetch Group Order API call
    let groupOrderBasicDetail = {
      user_id: groupOrderDetail.data.user_info.userId,
      user_name:
        groupOrderDetail.data.user_info.userFirstName +
        " " +
        groupOrderDetail.data.user_info.userLastName,
      link: groupLink,
      is_link_displayed,
      idGroupOrder: groupId,
    };
    dispatch(UpdateGroupOrderBasicDetailAPI(groupOrderBasicDetail));
    if (groupOrderDetail.data.user_info.userId === profile.user_id) {
      bOrderAsParticipant = false;
    }
  }
  // SET MENU LOCATION
  dispatch(
    SetLocationForMenuAPI({
      locationId1: locationObject.locationId1,
      locationId2: locationObject.locationId2,
      orderType: locationObject.orderType,
      menuShowAddress: locationObject.menuShowAddress,
      estimatedTimeMinutes: locationObject.estimatedTimeMinutes,
      type:
        locationObject.locationId1 && locationObject.locationId2
          ? "common"
          : "single",
    })
  );
  // Create Empty Order
  let orderObject = {
    has_favorites: false,
    orderType: locationObject.orderType,
    isGroupOrder: locationObject.isGroupOrder
      ? locationObject.isGroupOrder
      : false,
    bOrderAsParticipant,
    platform: "web",
    mixtLocationId: locationObject.locationId1
      ? locationObject.locationId1
      : null,
    orderDate:
      selected_time !== 0 ? selected_time : locationObject.selected_time,
    user_info: {
      userId: profile.user_id,
      userFirstName: profile.first_name,
      userLastName: profile.last_name,
      emailAddress: profile.email,
      phoneNumber: profile.phone ? profile.country_code + profile.phone : "+1",
      punchhAccessToken: profile.access_token,
    },
    hasUtensils: false,
    ...orderLocation,
  };
  if (groupId) orderObject.groupOrderId = parseInt(groupId);
  orderObject.orderId = uuidv4();
  // UPDATE ORDER OBJECT TO ADD LOCATION DETAILS FOR BOTH DELIVERY AND PICKUP
  if (
    locationObject.orderType === "delivery" &&
    locationObject.location_id2 !== ""
  ) {
    orderObject.doordashLocationId = locationObject.locationId2;
  }
  // GET ORDER ITEMS KEY AS EMPTY ARRAY FOR NOW
  orderObject.items = [];
  // CREATE PAYMENT OBJECT KEY FOR ORDER OBJECT

  let payment_info = CalculateTotalAmountOfOrder(orderObject);
  orderObject.payment_info = payment_info;

  if (discounts) orderObject.discounts = discounts;
  // orderObject.tipAmount = payment_info.tipAmount;
  dispatch(UpdateOrderDetailAPI(orderObject));
  return "";
};
export {
  populateDefaultList,
  calculateOrderPrice,
  AddItemToIngredientsList,
  makeIngredientItem,
  getModifierOrItemPrice,
  prepareItemDetailAndAdd,
  RemoveItemFromIngredientsList,
  getAddedAndRemovedItemsList,
  getIncludedProteinModifierId,
  checkModifierMinimumLimitAchieved,
  createOrderItemsData,
  updateModifiersCounter,
  rePopulateIngredientsFromOrderData,
  calculateOrderCalories,
  createEmptyCart,
};
