import React from "react";
import JCB from "../../assets/img/jcb.png";
import DISCOVER from "../../assets/img/discover.png";
import DINNER from "../../assets/img/diner.png";
import MASTERCARD from "../../assets/img/mastercard.png";
import VISA from "../../assets/img/visa.png";
import AMEX from "../../assets/img/amex.png";
import PAYMENTCARD from "../../assets/img/paymentCard.svg";

function CardImage({ type, className = "" }) {
  return (
    <img
      className={`width-32 ${className ? className + " height-32" : ""}`}
      src={
        type === "amex"
          ? AMEX
          : type === "americanexpress"
          ? AMEX
          : type === "visa"
          ? VISA
          : type === "mastercard"
          ? MASTERCARD
          : type === "dinner"
          ? DINNER
          : type === "discover"
          ? DISCOVER
          : type === "jcb"
          ? JCB
          : type === "unionpay"
          ? VISA
          : type === "hypercard"
          ? VISA
          : type === "elo"
          ? VISA
          : PAYMENTCARD
      }
    />
  );
}

export default CardImage;
