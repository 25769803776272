import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import CartIcon from "../../views/Cart/CartIcon";

export default function GuestMenu({ setShowLoginModal }) {
  // GET AUTH AND PROFILE DATA FROM REDUX STATE
  const order = useSelector((state) => state.order);

  // CREATE HISTORY OBJECT
  const history = useHistory();

  // OPEN PAGE
  const openPage = (page) => {
    history.push(page);
  };

  return (
    <Row className="flex-1 d-flex justify-content-end align-items-center">
      <span
        onClick={() => openPage("/decider/1")}
        className="d-none d-md-block BrandenGrotesqueBold cursor-pointer text-uppercase text-brown1 font-size-1 cpl-39"
      >
        group order
      </span>
      <span
        onClick={() => openPage("/signin")}
        className="d-none d-md-block BrandenGrotesqueBold cursor-pointer text-uppercase text-brown1 font-size-1 cpl-39"
      >
        sign in
      </span>
      <span
        onClick={() => openPage("/signup")}
        className="d-none d-md-block BrandenGrotesqueBold cursor-pointer text-uppercase text-brown1 font-size-1 cpl-39"
      >
        sign up
      </span>
      <div className={`px-0 text-center d-block cpl-39`}>
        <CartIcon isDashboard={false} setShowLoginModal={setShowLoginModal} />
      </div>
    </Row>
  );
}
