import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteAccountAPI } from "../../actions/Auth/Auth";
import { useSelector } from "react-redux";
import { CircularProgress, ClickAwayListener } from "@material-ui/core";
import FB from "../../assets/img/FB.svg";
import Twitter from "../../assets/img/Twitter.svg";
import Instagram from "../../assets/img/Instagram.svg";
import ErrorSuccessDialog from "../../views/ErrorSuccessDialog";
import PromoCode from "../../views/Cart/PromoCode";
import DecisionDialog from "../../views/DecisionDialog";

export default function UserMenu({
  show,
  setUserMenuClicked,
  setShowRewardModal,
}) {
  const [isAccountDeleted, setIsAccountDeleted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [askForCode, setAskForCode] = useState(false);
  const [codeType, setCodeType] = useState("");
  const [punchError, setPunchError] = useState(false);

  // CREATE HISTORY OBJECT
  const history = useHistory();

  // GET AUTH AND PROFILE DATA FROM REDUX STATE
  const profile = useSelector((state) => state.profile);

  // OPEN PAGE
  const openPage = (page) => {
    history.push(page);
    setUserMenuClicked(false);
  };

  // OPEN PAGE
  const openExternalPage = (page, type) => {
    window.open(page, type).focus();
    setUserMenuClicked(false);
  };

  // LOGOUT FUNCTION
  const logout = () => {
    setIsAccountDeleted(false);
    // REDIRECT TO HOME
    openPage("/logout");
  };

  // DELETE ACCOUNT FUNCTION
  const deleteAccount = async () => {
    setPunchError(false);
    // CALL THE DELETE ACCOUNT API
    setLoader(true);
    let result = await DeleteAccountAPI();
    // REDIRECT TO HOME
    if (result) setIsAccountDeleted(true);

    setLoader(false);
  };

  return (
    <>
      {show ? (
        <ClickAwayListener
          onClickAway={() => {
            setTimeout(() => {
              setUserMenuClicked(false);
            }, 100);
          }}
        >
          <div className="user-menu py-3 px-4">
            <div className="menuItems">
              <section>
                <p className="mb-0 BrandenGrotesqueBold font-size-1_5 text-uppercase default-letter-spacing text-brows2 cursor-pointer">
                  {profile.first_name} {profile.last_name}
                </p>
                <p
                  className="mb-3 BrandenGrotesqueBold font-size-1_1 text-uppercase default-letter-spacing text-orange1 cursor-pointer"
                  onClick={() => openPage("/profile")}
                >
                  view profile
                </p>
              </section>
              <section>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => openPage("/orders")}
                >
                  orders & favorites
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => {
                    setShowRewardModal(true);
                    setUserMenuClicked(false);
                  }}
                >
                  rewards
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => {
                    setAskForCode(true);
                    setUserMenuClicked(false);
                    setCodeType("digital");
                  }}
                >
                  have a digital reward code?
                </p>
              </section>
              <section>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => openPage("/cards")}
                >
                  payment settings
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() => openPage("/locations/delivery")}
                >
                  addresses
                </p>
              </section>
              <section>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() =>
                    openExternalPage("https://www.mixt.com/nutrition/", "_self")
                  }
                >
                  nutrition
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() =>
                    openExternalPage("https://www.mixt.com/faq/", "_self")
                  }
                >
                  faq
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() =>
                    openExternalPage("https://www.mixt.com/our-story/", "_self")
                  }
                >
                  about
                </p>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={() =>
                    openExternalPage("https://www.mixt.com/help/", "_self")
                  }
                >
                  help
                </p>
              </section>
              <section>
                <div className="d-flex">
                  <p className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer">
                    follow us
                  </p>
                  <img
                    src={FB}
                    alt={"FB"}
                    className={"ps-4 cursor-pointer"}
                    onClick={() =>
                      openExternalPage(
                        "http://www.facebook.com/hellomixt/",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={Twitter}
                    alt={"Twitter"}
                    className={"ps-4 cursor-pointer"}
                    onClick={() =>
                      openExternalPage(
                        "https://twitter.com/hellomixt",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={Instagram}
                    alt={"Instagram"}
                    className={"ps-4 cursor-pointer"}
                    onClick={() =>
                      openExternalPage(
                        "https://www.instagram.com/mixt/?hl=en",
                        "_blank"
                      )
                    }
                  />
                </div>
                <p
                  className="my-3 BrandenGrotesqueMedium text-uppercase font-size-0_9 text-brown2 default-letter-spacing cursor-pointer"
                  onClick={logout}
                >
                  Logout
                </p>
                <div className="d-flex justify-content-between">
                  <span className="mt-3 BrandenGrotesqueBold text-uppercase font-size-0_7 text-grey3 default-letter-spacing cursor-pointer">
                    <span
                      className="d-none d-md-flex"
                      onClick={() =>
                        openExternalPage("https://www.inv8.com", "_blank")
                      }
                    >
                      website by{" "}
                      <span className="text-orange1">&nbsp;inv8</span>
                    </span>
                    <span className="d-flex d-md-none">
                      app by{" "}
                      <span
                        className="text-orange1"
                        onClick={() =>
                          openExternalPage("https://www.inv8.com", "_blank")
                        }
                      >
                        &nbsp;inv8
                      </span>
                    </span>
                  </span>
                  <span
                    className="mt-3 BrandenGrotesqueRegular text-right text-capitalize font-size-0_7 text-grey3 default-letter-spacing cursor-pointer"
                    onClick={() => setPunchError(true)}
                  >
                    {loader ? (
                      <CircularProgress size={24} />
                    ) : (
                      "delete account?"
                    )}
                  </span>
                </div>
              </section>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <></>
      )}

      <ErrorSuccessDialog
        showError={isAccountDeleted}
        closeDialog={logout}
        text={"Account Delete Successfully"}
      />

      {askForCode && (
        <PromoCode
          setAskForCode={setAskForCode}
          codeType={codeType}
          setCodeType={setCodeType}
          updateRewardsList={setCodeType}
        />
      )}
      <DecisionDialog
        showError={punchError}
        text={
          "Are you sure you want to delete your account? All your data will be deleted and this cannot be undone."
        }
        yesButtonText="Confirm"
        noOption={setPunchError}
        yesOption={deleteAccount}
        heading={"Delete Account?"}
      />
    </>
  );
}
