import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseImg from "../../assets/img/CloseCircle.svg";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { OrderReducer } from "../../redux/reducers/OrderReducer";
const OrderActions = OrderReducer.actions;

function GroupLinkModal({ setIsGroupLinkDisplayed }) {
  const [showToolTip, setShowToolTip] = useState(false);

  const dispatch = useDispatch();
  const groupOrderDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const profileDetail = useSelector((state) => state.profile);
  console.log("groupOrderDetail", groupOrderDetail, profileDetail);
  const updateGroupLinkVisibility = () => {
    dispatch(OrderActions.updateGroupOrderVisibility());
    setIsGroupLinkDisplayed(false);
  };
  return (
    <Dialog
      maxWidth={"md"}
      open={true}
      onClose={updateGroupLinkVisibility}
      aria-labelledby="draggable-dialog-title"
      className={`customize_modal`}
    >
      <DialogContent className="group_info_model_detail">
        <img
          src={CloseImg}
          alt={"Close Image"}
          className={"close_payment_modal cursor-pointer"}
          onClick={updateGroupLinkVisibility}
        />
        {profileDetail.user_id === groupOrderDetail.user_id ? (
          <>
            <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-2 cmb-25">
              start a group order
            </h3>
            <div className="d-flex cmt-2 bg-yellow1 cpy-25 cpy-sm-32 px-2 px-sm-5 align-items-center">
              <h1 className="BrownSTDBold me-3 mb-0 rounded-full group_order_counter">
                1
              </h1>
              <div className=" text-brown1 d-flex flex-column ms-0 ms-sm-2 ms-md-5">
                <span className="BrownSTDRegular">
                  Copy your group order link below and send it to your group.
                </span>
                <div className="">
                  <span
                    className="BrownSTDBold"
                    style={{ wordBreak: "break-all" }}
                  >
                    {groupOrderDetail.link}
                  </span>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => {
                      setShowToolTip(false);
                    }}
                    open={showToolTip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Link Copied"
                  >
                    <span
                      className="BrownSTDBold cursor-pointer text-orange1 ms-4"
                      onClick={() => {
                        setShowToolTip(true);
                        navigator.clipboard.writeText(groupOrderDetail.link);
                        setTimeout(() => {
                          setShowToolTip(false);
                        }, 20000);
                      }}
                    >
                      Copy
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex cmt-2 cpy-25 cpy-sm-32 px-2 px-sm-5 align-items-center">
              <h1 className="BrownSTDBold  me-3 mb-0 rounded-full group_order_counter">
                2
              </h1>
              <div className=" text-brown1 text-left ms-0 ms-sm-2 ms-md-5">
                <span className="BrownSTDRegular">
                  After everyone in your group places their individual order by
                  going through the checkout process, select CHECKOUT to review,
                  pay, and place your group order.
                </span>
              </div>
            </div>
            <div className=" text-grey3 text-center cmt-2 cpy-0 cpy-sm-15 text-left d-flex flex-column px-2 px-sm-5">
              <span className="BrownSTDRegular text-grey4">Helpful info:</span>
              <span className="BrownSTDRegular cmt-2 font-size-1">
                With a group order in progress, you will not be able to place
                any additional orders.
              </span>
              <span className="BrownSTDRegular cmt-2 font-size-1">
                To place a different order you must complete or cancel your
                group order.
              </span>
              <span className="BrownSTDRegular cmt-2 cmt-sm-25 font-size-1">
                If you sign out, you can return to your group order by signing
                back in.
              </span>
              <span className="BrownSTDRegular cmt-2 cmt-sm-25 font-size-1">
                To cancel a group order select Checkout and click Cancel Group
                Order at the top of the page.
              </span>
            </div>
          </>
        ) : (
          <>
            <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-2 cmb-25">
              group order process
            </h3>
            <div className="d-flex cmt-2 bg-yellow1 cpy-25 cpy-sm-32 px-2 px-sm-5 align-items-center">
              <h1 className="BrownSTDBold  me-3 mb-0 rounded-full group_order_counter">
                1
              </h1>
              <div className=" text-brown1 d-flex flex-column ms-0 ms-sm-2 ms-md-5">
                <span className="BrownSTDRegular">Add items to your cart</span>
              </div>
            </div>
            <div className="d-flex cmt-2 cpy-25 cpy-sm-32 px-2 px-sm-5 align-items-center">
              <h1 className="BrownSTDBold  me-3 mb-0 rounded-full group_order_counter">
                2
              </h1>
              <div className=" text-brown1 text-left ms-0 ms-sm-2 ms-md-5">
                <span className="BrownSTDRegular">
                  When you’re ready to submit your order to the Group Order,
                  make sure to <b>CHECKOUT</b> to complete the order process.
                </span>
              </div>
            </div>
          </>
        )}
        <div
          key={"barcode"}
          className={`d-flex justify-content-center mb-2 align-items-center pt-4 px-3`}
        >
          <Button
            variant=""
            className={`text-uppercase BrandenGrotesqueBold text-white border-0  bg-brown2 rounded-full default-letter-spacing height-44 width-233`}
            onClick={() => {
              navigator.clipboard.writeText(groupOrderDetail.link);
              updateGroupLinkVisibility();
            }}
          >
            Got It
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GroupLinkModal;
