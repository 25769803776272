import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RightArrow from "../../assets/img/MenuDetailRight.svg";
import CloseCircle from "../../assets/img/CloseCircle.svg";
import { CircularProgress } from "@material-ui/core";
import CardImage from "./CardImage";
import PAYMENTCARD from "../../assets/img/paymentCard.svg";
import { UpdateOrderDetailAPI } from "../../actions/Cart/Cart";
import {
  DeletePaymentCardAPI,
  GetPaymentCardAPI,
  UpdatePaymentCardsList,
} from "../../actions/Card/Card";
import DecisionDialog from "../DecisionDialog";

function CardsList({
  setSelectedCardIndex,
  selectedCardIndex,
  setDisplayType,
  allowDelete = false,
  setIsCalculateAPIRequiredToCall = null,
  cardDeleting = false,
}) {
  const [cardDeletingStatus, setCardDeletingStatus] = useState(false);
  const [deleteCardId, setDeletedCardId] = useState("");
  const [cardIndex, setCardIdex] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const profile = useSelector((state) => state.profile);
  const cardsData = profile && profile.cards_list ? profile.cards_list : [];
  const cardsList = useSelector((state) => state.profile.cards_list);
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const dispatch = useDispatch();

  const setDeletedCardIdex = async (index) => {
    setCardIdex(index);
    setShowAlert(true);
  };
  const deleteCard = async () => {
    setCardDeletingStatus(true);
    setShowAlert(false);
    if (allowDelete) setSelectedCardIndex(true);
    let paymentCardId = cardsList[cardIndex].idPaymentCard;
    await DeletePaymentCardAPI(paymentCardId);
    let response = await GetPaymentCardAPI();
    // UPDATE CARDS LIST IN REDUX
    dispatch(
      UpdatePaymentCardsList(response && response.data ? response.data : [])
    );
    if (orderData.hasOwnProperty("orderType") && orderData.orderType) {
      let orderItem = _.cloneDeep(orderData);
      if (orderItem.payment_info.idPaymentCard === paymentCardId) {
        orderItem.payment_info.idPaymentCard = 0;
        // UPDATE DATA IN REDUX STATE
        dispatch(UpdateOrderDetailAPI(orderItem));
      }
    }
    if (allowDelete) setSelectedCardIndex(false);
    else {
      let cardsList = response && response.data ? response.data : [];

      setSelectedCardIndex(
        cardsList.length > 0 ? cardsList[0].idPaymentCard : 0,
        cardIndex
      );
    }
    setCardDeletingStatus(false);
  };

  return (
    <div className="card_list">
      <h3 className="text-capitalize BrownSTDRegular font-size-1_2 text-grey4 text-center mb-5">
        Payment method
      </h3>
      <div className="d-flex flex-column px-1 mx-0 px-md-5 mx-md-4">
        {cardsData.map((cardDetail, index) => {
          return (
            <div
              key={index}
              className={`px-2 d-flex card_item cursor-pointer ${
                selectedCardIndex === index ? "bg-yellow1" : ""
              }`}
            >
              <CardImage type={cardDetail.cardType.toLowerCase()} />
              <div
                onClick={() => {
                  setSelectedCardIndex(cardDetail.idPaymentCard, index);
                  setDisplayType(1);
                  setIsCalculateAPIRequiredToCall(true);
                }}
                className="d-flex align-items-center flex-1 px-3 BrownSTDBold font-size-0_9 text-grey1"
              >
                {cardDetail.lastFourDigits} - Expires: {cardDetail.expiryMonth}/{" "}
                {cardDetail.expiryYear}
              </div>
              {/* {allowDelete ? ( */}
              <div
                className={`px-2 h-100`}
                onClick={() => {
                  setDeletedCardId(cardDetail.idPaymentCard);
                  setDeletedCardIdex(index);
                  setIsCalculateAPIRequiredToCall(true);
                }}
              >
                {cardDeletingStatus &&
                deleteCardId === cardDetail.idPaymentCard ? (
                  <CircularProgress size={24} />
                ) : (
                  <img src={CloseCircle} />
                )}
              </div>
            </div>
          );
        })}
        <div
          className="d-flex align-items-center justify-content-between cursor-pointer card_item"
          onClick={() => setDisplayType(3)}
        >
          <div>
            <img src={PAYMENTCARD} />
            <span className="px-3 BrownSTDBold font-size-0_9 text-grey1 mb-0">
              Add a Credit or Debit Card
            </span>
          </div>
          <img src={RightArrow} />
        </div>
      </div>
      <DecisionDialog
        showError={showAlert}
        text={"Are you sure to delete this card?"}
        heading={"Delete Card"}
        yesButtonText={"Confirm"}
        noOption={setShowAlert}
        yesOption={deleteCard}
      />
    </div>
  );
}

export default CardsList;
