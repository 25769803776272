import { createSlice } from "@reduxjs/toolkit";

export const ProfileReducer = createSlice({
  name: "profile",
  initialState: { cards_list: [] },
  reducers: {
    updateUser: (state, action) => {
      state.address_line1 = action.payload.address_line1;
      state.avatar_remote_url = action.payload.avatar_remote_url;
      state.birthday = action.payload.birthday;
      state.city = action.payload.city;
      state.created_at = action.payload.created_at;
      state.email = action.payload.email;
      state.email_verified = action.payload.email_verified;
      state.fb_uid = action.payload.fb_uid;
      state.first_name = action.payload.first_name;
      state.gender = action.payload.gender;
      state.id = action.payload.id;
      state.last_name = action.payload.last_name;
      state.state = action.payload.state;
      state.zip_code = action.payload.zip_code;
      state.test_user = action.payload.test_user;
      state.allow_multiple = action.payload.allow_multiple;
      state.authentication_token = action.payload.authentication_token;
      state.referral_code = action.payload.referral_code;
      state.referral_path = action.payload.referral_path;
      state.title = action.payload.title;
      state.user_code = action.payload.user_code;
      state.user_id = action.payload.user_id;
      state.phone = action.payload.phone;
      state.country_code = action.payload.country_code;
      state.temp_country_code = action.payload.temp_country_code;
      state.isFirstLogin = action.payload.isFirstLogin;
      state.allow_push_notifications = action.payload.allow_push_notifications;
      state.access_token = action.payload.access_token;
      state.isValidPhoneNumber = action.payload.isValidPhoneNumber;
      state.user_as_qrcode = action.payload.user_as_qrcode;
      state.cards_list = [];
    },
    EmptyProfile: (state, action) => {
      return { ...state.initialState };
    },
    UpdateCardsList: (state, action) => {
      state.cards_list = action.payload.cardsList;
    },
  },
});
