import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  GetOrderTrackDetailAPI,
  GetOrderTrackLatestDetailAPI,
  SetTrackOrderIdAPI,
} from "../../actions/Orders/Order";
import { ORDER_STATUSES } from "../../config/config";
import TrackStatus from "../../assets/img/TrackStatus.svg";
import TimeIcon from "../../assets/img/TimeIcon.png";
import TrackLocation from "../../assets/img/TrackLocation.svg";
import TrackingMap from "./TrackingMap";
import { useDispatch, useSelector } from "react-redux";
import { DecryptReduxAuth } from "../../actions/General/General";
import { useHistory } from "react-router-dom";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import moment from "moment-timezone";

function TrackOrder({ searchId }) {
  const [trackDetail, setTrackDetail] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let auth = useSelector((state) => state.auth.data);
  let track_order_id = useSelector((state) => state.order.track_order_id);
  auth = DecryptReduxAuth(auth);

  const getTrackDetail = async () => {
    let result = await GetOrderTrackDetailAPI(searchId);
    if (!result.generalError) {
      dispatch(SetTrackOrderIdAPI(""));
      setTrackDetail(result.data);
      setLoader(false);
      setTimeout(() => {
        getTrackLatestDetail(result.data);
      }, 1000);
    } else {
      dispatch(SetTrackOrderIdAPI(searchId));
      setLoader(false);
      setError(result.generalError);
    }
  };
  useEffect(() => {
    if (Boolean(auth.serverPrivateKey)) getTrackDetail();
    else dispatch(SetTrackOrderIdAPI(searchId));
  }, []);

  useEffect(() => {
    if (Boolean(auth.serverPrivateKey) && track_order_id != "")
      getTrackDetail();
    else dispatch(SetTrackOrderIdAPI(searchId));
  }, [auth.serverPrivateKey]);

  const getTrackLatestDetail = async (trackDetailData) => {
    let result = await GetOrderTrackLatestDetailAPI(searchId);
    trackDetailData = { ...trackDetailData, ...result.data };
    setTrackDetail(trackDetailData);

    setTimeout(() => {
      getTrackLatestDetail(trackDetailData);
    }, 10000);
  };

  const closeErrorDialog = () => {
    dispatch(SetTrackOrderIdAPI(""));
    setError("");
    history.push("/dashboard");
  };

  return (
    <div className="pt-3 d-flex flex-column align-items-center justify-content-center track_page">
      {loader ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {" "}
          {trackDetail && (
            <div className="tracking p-4">
              <img
                className="ms-md-4 mt-md-4 cursor-pointer back-image"
                alt={"Back image"}
                onClick={() => history.push("/orders")}
              />
              <div className="ms-4">
                <h2 className="BrandenGrotesqueBold font-size-1_2 text-grey1 border-bottom-1 border-white1 pb-1">
                  Delivery Status
                </h2>
                <p className="BrownSTDRegular font-size-0_9 text-grey1">
                  <img src={TrackStatus} className="me-2" />
                  {ORDER_STATUSES[trackDetail.dasherStatus ? trackDetail.dasherStatus : trackDetail.orderStatus]}
                </p>
                <div className="d-flex align-items-start">
                  <img src={TimeIcon} className="me-2 mt-1 cml-3" style={{ width: '20px' }} />
                  <div className="cpl-1">
                    <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-0">
                      {moment
                        .tz(trackDetail.orderDateTime * 1000, trackDetail.locationTimeZone)
                        .format("dddd, MMMM DD, YYYY")}
                    </p>
                    <p className="BrownSTDRegular font-size-0_9">
                      Estimated Time:{" "}
                      {moment.tz(trackDetail.orderDateTime * 1000, trackDetail.locationTimeZone).format("LT")} -{" "}
                      {moment
                        .tz(trackDetail.orderDateTime * 1000, trackDetail.locationTimeZone)
                        .add(15, "minutes")
                        .format("LT")}
                    </p>

                  </div>
                </div>
              </div>
              <div className="tracking_map">
                <TrackingMap trackDetail={trackDetail} />
              </div>
              <div className="ms-4">
                <h2 className="BrandenGrotesqueBold font-size-1_2 text-grey1 border-bottom-1 border-white1 pt-2 pb-1">
                  Delivery Location
                </h2>
                <div className="d-flex align-items-start">
                  <img src={TrackLocation} style={{ marginLeft: '-3px' }} />
                  <div className="cml-10">
                    <p className="BrownSTDBold font-size-0_9 text-grey1 mb-0">
                      {trackDetail.deliveryInfo.locationName}
                    </p>
                    <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-0">
                      {[
                        trackDetail.deliveryInfo.fullAddress,
                        trackDetail.deliveryInfo.companyName,
                        trackDetail.deliveryInfo.floorAddress,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                    <p className="BrownSTDRegular font-size-0_9 text-grey1">
                      {[
                        trackDetail.deliveryInfo.city,
                        trackDetail.deliveryInfo.state +
                        " " +
                        trackDetail.deliveryInfo.zipCode,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <ErrorSuccessDialog
        showError={Boolean(error)}
        text={error}
        closeDialog={closeErrorDialog}
      />
    </div>
  );
}

export default TrackOrder;
