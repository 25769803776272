import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RefreshCartItems,
  getGroupOrderDetailOfLoggedInUser,
} from "../Cart/cartFunctions";
import MessagesList from "../../config/messages.json";
import DecisionDialog from "../DecisionDialog";
import { useHistory } from "react-router-dom";
import MessageDialog from "../MessageDialog";
import { SetGroupOrderIdAPI } from "../../actions/Orders/Order";
import { CircularProgress } from "@material-ui/core";
import { DecryptReduxAuth } from "../../actions/General/General";
import { createEmptyCart } from "../Menu/MenuFunctions";
import {
  ResetCartDetailAPI,
  UpdateOrderDetailAPI,
  cancelGroupOrderAPI,
} from "../../actions/Cart/Cart";
import { UpdateGuestDeliveryInfo } from "../../actions/Locations/Locations";
import { AuthReducer } from "../../redux/reducers/AuthReducer";
const AuthActions = AuthReducer ? AuthReducer.actions : "";

function Decider(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [orderState, setOrderState] = useState({
    activeGroupOrderId: "",
    hasCart: false,
    joinGroup: false,
    createGroup: props.searchId && props.searchId !== undefined ? true : false,
    stateUpdated: false,
  });
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [errorType, setErrorType] = useState("");
  const [loader, setLoader] = useState("");
  const [yesButtonText, setYesButtonText] = useState("");
  const [noButtonText, setNoButtonText] = useState("");
  let orderData = useSelector((state) => state.order.calculate_order_data);
  let groupOrderId = useSelector((state) => state.order.group_order_id);
  let profile = useSelector((state) => state.profile);

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  const checkWhereToRedirect = async () => {
    setLoader(true);
    let orderStateLocal = _.cloneDeep(orderState);
    let activeGroupId = !auth.isGuest
      ? await getGroupOrderDetailOfLoggedInUser()
      : "";
    orderStateLocal.activeGroupOrderId = activeGroupId;

    if (
      orderData.hasOwnProperty("orderType") &&
      orderData.groupOrderId !== activeGroupId &&
      orderData.items.length > 0
    ) {
      orderStateLocal.hasCart = true;
    }
    if (groupOrderId && activeGroupId !== parseInt(groupOrderId)) {
      orderStateLocal.joinGroup = true;
    }
    setOrderState({ ...orderStateLocal, stateUpdated: true });
    // console.log("groupOrderId", groupOrderId, activeGroupId);
    // if (groupOrderId && !activeGroupId) {
    //   console.log("alert");
    //   let message = "Group Order is unavailable";

    //   setErrorTitle(MessagesList[message + " Title"]);
    //   console.log(MessagesList[message + " Title"]);
    //   setErrorDescription(MessagesList[message]);
    //   setErrorType("cancelled");
    //   setLoader(false)
    // } else {
    //   setOrderState({ ...orderStateLocal, stateUpdated: true });
    //   console.log({ ...orderStateLocal, stateUpdated: true });
    // }
  };

  const checkConflictBetweenOrderState = async () => {
    // Cancel Group Order
    // Join Group Order
    // Group Order Active
    let message = "";
    if (orderState.activeGroupOrderId !== "" && orderState.hasCart) {
      message = "Cancel Group Order";
      setYesButtonText("Cancel");
      setNoButtonText("Do Not Cancel");
    } else if (orderState.activeGroupOrderId !== "" && orderState.createGroup) {
      message = "Group Order Active";
      setYesButtonText("Cancel and Create New Group Order");
      setNoButtonText("Do Not Cancel");
    } else if (orderState.hasCart && orderState.createGroup) {
      message = "Creating Group Order";
      setYesButtonText("Ok");
      setNoButtonText("Cancel");
    } else if (
      (orderState.activeGroupOrderId !== "" || orderState.hasCart) &&
      orderState.joinGroup
    ) {
      setYesButtonText("Ok");
      setNoButtonText("Cancel");
      message = "Join Group Order";
    } else {
      if (auth.isGuest)
        history.replace(`${orderState.createGroup ? "/signin/1" : "/signin"}`);
      else {
        if (
          orderState.activeGroupOrderId !== "" ||
          (orderState.joinGroup && groupOrderId)
        )
         await createOrder(
            orderState.activeGroupOrderId !== ""
              ? orderState.activeGroupOrderId
              : groupOrderId,
            orderState.activeGroupOrderId !== "" ? true : false
          );
        else if (orderState.hasCart) updateGuestCartToOriginalCart();
        else if (orderState.createGroup)
          history.replace("/locations?is_group=true");
        else history.replace("/dashboard");
      }
    }
    if (message !== "") {
      setErrorTitle(MessagesList[message + " Title"]);
      setErrorDescription(MessagesList[message]);
    }
    setLoader(false);
  };
  useEffect(() => {
    checkWhereToRedirect();
  }, []);
  useEffect(() => {
    if (orderState.stateUpdated) {
      checkConflictBetweenOrderState();
    }
  }, [orderState]);

  const createOrder = async (id, RefreshCart) => {
    let result = await createEmptyCart(1, null, 0, id);
    if (result !== "") {
      let message = "";
      setErrorType("cancelled");
      if ("Group order is submitted." === result) {
        message = "Group order is submitted";
      } else {
        message = "Group order is cancelled";
      }
      setErrorTitle(MessagesList[message + " Title"]);
      setErrorDescription(MessagesList[message]);
      setLoader(false);
    } else {
      dispatch(
        SetGroupOrderIdAPI({
          group_order_id: "",
          group_order_user_name: "",
        })
      );
      if (orderState.activeGroupOrderId !== "" && RefreshCart) {
        await RefreshCartItems(dispatch);
      }
      history.replace("/menu");
    }
  };

  const updateGuestCartToOriginalCart = async () => {
    let orderDetail = _.cloneDeep(orderData);
    let userDetail = {
      userId: profile.user_id,
      userFirstName: profile.first_name,
      userLastName: profile.last_name,
      emailAddress: profile.email,
      phoneNumber: profile.phone ? profile.country_code + profile.phone : "+1",
      punchhAccessToken: profile.access_token,
    };
    orderDetail.user_info = userDetail;
    if (orderDetail.orderType === "delivery") {
      let locationDetail = await UpdateGuestDeliveryInfo(
        orderDetail.delivery_info,
        profile.user_id
      );
      orderDetail.delivery_info = {
        ...orderData.delivery_info,
        ...locationDetail,
      };

      delete orderDetail.delivery_info.guestFullAddress;
      // menuLocationInfo.idUserDeliveryAddress =
      //   locationDetail.idUserDeliveryAddress;
      // menuLocationInfo.userSelectedLocationId =
      //   locationDetail.idUserDeliveryAddress;
      // dispatch(SetLocationForMenuAPI(menuLocationInfo));
    }
    dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderDetail)));
    dispatch(
      AuthActions.updateGuestOrderStatus({
        hasGuestOrder: false,
      })
    );
    history.replace("/cart");
  };

  const yesOptionSelected = async () => {
    setLoader(true);
    if (orderState.activeGroupOrderId !== "")
      await cancelGroupOrderAPI(orderState.activeGroupOrderId);

    if (orderState.createGroup) {
      if (orderState.hasCart) await ResetCartDetailAPI(dispatch);
      if (auth.isGuest) {
        history.replace("/signin/1");
      } else {
        history.replace("/locations?is_group=true");
      }
    } else if (orderState.joinGroup) {
      if (orderState.hasCart) await ResetCartDetailAPI(dispatch);
      if (auth.isGuest) {
        history.replace("/signin");
      } else {
        createOrder(groupOrderId, false);
      }
    } else if (orderState.hasCart) {
      updateGuestCartToOriginalCart();
    }
  };
  const noOptionSelected = async () => {
    setLoader(true);
    if (orderState.createGroup) {
      if (orderState.activeGroupOrderId !== "") {
        createOrder(orderState.activeGroupOrderId, true);
      } else {
        history.replace("/");
      }
    } else if (orderState.joinGroup) {
      if (auth.isGuest) {
        history.replace("/");
      } else if (orderState.activeGroupOrderId !== "") {
        createOrder(orderState.activeGroupOrderId, true);
      } else {
        history.replace("/dashboard");
      }
    } else if (orderState.hasCart) {
      await ResetCartDetailAPI(dispatch);
      createOrder(orderState.activeGroupOrderId, true);
    }
  };
  const closeErrorMessage = () => {
    setErrorType("");
    setErrorDescription("");
    setErrorTitle("");
    history.replace("/dashboard");
  };
  return (
    <div>
      {loader ? (
        <div className="pt-3">
          <CircularProgress size={24} />
        </div>
      ) : errorTitle && errorType !== "cancelled" ? (
        <DecisionDialog
          showError={errorTitle ? true : false}
          text={errorDescription}
          noOption={noOptionSelected}
          yesOption={yesOptionSelected}
          heading={errorTitle}
          cancelButtonText={noButtonText}
          yesButtonText={yesButtonText}
        />
      ) : (
        errorTitle &&
        errorType === "cancelled" && (
          <MessageDialog
            showError={errorType ? true : false}
            text={errorDescription}
            closeDialog={closeErrorMessage}
            heading={errorTitle}
          />
        )
      )}
    </div>
  );
}

export default Decider;
