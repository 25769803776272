import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MenuCarouselList from "./MenuCarouselList";
import CustomIngredient from "../../assets/img/CustomIngredient.jpg";
import { Button } from "react-bootstrap";
import scrollSpy from "simple-scrollspy";
import MessageDialog from "../MessageDialog";
import { useDraggable } from "react-use-draggable-scroll";
import {
  CreateGoogleAnalyticsAPI,
  getImageUrl,
} from "../../actions/General/General";
import {
  populateDefaultList,
  prepareItemDetailAndAdd,
  RemoveItemFromIngredientsList,
} from "./MenuFunctions";
import itemsCompletedImg from "../../assets/img/PackageItemComplete.svg";
import itemsInCompletedImg from "../../assets/img/PackageItemInComplete.png";
import MessagesList from "../../config/messages.json";

function MenuCustomization({
  itemDetail,
  customizeOrder,
  ingredientsList,
  totalPrice,
  setIngredientsList,
  setNotDisplayedModifierIds,
  notDisplayedModifierIds,
  calories,
}) {
  const options = {
    hrefAttribute: "id",
    activeClass: "selected",
    sectionClass: ".scrollspy", // Query selector to your sections
    menuActiveTarget: ".menu-item", // Query selector to your elements that will be added `active` class
    offset: 610, // Menu item will active before scroll to a matched section 100px
    scrollContainer: "", // Listen scroll behavior on `.scroll-container` instead of `window`
  };

  // or shorter:
  scrollSpy("#customize-menu", options);
  // CREATE REF FOR DRAGGABLE
  const draggableRef = useRef();
  const { events } = useDraggable(draggableRef);

  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const modifiersList = useSelector(
    (state) => state.menu.item_detail.modifiers
  );

  // CREATE LOCAL STATE
  const [subMenuHeight, setSubMenuHeight] = useState("0px");
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [showLimitError, setShowLimitError] = useState(false);
  const [limitMessage, setShowLimitMessage] = useState("");
  const [addItemId, setAddedItemId] = useState(-1);

  // CALLED WHEN AN ITEM IS ADDED IN INGREDIENTS LIST
  const addInIngredientsList = (ingredientItem, modifierData) => {
    let item = _.cloneDeep(ingredientItem);
    console.log("item", item);
    delete item.position;
    setAddedItemId(item.menuItem.itemIdBrink);

    let itemType = modifierData.isDressing ? "dressing" : "other";
    if (
      !ingredientsList.hasOwnProperty("DefaultProtein") &&
      modifierData.minimum > 0 &&
      modifierData.prefillRequired
    ) {
      itemType = "included_protein";
    }

    let ingredientsArray = prepareItemDetailAndAdd(
      item,
      modifierData,
      ingredientsList,
      {
        itemType,
        isRemovedModifier: notDisplayedModifierIds.indexOf(
          modifierData.brinkId
        ),
      }
    );
    setIngredientsList(_.cloneDeep(ingredientsArray));
  };

  const RemoveFromIngredientsList = (brinkId, isIncludedProtein) => {
    setAddedItemId(-1);
    // CALL THE REMOVE INGREDIENT METHOD, THAT RETURNS UPDATED INGREDIENTS LIST, NEWLY ADDED INCLUDED PROTEIN ID AND DEFAULT REMOVED INGREDIENT ID
    let {
      currentIngredientsList,
      includedProteinItemId,
      defaultDeletedItemModifierId,
    } = RemoveItemFromIngredientsList(
      ingredientsList,
      brinkId,
      isIncludedProtein
    );

    // IF DELETED INGREDIENT IS INCLUDED PROTEIN AND NEXT ADDED INCLUDED PROTEIN IS NOT NULL THEN ADD INCLUDED PROTEIN AND UPDATE INGREDIENTS LIST
    // OTHERWISE UPDATE INGREDIENTS LIST
    if (isIncludedProtein && includedProteinItemId !== null) {
      currentIngredientsList = populateDefaultList(
        [],
        modifiersList,
        includedProteinItemId,
        currentIngredientsList
      );
      setIngredientsList(_.cloneDeep(currentIngredientsList));
    } else {
      setIngredientsList(_.cloneDeep(currentIngredientsList));
    }

    // IF DEFAULT INGREDIENT IS REMOVED, THEN UPDATE THE DISPLAYED MODIFIER LIST SO THAT MORE INGREDIENT MODIFIER WILL NOT DISPLAY AND ITS RELATED SUBSTITUTION MODIFIER WILL DISPLAYED
    if (defaultDeletedItemModifierId !== null) {
      let notDisplayedModifierIdsList = _.cloneDeep(notDisplayedModifierIds);
      notDisplayedModifierIdsList.push(defaultDeletedItemModifierId);

      setNotDisplayedModifierIds(_.cloneDeep(notDisplayedModifierIdsList));
    }
  };

  const subMenuClicked_ref = React.useRef({ isClicked: false, id: 0 });

  const subMenuClickedData = (isClicked, id) => {
    subMenuClicked_ref.current = { isClicked, id }; // keep updated
  };

  const ref = useRef();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (ref.current) {
      let height = ref.current.clientHeight + "px";
      setSubMenuHeight(height);
    }
    if (modifiersList.length > 0) {
      setSelectedGroup(modifiersList[0].brinkId);
    }
  }, [ref]);

  useEffect(() => {
    if (selectedGroup != 0) {
      var element = document.getElementById("customize_" + selectedGroup);
      if (element) {
        var headerOffset = 390;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selectedGroup]);

  useEffect(() => {
    var e = document.getElementsByClassName("menu-item");
    var observer = new MutationObserver(function (event) {
      if (event[1]) {
        let activeId = event[1].target.id;
        activeId = activeId.replace("#customize_", "");
        if (parseInt(activeId) === subMenuClicked_ref.current.id) {
          subMenuClickedData(false, 0);
          setSelectedGroup(_.cloneDeep(activeId));
        } else if (!subMenuClicked_ref.current.isClicked) {
          setSelectedGroup(_.cloneDeep(activeId));
        }
      }
    });

    for (let i = 0; i < e.length; i++)
      observer.observe(e[i], {
        attributes: true,
        attributeFilter: ["class"],
        childList: false,
        characterData: false,
      });

    setTimeout(function () {
      e.className = "hello";
    }, 1000);
  }, []);

  useEffect(() => {
    CreateGoogleAnalyticsAPI("ItemCustomizeView", {});
  });

  return (
    <div className="d-flex flex-column w-100">
      <div
        className="menu-customization-header menu-customization-header-item d-flex flex-column pt-md-1 pt-lg-4"
        ref={ref}
      >
        <div>
          <p className="BrownSTDRegular text-center pt-md-3 mb-0 customize-top-text">
            Customize - Tap items to add or remove them
          </p>
          <div className="cpx-30 cpx-md-70 w-100">
            <h2 className="BrandenGrotesqueBold font-size-1_9 text-grey4 default-letter-spacing mb-0 cpt-10">
              {itemDetail.name}
            </h2>
            <p className="BrownSTDRegular font-size-0_9 text-grey4">
              ${totalPrice} - {calories} cals
            </p>
          </div>
          <div className="w-100 justify-content-center mb-2">
            <div className="d-flex justify-content-center top-menu position-relative">
              <MenuCarouselList
                ingredients={ingredientsList}
                RemoveFromIngredientsList={RemoveFromIngredientsList}
                addedId={addItemId}
              />
            </div>
          </div>
        </div>
        <div
          id="customize-menu"
          className="d-flex top-menu overflow-x-scroll scrollbar-hide text-center align-self-end"
          {...events}
          ref={draggableRef}
        >
          {modifiersList.map((modifier) => {
            if (!modifier.showItemsAsBoolean)
              return (
                <p
                  id={"#customize_" + modifier.brinkId}
                  className={`menu-item default-letter-spacing BrandenGrotesqueBold font-size-1_1 text-uppercase cursor-pointer ${
                    selectedGroup === modifier.brinkId
                      ? "selected"
                      : "text-orange1"
                  }`}
                  onClick={() => {
                    subMenuClickedData(true, modifier.idItemModifiers);
                    setSelectedGroup(modifier.brinkId);
                  }}
                  key={modifier.idItemModifiers}
                >
                  {modifier.displayName}
                </p>
              );
          })}
        </div>
      </div>
      <div className="w-100" style={{ marginTop: subMenuHeight }}>
        <div className="custom-menu-item-group">
          {modifiersList.map((modifier) => {
            let modifierGroupItemsList = _.sortBy(modifier.modifierGroupItems, [
              function (o) {
                return o.position;
              },
            ]);

            let textArray = [];
            let showItemImage = null;
            if (
              itemDetail.categoryCustomType === 2 ||
              itemDetail.itemCustomType === 2
            ) {
              modifier.minimum > 0
                ? textArray.push("at least " + modifier.minimum)
                : "";
              modifier.maximum > 0
                ? textArray.push("up to " + modifier.maximum)
                : "";
              ingredientsList.hasOwnProperty("modifiersCounter") &&
              ingredientsList.modifiersCounter &&
              ingredientsList.modifiersCounter.hasOwnProperty(
                modifier.brinkId
              ) ? (
                <>
                  {
                    (showItemImage =
                      ingredientsList.modifiersCounter[modifier.brinkId] >=
                      modifier.minimum
                        ? itemsCompletedImg
                        : itemsInCompletedImg)
                  }
                  {textArray.push(
                    ingredientsList.modifiersCounter[modifier.brinkId] +
                      " selected"
                  )}
                </>
              ) : (
                ""
              );
            }
            let text = textArray.join(", ");
            if (!modifier.showItemsAsBoolean)
              return (
                <div
                  id={"customize_" + modifier.brinkId}
                  className="section scrollspy w-100 justify-content-center py-4"
                  key={modifier.idItemModifiers}
                >
                  <div className="d-flex justify-content-center top-menu flex-column cpl-10 cme-md-0">
                    <div className="d-flex align-items-center">
                      {(itemDetail.categoryCustomType === 2 ||
                        itemDetail.itemCustomType === 2) &&
                      showItemImage ? (
                        <img
                          src={showItemImage}
                          className="package-customization-icon cmt-2"
                        />
                      ) : (
                        ""
                      )}

                      <p className="BrownSTDBold font-size-0_9 text-brown2 ps-2 pt-4 mt-2 cpb-10">
                        Add {modifier.displayName}
                      </p>
                      {itemDetail.categoryCustomType === 2 ||
                      itemDetail.itemCustomType === 2 ? (
                        <p className="BrownSTDBold font-size-0_9 text-brown2 pe-2 pt-4 mt-2 cpb-10">
                          &nbsp;{text ? "- " + text : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex flex-wrap text-center justify-content-around justify-content-md-center">
                      {modifierGroupItemsList.map((groupItem) => {
                        let image = getImageUrl(groupItem.menuItem);
                        return (
                          <div
                            onClick={() => {
                              let modifierMaxiName =
                                modifier.hasOwnProperty("customGroupName") &&
                                modifier.customGroupName === "Proteins"
                                  ? "Protein"
                                  : "";
                              if (
                                !ingredientsList.modifiersCounter ||
                                !ingredientsList.modifiersCounter.hasOwnProperty(
                                  modifier["brinkId" + modifierMaxiName]
                                ) ||
                                (ingredientsList.modifiersCounter &&
                                  ingredientsList.modifiersCounter[
                                    modifier["brinkId" + modifierMaxiName]
                                  ] < modifier["maximum" + modifierMaxiName]) ||
                                modifier["maximum" + modifierMaxiName] === 0
                              ) {
                                let modifierData = _.cloneDeep(modifier);
                                delete modifierData["modifierGroupItems"];
                                addInIngredientsList(groupItem, modifierData);
                              } else {
                                setShowLimitError(true);
                                setShowLimitMessage(
                                  MessagesList["Can not exceed maximum limit"] +
                                    " " +
                                    modifier["maximum" + modifierMaxiName] +
                                    " for " +
                                    modifier.displayName
                                );
                              }
                            }}
                            className="d-flex flex-column customizeItem cursor-pointer"
                            key={groupItem.idModifierGroupItems}
                          >
                            <img
                              src={image === "" ? "" : image}
                              className={"customizeItemImage mix-blend-mode"}
                            />
                            <p className="BrownSTDRegular font-size-0_8 text-grey1 mt-3 h-100 text-capitalize">
                              {groupItem.menuItem.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
          })}
        </div>

        <div className="w-100 position-sticky text-center customize-order-button py-4 bg-white">
          <Button
            className="btn-half width-390 default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full"
            type="button"
            variant=""
            onClick={() => customizeOrder(ingredientsList)}
          >
            done customizing
          </Button>
        </div>
      </div>
      <MessageDialog
        showError={showLimitError}
        text={limitMessage}
        closeDialog={setShowLimitError}
      />
    </div>
  );
}

export default MenuCustomization;
