import React, { useEffect, useState } from "react";
import BarCodeIcon from "../../assets/img/BarCodeIcon.svg";
import RewardLock from "../../assets/img/RewardLock.png";
import RewardIcon from "../../assets/img/Rewards_icon.svg";
import CloseCircle from "../../assets/img/CloseCircle.svg";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  GetApplicableOffersAPI,
  GetMetaRewardsAPI,
  GetRedemptionsRewardsAPI,
  GetRewardsAPI,
  GetUserPointsAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import { CircularProgress } from "@material-ui/core";
import { updateCartRewards } from "../../actions/General/General";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PromoCode from "./PromoCode";
import BarCode from "./BarCode";
import QRCode from "react-qr-code";
import ErrorSuccessDialog from "../ErrorSuccessDialog";


function ShowAllLockedRewards({ setShowRewardModal }) {
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const history = useHistory();

  // CREATE LOCAL STATE
  const [updateRewardsList, setUpdateRewardsList] = useState(true);
  const [confirmUnlock, setConfirmUnlock] = useState(false);
  const [useInStore, setUseInStore] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(false);
  const [confirmRewardData, setConfirmRewardData] = useState("");
  const [codeType, setCodeType] = useState("");
  const [askForCode, setAskForCode] = useState(false);
  const [applicableOffers, setApplicableOffers] = useState([]);
  const [metaOffers, setMetaOffers] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [accountBalance, setAccountBalance] = useState({});
  const [appliedReward, setAppliedReward] = useState(
    orderData &&
      orderData.discounts &&
      orderData.discounts[0] &&
      orderData.discounts[0].rewardId
      ? parseInt(orderData.discounts[0].rewardId)
      : ""
  );
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(async () => {
    if (updateRewardsList === true) {
      setLoader(true);

      // CALL SUGGESTIONS, REWARDS, CALCULATE API
      let responses = await Promise.all([
        GetRewardsAPI(),
        GetApplicableOffersAPI(),
        GetUserPointsAPI(),
        GetMetaRewardsAPI(),
      ]);

      // SET LOCAL STATES FOR REWARDS, SUGGESTIONS, ORDER DETAIL
      setRewards(
        responses[0].data.hasOwnProperty("rewards")
          ? responses[0].data.rewards
          : []
      );
      setAccountBalance(
        responses[2].data.hasOwnProperty("account_balance_details")
          ? responses[2].data.account_balance_details
          : {}
      );
      setApplicableOffers(responses[1].data);
      setMetaOffers(
        responses[3] && responses[3].data && responses[3].data.redeemables
          ? responses[3].data.redeemables
          : []
      );
      setLoader(false);
      setUpdateRewardsList(false);
    }
  }, [updateRewardsList]);

  // UPDATE REWARD STATE AND UPDATE CART ACCORDING TO REWARD
  const updateCartReward = async (reward, rewardType) => {
    setLoader(true);
    let reward_id =
      rewardType === "redeemable" ? reward.redeemable_id : reward.reward_id;
    let reward_amount =
      rewardType === "redeemable" ? 0 : reward.discount_amount;
    // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
    let orderItem = _.cloneDeep(orderData);
    orderItem = await updateCartRewards(
      reward_id,
      reward.name,
      reward_amount ? reward_amount : 0,
      rewardType,
      orderItem,
      appliedReward,
      true
    );

    // IF CLICKED REWARD IS ALREADY SELECTED, THEN EMPTY THE REWARDS OTHER ADD REWARD
    if (appliedReward === reward_id) setAppliedReward("");
    else setAppliedReward(reward_id);
    dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
    setLoader(false);
    if (orderItem.items && orderItem.items.length > 0) {
      history.push("/menu");
    } else {
      history.push("/locations?is_reward=true");
    }
    setConfirmUnlock(false);
    setShowRewardModal(false);
    setUseInStore(false);
  };

  useEffect(async () => {
    if (!askForCode) {
      // CALL POINTS API
      let responses = await GetUserPointsAPI();
      // SET LOCAL STATES FOR POINTS
      setAccountBalance(
        responses.data.hasOwnProperty("account_balance_details")
          ? responses.data.account_balance_details
          : {}
      );
    }
  }, [askForCode]);

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    const isMobile = window.innerWidth > 767 ? false : true;
    setIsMobile(isMobile);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const showQrCode = async () => {
    try {
      setLoader(true);
        let response = await GetRedemptionsRewardsAPI(confirmRewardData.reward.reward_id);
        setQrCode(true)
      if (response?.data) {
          
        if (response.data?.redemption_tracking_code) {
          setQrCodeData(response.data.redemption_tracking_code);
        }
      } else {
        console.log("Response is false or data is missing.");
      }
      setQrCode(true); 
      setLoader(false); 
        
    } catch (error) {
      console.log("Error fetching reward:", error);
      setLoader(false);
        setQrCode(true); 
       
    }
};

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {
          if (confirmUnlock) {
            setConfirmUnlock(false);
            setUseInStore(false);
          }
          else{
            setShowRewardModal(false);
          }
        }}
        aria-labelledby="draggable-dialog-title"
        className={`customize_modal ${confirmUnlock ? "confirm-reward" : ""}`}
      >
        <DialogContent>
          {!qrCode && !confirmUnlock && !useInStore ?
            // show all rewards with view button
           (
            <>
              <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-2 cmb-33">
                Mixt Rewards
              </h3>
              <div className="BrownSTDRegular font-size-0_9 text-brown1 text-center cmt-2 cmb-20 bg-yellow1 cpy-32 text-center">
                You have{" "}
                <span className="text-green4 BrownSTDBold font-size-1_1">
                  {accountBalance &&
                  accountBalance.hasOwnProperty("redeemable_points")
                    ? accountBalance.redeemable_points
                    : 0}{" "}
                </span>{" "}
                Reward Points
              </div>
              {loader && <CircularProgress size={24} />}

              <div
                key={"barcode"}
                className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 reward-item`}
              >
                <div className="d-flex align-items-center flex-1">
                  <img src={BarCodeIcon} />
                  <div className="d-flex flex-column">
                    <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                      {!isMobile
                        ? "Earn points by entering your receipt barcode"
                        : "Earn points at the register or using a receipt"}
                    </span>
                  </div>
                </div>
                <Button
                  variant=""
                  className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-green3 rounded-full default-letter-spacing height-30 width-85`}
                  onClick={() => {
                    setAskForCode(!askForCode);
                    setCodeType("barcode");
                  }}
                >
                  Earn
                </Button>
              </div>
              {rewards &&
                rewards.map((reward, index) => {
                  return (
                    <div
                      key={reward.reward_id}
                      className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 reward-item ${
                        appliedReward === reward.reward_id ? "bg-yellow1" : ""
                      }`}
                    >
                      <div className=" flex-1">
                        <img src={RewardIcon} className={"width-22"} />
                        <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                          {reward.name}
                        </span>
                      </div>
                      <Button
                        variant=""
                        className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-grey4 rounded-full default-letter-spacing height-30 width-85`}
                        onClick={() => {
                          setUseInStore(true);
                          setConfirmRewardData({
                            index,
                            reward,
                            type: "reward",
                          });
                        }}
                      >
                        {appliedReward === reward.reward_id ? "remove" : "view"} 
                        {/* on click view show use in store and start online order buttons */}
                        {/* : "use now"} */}
                      </Button>
                    </div>
                  );
                })}
              {applicableOffers &&
                applicableOffers.map((reward, index) => {
                  if (reward.points > 0)
                    return (
                      <div
                        key={reward.redeemable_id}
                        className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 reward-item ${
                          accountBalance.redeemable_points < reward.points
                            ? "bg-white1 cursor-pointer"
                            : ""
                        } ${
                          appliedReward === reward.redeemable_id
                            ? "bg-yellow1"
                            : ""
                        }`}
                      >
                        <div className="d-flex align-items-center flex-1">
                          <img
                            src={
                              accountBalance.redeemable_points < reward.points
                                ? RewardLock
                                : RewardIcon
                            }
                            className={"width-22"}
                          />
                          <div className="d-flex flex-column">
                            <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                              {reward.name}
                            </span>
                            <span className="BrownSTDBold font-size-0_9 text-green3 ms-2">
                              {reward.points} points
                            </span>
                          </div>
                        </div>
                        {accountBalance.redeemable_points >= reward.points && (
                          <Button
                            variant=""
                            className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-grey4 rounded-full default-letter-spacing height-30 width-85`}
                            onClick={() => {
                              setConfirmUnlock(true);
                              setConfirmRewardData({
                                index,
                                reward,
                                type: "redeemable",
                              });
                            }}
                          >
                            {appliedReward === reward.redeemable_id
                              ? "remove"
                              : "view"}
                            {/* : "use now"} */}
                          </Button>
                        )}
                      </div>
                    );
                })}
              {metaOffers &&
                metaOffers.map((reward, index) => {
                  let userPoints = accountBalance.hasOwnProperty(
                    "redeemable_points"
                  )
                    ? accountBalance.redeemable_points
                    : 0;
                  if (reward.points_required_to_redeem > userPoints) {
                    return (
                      <div
                        key={reward.redeemable_id}
                        className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 bg-white1 reward-item cursor-pointer`}
                        onClick={() => {
                          setShowError(true);
                          setErrorMessage("Unlock Rewards");
                        }}
                      >
                        <div className="d-flex align-items-center flex-1">
                          <img src={RewardLock} className={"width-22"} />
                          <div className="d-flex flex-column">
                            <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                              {reward.name}
                            </span>
                            <span className="BrownSTDBold font-size-0_9 text-green3 ms-2">
                              {reward.points_required_to_redeem} points
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              <div
                className="BrownSTDRegular font-size-0_9 text-brown1 text-center pt-3 text-center cursor-pointer"
                onClick={() => {
                  setAskForCode(!askForCode);
                  setCodeType("digital");
                }}
              >
                Have a{" "}
                <span className="text-green4 BrownSTDBold">
                  Digital Reward Code?
                </span>
              </div>
              <div className="text-center">
                <Button
                  variant=""
                  className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_1 bg-brown2 rounded-full default-letter-spacing btn-half width-268 cmt-34 mx-auto`}
                  onClick={() => setShowRewardModal(false)}
                >
                  Done
                </Button>
              </div>
            </>
          ) : 
          // show use in store and start online order buttons
          !qrCode && useInStore && !confirmUnlock ? (
            <div className="d-flex flex-column">
              <img
                src={CloseCircle}
                className={"close-confirm-reward cursor-pointer"}
                onClick={() => {setConfirmUnlock(false); setUseInStore(false);setQrCode(false)}}
              />
              <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-10 cmb-21">
                Mixt Rewards 
              </h3>
              <h4 className="BrandenGrotesqueBold font-size-0_9 text-grey4 text-center  cmt-0 cmb-3">
             {confirmRewardData?.reward?.name}
              </h4>
              
              <div className="d-flex flex-column align-items-center justify-content-center" style={{marginTop:"10rem"}}>
              <div className="">

                  {loader && <CircularProgress size={24} />}
                </div>
                <div className="">
               
                  <Button
                    variant=""
                  className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-green3 rounded-full default-letter-spacing ` }
                  style={{width:"180px", height:"36px"}}
                  disabled={loader} 
                    onClick={() => {
                      showQrCode();
                    }}
                  >
                    USE IN-STORE
                  
                  </Button>

                </div>
                <div className="">
                  <Button
                    variant=""
                    className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-green3 rounded-full default-letter-spacing`}
                  style={{width:"180px", height:"36px"}}
                  disabled={loader} 
                    onClick={() => {
                      setConfirmUnlock(true);
                    }}
                  >
                    START ONLINE ORDER
                  </Button>

                </div>
              </div>     
              </div>
          ):
          
          qrCode ?  (
            <div className="d-flex flex-column">
              <img
                src={CloseCircle}
                className={"close-confirm-reward cursor-pointer"}
                onClick={() => {setConfirmUnlock(false);setQrCode(false)}}
              />
              <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-10 cmb-21">
                Mixt Rewards 
              </h3>
              <h4 className="BrandenGrotesqueBold font-size-0_9 text-grey4 text-center  cmt-0 cmb-3">
              {confirmRewardData?.reward?.name}
              </h4>
              
              <div className=" text-center" style={{marginTop:"5rem",marginBottom:"3rem",borderRadius: "10px"}}>
              { qrCodeData ? <div className={`m-auto`} style={{ borderRadius: "30px",border: "1px solid #00000030",padding: "2rem",width: "40%"}}>
              
              <b style={{fontSize: "14px"}}>{profile.first_name} {profile.last_name}</b>

                    <QRCode
                      size={128}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                        marginTop:"14px",
                        marginBottom:"14px"
                      }}
                      value={qrCodeData}
                      viewBox={`0 0 256 256`}
                    />
                    <b style={{fontSize: "14px"}}>{qrCodeData}</b>
                  </div>
                :  <b style={{fontSize: "14px"}}>Unfortunately, this reward is not available at the moment.</b>
                }
              
              </div>     
              </div>
          ):
            // show reward detail
          (
            <div className="d-flex flex-column">
              <img
                src={CloseCircle}
                className={"close-confirm-reward cursor-pointer"}
                onClick={() => {
                  if (confirmRewardData.type === "redeemable") {
                    setConfirmUnlock(false);
                    setUseInStore(false);
                  } else {
                  setConfirmUnlock(false);
                  setUseInStore(true);
                  }
                }}
              />
              <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-10 cmb-21">
                Mixt Rewards
              </h3>
              <h4 className="BrandenGrotesqueBold font-size-1 text-grey4 text-center text-uppercase cmt-0 cmb-3">
                {confirmRewardData.type === "reward"
                  ? rewards[confirmRewardData.index].name
                  : applicableOffers[confirmRewardData.index].name}
              </h4>
              <div
                className="BrownSTDRegular font-size-0_9 text-grey4 text-center cmb-37 cmt-15 line-height-1_25"
                dangerouslySetInnerHTML={{
                  __html:
                    confirmRewardData.type === "reward"
                      ? rewards[confirmRewardData.index].description.replaceAll(
                          "*",
                          ""
                        )
                      : applicableOffers[
                          confirmRewardData.index
                        ].description.replaceAll("*", ""),
                }}
              ></div>
              <div className="text-center">
                <Button
                  variant=""
                  className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 bg-green3 default-letter-spacing btn-half width-233 mb-0`}
                  onClick={() =>
                    updateCartReward(
                      confirmRewardData.reward,
                      confirmRewardData.type
                    )
                  }
                >
                  {loader ? <CircularProgress size={24} /> : "Start Order"}
                </Button>
              </div>
            </div>
          )
          }
        </DialogContent>
      </Dialog>
      {askForCode ? (
        codeType === "barcode" ? (
          <BarCode setAskForCode={setAskForCode} />
        ) : (
          <PromoCode
            setAskForCode={setAskForCode}
            codeType={codeType}
            setCodeType={setCodeType}
            getAllRewardsList={() => setUpdateRewardsList(true)}
          />
        )
      ) : (
        ""
      )}
      <ErrorSuccessDialog
        showError={showError}
        text={errorMessage}
        closeDialog={setShowError}
      />
    </>
  );
}

export default ShowAllLockedRewards;
