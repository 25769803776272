import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { useSelector } from "react-redux";
import Locations from "./Locations.js";
import { useDispatch } from "react-redux";
import {
  GetPickUpLocationsAPI,
  GetPickUpLocationsByRadiusAPI,
  GetDeliveryLocationsAPI,
  // RefreshClosestLocationAPI,
} from "../../actions/Locations/Locations";
import { Button } from "react-bootstrap";

import UserMarker from "../../assets/img/LocationUser.png";
import PickupSelected from "../../assets/img/PickupSelected.png";
import PickupNotSelected from "../../assets/img/PickupNotSelected.png";
import DeliverySelected from "../../assets/img/DeliverySelected.png";
import DeliveryNotSelected from "../../assets/img/DeliveryNotSelected.png";
import { DecryptReduxAuth } from "../../actions/General/General.js";
import { CustomFunc } from "../../actions/RSA/Rsa.js";
import { GuestUser } from "../../actions/Auth/Auth.js";

function MapComponent(props) {
  // CREATE LOCAL STATE
  const [locations, setLocations] = useState([]);
  const [locationType, setLocationType] = useState(
    props.searchId ? props.searchId : "pickup"
  );
  // const [locationType, setLocationType] = useState("pickup");
  const [selectedPlace, setSelectedPlace] = useState("");
  const [selectedPlaceDetail, setSelectedPlaceDetail] = useState({});
  const [selectedPlaceLatLng, setSelectedPlaceLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showingInfoWindow, setShowInfoWindow] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [showLocations, setShowLocations] = useState(false);

  // CREATE LOCAL STATE VARIABLE FOR PICKUP TIME IDENTIFICATION AND SELECTED TIME
  const [actionType, setActionType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  // GET ALL PICK UP LOCATIONS FROM REDUX STATE
  const pickUpLocations = useSelector(
    (state) => state.location.pickupLocations
  );
  const deliveryLocations = useSelector(
    (state) => state.location.deliveryLocations
  );

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  useEffect(() => {
    if (locationType === "pickup") setLocations(pickUpLocations);
    else setLocations(deliveryLocations);
  }, [pickUpLocations, deliveryLocations]);

  useEffect(() => {
    setShowInfoWindow(false);
  }, [locationType]);

  const updateLocations = (type) => {
    if (
      document.getElementsByClassName("map")[0] &&
      document
        .getElementsByClassName("map")[0]
        .querySelectorAll('[role = "dialog"]')[0]
    )
      document
        .getElementsByClassName("map")[0]
        .querySelectorAll('[role = "dialog"]')[0].parentElement.innerHTML = "";
    setSelectedLocation(null);
    setSelectedMarker(null);
    setLocationType(type);
    setLocations(null);
    setTimeout(() => {
      if (type === "pickup") setLocations(pickUpLocations);
      else setLocations(deliveryLocations);
    }, 100);
  };

  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE LOCAL STATE VARIABLES
  const [currentLatLng, setCurrentLatLng] = useState({
    lat: 37.79055703923744,
    lng: -122.40186705390119,
  });
  const [movedLatLng, setMovedLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState(12);

  const updateLatLong = () => {
    dispatch(GetDeliveryLocationsAPI());
    // RefreshClosestLocationAPI();
    // GET CURRENT USER POSITION FROM NAVIGATOR
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // SAVE CURRENT USER LATITUDE LONGITUDE IN VARIABLES
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;

        // UPDATE CURRENT USER LAT LONG
        setCurrentLatLng({ lat, lng });

        // GET PICK UP LOCATIONS FOR LATITUDE LONGITUDE
        dispatch(
          GetPickUpLocationsByRadiusAPI({ latitude: lat, longitude: lng })
        );
        // dispatch(GetPickUpLocationsAPI());
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      },
      { enableHighAccuracy: true }
    );
  };

  // CHECK USER HAS ALLOWED LOCATION ACCESS OR NOT
  const checkLocationAccess = (isAccessChanged) => {
    // RefreshClosestLocationAPI();
    if (!navigator.permissions) {
      // USER HAS NOT YET GRATED OR DECLINED TO LOCATION ACCESS
      dispatch(GetPickUpLocationsAPI());
      updateLatLong();
    } else {
      // CHECK FOR LOCATION LOCATION
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          // USER HAS GRANTED ACCESS ON LOCATION
          if (result.state === "granted") {
            updateLatLong();
          } else if (result.state === "prompt" && !isAccessChanged) {
            // USER HAS NOT YET GRATED OR DECLINED TO LOCATION ACCESS
            dispatch(GetPickUpLocationsAPI());
            updateLatLong();
          } else if (result.state === "denied" && !isAccessChanged) {
            // USER HAS DECLINED TO LOCATION ACCESS
            dispatch(GetPickUpLocationsAPI());
            dispatch(GetDeliveryLocationsAPI());
          }

          // CHECK LOCATION ACCESS HAS BEEN CHANGED
          result.onchange = function () {
            // CHECK LOCATION ACCESS AGAIN
            checkLocationAccess(true);
          };
        });
    }
  };

  useEffect(() => {
    if (auth.serverPublicKey === "") {
      CustomFunc().then(() => {
        // setLoader(false);
        // dispatch(GuestUser()).then(() => {
        //   checkLocationAccess(false);
        // });
        GuestUser(dispatch).then(() => {
          checkLocationAccess(false);
        });
      });
    } else {
      checkLocationAccess(false);
    }
  }, []);

  const onMarkerClick = (props, marker, e) => {
    setShowInfoWindow(() => false);
    setSelectedPlaceDetail(props.detail);
    setSelectedPlace(props.name);
    setSelectedPlaceLatLng({
      lat: props.detail.latitude,
      lng: props.detail.longitude,
    });
    setSelectedMarker(marker);
    setShowInfoWindow(() => true);
  };

  const onPopUpOpen = () => {
    document
      .getElementById("infoWindow")
      .addEventListener("click", function () {
        // console.log("clicked",selectedPlaceDetail);
        setSelectedLocation(selectedPlaceDetail);
        setActionType("pickTime");
      });
  };

  const searchArea = () => {
    // GET PICK UP LOCATIONS FOR LATITUDE LONGITUDE
    dispatch(
      GetPickUpLocationsByRadiusAPI({
        latitude: movedLatLng.lat,
        longitude: movedLatLng.lng,
      })
    );
    setShowSearchButton(false);
    // setShowLocations(true)
  };

  const mapDragEnd = (mapProps, map) => {
    setShowSearchButton(true);
    // UPDATE LAT LONG
    setMovedLatLng({ lat: map.center.lat(), lng: map.center.lng() });
  };
  return (
    <div className="map_view">
      <Map
        center={currentLatLng}
        className={"map"}
        zoom={zoom}
        google={props.google}
        onDragend={mapDragEnd}
      >
        <Marker
          className={"userMarker w-10"}
          position={{ lat: currentLatLng.lat, lng: currentLatLng.lng }}
          icon={{
            url: UserMarker,
            anchor: new props.google.maps.Point(39, 43),
            scaledSize: new props.google.maps.Size(39, 43),
          }}
        />
        {locations &&
          locations.map((location, index) => {
            return (
              <Marker
                onClick={onMarkerClick}
                key={index}
                name={location.name}
                detail={location}
                position={{
                  lat:
                    locationType === "pickup"
                      ? location.coordinates.coordinates[1]
                      : location.latitude,
                  lng:
                    locationType === "pickup"
                      ? location.coordinates.coordinates[0]
                      : location.longitude,
                }}
                icon={{
                  url:
                    location.latitude === selectedPlaceLatLng.lat &&
                    location.longitude === selectedPlaceLatLng.lng
                      ? locationType === "pickup"
                        ? PickupSelected
                        : DeliverySelected
                      : locationType === "pickup"
                      ? PickupNotSelected
                      : DeliveryNotSelected,
                  anchor: new props.google.maps.Point(39, 43),
                  scaledSize: new props.google.maps.Size(39, 43),
                }}
              />
            );
          })}
        {locations && selectedMarker !== "" ? (
          <InfoWindow
            onOpen={onPopUpOpen}
            style={{ borderRadius: "0", display: "none" }}
            className="info-window-detail"
            marker={selectedMarker ? selectedMarker : null}
            visible={showingInfoWindow}
          >
            <div
              id={"infoWindow"}
              className="cursor-pointer"
            >
              <p className="font-size-1_1 text-grey4 BrandenGrotesqueBold mb-0 text-uppercase">
                {locationType === "delivery"
                  ? selectedPlaceDetail.locationName
                  : selectedPlaceDetail.hasOwnProperty("streetAddress1")
                  ? selectedPlaceDetail.streetAddress1
                  : selectedPlace}
              </p>
              {locationType === "delivery" ? (
                selectedPlaceDetail.hasOwnProperty("fullAddress") ? (
                  <p className="font-size-0_9 text-grey1 BrownSTDRegular mb-0">
                    {[
                      selectedPlaceDetail.floorAddress
                        ? selectedPlaceDetail.fullAddress.split(",")[0] +
                          ", " +
                          selectedPlaceDetail.floorAddress
                        : selectedPlaceDetail.fullAddress.split(",")[0],
                      selectedPlaceDetail.city,
                      selectedPlaceDetail.state +
                        " " +
                        selectedPlaceDetail.zipCode,
                    ].join(", ")}
                  </p>
                ) : (
                  ""
                )
              ) : selectedPlaceDetail.hasOwnProperty("streetAddress1") ? (
                <p className="font-size-0_9 text-grey1 BrownSTDRegular mb-0">
                  {[
                    selectedPlaceDetail.city,
                    selectedPlaceDetail.state,
                    selectedPlaceDetail.zipCode,
                  ].join(", ")}
                </p>
              ) : (
                ""
              )}
            </div>
          </InfoWindow>
        ) : null}
      </Map>
      {showSearchButton && (
        <Button
          className="search-area default-letter-spacing text-center bg-brown2 border-brown2 text-white rounded-full w-auto m-auto btn-half px-4"
          type="button"
          variant=""
          onClick={searchArea}
        >
          Search this area
        </Button>
      )}
      <Locations
        locationType={locationType}
        updateLocations={updateLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        actionType={actionType}
        setActionType={setActionType}
        showLocations={showLocations}
        setShowLocations={setShowLocations}
      />
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(MapComponent);
