import React from "react";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import { LogoutAPI } from "../../actions/Auth/Auth";
import { useSelector } from "react-redux";
import { DecryptReduxAuth } from "../../actions/General/General";

export default function Menu({ show, setMenuClicked, setShowRewardModal }) {
  // GET REDUX AUTH STATE, SO THAT MENU WILL BE SHOWN ACCORDING TO THAT
  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  // CREATE HISTORY OBJECT
  const history = useHistory();

  // OPEN PAGE
  const openPage = (page) => {
    if (page !== "/reward") history.push(page);
    setMenuClicked(false);
  };

  // LOGOUT FUNCTION
  const logout = () => {
    // REDIRECT TO HOME
    openPage("/logout");
  };

  // OPEN PAGE
  const openExternalPage = (page, type) => {
    window.open(page, type).focus();
    setUserMenuClicked(false);
  };

  return (
    <Slide
      direction="right"
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={1000}
    >
      <div className="menu">
        <div className="menuItems">
          {auth.isGuest ? (
            <>
              <p
                onClick={() =>
                  openExternalPage("https://www.mixt.com", "_self")
                }
              >
                Home
              </p>
              <p onClick={() => openPage("/locations")}>Locations</p>
              <p onClick={() => openPage("/signin")}>Sign In</p>
              <p onClick={() => openPage("/signup")}>Sign Up</p>
            </>
          ) : (
            <>
              <p
                onClick={() =>
                  openExternalPage("https://www.mixt.com", "_self")
                }
              >
                Home
              </p>
              <p onClick={() => openPage("/dashboard")}>Order online</p>
              <p onClick={() => openPage("/menu")}>Menu</p>
              <p onClick={() => openPage("/locations")}>Locations</p>
              <p onClick={() => openPage("/orders")}>Order History</p>
              <p onClick={() => openPage("/profile")}>Profile</p>
              <p
                onClick={() => {
                  setShowRewardModal(true);
                  setMenuClicked(false);
                }}
              >
                Rewards
              </p>
              <p
                onClick={() =>
                  openExternalPage("https://www.mixt.com/help", "_self")
                }
              >
                Help
              </p>
              <p onClick={logout}>Logout</p>
            </>
          )}
        </div>
      </div>
    </Slide>
  );
}
