import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import DownArrow from "../../assets/img/DownArrowNew.png";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  SetLocationForMenuAPI,
  UpdateCampaignIdAPI,
} from "../../actions/Menu/Menu";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MessageDialog from "../MessageDialog";
import { ResetCartDetailAPI } from "../../actions/Cart/Cart";
import { useSelector, useDispatch } from "react-redux";
import { UpdateOrderDetailAPI } from "../../actions/Cart/Cart";
import { CircularProgress } from "@material-ui/core";
import DecisionDialog from "../DecisionDialog";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import {
  addFavoriteItemToOrder,
  completeEditCart,
  createAllSlotsOfSelectedLocation,
  createMenuLocationDetailObject,
  getDeliveryLocationDetail,
  getFlowType,
  isNeededToEmptyCart,
} from "./LocationFunction";
import { createEmptyCart } from "../Menu/MenuFunctions";

function SelectTime({
  locationType,
  selectedLocation,
  setActionType,
  parentPage = "",
  addPrepTimeMinutes = 0,
}) {
  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  const location = new URLSearchParams(window.location.search);

  // GET SELECTED LOCATION DETAIL FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);

  // SET LOCAL STATE
  const [slots, setSlots] = useState({});
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    moment.tz(selectedLocation.timezone).unix()
  );
  const [flowType, setFlowType] = useState("");
  const [timezone, setTimezone] = useState("");
  const [visibility, setVisibility] = useState("hidden");
  const [showSelectedTimeError, setShowSelectedTimeError] = useState(false);
  const [locationForMenu, setLocationForMenu] = useState({});
  const [loader, setLoader] = useState(false);
  const [takeDecision, setTakeDecision] = useState(false);
  const [isFavoriteErrorDisplayed, setIsFavoriteErrorDisplayed] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [generalError, setGeneralError] = useState("");

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitSelectedTime();
    }
  };

  const clearError = () => {
    setGeneralError("");
    setShowSelectedTimeError(false);
  };

  useEffect(() => {
    let flowType = getFlowType(location, parentPage);
    setFlowType(flowType);
    calculateSlot();
    let isGroupOrder = location.get("is_group") || false;
    let APIObj = createMenuLocationDetailObject(
      locationType,
      selectedLocation,
      isGroupOrder
    );
    APIObjCreate(APIObj);
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [selectedDate]);

  useEffect(async () => {
    let keys = Object.keys(slots);
    if (
      flowType === "reorder" &&
      keys.length > 0 &&
      locationForMenu.hasOwnProperty("orderType")
    ) {
      await ResetCartDetailAPI(dispatch);
      let locationObject = locationForMenu;
      locationObject.selectedTime = slots[keys[0]].slots[0];
      let result = null;
      if (locationType === "delivery")
        result = await getNearestLocationDetail(locationObject);
      createEmptyCart(0, locationObject, locationObject.selectedTime);
      if ((result && result.status) || locationType === "pickup")
        setTimeout(() => {
          setActionType(1, "");
        }, 300);
      else
        setActionType(0, result && result.message ? result.message : "Error");
    }
  }, [slots, locationForMenu]);

  const calculateSlot = () => {
    let result = createAllSlotsOfSelectedLocation(
      selectedDate,
      selectedLocation,
      addPrepTimeMinutes,
      locationType
    );
    setTimezone(result.timezone);
    setSlots(result.allSlots);
  };

  const getNearestLocationDetail = async (locationObject = null) => {
    let result = await getDeliveryLocationDetail(
      selectedLocation,
      Boolean(locationObject) ? locationObject : locationForMenu,
      Boolean(locationObject) ? locationObject.selectedTime : selectedTime
    );
    if (!result.status) {
      setGeneralError(result.error);
      setShowSelectedTimeError(true);
      setLoader(false);
      return { status: false, message: result.error };
    } else {
      APIObjCreate(result.data);
      return { status: true, message: "" };
    }
  };

  const APIObjCreate = (APIObj) => {
    let itemAvailabilityData = { ...APIObj };
    if (itemAvailabilityData.locationId1 && itemAvailabilityData.locationId2) {
      APIObj.type = "common";
      itemAvailabilityData.type = "common";
    }
    if (parentPage !== "cart") {
      setLocationForMenu(APIObj);
    }
  };

  const discardLocationChanges = () => {
    setTakeDecision(false);
    history.push("/dashboard");
  };

  const submitSelectedTime = async () => {
    // IF TIME IS NOT SELECTED SHOW THE ERROR DIALOG
    if (selectedTime === 0) {
      setShowSelectedTimeError(true);
      setGeneralError(
        locationType === "delivery"
          ? "Please select a date and time for your delivery."
          : "Please select a date and time to pick Up your order."
      );
    } else {
      setLoader(true);
      let locationObject = _.cloneDeep(locationForMenu);
      let isGroupOrder = location.get("is_group") || false;
      let isLocationChanged =
        flowType === "edit"
          ? false
          : isNeededToEmptyCart(orderData, locationObject, isGroupOrder);
      if (isLocationChanged) {
        setTakeDecision(true);
      } else {
        await finalizeFlow(1);
      }
    }
  };

  const finalizeFlow = async (isResetCart = 0) => {
    setTakeDecision(false);
    let isCartCleared = false;
    if (!isResetCart) {
      isCartCleared = true;
      await ResetCartDetailAPI(dispatch);
    }

    let isCartExist = orderData.hasOwnProperty("orderType");
    let campaignSelected = location.get("cid") || "";
    dispatch(UpdateCampaignIdAPI(campaignSelected));

    if (flowType === "edit")
      completeEditCart(orderData, selectedTime, dispatch, setActionType);
    else {
      let result = true;
      if (locationType === "delivery") {
        result = await getNearestLocationDetail();
      }
      if (result === true || (result && result.status)) {
        let isReward = location.get("is_reward") || false;
        let isGroupOrder = false;
        if (flowType === "group") {
          isGroupOrder = true;
        }
        result = "";
        if (!isCartExist || isCartCleared)
          result = await createEmptyCart(
            isGroupOrder,
            locationForMenu,
            selectedTime,
            "",
            isReward ? orderData.discounts : false
          );

        if (result !== "") {
          setShowError(true);
          setErrorMessage(result);
        } else {
          if (flowType == "favorite") {
            let favoriteItemId = location.get("id");
            let favoriteResult = await addFavoriteItemToOrder(
              locationForMenu,
              favoriteItemId,
              history,
              dispatch
            );
            if (favoriteResult && !favoriteResult.status) {
              setIsFavoriteErrorDisplayed(true);
              setShowError(true);
              setErrorMessage(favoriteResult.error);
            }
          } else {
            if (orderData.hasOwnProperty("orderType") && isResetCart === 1) {
              let orderItem = _.cloneDeep(orderData);
              orderItem.orderDate = selectedTime;
              dispatch(UpdateOrderDetailAPI(orderItem));
            }
            setTimeout(() => {
              history.push("/menu");
            }, [1000]);
          }
        }
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!showError) {
      let anyFavoriteItemSelected = location.get("id");
      if (anyFavoriteItemSelected !== null && isFavoriteErrorDisplayed) {
        history.push("/menu");
      }
    }
  }, [showError]);

  return (
    <>
      {parentPage !== "reorder" && (
        <div>
          <img
            className="ms-md-4 mt-md-4 cursor-pointer back-image"
            alt={"Back image"}
            onClick={() => setActionType("")}
          />
          <p
            className={`text-center BrownSTDRegular font-size-1_2 ${
              location.get("is_group") ? "text-orange1" : "text-grey4"
            } mb-2 mt-3 mt-md-4 pt-md-3`}
          >
            Select {location.get("is_group") ? "Group Order " : ""}
            {locationType === "pickup" ? "Pick Up" : "Delivery"} Date & Time
          </p>
          <div
            className={`slots_list ${
              parentPage === "cart" ? "cpb-12" : "pb-5"
            } pt-4 mb-5`}
          >
            {Object.entries(slots).map(([key, value], index) => {
              let slotsList = value.slots;
              return (
                <div key={index}>
                  <div className="position-relative" key={index}>
                    <div
                      onClick={() => {
                        setVisibility("visible1");
                      }}
                      className={`d-flex justify-content-between ${
                        index === 0 ? "cursor-pointer" : ""
                      }`}
                    >
                      <h4 className="BrownSTDRegular font-size-0_9 text-grey1 pt-2">
                        <span className="BrownSTDBold font-size-1 me-2">
                          {value.tag}
                        </span>
                        <span>{key}</span>
                      </h4>
                      <img
                        src={DownArrow}
                        alt={"Down Arrow"}
                        className={"align-self-center"}
                      />
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        style={{ position: "absolute", visibility: "hidden" }}
                        autoOk={true}
                        value={selectedDate * 1000}
                        open={visibility === "visible1" ? true : false}
                        disableToolbar
                        aria-required="true"
                        variant="inline"
                        onClose={() => setVisibility("hidden")}
                        format="dd-MM-yyyy"
                        onChange={(e) => {
                          setVisibility("hidden");
                          setSelectedDate(moment.tz(e, timezone).unix());
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {slotsList.map((timeVal, index1) => {
                    if (index1 > 0) {
                      return (
                        <p
                          className={`BrownSTDLight font-size-0_9 text-grey1 cursor-pointer position-relative ${
                            selectedTime === slotsList[index1 - 1]
                              ? "selectedTime bg-yellow1"
                              : ""
                          } `}
                          key={index1}
                          onClick={() => setSelectedTime(slotsList[index1 - 1])}
                        >
                          {`${moment
                            .tz(slotsList[index1 - 1] * 1000, timezone)
                            .format("hh:mm A")} - ${moment
                            .tz(timeVal * 1000, timezone)
                            .format("hh:mm A")}`}
                        </p>
                      );
                    }
                  })}
                </div>
              );
            })}

            <div className="position-relative">
              <div
                onClick={() => {
                  setVisibility("visible2");
                }}
                className={`d-flex justify-content-between cursor-pointer`}
              >
                <h4 className="BrownSTDRegular font-size-0_9 text-grey1 pt-2">
                  <span className="BrownSTDBold font-size-1 me-2">
                    Select Another Date & Time
                  </span>
                </h4>
                <img
                  src={DownArrow}
                  alt={"Down Arrow"}
                  className={"align-self-center"}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ position: "absolute", visibility: "hidden" }}
                  autoOk={true}
                  value={selectedDate * 1000}
                  open={visibility === "visible2" ? true : false}
                  disableToolbar
                  aria-required="true"
                  variant="inline"
                  onClose={() => setVisibility("hidden")}
                  format="dd-MM-yyyy"
                  onChange={(e) => {
                    setVisibility("hidden");
                    setSelectedDate(moment.tz(e, timezone).unix());
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="text-center time-confirm-button">
            <Button
              className="mb-4 default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full btn-half width-268 m-auto"
              type="submit"
              variant=""
              onClick={submitSelectedTime}
            >
              {loader ? <CircularProgress size={24} /> : "Confirm"}
            </Button>
          </div>

          <MessageDialog
            showError={showSelectedTimeError}
            text={generalError}
            closeDialog={clearError}
          />
          <DecisionDialog
            showError={takeDecision}
            text={
              "Changing Pick Up or Delivery locations will restart your order."
            }
            noOption={discardLocationChanges}
            yesOption={finalizeFlow}
            heading={"Change Location"}
          />
          <ErrorSuccessDialog
            showError={showError}
            text={errorMessage}
            closeDialog={setShowError}
          />
        </div>
      )}
    </>
  );
}
export default SelectTime;
