import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseImg from "../../assets/img/CloseCircle.svg";
import Checkout from "./Checkout";
import CardsList from "./CardsList";
import AddCardForm from "./AddCardForm";
import { useSelector, useDispatch } from "react-redux";
import {
  RemoveGroupOrderBasicDetailAPI,
  ResetCartDetailAPI,
  SubmitOrderDataAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import { useHistory } from "react-router-dom";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import { UpdateLastOrderAPI } from "../../actions/Orders/Order";
import { CreateGoogleAnalyticsAPI } from "../../actions/General/General";

function PaymentModel({
  totalPrice,
  setDisplayContentType,
  orderDestination,
  updatePaymentInfo,
  setIsCalculateAPIRequiredToCall,
}) {
  // CREATE LOCAL STATE
  const [displayType, setDisplayType] = useState(1); // 1 = SHOW CHECKOUT, 2 = SHOW CARDS LIST, 3 = ADD CARD FORM
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHeading, setErrorHeading] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [userHavePaymentCards, setUserHavePaymentCards] = useState(true);
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const orderDetailData = useSelector((state) => state.order.calculated_order);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    CreateGoogleAnalyticsAPI("PaymentView", {});
  });

  const updatePaymentCard = (selectedCardId, index) => {
    setSelectedCardIndex(index);
    let orderItem = _.cloneDeep(orderData);

    if (selectedCardId !== "") {
      orderItem.payment_info = {
        ...orderItem.payment_info,
        idPaymentCard: parseInt(selectedCardId),
      };
    }
    // UPDATE DATA IN REDUX STATE
    dispatch(UpdateOrderDetailAPI(orderItem));
  };

  const placeOrder = async () => {
    // GET ORDER DETAIL
    let orderDetail = _.cloneDeep(orderData);

    // REMOVE TIP DETAIL THAT USED FOR VISIBILITY
    delete orderDetail.tipPercentage;
    delete orderDetail.tipAmount;

    // SET ORDER DATE
    orderDetail.orderDate = orderDetailData.orderDate;

    // GET PAYMENT INFO
    let payment_info = _.cloneDeep(orderDetail.payment_info);

    // UPDATE TOTAL AMOUNT
    payment_info.subTotalAmount = parseFloat(orderDetailData.subtotal);
    payment_info.totalAmount = parseFloat(orderDetailData.total);
    payment_info.tipName = "Tip";

    // UPDATE PAYMENT INFO
    orderDetail.payment_info = payment_info;

    // UPDATE CALC_RESP_INFO KEY
    orderDetail.calc_resp_info = JSON.stringify(orderDetailData);
    // SUBMIT ORDER
    if (orderDetail.payment_info.idPaymentCard === 0) {
      setLoader(false);
      setShowErrorDialog(true);
      setErrorMessage("Payment Method");
      setUserHavePaymentCards(false);
    } else {
      let orderDetailResponse = await SubmitOrderDataAPI(orderDetail);
      if (!orderDetailResponse.generalError) {
        // EMPTY CART DATA
        CreateGoogleAnalyticsAPI("OrderSubmitted", {});
        CreateGoogleAnalyticsAPI("MixtOrderingReceipt", {
          TheReceiptTotal: orderDetail.payment_info.totalAmount,
          TheReceiptOrderId: orderDetailResponse.data.orderId,
          Platform: "Web",
        });
        setIsOrderSubmitted(true);
        // setShowErrorDialog(true);
        // setErrorMessage("Order Placed");
        setDisplayContentType('orderCreatedSuccessFully')
        dispatch(UpdateLastOrderAPI(true));
      } else {
        dispatch(UpdateLastOrderAPI(true));
        setLoader(false);
        setShowErrorDialog(true);
        setErrorHeading("Error");
        setErrorMessage(orderDetailResponse.generalError);
      }
    }
  };

  const updateModelStatus = async (status) => {
    setShowErrorDialog(status);
    if (!userHavePaymentCards) {
      setUserHavePaymentCards(true);
      setDisplayType(3);
    } else if (isOrderSubmitted) {
      RemoveGroupOrderBasicDetailAPI(dispatch);
      setTimeout(async () => {
        await ResetCartDetailAPI(dispatch);
        history.push("/orders");
      }, 300);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="draggable-dialog-title"
        className="customize_modal paymentModel"
        onClose={() => {
          if (!loader)
            displayType === 1 ? setDisplayContentType("") : setDisplayType(1);
        }}
      >
        <img
          src={CloseImg}
          alt={"Close Image"}
          className={"close_payment_modal cursor-pointer"}
          onClick={() => {
            if (!loader)
              displayType === 1 ? setDisplayContentType("") : setDisplayType(1);
          }}
        />
        <DialogContent className="p-5 m-0 border-0 bg-white">
          {displayType === 1 ? (
            <Checkout
              selectedCardIndex={selectedCardIndex}
              totalPrice={totalPrice}
              placeOrder={placeOrder}
              orderDestination={orderDestination}
              setDisplayType={setDisplayType}
              updatePaymentInfo={updatePaymentInfo}
              loader={loader}
              setLoader={setLoader}
            />
          ) : displayType === 2 ? (
            <CardsList
              setDisplayType={setDisplayType}
              selectedCardIndex={selectedCardIndex}
              setSelectedCardIndex={updatePaymentCard}
              setIsCalculateAPIRequiredToCall={setIsCalculateAPIRequiredToCall}
            />
          ) : (
            <AddCardForm
              updatePaymentCard={updatePaymentCard}
              setDisplayType={setDisplayType}
            />
          )}
        </DialogContent>
      </Dialog>
      <ErrorSuccessDialog
        showError={showErrorDialog}
        text={errorMessage}
        closeDialog={updateModelStatus}
      />
    </>
  );
}

export default PaymentModel;
