import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment-timezone";
import {
  getTimeAndClosedStatus,
  getBusinessAndCutOffTiming,
} from "../../actions/General/General";

function PickLocationItem({
  location,
  setSelectedLocation,
  setActionType,
  showLocations,
}) {
  // SET LOCAL STATE
  const [isClosedNow, setIsClosedNow] = useState(false);
  const [timezone, setTimeZone] = useState("");
  const [workStartTime, setWorkStartTime] = useState(0);
  const [workEndTime, setWorkEndTime] = useState(0);
  const [holidayStartTime, setHolidayStartTime] = useState(0);
  const [holidayEndTime, setHolidayEndTime] = useState(0);

  useEffect(() => {
    let timezone = location.timezone;
    setTimeZone(timezone);
    let currentDay = moment.tz(timezone).day();

    let days = [0, 1, 2, 3, 4, 5, 6];
    while (days.length > 0 && currentDay !== undefined && currentDay !== null) {
      // GET BUSINESS AND CUT OFF TIMING OF SELECTED LOCATION
      let { businessTimings, cutoffTimings } = getBusinessAndCutOffTiming(
        "pickup",
        location,
        currentDay
      );
      if (businessTimings.openTime !== 0) {
        // GET START TIME, END TIME AND CLOSE STATUS
        let { startTime, endTime, isClosed } = getTimeAndClosedStatus(
          "pickup",
          businessTimings,
          cutoffTimings,
          timezone,
          "",
          location.locationExceptions
        );
        // UPDATE STATS
        setIsClosedNow(isClosed);
        if ((currentDay === 0 || currentDay === 6) && holidayStartTime === 0) {
          setHolidayStartTime(startTime);
          setHolidayEndTime(endTime);
          days = days.filter((value) => value !== 0 && value !== 6);
        } else if (workStartTime === 0) {
          setWorkStartTime(startTime);
          setWorkEndTime(endTime);

          days = days.filter((value) => value === 0 && value === 6);
        } else {
          days = days.filter((value) => value !== currentDay);
        }
      } else {
        days = days.filter((value) => value !== currentDay);
      }
      const random = Math.floor(Math.random() * days.length);
      currentDay = days[random];
    }
  }, [location]);
  return (
    <div
      className={`location_item mb-4 pb-3 mx-2 ${
        !showLocations ? "d-none" : ""
      } d-lg-block`}
    >
      <h1 className="BrandenGrotesqueBold font-size-1_2 default-letter-spacing text-grey4 text-uppercase mb-1">
        {location.name}
      </h1>
      <p className="BrownSTDRegular font-size-0_9 text-grey5 mb-0">
        {location.description}
      </p>
      <p className="BrownSTDBold font-size-0_9 text-grey1 mb-1">
        {[
          location.streetAddress1,
          location.streetAddress2,
          location.city,
          location.state,
          location.zipCode,
        ]
          .filter(Boolean)
          .join(", ")}
      </p>
      <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1">
        {workStartTime > 0 &&
          `Monday - Friday, ${moment
            .tz(workStartTime * 1000, timezone)
            .format("hh:mm A")} - ${moment
            .tz(workEndTime * 1000, timezone)
            .format("hh:mm A")}`}
      </p>
      {holidayStartTime > 0 && (
        <p className="BrownSTDRegular font-size-0_9 text-grey1 mb-1 ">
          Saturday - Sunday,
          {" " +
            moment.tz(holidayStartTime * 1000, timezone).format("hh:mm A")}{" "}
          -{" " + moment.tz(holidayEndTime * 1000, timezone).format("hh:mm A")}
        </p>
      )}

      {isClosedNow && (
        <p className="BrownSTDRegular font-size-0_9 text-orange1 mb-1">
          Closed Now
        </p>
      )}

      <div className="w-100 text-end">
        <Button
          onClick={() => {
            setActionType("pickTime");
            setSelectedLocation(location);
          }}
          variant=""
          className="text-white bg-brown1 border-brown1 rounded-full BrandenGrotesqueBold font-size-1 default-letter-spacing text-uppercase width-93 height-30 line-height-1 cpy-5 px-0"
        >
          Select
        </Button>
      </div>
    </div>
  );
}

export default PickLocationItem;
