import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import {
  RefreshCartItems,
  addGroupOrderParticipantItemsDetail,
} from "./cartFunctions";
import MessagesList from "../../config/messages.json";
import { UpdateOrderDetailAPI } from "../../actions/Cart/Cart";

function OrderPrice({
  pricingData,
  flow,
  discounts,
  tipAmount,
  isGroupOrder = false,
  setDisplayContentType,
  displayContentType = "",
  setErrorMessage,
  setErrorTitle,
  setErrorType,
  setIsCalculateAPIRequiredToCall,
}) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);

  const [loader, setLoader] = useState(false);

  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE
  const dispatch = useDispatch();

  const orderPriceSubmit = async () => {
    flow === "Reorder"
      ? setDisplayContentType("reorder")
      : flow === "GroupParticipant"
      ? participantGroupOrder()
      : flow === "GroupAdmin"
      ? GroupOrderAdminCartContinue()
      : setDisplayContentType("payment");
  };
  const GroupOrderAdminCartContinue = async () => {
    setIsCalculateAPIRequiredToCall(false);
    let isCallAPI = await RefreshCartItems(dispatch);
    if (isCallAPI) {
      setErrorType("more items added");
      setErrorTitle(MessagesList["More Items Added Title"]);
      setErrorMessage(MessagesList["More Items Added"]);
    } else {
      setDisplayContentType("payment");
    }
  };
  const participantGroupOrder = async () => {
    setLoader(true);
    let result = await addGroupOrderParticipantItemsDetail(dispatch);

    if (result.hasOwnProperty("generalError") && result.generalError) {
      setErrorType("order cancelled");

      if ("Group order is submitted." === result.generalError) {
        setErrorTitle(MessagesList["Group order is submitted Title"]);
        setErrorMessage(MessagesList["Group order is submitted"]);
      } else {
        setErrorTitle(MessagesList["Group order is cancelled Title"]);
        setErrorMessage(MessagesList["Group order is cancelled"]);
      }
    } else setDisplayContentType("orderCreatedSuccessFully");
  };
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!isPromoModalOpen) orderPriceSubmit();
    }
  };
  const RemovePromoCode = () => {
    setIsCalculateAPIRequiredToCall(true);
    let orderItem = _.cloneDeep(orderData);
    delete orderItem.discounts;
    dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
  };
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [orderPriceSubmit]);

  return (
    <>
      <div className={`d-flex flex-column px-4 ${flow !== "GroupParticipant" ? "py-3":"pt-3"}`}>
        {/* TAX/TOTAL DIV */}
        <>
          <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
            <span>subtotal</span>
            <span>${pricingData.subtotal}</span>
          </div>

          {flow !== "GroupParticipant" && pricingData.employerSurcharge > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <span
                className="border-white3 border-bottom cursor-pointer"
                onClick={() => {
                  if (setIsCalculateAPIRequiredToCall)
                    setIsCalculateAPIRequiredToCall(false);
                  setErrorType("employerSurcharge");
                  setErrorTitle(pricingData.employerSurchargeName);
                  setErrorMessage(
                    "A 6% surcharge will be added to your bill to help mitigate the costs of employee benefits."
                  );
                }}
              >
                {pricingData.employerSurchargeName}
              </span>
              <span>${pricingData.employerSurcharge}</span>
            </div>
          )}

          {flow !== "GroupParticipant" && pricingData.deliverySurcharge > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <span
                className="border-white3 border-bottom cursor-pointer"
                onClick={() => {
                  if (setIsCalculateAPIRequiredToCall)
                    setIsCalculateAPIRequiredToCall(false);
                  setErrorType("deliverySurcharge");
                  setErrorTitle(pricingData.deliverySurchargeName);
                  setErrorMessage(
                    "This is a separate price for delivery service and changes based on the volume and destination of your order. Please view your cart for the price to deliver your current order. This is not a gratuity."
                  );
                }}
              >
                {pricingData.deliverySurchargeName}
              </span>
              <span>${pricingData.deliverySurcharge}</span>
            </div>
          )}

          {flow !== "GroupParticipant" && discounts && discounts.length > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <div>
                <span>
                  rewards -{" "}
                  {discounts[0].rewardType === "redemption_code"
                    ? discounts[0].rewardId
                    : discounts[0].name}
                </span>
                {discounts[0].rewardType === "redemption_code" &&
                  flow !== "Reorder" && (
                    <span
                      onClick={() => {
                        RemovePromoCode();
                      }}
                      className="text-decoration-underline cursor-pointer ms-3"
                    >
                      Remove
                    </span>
                  )}
              </div>
              <span className="width-85 text-right">
                - ${pricingData.discount}
              </span>
            </div>
          )}

          {flow !== "GroupParticipant" &&
            pricingData.serviceFeeSurcharge > 0 && (
              <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
                <span
                  className="border-white3 border-bottom cursor-pointer"
                  onClick={() => {
                    if (setIsCalculateAPIRequiredToCall)
                      setIsCalculateAPIRequiredToCall(false);
                    setErrorType("serviceFeeCharge");
                    setErrorTitle(pricingData.serviceFeeSurchargeName);
                    setErrorMessage(
                      "15% of your subtotal will be added to your bill to facilitate the logistics of your order and order delivery. Not an employee gratuity."
                    );
                  }}
                >
                  {pricingData.serviceFeeSurchargeName}
                </span>
                <span>${pricingData.serviceFeeSurcharge}</span>
              </div>
            )}

          {flow !== "GroupParticipant" &&
            pricingData.internalDeliverySurcharge > 0 && (
              <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
                <span
                  className="border-white3 border-bottom cursor-pointer"
                  onClick={() => {
                    if (setIsCalculateAPIRequiredToCall)
                      setIsCalculateAPIRequiredToCall(false);
                    setErrorType("deliverySurcharge");
                    setErrorTitle(pricingData.internalDeliverySurchargeName);
                    setErrorMessage(
                      "This is a separate price for delivery service and changes based on the volume and destination of your order. Please view your cart for the price to deliver your current order. This is not a gratuity."
                    );
                  }}
                >
                  {pricingData.internalDeliverySurchargeName}
                </span>
                <span>${pricingData.internalDeliverySurcharge}</span>
              </div>
            )}

          {flow !== "GroupParticipant" && pricingData.otherSurcharges > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <span>Other Charge</span>
              <span>${pricingData.otherSurcharges}</span>
            </div>
          )}

          {flow !== "GroupParticipant" && pricingData.totalTax > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <span>tax</span>
              <span>${pricingData.totalTax}</span>
            </div>
          )}
          {flow === "Reorder" && tipAmount > 0 && (
            <div className="d-flex justify-content-between BrownSTDRegular font-size-0_9 text-capitalize mb-2">
              <span>tip</span>
              <span>${tipAmount.toFixed(2)}</span>
            </div>
          )}
        </>
        {flow !== "GroupParticipant" && (
          <div className="d-flex justify-content-between BrownSTDBold font-size-0_9 text-capitalize mb-4">
            <span>total</span>
            <span className="BrownSTDBold">
              ${pricingData.displayTotalAmount}
            </span>
          </div>
        )}
        <div className="text-center">
          {!isGroupOrder && (
            <Button
              className={`mw-100 btn-half ${
                flow === "GroupParticipant"
                  ? "add_to_group_order"
                  : "width-268 font-size-1_1"
              } default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 text-white rounded-full m-auto`}
              type="button"
              variant=""
              disabled={flow === "GroupParticipant" && loader}
              onClick={() => {
                orderPriceSubmit();
              }}
            >
              {flow === "Reorder" ? (
                displayContentType === "reorder" ? (
                  <CircularProgress size={24} />
                ) : (
                  "Reorder - $" + pricingData.displayTotalAmount
                )
              ) : flow === "GroupParticipant" ? (
                loader ? (
                  <CircularProgress size={24} />
                ) : (
                  "Add To Group Order"
                )
              ) : pricingData.displayTotalAmount ? (
                "Continue - $" + pricingData.displayTotalAmount
              ) : (
                "Continue - $"
              )}{" "}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default OrderPrice;
