import React, { useState, useEffect } from "react";
import { FieldsValidation } from "../../actions/General/General";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

function TextFieldComponent({
  id = null,
  hasFocus = null,
  hasBlur = null,
  label,
  name,
  value,
  required = false,
  setValue,
  placeholder,
  type = "text",
  autocomplete = true,
  disabled = false,
  errorMessages = {},
  setErrorMessages,
  validationType = "",
  mapRef = null,
  classes = "",
}) {
  // CREATE LOCAL STATE FOR VALUE
  const [localValue, setLocalValue] = useState(
    value === undefined || value === null ? "" : value
  );
  const [localType, setLocalType] = useState(type);

  // FUNCTION CALLED WHEN FIELD VALUE IS CHANGED
  const valueChanged = (text, isOnBlur) => {
    let isProceed = true;
    if (type === "number") {
      text = text < 0 ? 0 : text;
      if (text >= 10000) isProceed = false;
      let textParts = text.split(".");
      if (textParts.length > 1) {
        text = parseFloat(text).toFixed(
          textParts[1].length > 2 ? 2 : textParts[1].length
        );
      }

      if (value === text) {
        isProceed = false;
      }
      if (isOnBlur) {
        isProceed = true;
      }
    }
    if (isProceed) {
      // APPLY EMPTY CHECK IF REQUIRED
      if (required && text === "") {
        updateErrorMessages(label + " is required");
      } else {
        // APPLY CUSTOM VALIDATION
        if (validationType && text !== "") {
          let message = FieldsValidation(text, validationType);
          updateErrorMessages(message);
        } else {
          updateErrorMessages("");
        }
      }

      // UPDATE FIELD VALUE LOCALLY AND IN PARENT COMPONENT
      setLocalValue(text);
      // if (type === "number") setValue(text, isOnBlur);
      if (type === "number") setValue(text, true);
      else setValue(text);
    }
  };

  // UPDATE ERROR MESSAGE
  const updateErrorMessages = (error) => {
    if (validationType || required) {
      let updatedErrorMessages = { ...errorMessages, [name]: error };
      setErrorMessages(updatedErrorMessages);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    setLocalType(type);
  }, [type]);

  const togglePassword = () => {
    setLocalType((localType) =>
      localType === "password" ? "text" : "password"
    );
  };
  return (
    <>
      <TextField
        id={id}
        onFocus={() => {
          if (hasFocus) {
            hasFocus();
          }
        }}
        onBlur={() => {
          if (hasBlur) {
            hasBlur();
          }
        }}
        inputRef={mapRef}
        className={"textField w-100 cmb-30 " + classes}
        placeholder={placeholder}
        // autoComplete={autocomplete}
        autoComplete={autocomplete.toString()}
        label={label}
        name={name}
        type={localType}
        aria-label={label}
        aria-required="true"
        fieldtype={type}
        disabled={disabled}
        required={required}
        value={localValue}
        onChange={(e) => valueChanged(e.target.value, 0)}
        error={errorMessages[name] && errorMessages[name] !== "" ? true : false}
        helperText={errorMessages[name]}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={togglePassword} position="end">
              {localType === "password" ? "show" : "hide"}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
export default TextFieldComponent;
