import { createSlice } from "@reduxjs/toolkit";

export const MenuReducer = createSlice({
  name: "menu",
  initialState: {
    loader: false,
    campaign_id: "",
    menu_list: [],
    location_info: {
      location_id1: "",
      location_id2: "",
      location_type: "",
      order_type: "",
      estimatedTimeMinutes: 0,
      menuShowAddress: "",
    },
    item_detail: {},
    dressing_detail: [],
  },
  reducers: {
    AddLocation: (state, action) => {
      state.location_info.location_id1 = action.payload.locationId1;
      state.location_info.location_id2 = action.payload.locationId2
        ? action.payload.locationId2
        : "";
      state.location_info.location_type = action.payload.type;
      state.location_info.order_type = action.payload.orderType;
      state.location_info.menuShowAddress = action.payload.menuShowAddress;
      state.location_info.estimatedTimeMinutes = action.payload
        .estimatedTimeMinutes
        ? action.payload.estimatedTimeMinutes
        : 0;
    },
    UpdateMenuCategories: (state, action) => {
      state.menu_list = action.payload.menuList;
      state.loader = false;
    },
    UpdateItemDetail: (state, action) => {
      state.item_detail = action.payload.itemDetail;
    },
    UpdateCampaignId: (state, action) => {
      state.campaign_id = action.payload.campaignId;
    },
    UpdateDressingDetail: (state, action) => {
      state.dressing_detail = action.payload.dressingDetail;
    },
    UpdateMenuLoader: (state, action) => {
      state.loader = action.payload.loader;
    },
  },
});
