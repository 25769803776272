import React, { useEffect, useRef, useState } from "react";
import SuggestionDefaultImage from "../../assets/img/SuggestionDefaultImage.png";
import { useDraggable } from "react-use-draggable-scroll";
import { getImageUrl } from "../../actions/General/General";
import { useDispatch } from "react-redux";
import { GetSuggestionItemsAPI } from "../../actions/Cart/Cart";
import { addSuggestionItemToCart } from "./cartFunctions";

function Suggestions({
  locationId,
  flow,
  setIsCalculateAPIRequiredToCall = null,
}) {
  // CREATE DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);

  useEffect(async () => {
    if (locationId) {
      let result = await GetSuggestionItemsAPI(locationId);
      setSuggestions(result.data);
    }
  }, [locationId]);

  // CREATE REF FOR SUGGESTION DRAGGABLE
  const suggestionsRef = useRef();
  const { suggestionsEvents } = useDraggable(suggestionsRef);

  return (
    <div className="mx-2 mx-md-4 my-3 border-bottom-1">
      {/* SUGGESTIONS DIV */}
      <h2 className="BrandenGrotesqueBold font-size-1_3 text-grey1 border-bottom-1 pb-2">
        Favorite Additions
      </h2>
      <div
        className={`d-flex overflow-x-scroll scrollbar-hide ${
          flow === "GroupParticipant" ? "flex-row" : "flex-row flex-md-column"
        }`}
        {...suggestionsEvents}
        ref={suggestionsRef}
      >
        {suggestions.map((suggestionItem) => {
          let image = getImageUrl(suggestionItem);
          return (
            <div
              key={suggestionItem.idSuggestionItems}
              className="suggestionItem d-flex align-items-center p-2 mb-md-3 cursor-pointer flex-1"
              onClick={() =>
                addSuggestionItemToCart(
                  suggestionItem,
                  setIsCalculateAPIRequiredToCall,
                  dispatch
                )
              }
            >
              <img
                src={image ? image : SuggestionDefaultImage}
                alt={suggestionItem.itemName}
                className={"cart-image-border"}
              />
              <div className="d-flex flex-column mx-3">
                <h3 className="text-grey1 text-capitalize BrownSTDRegular font-size-0_8 mb-0">
                  {suggestionItem.itemName}
                </h3>
                <p className="text-grey1 text-capitalize BrownSTDBold font-size-1 mb-0">
                  ${suggestionItem.price.toFixed(2)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {/* SUGGESTIONS DIV */}
    </div>
  );
}

export default Suggestions;
