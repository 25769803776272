import React, { Suspense } from "react";
import { Row } from "react-bootstrap";
import LogoImage from "../../assets/img/Logo.svg";
import { CircularProgress } from "@material-ui/core";

function Logo() {
  return (
    <Suspense fallback={CircularProgress}>
      <Row className={"flex-column align-content-center align-items-center"}>
        <img src={LogoImage} alt={"Logo"} />
      </Row>
    </Suspense>
  );
}

export default Logo;
