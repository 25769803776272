import React, { useState } from "react";
import {
  CancelChangeOrderAPI,
  UpdateLastOrderAPI,
} from "../../actions/Orders/Order";
import { CircularProgress } from "@material-ui/core";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import moment from "moment-timezone";
import DecisionDialog from "../DecisionDialog";
import MessagesList from "../../config/messages.json";
import { useDispatch } from "react-redux";

function CancelChangeOrder({
  idOrderHistory,
  orderDate,
  locationTimeZone,
  cancelOrderIds,
  setCancelOrderIds,
}) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [cancelChangeOrderDetail, setCancelChangeOrderDetail] = useState({
    id: 0,
    type: 0,
  });

  const [errorHeading, setErrorHeading] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  var now = moment.tz(locationTimeZone).format("L");
  var orderTime = moment.tz(orderDate * 1000, locationTimeZone).format("L");
  var isCurrentDayOrder = now === orderTime ? 0 : 1;

  const ConfirmCancelChangeOrder = async (id, type) => {
    if (isCurrentDayOrder === 0) {
      setErrorHeading(type === 1 ? "Cancel Order" : "Change Order");
      setShowErrorModal(true);
      setShowErrorMessage(
        type === 1
          ? "Same day orders cannot be cancelled."
          : "Same day orders cannot be changed."
      );
    } else {
      setCancelChangeOrderDetail({ id, type });
      setShowConfirmationModal(true);
    }
  };

  const CancelChangeOrder = async () => {
    // CANCEL/CHANGE ORDER
    setShowConfirmationModal(false);
    setLoader(true);
    const cancelChangeResponse = await CancelChangeOrderAPI({
      orderId: cancelChangeOrderDetail.id,
      orderType: cancelChangeOrderDetail.type,
    });
    setErrorHeading(
      cancelChangeOrderDetail.type === 1 ? "Cancel Order" : "Change Order"
    );
    setShowErrorModal(true);
    if (cancelChangeResponse.generalError)
      setShowErrorMessage(cancelChangeResponse.generalError);
    else {
      dispatch(UpdateLastOrderAPI(true));
      setShowErrorMessage(
        cancelChangeOrderDetail.type === 1
          ? "Your order has been cancelled"
          : "Your order has been changed"
      );
      setCancelOrderIds(_.cloneDeep([...cancelOrderIds, idOrderHistory]));
    }
    setLoader(false);
  };

  return (
    <>
      {false ? (
        <div></div>
      ) : (
        <div>
          {cancelOrderIds.indexOf(idOrderHistory) === -1 ? (
            <>
              <span
                className="BrownSTDRegular font-size-0_9 text-grey8 cursor-pointer"
                onClick={() => ConfirmCancelChangeOrder(idOrderHistory, 1)}
              >
                {loader &&
                cancelChangeOrderDetail.id === idOrderHistory &&
                cancelChangeOrderDetail.type === 1 ? (
                  <CircularProgress size={24} />
                ) : (
                  "Cancel Order"
                )}
              </span>
              <span
                className="BrownSTDRegular font-size-0_9 text-grey8 cursor-pointer ms-1 ms-sm-3"
                onClick={() => ConfirmCancelChangeOrder(idOrderHistory, 2)}
              >
                {loader &&
                cancelChangeOrderDetail.id === idOrderHistory &&
                cancelChangeOrderDetail.type === 2 ? (
                  <CircularProgress size={24} />
                ) : (
                  "Change Order"
                )}
              </span>
              <DecisionDialog
                showError={showConfirmationModal}
                text={
                  MessagesList[
                    cancelChangeOrderDetail.type === 2
                      ? "Change order confirmation"
                      : "Cancel order confirmation"
                  ]
                }
                noOption={setShowConfirmationModal}
                yesButtonText={
                  cancelChangeOrderDetail.type === 2 ? "Confirm" : "Ok"
                }
                yesOption={CancelChangeOrder}
                heading={
                  MessagesList[
                    cancelChangeOrderDetail.type === 2
                      ? "Change order confirmation Title"
                      : "Cancel order confirmation Title"
                  ]
                }
              />
            </>
          ) : (
            ""
          )}
          <ErrorSuccessDialog
            showError={showErrorModal}
            text={showErrorMessage}
            closeDialog={setShowErrorModal}
          />
        </div>
      )}
    </>
  );
}

export default CancelChangeOrder;
