import React from "react";

// react-bootstrap components
import { Row, Col } from "react-bootstrap";
import ResetPasswordForm from "./ResetPasswordForm";
import ImgComponent from "./ImgComponent";
function ResetPassword() {
  return (
    <Row className="auth-forms">
      <Col className="p-0 pt-0 d-flex justify-content-center">
        <ResetPasswordForm />
      </Col>
      <Col className="p-0 image_page signup_page forget_password_page">
        <ImgComponent />
      </Col>
    </Row>
  );
}
export default ResetPassword;
