import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import LoginForm from "./LoginForm";
import ImgComponent from "./ImgComponent";
import { CreateGoogleAnalyticsAPI } from "../../actions/General/General";

function Login(props) {
  useEffect(() => {
    CreateGoogleAnalyticsAPI("SignInView", {});
  });

  return (
    <Row className="auth-forms">
      <Col className="p-0 pt-0 d-flex justify-content-center">
        <LoginForm
          isGroup={
            props.searchId && props.searchId !== undefined ? true : false
          }
        />
      </Col>
      <Col className="p-0 image_page login_page">
        <ImgComponent />
      </Col>
    </Row>
  );
}
export default Login;
