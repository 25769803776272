import React from "react";

function TipElement({
  tipPercentage,
  setTipPercentage,
  title,
  value,
  orderData,
}) {
  return (
    <div className="d-flex flex-column w-auto text-center">
      <span
        className={`BrownSTDBold font-size-0_7 text-grey1 cursor-pointer px-1 ${
          tipPercentage === value ? "border-bottom-1 border-orange1" : ""
        }`}
        onClick={() => setTipPercentage(value)}
      >
        {title}
      </span>
      {tipPercentage === value && tipPercentage !== -1 && (
        <span
          className={`BrownSTDBold font-size-0_7 text-grey1 cursor-pointer px-1 pt-1`}
        >
          $
          {orderData.hasOwnProperty("payment_info") &&
          orderData.payment_info &&
          orderData.payment_info.hasOwnProperty("tipAmount") &&
          orderData.payment_info.tipAmount
            ? orderData.payment_info.tipAmount
            : 0}
        </span>
      )}
    </div>
  );
}

export default TipElement;
