import { sendPostCallToSever } from "../Axios/Axios";
import store from "../../redux/store";
import { AuthReducer } from "../../redux/reducers/AuthReducer";
import { MICRO_SERVICES } from "../../config/config";
const AuthActions = AuthReducer.actions;

const StartUpAPI = (public_key) => {
  return sendPostCallToSever(
    { public_key },
    MICRO_SERVICES.USERS + "/startup",
  ).then((result) => {
    return result;
  });
};

const UpdateStartUpData = (data) => {
  store.dispatch(AuthActions.setStartUpData(data));
};

export { StartUpAPI, UpdateStartUpData };
