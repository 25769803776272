import React, { useState } from "react";
import { Navbar, Container, Row } from "react-bootstrap";
import Logo from "../../assets/img/LogoNew.png";
import RewardsIcon from "../../assets/img/Rewards.svg";
import GroupOrder from "../../assets/img/GroupOrder.svg";
import MenuIcon from "../../views/Menu/MenuIcon";
import LoggedInUser from "../../assets/img/LoggedInUser.svg";
import { useSelector } from "react-redux";
import MenuComponent from "./Menu.js";

import UserMenuComponent from "./UserMenu.js";
import { DecryptReduxAuth } from "../../actions/General/General";
import CartIcon from "../../views/Cart/CartIcon";

import { useHistory } from "react-router-dom";
import ShowAllLockedRewards from "../../views/Cart/ShowAllLockedRewards";
import ShowLoginModal from "../../views/Auth/ShowLoginModal";
import LastOrderStatus from "./LastOrderStatus";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import GuestMenu from "./GuestMenu";
import GroupMenu from "./GroupMenu.js";

function Header({ currentPathName, isAuthRoute }) {
  // GET AUTH AND PROFILE DATA FROM REDUX STATE
  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);
  const profile = useSelector((state) => state.profile);
  const order = useSelector((state) => state.order.calculate_order_data);
  const history = useHistory();

  // CREATE LOCAL STATE
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);
  const [userMenuClicked, setUserMenuClicked] = useState(false);
  const [groupOrderMenuClicked, setGroupOrderMenuClicked] = useState(false);

  const renderTooltip_Rewards = (props) => (
    <Tooltip id="button-tooltip" className="d-none d-md-flex" {...props}>
      Rewards
    </Tooltip>
  );
  const renderTooltip_User = (props) => (
    <Tooltip id="button-tooltip" className="d-none d-md-flex" {...props}>
      User
    </Tooltip>
  );
  const renderTooltip_Group = (props) => (
    <Tooltip id="button-tooltip" className="d-none d-md-flex" {...props}>
      Group Order
    </Tooltip>
  );

  return (
    <Navbar
      expand="lg"
      className={`px-3 px-md-4 navBar py-1 cpy-md-16 py-4 ${
        menuClicked ? "open" : ""
      } ${
        isAuthRoute !== 0
          ? " bg-white position-fixed w-100"
          : " position-relative"
      }`}
    >
      <Container fluid className={"p-0"}>
        {!auth.isGuest ? (
          <>
            <div className="d-flex align-items-center flex-1 position-relative">
              <img
                className={`menuIcon ${
                  currentPathName === "Dashboard" ? "" : "d-none"
                } d-sm-block loggedin ${menuClicked ? "open" : ""}`}
                alt={"Menu Icon"}
                onClick={() => setMenuClicked(!menuClicked)}
              />
              <img
                className={`menu-back-icon ${
                  currentPathName === "Dashboard" || currentPathName === "Cart"
                    ? "d-none"
                    : ""
                } d-sm-none loggedin ${menuClicked ? "open" : ""}`}
                alt={"Menu Icon"}
                onClick={() => history.goBack()}
              />
              {!auth.isGuest &&
                currentPathName !== "Home" &&
                currentPathName !== "Login" &&
                currentPathName !== "Register" &&
                currentPathName !== "Reset Password" &&
                currentPathName !== "Reward" && (
                  <LastOrderStatus
                    user_name={profile.first_name}
                    className={"d-none d-md-flex"}
                  />
                )}
            </div>

            <img
              src={Logo}
              alt={"Logo"}
              className={`${
                currentPathName !== "Cart" ? "cursor-pointer" : ""
              } width-65`}
              onClick={() => {
                currentPathName !== "Cart" ? history.push("/dashboard") : "";
              }}
            />
            {["Home", "Login", "Register", "Reset Password", "Reward"].indexOf(
              currentPathName
            ) === -1 ? (
              <Row className="flex-1 d-flex justify-content-end top_menu_section align-items-center">
                <div className="p-0">
                <div className="d-flex gap-3">
                  {order &&
                    Object.keys(order).length > 0 &&
                    currentPathName !== "Menu" && (
                      <div
                        className={`px-1 d-none d-md-flex align-items-center`}
                      >
                        <MenuIcon />
                      </div>
                    )}
                  {(!order || !order.isGroupOrder) && (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip_Group}
                    >
                      <img
                        onClick={() =>
                          setGroupOrderMenuClicked(!groupOrderMenuClicked)
                        }
                        src={GroupOrder}
                        alt={"Group Order Icon"}
                        className={`d-none  ${
                          currentPathName === "Cart" ? "d-none" : "d-lg-flex"
                        } cursor-pointer`}
                      />
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 100 }}
                    overlay={renderTooltip_Rewards}
                  >
                    <img
                      onClick={() => setShowRewardModal(true)}
                      src={RewardsIcon}
                      alt={"Reward Icon"}
                      className={`d-none  ${
                        currentPathName === "Cart" ? "d-none" : "d-sm-flex"
                      } cursor-pointer`}
                    />
                  </OverlayTrigger>
                </div>
                </div>
                <div
                  className={`px-0 text-center ${
                    currentPathName === "Cart" ? "d-none" : "d-block"
                  }`}
                >
                  <CartIcon isDashboard={false} />
                </div>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 100 }}
                  overlay={renderTooltip_User}
                >
                  <img
                    src={LoggedInUser}
                    alt={"Logged In User Icon"}
                    className={`${
                      currentPathName === "Cart" ? "d-none" : "d-block"
                    } d-sm-block cursor-pointer`}
                    onClick={() => setUserMenuClicked(!userMenuClicked)}
                  />
                </OverlayTrigger>
                <img
                  alt={"Close Icon"}
                  className={`${
                    currentPathName === "Cart" ? "d-block" : "d-none"
                  } d-sm-none cursor-pointer menu-close-icon`}
                  onClick={() => history.push("/menu")}
                />
              </Row>
            ) : (
              <span className="flex-1 d-flex"></span>
            )}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center flex-1 position-relative">
              <img
                className={`pt-2 menuIcon d-none d-sm-block  ${
                  menuClicked ? "open" : ""
                } ${
                  currentPathName === "Login" ||
                  currentPathName === "Register" ||
                  currentPathName === "Reward" ||
                  currentPathName === "Reset Password"
                    ? "cmt-20"
                    : ""
                }`}
                alt={"Menu Icon"}
                onClick={() => setMenuClicked(!menuClicked)}
              />
              <img
                className={`menu-back-icon ${
                  currentPathName === "Home" || currentPathName === "Locations"
                    ? "d-none"
                    : ""
                } d-sm-none ${menuClicked ? "open" : ""} ${
                  currentPathName === "Login" ||
                  currentPathName === "Register" ||
                  currentPathName === "Reward" ||
                  currentPathName === "Reset Password"
                    ? "cmt-20"
                    : ""
                }`}
                alt={"Menu Icon"}
                onClick={() => history.goBack()}
              />
            </div>
            {/* {currentPathName === "Home" ? ( */}
            {currentPathName !== "Login" &&
            currentPathName !== "Register" &&
            currentPathName !== "Reward" &&
            currentPathName !== "Reset Password" ? (
              <img
                src={Logo}
                alt={"Logo"}
                className={`${
                  currentPathName !== "Cart" ? "cursor-pointer" : ""
                } width-65`}
                onClick={() => {
                  currentPathName !== "Cart" ? history.push("/locations") : "";
                }}
              />
            ) : (
              <span></span>
            )}
            {currentPathName !== "Login" &&
            currentPathName !== "Register" &&
            currentPathName !== "Reward" &&
            currentPathName !== "Reset Password" ? (
              <GuestMenu setShowLoginModal={setShowLoginModal} />
            ) : (
              <span></span>
            )}
          </>
        )}
        <MenuComponent
          setShowRewardModal={setShowRewardModal}
          show={menuClicked}
          setMenuClicked={setMenuClicked}
        />
        <UserMenuComponent
          show={userMenuClicked}
          setUserMenuClicked={setUserMenuClicked}
          setShowRewardModal={setShowRewardModal}
        />
        <GroupMenu
          show={groupOrderMenuClicked}
          setGroupOrderMenuClicked={setGroupOrderMenuClicked}
        />
        {showRewardModal && (
          <ShowAllLockedRewards setShowRewardModal={setShowRewardModal} />
        )}
        {showLoginModal && (
          <ShowLoginModal setShowLoginModal={setShowLoginModal} />
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
