import React, { useEffect, useState } from "react";
import RewardIcon from "../../assets/img/Rewards_icon.svg";
import BlackPromoCodeIcon from "../../assets/img/BlackPromoCodeIcon.svg";
import PromoCodeIcon from "../../assets/img/PromoCodeIcon.svg";
import RightArrow from "../../assets/img/RightArrow.jpg";
import { Button } from "react-bootstrap";
import PromoCode from "./PromoCode.js";
import { useSelector, useDispatch } from "react-redux";
import MessagesList from "../../config/messages.json";
import { loadAllRewards, updateRewardDetailInCart } from "./cartFunctions";
import { UpdateOrderDetailAPI } from "../../actions/Cart/Cart";
import { CircularProgress } from "@material-ui/core";

function Rewards({
  setErrorMessage = null,
  setErrorType = null,
  setIsCalculateAPIRequiredToCall = null,
}) {
  const [applicableOffers, setApplicableOffers] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [accountBalance, setAccountBalance] = useState({});
  const [loader, setLoader] = useState(false);
  let isRewardsCalled = false;
  // CREATE DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  useEffect(() => {
    getAllRewardsList();
  }, []);
  const getAllRewardsList = () => {
    if (!isRewardsCalled) {
      isRewardsCalled = true;
      loadAllRewards(setApplicableOffers, setRewards, setAccountBalance);
    }
  };
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);

  // CREATE LOCAL STATE
  const [appliedReward, setAppliedReward] = useState(
    orderData.discounts &&
      orderData.discounts[0] &&
      orderData.discounts[0].rewardId
      ? parseInt(orderData.discounts[0].rewardId)
      : ""
  );

  const [codeType, setCodeType] = useState("");
  const [askForCode, setAskForCode] = useState(false);

  // UPDATE REWARD STATE AND UPDATE CART ACCORDING TO REWARD
  const updateCartReward = async (reward, rewardType) => {
    let reward_id = "";
    if (
      appliedReward !==
      (rewardType === "redeemable" ? reward.redeemable_id : reward.reward_id)
    ) {
      setLoader(true);
      setAppliedReward(
        rewardType === "redeemable" ? reward.redeemable_id : reward.reward_id
      );
      reward_id = await updateRewardDetailInCart(reward, rewardType, dispatch);
      if (reward_id) {
        setAppliedReward(reward_id);
        setIsCalculateAPIRequiredToCall(true);
        setLoader(false);
      } else {
        let error = MessagesList["Reward Can not be used"];
        // if (rewardType === "redeemable") {
        let rewardDescription = reward.description ? reward.description : "";
        rewardDescription = rewardDescription.replace(/\*.*\*/, "");
        error += "<br /><br />" + rewardDescription;
        // }
        setLoader(false);
        setAppliedReward("");
        setErrorType("reward");
        setErrorMessage(error);
      }
    }
    if (!reward_id) {
      if (
        orderData.hasOwnProperty("discounts") &&
        orderData.discounts.length > 0
      ) {
        setIsCalculateAPIRequiredToCall(true);
      }
      let orderItem = _.cloneDeep(orderData);
      delete orderItem.discounts;
      dispatch(UpdateOrderDetailAPI(_.cloneDeep(orderItem)));
    }
  };

  useEffect(() => {
    setAppliedReward(
      orderData.discounts &&
        orderData.discounts[0] &&
        orderData.discounts[0].rewardId
        ? parseInt(orderData.discounts[0].rewardId)
        : ""
    );
  }, [orderData]);

  return (
    <div className="d-flex flex-column px-2 px-md-4 py-3">
      {/* REWARDS DIV */}
      <h2 className="text-capitalize BrandenGrotesqueBold font-size-1_3 text-grey1">
        Mixt Rewards
      </h2>
      {rewards &&
        rewards.map((reward, index) => {
          return (
            <div
              key={reward.reward_id}
              className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 reward-item ${
                appliedReward === reward.reward_id ? "bg-yellow1" : ""
              }`}
            >
              <div className=" flex-1">
                <img src={RewardIcon} className={"width-22"} />
                <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                  {reward.name}
                </span>
              </div>
              <Button
                variant=""
                className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-grey4 rounded-full default-letter-spacing height-30 width-85`}
                onClick={() => {
                  updateCartReward(reward, "reward");
                }}
              >
                {appliedReward === reward.reward_id ? (
                  loader ? (
                    <CircularProgress size={24} />
                  ) : (
                    "remove"
                  )
                ) : (
                  "use now"
                )}
              </Button>
            </div>
          );
        })}
      {applicableOffers &&
        applicableOffers.map((reward, index) => {
          if (
            accountBalance.redeemable_points >= reward.points &&
            reward.points > 0
          )
            return (
              <div
                key={reward.redeemable_id}
                className={`d-flex justify-content-between mb-3 align-items-center py-4 px-3 border-white1 reward-item ${
                  appliedReward === reward.redeemable_id ? "bg-yellow1" : ""
                }`}
              >
                <div className="d-flex align-items-center flex-1">
                  <img src={RewardIcon} className={"width-22"} />
                  <div className="d-flex flex-column">
                    <span className="BrownSTDRegular font-size-0_7 text-brown1 ms-2">
                      {reward.name}
                    </span>
                    <span className="BrownSTDBold font-size-0_9 text-green3 ms-2">
                      {reward.points} points
                    </span>
                  </div>
                </div>
                <Button
                  variant=""
                  className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-0_7 bg-grey4 rounded-full default-letter-spacing height-30 width-85`}
                  onClick={() => {
                    updateCartReward(reward, "redeemable");
                  }}
                >
                  {appliedReward === reward.redeemable_id ? (
                    loader ? (
                      <CircularProgress size={24} />
                    ) : (
                      "remove"
                    )
                  ) : (
                    "use now"
                  )}
                </Button>
              </div>
            );
        })}
      <div className="d-flex flex-column pt-3">
        <div className="d-flex justify-content-between align-items-center py-2 border-bottom-1">
          <div>
            <img src={BlackPromoCodeIcon} />
            <span
              className="BrownSTDRegular font-size-0_8 text-brown1 ms-3 ps-1 cursor-pointer"
              onClick={() => {
                setAskForCode(!askForCode);
                setCodeType("digital");
              }}
            >
              Have a Digital Reward Code?
            </span>
          </div>
          <img src={RightArrow} className={"right-icon"} />
        </div>
        <div className="d-flex justify-content-between align-items-center py-2">
          <div>
            <img src={PromoCodeIcon} />
            <span
              className="BrownSTDRegular font-size-0_8 text-brown1 ms-3 cursor-pointer"
              onClick={() => {
                setAskForCode(!askForCode);
                setCodeType("promo");
              }}
            >
              Have a Promo Code?
            </span>
          </div>
          <img src={RightArrow} className={"right-icon"} />
        </div>
      </div>

      {/* LOCKED REWARDS DIV */}
      {/* PROMO CODE DIV */}
      {askForCode && (
        <PromoCode
          setAskForCode={setAskForCode}
          codeType={codeType}
          setCodeType={setCodeType}
          getAllRewardsList={getAllRewardsList}
          setIsCalculateAPIRequiredToCall={setIsCalculateAPIRequiredToCall}
        />
      )}
      {/* PROMO CODE DIV */}
    </div>
  );
}

export default Rewards;
