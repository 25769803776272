import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "react-bootstrap";
import MessagesList from "../config/messages.json";

function ErrorSuccessDialog({ showError, text, closeDialog }) {
  return (
    <Dialog
      open={showError}
      aria-labelledby="draggable-dialog-title"
      className="customize_modal"
    >
      <DialogContent>
        {MessagesList.hasOwnProperty(text + " Title") && (
          <h2>{MessagesList[text + " Title"]}</h2>
        )}
        <DialogContentText>
          <div
            dangerouslySetInnerHTML={{
              __html: MessagesList.hasOwnProperty(text) ? MessagesList[text] : text,
            }}
          ></div>

        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center p-0">
        <Button
          autoFocus
          className="text-grey4 CalibriBold"
          onClick={() => closeDialog(false)}
          variant=""
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorSuccessDialog;
