import aesjs from "aes-js";
import NodeRSA from "node-rsa";
import { key_256, iv } from "./clientKeys.js";

import { StartUpAPI, UpdateStartUpData } from "../StartUp/StartUp";

const starter_key = new NodeRSA({ b: 2048 });

const starter_public = starter_key.exportKey("pkcs1-public");
const starter_private = starter_key.exportKey("pkcs1-private");

const key = new NodeRSA();
// key.setOptions({
//   encryptionScheme: "pkcs1_oaep",
// });
function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const rsaEncryption = () => {
  const buffer = {
    key: _arrayBufferToBase64(key_256),
    iv: _arrayBufferToBase64(iv),
  };
  return key.encrypt(buffer, "base64");
};

const encryptData = (data, server_key) => {
  key.importKey(server_key, "pkcs1-public");
  var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(data));
  var aesOfb = new aesjs.ModeOfOperation.cbc(key_256, iv);
  var encryptedBytes = aesOfb.encrypt(aesjs.padding.pkcs7.pad(textBytes));
  var encryptedBase64 = _arrayBufferToBase64(encryptedBytes);
  const sendData = {
    value: encryptedBase64,
    handler: rsaEncryption(),
  };
  return sendData;
};

const rsaDecryption = (data, validKey) => {
  key.importKey(validKey, "pkcs1-private");
  const buffer = key.decrypt(data);
  return JSON.parse(buffer);
};

const decryptData = (data, rsaData) => {
  var aesOfb = new aesjs.ModeOfOperation.cbc(
    Buffer.from(_base64ToArrayBuffer(rsaData.key)),
    Buffer.from(_base64ToArrayBuffer(rsaData.iv))
  );
  var decryptedBytes = aesjs.padding.pkcs7.strip(
    aesOfb.decrypt(Buffer.from(_base64ToArrayBuffer(data)))
  );
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  const parsedData = JSON.parse(decryptedText);

  return parsedData;
};

const CustomFunc = async () => {
  let result = await StartUpAPI(starter_public);
  const rsaData = rsaDecryption(result.data.handler, starter_private);
  const decryptedData = decryptData(result.data.value, rsaData);
  UpdateStartUpData({
    publicKey: starter_public,
    privateKey: starter_private,
    clientId: decryptedData.client_id,
    clientSecret: decryptedData.client_secret,
    serverPublicKey: decryptedData.server_key,
    businessKey: decryptedData.business_key,
  });
};
export { CustomFunc, encryptData, rsaDecryption, decryptData, key };
