import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  GetLastOrderStatusAPI,
  UpdateLastOrderAPI,
} from "../../actions/Orders/Order";
import { ORDER_STATUSES } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function LastOrderStatus({ user_name, className }) {
  const history = useHistory();
  const update_last_order = useSelector(
    (state) => state.order.update_last_order
  );
  const dispatch = useDispatch();
  const [lastOrderDetail, setLastOrderDetail] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [isCurrentDayOrder, setIsCurrentDayOrder] = useState(false);
  const getLastOrderStatus = async () => {
    let lastOrderStatus = await GetLastOrderStatusAPI();
    if (lastOrderStatus.data.length > 0) {
      setLastOrderDetail(lastOrderStatus.data[0]);
      var now = moment.tz(lastOrderStatus.data[0].locationTimeZone).format("L");
      var orderTime = moment
        .tz(
          lastOrderStatus.data[0].orderDate * 1000,
          lastOrderStatus.data[0].locationTimeZone
        )
        .format("L");
      var isCurrentDayOrder = now === orderTime ? 0 : 1;
      setIsCurrentDayOrder(isCurrentDayOrder);
    } else {
      setLastOrderDetail({});
    }
    dispatch(UpdateLastOrderAPI(false));
    setLoadingData(false);
  };
  useEffect(async () => {
    if (update_last_order) getLastOrderStatus();
  }, [update_last_order]);
  useEffect(async () => {
    getLastOrderStatus();
  }, []);
  return (
    <>
      {!loadingData && (
        <div className={`greeting-text flex-column ${className}`}>
          {lastOrderDetail.hasOwnProperty("brinkOrderId") &&
          lastOrderDetail.status !== "cancelled" ? (
            <>
              {lastOrderDetail.orderType === "delivery" ? (
                <div
                  className="d-flex flex-column cursor-pointer"
                  onClick={() => {
                    if (lastOrderDetail.destinationEnum === 2)
                      history.push("/track/" + lastOrderDetail.deliveryId);
                    else
                      history.push("/orders/" + lastOrderDetail.idOrderHistory);
                  }}
                >
                  <span className="BrownSTDBold text-brown1 font-size-1_1">
                    Delivery Status: {ORDER_STATUSES[lastOrderDetail.status]}
                  </span>
                  {isCurrentDayOrder === 0 ? (
                    <span className="BrownSTDBold text-brown1 font-size-1_1">
                      Delivery Time:{" "}
                      {moment
                        .tz(
                          lastOrderDetail.orderDate * 1000,
                          lastOrderDetail.locationTimeZone
                        )
                        .format("hh:mm a")}{" "}
                      -{" "}
                      {moment
                        .tz(
                          lastOrderDetail.orderDate * 1000,
                          lastOrderDetail.locationTimeZone
                        )
                        .add(15, "minutes")
                        .format("hh:mm a")}{" "}
                    </span>
                  ) : (
                    <span className="BrownSTDBold text-brown1 font-size-0_9">
                      Delivery Date & Time:{" "}
                      {moment
                        .tz(
                          lastOrderDetail.orderDate * 1000,
                          lastOrderDetail.locationTimeZone
                        )
                        .format("MM/DD")}
                      {" between "}
                      {moment
                        .tz(
                          lastOrderDetail.orderDate * 1000,
                          lastOrderDetail.locationTimeZone
                        )
                        .format("hh:mm a")}{" "}
                      -{" "}
                      {moment
                        .tz(
                          lastOrderDetail.orderDate * 1000,
                          lastOrderDetail.locationTimeZone
                        )
                        .add(15, "minutes")
                        .format("hh:mm a")}{" "}
                    </span>
                  )}
                </div>
              ) : (
                <div
                  className="d-flex flex-column cursor-pointer"
                  onClick={() => {
                    history.push("/orders/" + lastOrderDetail.idOrderHistory);
                  }}
                >
                  <span className="BrownSTDBold text-brown1 font-size-1_1">
                    Pick Up your order{" "}
                    {isCurrentDayOrder === 0
                      ? ""
                      : "on " +
                        moment
                          .tz(
                            lastOrderDetail.orderDate * 1000,
                            lastOrderDetail.locationTimeZone
                          )
                          .format("MM/DD")}
                  </span>
                  <span className="BrownSTDBold text-brown1 font-size-1_1">
                    between{" "}
                    {moment
                      .tz(
                        lastOrderDetail.orderDate * 1000,
                        lastOrderDetail.locationTimeZone
                      )
                      .format("hh:mm a")}{" "}
                    -{" "}
                    {moment
                      .tz(
                        lastOrderDetail.orderDate * 1000,
                        lastOrderDetail.locationTimeZone
                      )
                      .add(15, "minutes")
                      .format("hh:mm a")}{" "}
                  </span>
                </div>
              )}
            </>
          ) : (
            <span className="BrownSTDBold text-brown1 font-size-1_3">
              Good{" "}
              {moment().format("HH") >= 0 && moment().format("HH") < 12
                ? "Morning"
                : moment().format("HH") >= 12 && moment().format("HH") < 17
                ? "Afternoon"
                : "Evening"}
              , {user_name}!
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default LastOrderStatus;
