import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LogoutAPI } from "../actions/Auth/Auth";
import { useHistory } from "react-router-dom";
import { ResetCartDetailAPI } from "../actions/Cart/Cart";
import { ResetLocationAPI } from "../actions/Locations/Locations";

function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // ResetCartDetailAPI(dispatch);
    dispatch(ResetLocationAPI());
    dispatch(LogoutAPI());
    history.push("/");
  });
  return <div></div>;
}

export default Logout;
