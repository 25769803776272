import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "react-bootstrap";

function DecisionDialog({
  showError,
  text,
  noOption,
  yesOption,
  heading = "",
  yesButtonText = "",
  cancelButtonText = "Cancel",
}) {
  return (
    <Dialog
      open={showError}
      aria-labelledby="draggable-dialog-title"
      className="customize_modal"
    >
      <DialogContent>
        {heading !== "" && <h2>{heading}</h2>}
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center p-0">
        <Button
          className="text-grey4"
          autoFocus
          onClick={() => noOption(false)}
          variant=""
        >
          {cancelButtonText}
        </Button>
        <Button
          className="text-grey4"
          autoFocus
          onClick={() => yesOption(false)}
          variant=""
        >
          {yesButtonText ? yesButtonText : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DecisionDialog;
