import React from "react";
import { Button } from "react-bootstrap";
import { Dialog, DialogContent } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseImg from "../../assets/img/CloseCircle.svg";

function ShowLoginModal({ setShowLoginModal }) {
  const history = useHistory();
  return (
    <Dialog
      open={true}
      onClose={() => {
        setShowLoginModal(false);
      }}
      aria-labelledby="customized-dialog-title"
      className={`customize_modal customize_login_modal`}
    >
      <img
        src={CloseImg}
        alt={"Close Image"}
        className={"close_payment_modal cursor-pointer"}
        onClick={() => {
          setShowLoginModal(false);
        }}
      />
      <DialogContent>
        <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-2 cmb-33">
          sign in or sign up
        </h3>
        <div className="BrownSTDRegular font-size-1_1 text-brown1 text-center cmt-2 cmb-20 bg-yellow1 cpy-32 text-center d-flex flex-column flex-md-row justify-content-center">
          <span>To place your order,&nbsp;</span>
          <span> please sign in or sign up.</span>
        </div>

        <div
          key={"barcode"}
          className={`d-flex justify-content-center mb-2 align-items-center pt-4 px-3`}
        >
          <Button
            variant=""
            className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_1 bg-brown2 rounded-full default-letter-spacing height-44 width-233`}
            onClick={() => {
              setShowLoginModal(false);
              history.push("/signin");
            }}
          >
            Sign in
          </Button>
          <Button
            variant=""
            className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_1 bg-brown2 rounded-full default-letter-spacing height-44 width-233 cml-35`}
            onClick={() => {
              setShowLoginModal(false);
              history.push("/signup");
            }}
          >
            Sign up
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default ShowLoginModal;
