import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/custom.scss";
import "./assets/css/general.css";

import Layout from "./layouts/Layout.js";

import store from "./redux/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { CircularProgress } from "@material-ui/core";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
const OtherComponent = loadable(() =>
  import(/* webpackPreload: true */ "./layouts/Layout.js")
);
import "./assets/css/auth.css?v=1.0.0";
import PasswordPrompt from "./views/Auth/PasswordPrompt.js";

let persistor = persistStore(store);

const container = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <meta http-equiv="X-Content-Type-Options" content="nosniff" />
    </Helmet>
    <PersistGate persistor={persistor}>
      <Suspense fallback={CircularProgress}>
        <PasswordPrompt>
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                render={(props) => <OtherComponent {...props} />}
              />
            </Switch>
          </BrowserRouter>
        </PasswordPrompt>
      </Suspense>
    </PersistGate>
  </Provider>,
  container
);
