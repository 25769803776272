import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { updateCartDeliveryInstructionsAPI } from "../../actions/Cart/Cart";

function UpdateDeliveryInstructions({ setActionType, instructions }) {
  // CREATE LOCAL STATE
  const [instructionText, setInstructionText] = useState(instructions);

  // CREATE DISPATCH OBJECT
  const dispatch = useDispatch();

  const submitForm = () => {
    dispatch(updateCartDeliveryInstructionsAPI(instructionText));
    setActionType(false);
  };

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="draggable-dialog-title"
        onClose={() => setActionType(false)}
        maxWidth={"lg"}
        className={"delivery-instructions-PopUp"}
      >
        <DialogContent>
          <h2 className="BrandenGrotesqueBold font-size-1_1 text-brown2 text-uppercase">
            Delivery instructions
          </h2>
          <p className="BrandenGrotesqueBold font-size-1_1 text-brown2 mb-0">
            Add specific instructions for delivery
          </p>
          <TextField
            autoComplete={"false"}
            minRows={10}
            multiline
            value={instructionText}
            onChange={(e) => setInstructionText(e.target.value)}
            className={
              "textField w-100 delivery-instructions-field font-size-0_9"
            }
            placeholder={
              "Enter notes for delivery such as security code, location details, etc."
            }
            label={
              "Enter notes for delivery such as security code, location details, etc."
            }
            aria-label={
              "Enter notes for delivery such as security code, location details, etc."
            }
            aria-required="true"
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center px-2 py-4">
          <Button
            variant={""}
            onClick={() => setActionType(false)}
            className={
              "btn-half default-letter-spacing btn-custom BrandenGrotesqueBold border-brown2 bg-white text-brown2 text-uppercase rounded-full font-size-1_1"
            }
          >
            Cancel
          </Button>
          <Button
            variant={""}
            onClick={submitForm}
            className={
              "btn-half default-letter-spacing btn-custom BrandenGrotesqueBold border-brown2 bg-brown2 text-white text-uppercase rounded-full font-size-1_1 ms-3"
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateDeliveryInstructions;
