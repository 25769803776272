// LOAD LIBRARIES
import React, { useEffect, useState } from "react";
import { Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../assets/css/campaign.css?v=1.0.0";
import Campaigns from "./Campaigns";
import CartIcon from "./Cart/CartIcon";
import FirstReward from "./FirstReward";
import { useSelector } from "react-redux";
import { GetPreviousOrdersListAPI } from "../actions/Orders/Order";

function Dashboard() {
  const location = new URLSearchParams(window.location.search);
  let isRewardUsed = location.get("reward_earned");
  const [firstRewardEarned, setFirstRewardEarned] = useState(isRewardUsed);
  const [userTypeOfOrder, setUserTypeOfOrder] = useState("");
  const [hasOrders, setHasOrders] = useState(false);
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const groupOrderDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (
      groupOrderDetail.hasOwnProperty("user_id") &&
      groupOrderDetail.user_id === profile.user_id
    )
      setUserTypeOfOrder("group_admin");
    else if (
      groupOrderDetail.hasOwnProperty("user_id") &&
      groupOrderDetail.user_id !== profile.user_id &&
      orderData.hasOwnProperty("orderType")
    )
      setUserTypeOfOrder("group_invited");
    else if (
      !groupOrderDetail.hasOwnProperty("user_id") &&
      orderData.hasOwnProperty("orderType")
    )
      setUserTypeOfOrder("normal_user");
    else setUserTypeOfOrder("");
  }, [profile, orderData, groupOrderDetail]);

  useEffect(() => {
    const getOrders = async () => {
      const ordersListData = await GetPreviousOrdersListAPI({
        date: "",
      });
      if (!ordersListData.generalError) {
        setHasOrders(ordersListData.data.orders ? true : false);
      }
    };

    getOrders();
  }, []);

  const buttonClick = (path) => {
    // REDIRECT TO PROVIDE PATH
    history.push(path);
  };

  return (
    <>
      <Row className={"d-flex flex-column"}>
        <Campaigns />
        <Row
          className={
            "justify-content-center align-items-end w-100 dashboard-buttons cmb-20 cmb-md-0"
          }
        >
          {userTypeOfOrder === "" ? (
            <div className="d-flex flex-column">
              <Button
                variant={""}
                onClick={() => {
                  buttonClick("/locations");
                }}
                className={`default-letter-spacing btn-custom BrandenGrotesqueBold border-brown1 bg-transparent text-brown1 text-uppercase rounded-full font-size-1_1 width-268`}
              >
                New Order
              </Button>
              <Button
                variant={""}
                onClick={() => {
                  buttonClick("/locations?is_group=true");
                }}
                className={`mt-3 default-letter-spacing group_order btn-custom BrandenGrotesqueBold border-brown1 bg-transparent text-brown1 text-uppercase rounded-full font-size-1_1 width-268`}
              >
                Group Order
              </Button>
              {hasOrders && (
                <Button
                  variant={""}
                  onClick={() => {
                    buttonClick("orders");
                  }}
                  className={`mt-3 default-letter-spacing btn-custom BrandenGrotesqueBold border-brown1 bg-transparent text-brown1 text-uppercase rounded-full font-size-1_1 width-268`}
                >
                  Reorder
                </Button>
              )}
            </div>
          ) : (
            <div className="d-flex flex-column">
              <Button
                variant={""}
                onClick={() => {
                  buttonClick("/menu");
                }}
                className={`default-letter-spacing btn-custom BrandenGrotesqueBold border-brown1 bg-transparent text-brown1 text-uppercase rounded-full font-size-1_1 width-268 view_menu`}
              >
                View Menu
              </Button>
            </div>
          )}
          <CartIcon isDashboard={true} />
        </Row>
      </Row>
      {firstRewardEarned && (
        <FirstReward setFirstRewardEarned={setFirstRewardEarned} />
      )}
    </>
  );
}
export default Dashboard;
