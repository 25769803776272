import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { usePaymentInputs } from "react-payment-inputs";
import {
  AddPaymentCardAPI,
  GetPaymentCardAPI,
  UpdatePaymentCardsList,
} from "../../actions/Card/Card";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import CardImage from "./CardImage";
import moment from "moment-timezone";
import { isMobile, isSafari } from "react-device-detect";

const ERROR_MESSAGES = {
  emptyExpiryDate: "Enter Valid Expiration Date",
};

function AddCardForm({ setDisplayType, updatePaymentCard }) {
  const { meta, getExpiryDateProps, getZIPProps, getCardImageProps } =
    usePaymentInputs({
      errorMessages: ERROR_MESSAGES,
    });

  const { erroredInputs, touchedInputs } = meta;
  // CREATE LOCAL STATE
  const [isCardHolderNameTouched, setIsCardHolderNameTouched] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [zip, setZip] = useState("");
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [iframe, setIframe] = useState(null);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const [loader, setLoader] = useState(false);
  const [isTokenExLoaded, setIsTokenExLoaded] = useState(false);

  const cardsData = useSelector((state) => state.profile.cards_list);
  const dispatch = useDispatch();

  const formRef = React.useRef(false);

  const setFormSubmitted = (x) => {
    formRef.current = x;
  };

  const TokenExConfig = () => {
    let date = new Date(moment().add(2, "days").unix() * 1000);
    let currentDate =
      date.getFullYear().toString() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0") +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0");
    var tokenexid = process.env.REACT_APP_TOEKN_EX_ID; //From client portal
    var clientSecretKey = process.env.REACT_APP_TOEKN_EX_SECRET_KEY; //From client portal
    var tokenScheme = "PCI";
    var origin = process.env.REACT_APP_TOEKN_EX_ORIGION;
    var concatenatedString =
      tokenexid + "|" + origin + "|" + currentDate + "|" + tokenScheme;
    var AuthenticationKey = GenerateHMAC(concatenatedString, clientSecretKey);

    let cvvMargin = "8px";
    if (isSafari && isMobile) cvvMargin = "2px";

    var iframeConfig = {
      origin: origin,
      timestamp: currentDate,
      tokenExID: tokenexid,
      tokenScheme: "PCI",
      authenticationKey: AuthenticationKey,
      pci: true,
      cvv: true,
      cvvContainerID: "container",
      cvvPlaceholder: "CVV",
      enablePrettyFormat: true,
      enableValidateOnBlur: true,
      font: "Mukta",
      placeholder: "Card Number",
      title: "Enter Card Number",
      styles: {
        base: "font-weight: 200 !important; font-size: 17px;height: 2.5rem;line-height: 1.25; border: 0; border-bottom: 2px solid #e9e7e2; width: 95%; margin-top: 2px; border-radius: 0;",
        focus: "border: 0; border-bottom: 2px solid #e9e7e2; outline: none",
        error: "border-bottom:2px solid #ff6418",
        cvv: {
          base: `font-weight: 200 !important;font-size: 17px;height: 2.95rem; line-height: 1.25; border: 0; border-bottom: 2px solid #e9e7e2; width: 96%; margin-top: ${cvvMargin}; border-radius: 0;`,
          focus: "border: 0; border-bottom: 2px solid #e9e7e2; outline: none",
          error: "border-bottom:2px solid #ff6418",
        },
      },
    };

    var iframe = new TokenEx.Iframe("tokenExIframeDiv", iframeConfig);
    setIframe(iframe);
    iframe.on("load", function () {
      setIsTokenExLoaded(true);
    });
    iframe.on("focus", function () {
      if (cardNumber === "") {
        setCardNumber("");
        // setCardType("");
      }
    });
    iframe.on("blur", function () {});
    iframe.on("validate", function (data) {
      if (formRef.current) document.getElementById("card_form").click();
    });
    iframe.on("cardTypeChange", function (data) {
      setCardType(data.possibleCardType);
    });
    iframe.on("tokenize", function (data) {
      setCardNumber(data.token);
      setCardType(data.cardType);
      setLastFourDigits(data.lastFour);
      document.getElementById("card_form").click();
    });
    iframe.on("error", function (data) {});

    iframe.load();

    function GenerateHMAC(concatenatedInfo, HMACkey) {
      let result = CryptoJS.HmacSHA256(concatenatedInfo, HMACkey);
      result = CryptoJS.enc.Base64.stringify(result);
      return result;
    }
  };

  useEffect(() => {
    if (!isIframeLoaded) {
      setIsIframeLoaded(true);
      TokenExConfig();
      if (document.getElementById("payment_card_image")) {
        if (isSafari && isMobile) {
          const collection =
            document.getElementById("payment_card_image").children;
          collection[0].style.marginTop = "20px";
        }
      }
    }
  }, [isIframeLoaded]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (
      cardNumber !== "" &&
      cardType !== "" &&
      erroredInputs.expiryDate === undefined &&
      touchedInputs.expiryDate &&
      erroredInputs.zip === undefined &&
      touchedInputs.zip &&
      cardHolderName !== ""
    ) {
      // GET PREVIOUSLY ADDED CARD LIST
      let previousCardList = _.cloneDeep(cardsData);
      let expiry_date = cardExpiry.target.value.split("/");
      let expiryMonth = expiry_date[0].replace(" ", "");
      let currentDate = new Date().getFullYear().toString();
      let expiryYear = expiry_date[1].replace(" ", "");
      // currentDate.substring(0, 2) + expiry_date[1].replace(" ", "");
      let cardObject = {
        networkToken: cardNumber,
        lastFourDigits: lastFourDigits,
        cardType:
          cardType === "americanExpress"
            ? "amex"
            : cardType === "masterCard"
            ? "mastercard"
            : cardType,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        zipCodeString:  zip.target.value.toString()
        // zipCode: parseInt(zip.target.value),
      };

      previousCardList.unshift(cardObject);

      let result = await AddPaymentCardAPI(cardObject);
      let response = await GetPaymentCardAPI();
      // UPDATE CARDS LIST IN REDUX
      dispatch(
        UpdatePaymentCardsList(response && response.data ? response.data : [])
      );
      updatePaymentCard(result.data.data.idPaymentCard, 0);

      setShowErrorDialog(true);
    } else {
      setIsCardHolderNameTouched(cardHolderName === "" ? true : false);
      touchedInputs.expiryDate = true;
      touchedInputs.zip = true;
    }
    setLoader(false);
  };

  const cardAddedSuccessfully = () => {
    setShowErrorDialog(false);
    setDisplayType(1);
  };

  return (
    <div className="w-90 w-md-70 m-auto px-3 Add-Card-From">
      {!isTokenExLoaded && <CircularProgress size={24} />}
      <h3
        className={`BrownSTDRegular font-size-1_2 text-grey4 text-center mt-3 mb-5 ${
          isTokenExLoaded ? "" : "d-none"
        }`}
      >
        Add a Credit or Debit Card
      </h3>
      <Form
        onSubmit={handleSubmit}
        className={`${isTokenExLoaded ? "" : "d-none"}`}
      >
        <Form.Group>
          <Form.FloatingLabel label="Card Holder Name">
            <Form.Control
              autoComplete={"false"}
              className="payment_field BrandenGrotesqueRegular"
              value={cardHolderName}
              onBlur={() => setIsCardHolderNameTouched(true)}
              onChange={(e) => {
                setCardHolderName(e.target.value);
              }}
              isInvalid={isCardHolderNameTouched && cardHolderName === ""}
              placeholder=" "
            />
            <Form.Control.Feedback
              type="invalid"
              className={`${
                isCardHolderNameTouched && cardHolderName === ""
                  ? "visible"
                  : "invisible"
              } d-block`}
            >
              Enter Card Holder Name
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
        <Form.Group className="d-flex" id={"payment_card_image"}>
          <CardImage
            type={cardType.toLowerCase()}
            className={"payment_card_image"}
          />
          <div className="fab fa-cc-mastercard text-danger"></div>
          <div
            id="tokenExIframeDiv"
            className="w-100"
            style={{ height: "60px" }}
          ></div>
        </Form.Group>
        <div className="d-flex">
          <Form.Group className="me-2 flex-1">
            <Form.FloatingLabel label="Expiration Date">
              <Form.Control
                autoComplete={"false"}
                className="payment_field cmt-1 BrandenGrotesqueRegular"
                {...getExpiryDateProps({ onChange: setCardExpiry })}
                value={cardExpiry ? cardExpiry.target.value : ""}
                isInvalid={touchedInputs.expiryDate && erroredInputs.expiryDate}
                placeholder={" "}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${
                  touchedInputs.expiryDate ? "visible" : "invisible"
                } d-block`}
              >
                {erroredInputs.expiryDate}
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Form.Group>
          <Form.Group className="ms-2 flex-1">
            <div id="container" style={{ height: "60px" }}></div>
          </Form.Group>
        </div>
        <Form.Group>
          <Form.FloatingLabel label="Zip Code">
            <Form.Control
              autoComplete={"false"}
              className="payment_field BrandenGrotesqueRegular"
              {...getZIPProps({ onChange: setZip })}
              value={zip ? zip.target.value : ""}
              isInvalid={touchedInputs.zip && erroredInputs.zip}
              placeholder=" "
            />
            <Form.Control.Feedback
              type="invalid"
              className={`${
                touchedInputs.zip ? "visible" : "invisible"
              } d-block`}
            >
              {erroredInputs.zip ? "Enter Zip Code" : ""}
            </Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
        <div className="text-center">
          <Button
            onClick={() => {
              setFormSubmitted(true);
              iframe.tokenize();
            }}
            variant=""
            type="button"
            className={`btn-half width-268 text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 border-0 rounded-full font-size-1_1 text-white px-3 line-height-1 mb-0 mt-5 default-letter-spacing`}
          >
            {loader ? <CircularProgress size={24} /> : "Save"}
          </Button>
          <Button
            variant=""
            id="card_form"
            type="submit"
            className={`d-none`}
          ></Button>
        </div>
      </Form>
      <ErrorSuccessDialog
        showError={showErrorDialog}
        text={"Card Added"}
        closeDialog={cardAddedSuccessfully}
      />
    </div>
  );
}

export default AddCardForm;
