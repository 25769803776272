import { createSlice } from "@reduxjs/toolkit";

export const LocationReducer = createSlice({
  name: "locations",
  initialState: {
    pickupLocations: [],
    pickupLocationsLoader: true,
    deliveryLocations: [],
    deliveryLocationsLoader: false,
  },
  reducers: {
    UpdatePickUpLocations: (state, action) => {
      state.pickupLocations = action.payload.pickupLocations;
      state.pickupLocationsLoader = false;
    },
    UpdateDeliveryLocations: (state, action) => {
      state.deliveryLocations = action.payload.deliveryLocations;
      state.deliveryLocationsLoader = false;
    },
    UpdatePickUpLocationsLoader: (state, action) => {
      state.pickupLocationsLoader = true;
    },
    UpdateDeliveryLocationsLoader: (state, action) => {
      state.deliveryLocationsLoader =
        action && action.payload && action.payload.hasOwnProperty("value")
          ? action.payload.value
          : true;
    },
    ResetLocationsDetail: (state, action) => {
      state.pickupLocations = [];
      state.deliveryLocations = [];
    },
  },
});
