import React from "react";
import DownArrow from "../../assets/img/DownArrow.png";
function PackageItemList({
  modifiers,
  packageIngredientsList,
  setShowCustomization,
}) {
  return (
    <>
      {modifiers.map((modifier) => {
        // if (modifier.minimum > 0) {
        {
          let nameWithCount = [];
          // LOOP OVER ALL SELECTED INGREDIENTS TO GET THE LIST OF SINGLE MODIFIER ITEM
          for (const item in packageIngredientsList) {
            // GET SINGLE SELECTED INGREDIENT DETAIL
            let ingredientItem = _.cloneDeep(packageIngredientsList[item]);
            if (ingredientItem.modifierId === modifier.brinkId) {
              if (ingredientItem.count > 1)
                nameWithCount.push(
                  ingredientItem.name + "(" + ingredientItem.count + ")"
                );
              else nameWithCount.push(ingredientItem.name);
            }
          }

          return (
            <div
              key={modifier.idItemModifiers}
              onClick={(e) => {
                setShowCustomization(true);
              }}
              className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 cursor-pointer d-flex justify-content-between"
            >
              <div className="d-flex flex-column">
                <span
                  className={`BrownSTDRegular font-size-0_9 text-grey1 ${
                    modifier.minimum === 0 || nameWithCount.length > 0
                      ? ""
                      : "text-orange1"
                  }`}
                >
                  {modifier.displayName}
                </span>
                {nameWithCount.length > 0 && (
                  <span className={`BrownSTDRegular font-size-0_9 text-grey1`}>
                    <span className="text-orange1">selected </span>-{" "}
                    {nameWithCount.join(", ")}
                  </span>
                )}
              </div>
              <img
                src={DownArrow}
                alt={"Down Arrow"}
                className={"align-self-center"}
              />
            </div>
          );
        }
      })}
    </>
  );
}
export default PackageItemList;
