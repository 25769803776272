import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Logo from "../../components/GeneralComponents/Logo";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_VALIDATIONS } from "../../config/config";
import { useHistory } from "react-router-dom";
import { ByPassVerifyUser, GuestUser, LoginAPI } from "../../actions/Auth/Auth";
import { CircularProgress } from "@material-ui/core";
import DecisionDialog from "../DecisionDialog";
import {
  CreateGoogleAnalyticsAPI,
  DecryptReduxAuth,
} from "../../actions/General/General";
import { CustomFunc } from "../../actions/RSA/Rsa";

function LoginForm({ isGroup }) {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE LOCAL STATE
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
  });
  const [generalError, setGeneralError] = useState("");
  const [loader, setLoader] = useState(false);
  const [punchError, setPunchError] = useState(false);
  let group_order_user_name = useSelector(
    (state) => state.order.group_order_user_name
  );

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  const createGuestLogin = async () => {
    if (auth.serverPublicKey === "") {
      await CustomFunc();
      await GuestUser(dispatch);
    }
  };
  useEffect(() => {
    createGuestLogin();
  }, []);
  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);

    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (email === "") {
      errors.email = "Email is required";
    }
    if (password === "") {
      errors.password = "Password is required";
    }

    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (errors.email === "" && errors.password === "") {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");

      // CALL API
      let data = dispatch(
        LoginAPI({
          email,
          password,
          privacy_policy: true,
          terms_and_conditions: true,
        })
      );
      data = await Promise.resolve(data);
      // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
      if (data && data.generalError) {
        if (data.generalError === "Server Crashed, Try Bypass") {
          setPunchError(true);
          setGeneralError(data.errorDetail);
        } else setGeneralError(data.generalError);
        // HIDE LOADER
        setLoader(false);
      } else {
        CreateGoogleAnalyticsAPI("SignedIn", {});
        // REDIRECT TO DASHBOARD
        openOtherForm(isGroup ? data.route+"/1" : data.route);
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  // OPEN OTHER FROM
  const openOtherForm = (page) => {
    history.push(page);
  };

  const ByPassLogin = () => {
    dispatch(ByPassVerifyUser());
  };

  return (
    <Row>
      <Col className={"width-340 px-0"}>
        <Logo />

        {group_order_user_name || isGroup ? (
          <>
            <h1
              className={
                "text-center text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_1 cmt-50 cmb-10"
              }
            >
              {isGroup ? "" : group_order_user_name + " invited you to a "}
              group order
            </h1>
            <p
              className={
                "text-center default-letter-spacing BrandenGrotesqueMedium font-size-1_1 cmt-10 cmb-50"
              }
            >
              Please sign in to {isGroup ? "place a Group Order" : "join the Group Order"}
            </p>
          </>
        ) : (
          <h1
            className={
              "text-center text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_7 cmt-50 cmb-80"
            }
            as="h3"
          >
            Sign In
          </h1>
        )}
        {generalError && <Alert variant={"danger"}>{generalError}</Alert>}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className={"flex-column"}>
            <Col className="px-0">
              <Text
                validationType={CUSTOM_VALIDATIONS.email}
                name={"email"}
                label={"Email"}
                placeholder={"Enter Email"}
                value={email}
                required={true}
                setValue={setEmail}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
              />
            </Col>
            <Col className="px-0">
              <Text
                name={"password"}
                label={"Password"}
                placeholder={"Password"}
                value={password}
                type={"password"}
                required={true}
                setValue={setPassword}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
              />
            </Col>
            <Button
              className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto"
              type="submit"
              variant=""
              onClick={submitForm}
            >
              {loader ? (
                <CircularProgress size={24} color={"inherit"} />
              ) : (
                "Sign In"
              )}
            </Button>
          </Row>
        </Form>
        <Row className={"flex-column width-326"}>
          <Col className="px-0 text-center">
            <Button
              onClick={() => openOtherForm("/reset_password")}
              variant=""
              className={`BrownSTDRegular bg-white border-0 font-size-1_1 text-green1 cmt-45 cmb-60 forgot-password`}
            >
              Forgot Password?
            </Button>
          </Col>
          <Col className="px-0 text-center">
            <p className="BrownSTDRegular text-grey1 font-size-1_1">
              Don't have an account?{" "}
              <button
                onClick={() => openOtherForm(isGroup ? "/signup/1" : "/signup")}
                variant=""
                className="border-0 bg-transparent BrandenGrotesqueBold font-size-1_1 text-green1 text-uppercase default-letter-spacing"
              >
                Sign Up
              </button>
            </p>
          </Col>
        </Row>
      </Col>
      <DecisionDialog
        showError={punchError}
        text={
          "There is an unexpected error occurred from punch, Do you want to bypass login?"
        }
        noOption={setPunchError}
        yesOption={ByPassLogin}
        heading={"Alert"}
      />
    </Row>
  );
}
export default LoginForm;
