import { MICRO_SERVICES } from "../../config/config";
import {
  sendGetCallToSever,
  sendPostCallToSever,
  sendDeleteCallToSever,
} from "../Axios/Axios";
import { ProfileReducer } from "../../redux/reducers/ProfileReducer";
const ProfileActions = ProfileReducer.actions;

const GetPaymentCardAPI = () => {
  // SEND CALL TO SERVER TO GET ALL PAYMENT CARDS
  return sendGetCallToSever(MICRO_SERVICES.ORDERS + "/payment-cards").then(
    (response) => {
      // RETURN ERROR
      if (response.error) {
        return { generalError: response.error };
      } else {
        return { data: response.data.data };
      }
    }
  );
};

const UpdatePaymentCardsList = (cardsList) => (dispatch) => {
  dispatch(ProfileActions.UpdateCardsList({cardsList}));
};

const DeletePaymentCardAPI = (cardId) => {
  // SEND CALL TO SERVER TO DELETE PAYMENT CARD
  return sendDeleteCallToSever(
    MICRO_SERVICES.ORDERS + "/payment-cards/" + cardId
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const AddPaymentCardAPI = (data) => {
  // SEND CALL TO SERVER TO ADD PAYMENT CARD
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/payment-cards/add"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data };
    }
  });
};

export {
  GetPaymentCardAPI,
  DeletePaymentCardAPI,
  AddPaymentCardAPI,
  UpdatePaymentCardsList,
};
