import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

import { AuthReducer } from "./reducers/AuthReducer";
import { LocationReducer } from "./reducers/LocationReducer";
import { ProfileReducer } from "./reducers/ProfileReducer";
import { MenuReducer } from "./reducers/MenuReducer";
import { OrderReducer } from "./reducers/OrderReducer";

const persistConfig = {
  key: "MixtDataUpdate",
  storage,
};

const reducers = combineReducers({
  auth: AuthReducer.reducer,
  location: LocationReducer.reducer,
  profile: ProfileReducer.reducer,
  menu: MenuReducer.reducer,
  order: OrderReducer.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

// export default configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(createStateSyncMiddleware({})),
});

initMessageListener(store);
export default store;
