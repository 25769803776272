import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InviteLink from "../Menu/InviteLink";
import { CancelGroupOrder } from "./cartFunctions";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MessagesList from "../../config/messages.json";
import DecisionDialog from "../DecisionDialog";
import { CreateGoogleAnalyticsAPI } from "../../actions/General/General";

function CheckOutDetail({
  isGroupOrder,
  order_user_id,
  logged_in_user_id,
  orderFlow,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const cancelGroupOrder = async () => {
    await CreateGoogleAnalyticsAPI("GroupOrderCancelled", {
      cancellationTime: new Date(),
    });
    setShowModal(false);
    await CancelGroupOrder(dispatch);
    setLoader(false);
    history.push("/dashboard");
  };
  const notCancelGroupOrder = async () => {
    setShowModal(false);
    setLoader(false);
  };
  return (
    <>
      <h3
        className={`BrandenGrotesqueBold font-size-1_3 ${
          isGroupOrder ? "text-orange1" : "text-grey1"
        } text-center pt-3 checkout`}
      >
        {isGroupOrder
          ? orderFlow === "GroupParticipant"
            ? "Group Order - Participant Checkout"
            : "Group Order - Checkout Now"
          : "Checkout"}
        {isGroupOrder && order_user_id === logged_in_user_id ? (
          <span
            onClick={async () => {
              setLoader(true);
              setShowModal(true);
            }}
            className="BrownSTDRegular font-size-0_8 text-grey3 ps-4 cursor-pointer"
          >
            {!loader ? "Cancel Group Order" : <CircularProgress size={24} />}
          </span>
        ) : (
          <></>
        )}
      </h3>
      <div className="d-flex flex-column flex-md-row justify-content-center">
        <InviteLink />
      </div>
      {showModal && (
        <DecisionDialog
          showError={showModal}
          text={MessagesList["Admin Cancel Group Order"]}
          noOption={notCancelGroupOrder}
          yesOption={cancelGroupOrder}
          heading={MessagesList["Admin Cancel Group Order Title"]}
          yesButtonText=" Cancel Group Order"
          cancelButtonText="Do Not Cancel"
        />
      )}
    </>
  );
}

export default CheckOutDetail;
