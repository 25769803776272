import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Date from "../../components/GeneralComponents/Date";
import Phone from "../../components/GeneralComponents/Phone";
import { CUSTOM_VALIDATIONS } from "../../config/config";
import { useHistory } from "react-router-dom";
import { UpdateProfileAPI } from "../../actions/Auth/Auth";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { phone } from "phone";
import RightArrow from "../../assets/img/MenuDetailRight.svg";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import { ProfileReducer } from "../../redux/reducers/ProfileReducer";
const ProfileActions = ProfileReducer.actions;

function Profile() {
  const userProfile = useSelector((state) => state.profile);
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE LOCAL STATE
  const [fullName, setFullName] = useState(
    userProfile.first_name && userProfile.last_name
      ? userProfile.first_name + " " + userProfile.last_name
      : userProfile.first_name
      ? userProfile.first_name
      : userProfile.last_name
      ? userProfile.last_name
      : ""
  );
  const [firstName, setFirstName] = useState(
    userProfile.first_name ? userProfile.first_name : ""
  );

  const [countryCode, setCountryCode] = useState(
    userProfile.country_code ? userProfile.country_code : "+1"
  );

  const [lastName, setLastName] = useState(
    userProfile.last_name ? userProfile.last_name : ""
  );
  const [email, setEmail] = useState(
    userProfile.email ? userProfile.email : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    userProfile.phone ? userProfile.phone : ""
  );
  const [birthDay, setBirthDay] = useState(
    userProfile.birthday ? userProfile.birthday : ""
  );
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const [generalError, setGeneralError] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  useEffect(() => {
    if (userProfile.isFirstLogin) setShowErrorPopup(true);
  }, [userProfile.isFirstLogin]);

  const setShowErrorPopup_end = () => {
    setShowErrorPopup(false);
    let userProfile_local = _.cloneDeep(userProfile);
    userProfile_local.isFirstLogin = false;
    dispatch(ProfileActions.updateUser(userProfile_local));
  };

  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);
    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (firstName === "") {
      errors.firstName = "First Name is required";
    }
    if (lastName === "") {
      errors.lastName = "Last Name is required";
    }
    if (email === "") {
      errors.email = "Email is required";
    }
    if (currentPassword !== "") {
      if (password === "") {
        errors.password = "Password is required";
      }
      if (password !== confirmPassword) {
        errors.confirmPassword = "Password doesn't match";
      }
    }
    if (phoneNumber === "") {
      errors.phoneNumber = "Phone Number is required";
    } else {
      let result = phone("+" + countryCode + phoneNumber);
      if (!result.isValid) {
        errors.phoneNumber = "Phone Number is not valid";
      }
    }
    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (
      errors.firstName === "" &&
      errors.lastName === "" &&
      errors.phoneNumber === "" &&
      errors.email === "" &&
      errors.password === "" &&
      errors.confirmPassword === ""
    ) {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");

      // CREATE REGISTER OBJECT
      let updateObject = {
        email,
        password,
        password_confirmation: password,
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
      };

      if (currentPassword) {
        updateObject.current_password = currentPassword;
      }

      if (birthDay) {
        updateObject.birthday = birthDay;
      }
      // CALL API
      let data = dispatch(
        UpdateProfileAPI(updateObject, userProfile.user_id, countryCode)
      );
      data = await Promise.resolve(data);
      // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
      if (data && data.generalError) {
        if (
          data.generalError ===
          "An active access token must be used to query information about the current user."
        ) {
          history.push("/logout");
        }
        setGeneralError(data.generalError);
        // HIDE LOADER
        setLoader(false);
      } else {
        // REDIRECT TO DASHBOARD
        openOtherForm("/dashboard");
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  // OPEN OTHER FROM
  const openOtherForm = (page) => {
    history.push(page);
  };

  return (
    <div className="p-0 pt-5 pt-sm-0 d-flex justify-content-center col">
      <Row className="mb-5">
        <Col className="width-390 px-0">
          <h3
            className={
              "text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_7 cmt-50 cmb-10"
            }
            as="h3"
          >
            {showPasswordFields ? "change password" : fullName}
          </h3>
          {generalError && <Alert variant={"danger"}>{generalError}</Alert>}
          <Form>
            <Row className={"flex-column"}>
              {!showPasswordFields ? (
                <>
                  <Col className="px-0">
                    <Text
                      name={"firstName"}
                      label={"First Name"}
                      placeholder={"Enter First Name"}
                      value={firstName}
                      required={true}
                      setValue={setFirstName}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0">
                    <Text
                      name={"lastName"}
                      label={"Last Name"}
                      placeholder={"Enter Last Name"}
                      value={lastName}
                      required={true}
                      setValue={setLastName}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0">
                    <Text
                      validationType={CUSTOM_VALIDATIONS.email}
                      name={"email"}
                      label={"Email"}
                      placeholder={"Enter Email"}
                      value={email}
                      disabled={true}
                      required={true}
                      setValue={setEmail}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0">
                    <Phone
                      name={"phoneNumber"}
                      label={"Phone Number"}
                      placeholder={"Enter Phone Number"}
                      value={phoneNumber}
                      required={true}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                      setValue={setPhoneNumber}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0 position-relative d-flex">
                    <Date
                      name={"birthDay"}
                      label={"Birthday"}
                      placeholder={"Enter Birthday"}
                      value={birthDay}
                      setValue={setBirthDay}
                    />
                    <span
                      className={
                        "fieldHint font-size-0_9 BrandenGrotesqueRegular text-grey2"
                      }
                    >
                      Enjoy a reward on your birthday!
                    </span>
                  </Col>
                  <Col
                    className="px-0 border-bottom-1 w-100 mt-4 mb-5 d-flex justify-content-between cursor-pointer"
                    onClick={() => setShowPasswordFields(true)}
                  >
                    <span
                      className={
                        "font-size-1_3 BrandenGrotesqueMedium text-grey1"
                      }
                    >
                      Change Password
                    </span>
                    <img
                      src={RightArrow}
                      alt={"Right Arrow"}
                      className={"align-self-center"}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col className="px-0">
                    <Text
                      validationType={CUSTOM_VALIDATIONS.password}
                      name={"currentPassword"}
                      label={"Current Password"}
                      placeholder={"Current Password"}
                      value={currentPassword}
                      type={`password`}
                      required={true}
                      setValue={setCurrentPassword}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0">
                    <Text
                      validationType={CUSTOM_VALIDATIONS.password}
                      name={"password"}
                      label={"Password"}
                      placeholder={"Enter New Password"}
                      value={password}
                      type={"password"}
                      required={true}
                      setValue={setPassword}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                  <Col className="px-0">
                    <Text
                      validationType={CUSTOM_VALIDATIONS.password}
                      name={"confirmPassword"}
                      label={"Confirm Password"}
                      placeholder={"Confirm Password"}
                      value={confirmPassword}
                      type={"password"}
                      required={true}
                      setValue={setConfirmPassword}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                    />
                  </Col>
                </>
              )}
              <Row className="d-flex justify-content-between">
                <Button
                  className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-white border-brown2 font-size-1_1 text-brown2 rounded-full width-168 height-44"
                  type="button"
                  variant=""
                  onClick={() =>
                    showPasswordFields
                      ? setShowPasswordFields(false)
                      : openOtherForm("/dashboard")
                  }
                >
                  cancel
                </Button>
                <Button
                  className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-168 height-44"
                  type="button"
                  variant=""
                  onClick={submitForm}
                >
                  {loader ? (
                    <CircularProgress size={24} color={"inherit"} />
                  ) : (
                    "save"
                  )}
                </Button>
              </Row>
            </Row>
          </Form>
        </Col>
      </Row>
      <ErrorSuccessDialog
        showError={showErrorPopup}
        closeDialog={setShowErrorPopup_end}
        text={"Please update your phone number."}
      />
    </div>
  );
}

export default Profile;
