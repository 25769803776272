import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  GetOrderDetailAPI,
  GetPreviousOrdersListAPI,
} from "../../actions/Orders/Order";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import OrderDetail from "./ViewOrderDetail";
import { CircularProgress } from "@material-ui/core";
import CancelChangeOrder from "./CancelChangeOrder";
import { ORDER_STATUSES } from "../../config/config";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import ReorderPreviousOrder from "../Cart/ReorderPreviousOrder";
import MessageDialog from "../MessageDialog";

function OrderHistory({ searchId }) {
  const history = useHistory();
  const orderData = useSelector((state) => state.order.calculate_order_data);

  // CREATE LOCAL STATE
  const [ordersList, setOrdersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [historyDate, setHistoryDate] = useState("");
  const [viewedOrderId, setViewedOrderId] = useState(searchId ? searchId : -1);
  const [cancelOrderIds, setCancelOrderIds] = useState([]);
  const [isEncodedIdsFetched, setIsEncodedIdsFetched] = useState(true);
  const [reorderId, setReorderId] = useState("");
  const [reorderDetail, setReorderDetail] = useState("");
  const [error, setError] = useState("");
  const getUserOrdersHistory = async () => {
    setLoader(true);
    const ordersListData = await GetPreviousOrdersListAPI({
      date: historyDate,
    });
    if (!ordersListData.generalError) {
      let newOrdersList = ordersList.concat(ordersListData.data.orders);
      setOrdersList(newOrdersList);
      if (ordersListData.data.orders.length === ordersListData.data.limit)
        setHistoryDate(newOrdersList[newOrdersList.length - 1].createdAt);
      else setHistoryDate("");
      // UPDATE TOTAL PAGE COUNT
    }
    setLoader(false);
  };

  useEffect(async () => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    // GET PREVIOUS ORDERS LIST WITH PAGE
    getUserOrdersHistory();
  }, []);
  const handleContainerOnBottom = () => {
    // GET NEXT LIST IF USER REACHED AT BOTTOM OF DOCUMENT
    if (historyDate !== "") getUserOrdersHistory();
  };

  const fetchCurrentOrderDetail = async (id) => {
    setReorderId(id);
    const orderDetail = await GetOrderDetailAPI(id);
    if (!orderDetail.generalError) {
      setReorderDetail(orderDetail.data);
    } else setReorderId("");
  };
  const setReorderResponse = (displayContent, error) => {
    setReorderDetail(displayContent);
    if (error) {
      setError(error);
      setReorderId("");
    }
  };

  return (
    <>
      <MessageDialog
        showError={error ? true : false}
        text={error}
        closeDialog={setError}
        heading={""}
      />
      {viewedOrderId === -1 ? (
        <>
          {reorderDetail && reorderDetail.orderType && (
            <ReorderPreviousOrder
              setDisplayContentType={setReorderResponse}
              items={reorderDetail.orderItemsJson.items}
              orderType={reorderDetail.orderType}
              locationId={
                reorderDetail.orderType === "pickup"
                  ? reorderDetail.mixtLocationId
                  : reorderDetail.orderItemsJson.delivery_info
                      .idUserDeliveryAddress
              }
            />
          )}
          <BottomScrollListener onBottom={handleContainerOnBottom} />
          <div className="pb-4 mb-4 pt-4 pt-md-0">
            {ordersList.map((orderDetail) => {
              let totalAmount = orderDetail.totalAmount;
              if (
                orderDetail.destinationEnum === 0 ||
                orderDetail.destinationEnum === 1
              ) {
                totalAmount += orderDetail.tipAmount;
              }
              return (
                <div
                  className="border mb-4 cp-19 bg-white6"
                  key={orderDetail.idOrderHistory}
                  id={orderDetail.idOrderHistory}
                >
                  <p className="BrandenGrotesqueBold font-size-0_9 text-grey1 text-uppercase mb-1">
                    {orderDetail.orderType + " - " + orderDetail.locationName}
                  </p>
                  <p className="BrownSTDRegular font-size-0_9 text-grey5">
                    {orderDetail.fullAddress}
                  </p>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      Order #
                    </span>
                    <span className="w-78 w-78 w-sm-82 w-md-85 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {orderDetail.encodedId
                        ? orderDetail.encodedId
                        : !isEncodedIdsFetched
                        ? "Loading..."
                        : orderDetail.brinkOrderId}
                    </span>
                  </div>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      {orderDetail.orderType}
                    </span>
                    {orderDetail.orderType === "pickup" ? (
                      <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                        {moment
                          .tz(
                            orderDetail.orderDate * 1000,
                            orderDetail.locationTimeZone
                          )
                          .format("dddd, MMMM D, YYYY hh:mm A")}{" "}
                        -{" "}
                        {moment
                          .tz(
                            orderDetail.orderDate * 1000,
                            orderDetail.locationTimeZone
                          )
                          .add(15, "minutes")
                          .format("hh:mm A")}{" "}
                      </span>
                    ) : (
                      <div className="d-flex flex-column flex-md-row w-78 w-sm-82 w-md-85 ">
                        <span className="BrownSTDRegular font-size-0_9 text-grey5 me-0 me-sm-5">
                          {moment
                            .tz(
                              orderDetail.orderDate * 1000,
                              orderDetail.locationTimeZone
                            )
                            .format("dddd, MMMM D, YYYY")}
                        </span>
                        <span className="BrownSTDRegular font-size-0_9 text-grey5">
                          {"Estimated Delivery Time: " +
                            moment
                              .tz(
                                orderDetail.orderDate * 1000,
                                orderDetail.locationTimeZone
                              )
                              .format("hh:mm A")}{" "}
                          -{" "}
                          {moment
                            .tz(
                              orderDetail.orderDate * 1000,
                              orderDetail.locationTimeZone
                            )
                            .add(15, "minutes")
                            .format("hh:mm A")}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      your order
                    </span>
                    <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {orderDetail.orderItemsName
                        .split("\n")
                        .map((str, index) => (
                          <p className="mb-0" key={"order_item_" + index}>
                            {str}
                          </p>
                        ))}
                    </span>
                  </div>
                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      total
                    </span>
                    <span className="w-78 w-78 w-sm-82 w-md-85 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      ${totalAmount.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex mb-3 pb-2">
                    <span className="w-22 w-sm-18 w-md-15 BrownSTDBold font-size-0_9 text-grey1 text-capitalize">
                      status
                    </span>
                    <span className="w-78 w-sm-82 w-md-85 BrownSTDRegular font-size-0_9 text-grey5">
                      {cancelOrderIds.indexOf(orderDetail.idOrderHistory) === -1
                        ? ORDER_STATUSES[orderDetail.status]
                          ? ORDER_STATUSES[orderDetail.status]
                          : orderDetail.status
                        : ORDER_STATUSES["cancelled"]}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {orderDetail.status !== "cancelled" ? (
                      <CancelChangeOrder
                        idOrderHistory={orderDetail.idOrderHistory}
                        orderDate={orderDetail.orderDate}
                        locationTimeZone={orderDetail.locationTimeZone}
                        cancelOrderIds={cancelOrderIds}
                        setCancelOrderIds={setCancelOrderIds}
                      />
                    ) : (
                      <span></span>
                    )}
                    <div className="d-flex gap-1 gap-sm-3">
                      {!orderDetail.isGroupOrder && (
                        <button
                          className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full w-auto px-2 px-sm-3 height-30 border-0"
                          onClick={() =>
                            fetchCurrentOrderDetail(orderDetail.idOrderHistory)
                          }
                        >
                          {reorderId === orderDetail.idOrderHistory ? (
                            <CircularProgress size={24} color="white" />
                          ) : (
                            "Reorder"
                          )}
                        </button>
                      )}
                      <button
                        className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full w-auto px-2 px-sm-3 height-30 border-0"
                        onClick={() =>
                          setViewedOrderId(orderDetail.idOrderHistory)
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {loader ? (
              <div className="circular-progress-bar">
                <CircularProgress size={24} />
              </div>
            ) : (
              ordersList.length === 0 && (
                <div className="d-flex flex-column align-items-center cmt-50">
                  <h2 className="BrandenGrotesqueBold font-size-1_5 text-brown1 text-uppercase">
                    no orders yet
                  </h2>
                  <p className="BrownSTDRegular font-size-1_1 text-grey5 mb-4 text-center mb-4 cmt-25">
                    You must be hungry!
                  </p>
                  <button
                    className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full width-168 height-30 border-0 cmt-50"
                    onClick={() =>
                      orderData.hasOwnProperty("orderType")
                        ? history.push("/menu")
                        : history.push("/locations")
                    }
                  >
                    order now
                  </button>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        <OrderDetail
          orderId={viewedOrderId}
          cancelOrderIds={cancelOrderIds}
          setCancelOrderIds={setCancelOrderIds}
          setActionType={setViewedOrderId}
        />
      )}
    </>
  );
}

export default OrderHistory;
