import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ResetCartDetailAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import {
  getImageUrl,
  GetOrderItemsListToSeparateArrays,
} from "../../actions/General/General";
import CartImage from "../../assets/img/MenuDefaultImage.jpg";
import DecisionDialog from "../DecisionDialog";
import { useHistory } from "react-router-dom";
import { GetOrderFavoriteItemsAPI } from "../../actions/Orders/Order";
import { CircularProgress } from "@material-ui/core";
import Refresh from "../../assets/img/Refresh.png";
import {
  AddToFavorite,
  RefreshCartItems,
  RemoveFromFavorite,
  RemoveItemFromCart,
  UpdateUtensilsInOrder,
} from "./cartFunctions";

function OrderDetail({
  hasUtensils,
  itemsList,
  orderId,
  locationId,
  flow,
  setEditId = null,
  setIsCalculateAPIRequiredToCall = null,
}) {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE LOCAL STATE
  const [favoriteItemList, setFavoriteItemList] = useState([]);
  const [includedProteinState, setIncludedProteinState] = useState([]);
  const [addedIngredientState, setAddedIngredientState] = useState([]);
  const [removedIngredientState, setRemovedIngredientState] = useState([]);
  const [dressingIngredientState, setDressingIngredientState] = useState([]);
  const [containerState, setContainerState] = useState([]);
  const [favoriteItemId, setFavoriteItemId] = useState("");
  const [loader, setLoader] = useState(false);

  const group_info = useSelector((state) => state.order.group_basic_detail);
  const profile = useSelector((state) => state.profile);

  // CREATE LOCAL STATE
  const [showError, setShowError] = useState(false);

  // CREATE DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // GET ORDER DETAIL FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);

  useEffect(async () => {
    let favoritesList = await GetOrderFavoriteItemsAPI(orderId);
    setFavoriteItemList(favoritesList.data);
  }, []);

  const updateHasFavorite = () => {
    if (flow !== "Reorder") {
      // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
      let orderItem = _.cloneDeep(orderData);
      orderItem.has_favorites = favoriteItemList.length === 0 ? false : true;
      // UPDATE DATA IN REDUX STATE
      dispatch(UpdateOrderDetailAPI(orderItem));
    }
  };

  useEffect(() => {
    updateHasFavorite();
  }, [favoriteItemList]);

  const emptyCart = async () => {
    await ResetCartDetailAPI(dispatch);
    history.push("/locations");
  };

  useEffect(() => {
    if (itemsList) {
      let {
        includedProteinsList,
        dressingsList,
        containerList,
        addedList,
        RemovedList,
      } = GetOrderItemsListToSeparateArrays(itemsList);
      setIncludedProteinState(includedProteinsList);
      setAddedIngredientState(addedList);
      setRemovedIngredientState(RemovedList);
      setDressingIngredientState(dressingsList);
      setContainerState(containerList);
    }
  }, [itemsList]);

  return (
    <div className="px-2 px-md-4 py-3 ">
      {/* ORDER DETAIL DIV */}
      <div className="d-flex justify-content-between border-bottom-1 mb-2">
        <h2 className="text-capitalize BrandenGrotesqueBold font-size-1_3 text-grey1">
          {group_info.hasOwnProperty("user_id")
            ? "your group order"
            : "your order"}
        </h2>
        <p
          className="BrandenGrotesqueBold font-size-1_3 text-orange1 mb-0 cursor-pointer"
          onClick={async () => {
            setLoader(true);
            await RefreshCartItems(dispatch);
            setLoader(false);
          }}
        >
          {group_info.hasOwnProperty("user_id") &&
            group_info.user_id === profile.user_id &&
            (!loader ? (
              <>
                <img src={Refresh} className="mb-2 me-1 width-22" />
                Refresh
              </>
            ) : (
              <CircularProgress size={24} />
            ))}
        </p>
      </div>

      {itemsList &&
        itemsList.map((cartDataItem, index) => {
          let itemImage = getImageUrl(cartDataItem);
          let favoriteItemIndex = -1;
          let isFavoriteItem = false;
          favoriteItemList.map((item, index1) => {
            if (item.itemUniqueId === cartDataItem.itemUniqueId) {
              isFavoriteItem = true;
              favoriteItemIndex = index1;
            }
          });
          return (
            <div
              className="d-flex flex-column border-bottom-1 pb-2"
              key={index}
            >
              <div className="d-flex justify-content-between align-items-center my-3">
                <span className="BrandenGrotesqueBold font-size-1_2 text-brown2 w-75">
                  {cartDataItem.itemName + " (" + cartDataItem.quantity + ")"}
                </span>
                <span className="BrownSTDRegular font-size-1_1 text-grey1 cpt-3">
                  ${cartDataItem.itemTotal.toFixed(2)}
                </span>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <img
                    className="car-image me-3 cart-image-border"
                    src={itemImage === "" ? CartImage : itemImage}
                  />
                </div>

                <div>
                  {includedProteinState[index] !== "" && (
                    <p
                      key={"included_protein" + index}
                      className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-3"
                    >
                      <span className="BrownSTDBold">Included Protein: </span>
                      {includedProteinState[index]}
                    </p>
                  )}

                  {addedIngredientState[index] !== "" && (
                    <div className=" mb-3">
                      <p
                        key={"added" + index}
                        className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0"
                      >
                        <span className="BrownSTDBold">Added: </span>
                        {addedIngredientState[index]}
                      </p>
                    </div>
                  )}

                  {removedIngredientState[index] !== "" && (
                    <div className=" mb-3">
                      <p
                        key={"removed" + index}
                        className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize"
                      >
                        <span className="BrownSTDBold">Removed: </span>
                        {removedIngredientState[index]}
                      </p>
                    </div>
                  )}

                  {dressingIngredientState.length > 0 && (
                    <div className="mb-3">
                      {dressingIngredientState[index] &&
                        dressingIngredientState[index].map(
                          (dressingMenuItem, index) => (
                            <div
                              key={"dressing_" + index}
                              dangerouslySetInnerHTML={{
                                __html: dressingMenuItem,
                              }}
                            />
                          )
                        )}
                    </div>
                  )}

                  {(containerState[index] !== "" ||
                    cartDataItem.notes !== "") && (
                    <div className="mb-3">
                      {containerState[index] !== "" && (
                        <p
                          key={"container" + index}
                          className="BrownSTDBold font-size-0_9 text-grey1 text-capitalize mb-0"
                        >
                          Add {containerState[index]}
                        </p>
                      )}

                      {cartDataItem.notes && cartDataItem.notes !== "" && (
                        <p className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0">
                          <span className="BrownSTDBold">Name: </span>
                          {cartDataItem.notes}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {isFavoriteItem ? (
                  <span
                    className="BrownSTDRegular font-size-0_8 text-grey3 d-flex justify-content-end cursor-pointer me-3"
                    onClick={() =>
                      RemoveFromFavorite(
                        favoriteItemIndex,
                        favoriteItemList,
                        setFavoriteItemId,
                        setFavoriteItemList,
                        setLoader
                      )
                    }
                  >
                    {loader && favoriteItemId === cartDataItem.itemUniqueId ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Remove From Favorites"
                    )}
                  </span>
                ) : (
                  flow === "Reorder" ||
                  (flow === "Normal" && (
                    <span
                      className="BrownSTDRegular font-size-0_8 text-orange1 d-flex justify-content-end cursor-pointer me-3"
                      onClick={() =>
                        AddToFavorite(
                          cartDataItem,
                          orderId,
                          locationId,
                          favoriteItemList,
                          setFavoriteItemList,
                          setFavoriteItemId,
                          setLoader
                        )
                      }
                    >
                      {loader &&
                      favoriteItemId === cartDataItem.itemUniqueId ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Add to Favorites"
                      )}
                    </span>
                  ))
                )}

                {flow !== "Reorder" && (
                  <>
                    <span
                      className="BrownSTDRegular font-size-0_8 text-grey3 cursor-pointer text-capitalize me-3"
                      onClick={() => setEditId(index)}
                    >
                      {!cartDataItem.isSuggestionItem ? "edit" : ""}
                    </span>
                    <span
                      className="BrownSTDRegular font-size-0_8 text-grey3 cursor-pointer"
                      onClick={() =>
                        RemoveItemFromCart(
                          itemsList,
                          index,
                          setIsCalculateAPIRequiredToCall,
                          setShowError,
                          dispatch
                        )
                      }
                    >
                      Remove
                    </span>
                  </>
                )}
              </div>
            </div>
          );
        })}

      {flow !== "GroupParticipant" && (
        <div className="d-flex justify-content-between py-3">
          <p className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0 pt-1">
            Add utensils
          </p>
          <div>
            <span
              onClick={() => {
                if (flow !== "Reorder")
                  UpdateUtensilsInOrder(
                    true,
                    setIsCalculateAPIRequiredToCall,
                    dispatch
                  );
              }}
              className={`font-size-0_9 text-grey1 text-capitalize mb-0 px-2 ${
                flow == "Reorder" ? "" : "cursor-pointer"
              } ${
                hasUtensils
                  ? "utensils-selected BrownSTDBold"
                  : "BrownSTDRegular"
              }`}
            >
              Yes
            </span>
            <span
              onClick={() => {
                if (flow !== "Reorder")
                  UpdateUtensilsInOrder(
                    false,
                    setIsCalculateAPIRequiredToCall,
                    dispatch
                  );
              }}
              className={`font-size-0_9 text-grey1 text-capitalize mb-0 px-2 ${
                flow === "Reorder" ? "" : "cursor-pointer"
              } ${
                !hasUtensils
                  ? "utensils-selected BrownSTDBold"
                  : "BrownSTDRegular"
              }`}
            >
              No
            </span>
          </div>
        </div>
      )}
      {/* ORDER DETAIL DIV */}
      <DecisionDialog
        showError={showError}
        text={"Are you sure you want to empty your cart?"}
        noOption={setShowError}
        yesOption={emptyCart}
        heading={"Alert"}
      />
    </div>
  );
}

export default OrderDetail;
