import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import RewardForm from "./RewardForm";
import ImgComponent from "./ImgComponent";

import { useSelector, useDispatch } from "react-redux";
import { RegisterAPI, UpdateDialCode } from "../../actions/Auth/Auth";

import { ProfileReducer } from "../../redux/reducers/ProfileReducer";

const ProfileActions = ProfileReducer.actions;

const Reward = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.profile);

  const UpdatePhoneCode = async () => {
    if (
      userProfile.user_id &&
      !userProfile.countryCode &&
      userProfile.temp_country_code
    ) {
      let userProfile_new = _.cloneDeep(userProfile);
      let updateResponse = UpdateDialCode(
        userProfile.user_id,
        parseInt(userProfile.temp_country_code).toString(),
        false
      );
      updateResponse = await Promise.resolve(updateResponse);
      userProfile_new.country_code = updateResponse.data.dialCode;
      userProfile_new.temp_country_code = null;
      let updateResponse1 = dispatch(
        ProfileActions.updateUser(userProfile_new)
      );
    }
  };
  useEffect(async () => {
    UpdatePhoneCode();
  }, [userProfile]);

  return (
    <Row className="auth-forms">
      <Col className="p-0 pt-0 d-flex justify-content-center">
        <RewardForm />
      </Col>
      <Col className="p-0 image_page signup_page">
        <ImgComponent />
      </Col>
    </Row>
  );
};
export default Reward;
