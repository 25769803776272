import React, { useEffect, useState } from "react";
import OrderType from "./OrderType";
import "../../assets/css/Orders.css";
import OrderHistory from "./OrderHistory";
import FavoriteMenu from "./FavoriteMenu";
import OrderGroupHistory from "./OrderGroupHistroy";

function Orders({ searchId }) {
  const location = new URLSearchParams(window.location.search);
  const isGroupOrder = location.get("is_group") || false;
  const [displayType, setDisplayType] = useState(isGroupOrder ? 3 : 1); // 1 means orders history, 2 means favorites, 3 means group order
  const [lastOrderId, setLastOrderId] = useState(searchId ? searchId : -1);
  useEffect(() => {
    setLastOrderId(-1);
  }, [displayType]);
  useEffect(() => {
    setDisplayType(isGroupOrder ? 3 : 1);
  }, [isGroupOrder]);
  return (
    <div className="w-100 m-auto orders_history">
      <OrderType displayType={displayType} setDisplayType={setDisplayType} />
      <div className="orders_history_img"></div>
      <div className="orders_history_list">
        {displayType === 1 ? (
          <OrderHistory searchId={lastOrderId} />
        ) : displayType === 3 ? (
          <OrderGroupHistory searchId={lastOrderId} />
        ) : (
          <FavoriteMenu />
        )}
      </div>
    </div>
  );
}

export default Orders;
