import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import Navbar from "../components/Navbars/Navbar";
import routes from "../routes/routes.js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { DecryptReduxAuth } from "../actions/General/General";
import Footer from "../components/Navbars/Footer";

function Layout() {
  // CREATE LOCAL STATE
  const [currentPathName, setCurrentPathName] = useState("");
  const [isAuthRoute, setIsAuthRoute] = useState(0);
  const [loader, setLoader] = useState(true);

  // GET AUTH STATE FROM REDUX
  let auth = useSelector((state) => state.auth.data);
  let profile = useSelector((state) => state.profile);

  // DECRYPT REDUX AUTH STATE
  auth = DecryptReduxAuth(auth);
  const history = useHistory();
  const location = useLocation();
  const mainPanel = React.useRef(null);

  // IF SERVER PUBLIC KEY EMPTY IN AUTH REDUX STATE, THEN GET THE SERVER KEY FROM API
  useEffect(() => {
    loader && setLoader(false);
  }, []);

  window.scrollTo({
    top: 0,
    behavior: "instant",
  });

  const getRoutes = (routes) => {
    let isValidPath = false;
    // LOOP OVER ALL ROUTE MENTIONED IN ROUTES.JS FILE
    let allRoutes = routes.map((prop, key) => {
      let pathName = location.pathname.split("/");

      let searchId = pathName.length > 1 ? pathName[2] : "";
      pathName = "/" + pathName[1];
      // CHECK IF THE URL IS A VALID URL
      if (!isValidPath && (pathName === "/" + prop.path || pathName === "/")) {
        if (prop.name !== currentPathName) {
          setIsAuthRoute(prop.authRequired);
          setCurrentPathName(prop.name);
        }
        isValidPath = true;
      }

      // IF PHONE NUMBER IS NOT VALID, REDIRECT THE USER TO PROFILE PAGE
      if (
        !auth.isGuest &&
        !profile.isValidPhoneNumber &&
        !profile.temp_country_code &&
        pathName !== "/profile"
      ) {
        history.push("/profile");
      }

      // IF USER IS NOT LOGGED IN AND TRIED IS ACCESS AUTH PAGES
      // IF USER IS LOGGED IN AND TRIED IS ACCESS NON AUTH PAGES
      // REDIRECT TO HOME PAGES

      // if (!auth.hasGuestOrder) {
        if (
          !auth.isGuest &&
          ((prop.authRequired === 0 && "/" + prop.path === pathName) ||
            pathName === "/")
        ) {
          history.push("/dashboard");
        }
        if (
          auth.isGuest &&
          prop.authRequired === 1 &&
          "/" + prop.path === pathName
        ) {
          history.push("/");
        }
      // }
      // RETURN ALL ROUTE FROM ROUTES.JS FILE
      return (
        <Route
          key={prop.path}
          path={prop.layout + prop.path}
          render={(props) => <prop.component {...props} searchId={searchId} />}
        />
      );
    });

    // IF NOT A VALID URL, REDIRECT TO DASHBOARD OR HOME PAGE ACCORDING TO LOGGED IN STATE
    if (!isValidPath) {
      if (!auth.isGuest) {
        history.push("/dashboard");
      } else {
        history.push("/");
      }
    }

    // RETURN ALL ROUTES
    return (
      <React.Suspense fallback={CircularProgress}>{allRoutes}</React.Suspense>
    );
  };
  return (
    <>
      {loader ? (
        <CircularProgress size={24} />
      ) : (
        <div className="wrapper">
          <div className="main-panel" ref={mainPanel}>
            <React.Suspense fallback={CircularProgress}>
              <Navbar
                currentPathName={currentPathName}
                isAuthRoute={isAuthRoute}
              />
            </React.Suspense>
            <React.Suspense fallback={CircularProgress}>
              <div
                className={`content ${
                  isAuthRoute !== 0 ? "cmt-110 cmt-md-95" : ""
                }`}
              >
                <Container fluid className={"p-0"}>
                  <Switch>{getRoutes(routes)}</Switch>
                </Container>
              </div>
            </React.Suspense>
          </div>
          <Footer currentPathName={currentPathName} />
        </div>
      )}
    </>
  );
}

export default Layout;
