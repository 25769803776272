import React, { useEffect } from "react";
import { ClickAwayListener } from "@material-ui/core";
import DownArrow from "../../assets/img/MenuDetailDown.svg";
import { useSelector } from "react-redux";

function DressingDropdown({
  ingredients,
  openedOptionsId,
  setOpenedOptionsId,
  setIngredients,
}) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const dressingItems = useSelector((state) => state.menu.dressing_detail);

  useEffect(() => {
    let isNeedToUpdateDressingState = false;

    // LOOP OVER ALL INGREDIENT ITEMS TO GET DRESSING INGREDIENTS
    for (const item in ingredients) {
      // IF INGREDIENT IS DRESSING ITEM
      if (ingredients[item].isDressing) {
        // GET DRESSING ITEM DETAIL
        let dressingItem = _.cloneDeep(ingredients[item]);

        // CHECK IF DRESSING INGREDIENT HAS MODIFIER CODES KEY
        // IF EXIST THEN GET DETAIL OF MODIFIER CODE OTHERWISE SET EMPTY ARRAY
        let dressingModifiers = dressingItem.hasOwnProperty("modifierCodes")
          ? dressingItem.modifierCodes
          : [];

        // IF DRESSING MODIFIERS LENGTH IS NOT EQUAL TO DRESSING INGREDIENT COUNT, THEN ADD FIRST DRESSING MODIFIER ID FOR DRESSING ITEMS
        if (dressingModifiers.length !== dressingItem.count) {
          // UPDATE THE VARIABLE THAT INDICATES THAT WE NEED TO UPDATE THE INGREDIENT STATE OR NOT
          isNeedToUpdateDressingState = true;

          // LOOP FOR REMAINING DRESSING INGREDIENTS TO ADD MODIFIER CODE
          for (let i = dressingModifiers.length; i < dressingItem.count; i++) {
            dressingModifiers.push({
              name: dressingItems[0].name,
              brinkId: dressingItems[0].brinkId,
            });
          }
        }

        dressingItem.modifierCodes = dressingModifiers;
        ingredients[item] = dressingItem;
      }
    }

    if (isNeedToUpdateDressingState) setIngredients(_.cloneDeep(ingredients));
  }, [ingredients]);
  return (
    <div>
      {Object.values(ingredients).map((modifier) => {
        if (modifier.isDressing)
          return Array.from(Array(modifier.count).keys()).map(
            (dressingIndex) => {
              return (
                <div key={modifier.idMenuItem + "_" + dressingIndex}>
                  <div
                    onClick={(e) => {
                      setOpenedOptionsId(
                        modifier.idMenuItem + "_" + dressingIndex
                      );
                    }}
                    className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 cursor-pointer d-flex justify-content-between"
                  >
                    <span className="BrownSTDRegular font-size-0_9 text-grey1">
                      {modifier.name} -{" "}
                      {modifier.hasOwnProperty("modifierCodes") &&
                      modifier.modifierCodes[dressingIndex]
                        ? modifier.modifierCodes[dressingIndex].name
                        : ""}
                    </span>
                    <img
                      src={DownArrow}
                      alt={"Down Arrow"}
                      className={"align-self-center"}
                    />
                  </div>
                  {openedOptionsId ===
                    modifier.idMenuItem + "_" + dressingIndex && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setOpenedOptionsId("");
                      }}
                    >
                      <div className="dropdown position-absolute bg-white">
                        {dressingItems.map((dressingItem, index) => {
                          return (
                            <li
                              className="p-3 cursor-pointer  "
                              key={index}
                              onClick={() => {
                                modifier.modifierCodes[dressingIndex] = {
                                  name: dressingItem.name,
                                  brinkId: dressingItem.brinkId,
                                };
                                setOpenedOptionsId("");
                              }}
                            >
                              {dressingItem.name}
                            </li>
                          );
                        })}
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              );
            }
          );
      })}
    </div>
  );
}

export default DressingDropdown;
