import React, { useState } from "react";
import FB from "../../assets/img/FB.svg";
import Twitter from "../../assets/img/Twitter.svg";
import Instagram from "../../assets/img/Instagram.svg";
import FooterCertified from "../../assets/img/FooterCertified.png";

function Footer({ currentPathName }) {

  const openPage = (url, type) => {
    window.open(url, type);
  };

  return (
    <div
      className={`bg-white mx-auto cpt-30 cpy-40 w-100 ${
        currentPathName === "Register" ? "register-page-footer" : ""
      } ${currentPathName === "Home" ? "cpt-60" : "cpt-md-30"} ${
        currentPathName === "Orders" ? "footer_fav" : "footer"
      }`}
      style={{
        marginTop:
          currentPathName === "Dashboard"
            ? "55px"
            : currentPathName === "Locations"
            ? "position-sticky"
            : "",
      }}
    >
      <div
        className={`d-flex justify-content-center flex-column flex-lg-row p-3 ${
          currentPathName !== "Orders" ? "cmt-100" : ""
        }`}
      >
        <div>
          <div className="mb-4 d-flex flex-wrap justify-content-center">
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/faq", "_self");
              }}
            >
              FAQ
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/our-story", "_self");
              }}
            >
              Our Story
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/nutrition", "_self");
              }}
            >
              Nutrition Calculator
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/news", "_self");
              }}
            >
              News
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("http://www.mixtrover.com", "_self");
              }}
            >
              Rover
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/loyalty", "_self");
              }}
            >
              Mixt + Rewards
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() => {
                openPage("https://www.mixt.com/help", "_self");
              }}
            >
              Help
            </span>
          </div>
        </div>
        <div className="mx-0 px-0 mx-md-4 px-md-3 pt-3 pt-md-0">
          <div className="d-flex justify-content-center justify-content-md-end mb-4">
            <img
              src={FB}
              alt={"FB"}
              className={"ps-4 cursor-pointer"}
              onClick={() =>
                openPage("http://www.facebook.com/hellomixt/", "_blank")
              }
            />
            <img
              src={Twitter}
              alt={"Twitter"}
              className={"ps-4 cursor-pointer"}
              onClick={() =>
                openPage("https://twitter.com/hellomixt", "_blank")
              }
            />
            <img
              src={Instagram}
              alt={"Instagram"}
              className={"ps-4 cursor-pointer"}
              onClick={() =>
                openPage("https://www.instagram.com/mixt/?hl=en", "_blank")
              }
            />
          </div>
          <div>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() =>
                openPage("https://www.mixt.com/privacy-policy/", "_self")
              }
            >
              Privacy Policy
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer"
              onClick={() =>
                openPage("https://www.mixt.com/terms-of-use/", "_self")
              }
            >
              Terms of Use
            </span>
            <span
              className="BrownSTDLight font-size-0_9 me text-grey9 cursor-pointer"
              onClick={() =>
                openPage("https://www.mixt.com/terms-of-use#copyright", "_self")
              }
            >
              Copyright Policy & Claims
            </span>
          </div>
          <div className="px-4 d-flex flex-column flex-md-row align-items-center">
            <span
              className="BrownSTDLight font-size-0_9 me-3 text-grey9 cursor-pointer py-4 py-md-0"
              onClick={() =>
                openPage("https://www.mixt.com/privacy-policy/#ccpa", "_self")
              }
            >
              California Privacy Rights
            </span>
            <span className="BrownSTDLight font-size-0_9 me-3 text-grey9 pb-3 pb-md-0">
              © {new Date().getFullYear()} Mixt, All rights reserved.
            </span>
          </div>
        </div>
        <div className="text-center">
          <img
            onClick={() => {
              openPage("https://www.bcorporation.net/", "_blank");
            }}
            src={FooterCertified}
            className={"width-50 cursor-pointer"}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
