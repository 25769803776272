import React from "react";
import Decrease from "../../assets/img/Decrease.svg";
import Increase from "../../assets/img/Increase.svg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MenuDefaultImage from "../../assets/img/MenuDefaultImage.jpg";
import { getImageUrl } from "../../actions/General/General";
import {
  prepareItemDetailAndAdd,
  RemoveItemFromIngredientsList,
} from "./MenuFunctions";
import { MENU_ALLERGEN_FREE_TEXT } from "../../config/config";

function PackageCustomizationItem({
  groupItem,
  ingredientName,
  modifier,
  ingredients,
  setIngredients,
}) {
  let image = getImageUrl(groupItem.menuItem);

  const increaseCounter = (item, modifierData) => {
    let ingredientsArray = prepareItemDetailAndAdd(
      item,
      modifierData,
      ingredients,
      {
        itemType: "other",
      }
    );
    setIngredients(_.cloneDeep(ingredientsArray));
  };

  const decreaseCounter = (brinkId) => {
    // CALL THE REMOVE INGREDIENT METHOD, THAT RETURNS UPDATED INGREDIENTS LIST, NEWLY ADDED INCLUDED PROTEIN ID AND DEFAULT REMOVED INGREDIENT ID
    let { currentIngredientsList } = RemoveItemFromIngredientsList(
      ingredients,
      brinkId,
      0
    );
    setIngredients(_.cloneDeep(currentIngredientsList));
  };

  return (
    <Card className="d-flex align-items-between flex-column justify-content-between packageCustomizeItem mx-2 singleMenuItem cursor-pointer">
      <div className="d-flex flex-column flex-1">
        <CardMedia
          className="customizeItemImage mix-blend-mode w-100"
          image={image === "" ? "" : image}
          title={groupItem.menuItem.name}
        />
        <CardContent className="d-flex flex-column justify-content-between flex-1 w-100">
          <div
            className="d-flex flex-column cursor-pointer my-2 text-start"
            key={groupItem.idModifierGroupItems}
          >
            <h3 className="BrandenGrotesqueBold font-size-1_5 text-grey4 default-letter-spacing text-uppercase">
              {groupItem.menuItem.name}
            </h3>
            <p className="BrownSTDRegular font-size-0_8 text-grey1 mt-3 h-100">
              {ingredientName &&
                ingredientName.substring(
                  0,
                  ingredientName.indexOf(MENU_ALLERGEN_FREE_TEXT) > -1
                    ? ingredientName.indexOf(MENU_ALLERGEN_FREE_TEXT)
                    : ingredientName.length
                )}
            </p>
          </div>
          <span className="d-flex align-items-center ms-auto">
            <img
              src={Decrease}
              className={"cursor-pointer mix-blend-mode"}
              onClick={() => {
                if (ingredients.hasOwnProperty(groupItem.menuItem.itemIdBrink))
                  decreaseCounter(groupItem.menuItem.itemIdBrink);
              }}
              style={{
                opacity: ingredients.hasOwnProperty(
                  groupItem.menuItem.itemIdBrink
                )
                  ? 1
                  : 0.5,
              }}
            />
            <span className="px-3 cpt-1 BrownSTDBold font-size-1_5 line-height-0">
              {ingredients.hasOwnProperty(groupItem.menuItem.itemIdBrink)
                ? ingredients[groupItem.menuItem.itemIdBrink].count
                : 0}
            </span>
            <img
              className={"cursor-pointer mix-blend-mode"}
              onClick={() => {
                if (
                  !ingredients.hasOwnProperty("modifiersCounter") ||
                  !ingredients.modifiersCounter.hasOwnProperty(
                    modifier.brinkId
                  ) ||
                  (ingredients.hasOwnProperty("modifiersCounter") &&
                    ingredients.modifiersCounter.hasOwnProperty(
                      modifier.brinkId
                    ) &&
                    ingredients.modifiersCounter[modifier.brinkId] <
                      modifier.maximum)
                ) {
                  let modifierData = _.cloneDeep(modifier);
                  delete modifierData["modifierGroupItems"];
                  increaseCounter(groupItem, modifierData);
                }
              }}
              src={Increase}
            />
          </span>
        </CardContent>
      </div>
    </Card>
  );
}

export default PackageCustomizationItem;
