import React from "react";
import { Dialog } from "@material-ui/core";
import Logo from "../../components/GeneralComponents/Logo";
import { Button } from "react-bootstrap";
import { RemoveGroupOrderBasicDetailAPI, ResetCartDetailAPI } from "../../actions/Cart/Cart";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function GuestCartCreated({ flow }) {
  const groupBasicDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Dialog
      open={true}
      aria-labelledby="draggable-dialog-title"
      className={`customize_modal confirm-reward`}
    >
      <div className="py-4 px-5">
        <Logo />
        <h1
          className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-20 cmb-15"
          style={{ letterSpacing: "3px" }}
        >
          {flow === "GroupAdmin"
            ? "nice work!"
            : flow === "GroupParticipant"
            ? "nice work!"
            : flow === "Normal"
            ? "thank you"
            : ""}
        </h1>
        <p className="BrownSTDRegular text-brown1 text-center cmb-25 line-height-1_25">
          <span className="font-size-0_9">
            {flow === "GroupAdmin"
              ? "Your Group Order has been successfully placed. Everyone is going to love you."
              : flow === "GroupParticipant"
              ? `Your order has been added to the Group Order. Please contact ${groupBasicDetail.user_name} to make any changes.`
              : flow === "Normal"
              ? "Your order has been successfully placed. That's how you salad!"
              : ""}
          </span>
        </p>
        <div className="text-center">
          <Button
            variant=""
            className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 rounded-full bg-green3 default-letter-spacing btn-half width-233 mb-3`}
            onClick={async () => {
              if (flow === "GroupAdmin")
                RemoveGroupOrderBasicDetailAPI(dispatch);
              await ResetCartDetailAPI(dispatch);
              if (flow === "GroupParticipant") history.push("dashboard");
              else history.push("orders");
            }}
          >
            {flow === "GroupAdmin"
              ? "Enjoy!"
              : flow === "GroupParticipant"
              ? "Sounds Good"
              : flow === "Normal"
              ? "Enjoy!"
              : ""}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default GuestCartCreated;
