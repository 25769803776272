import React, { useEffect, useState } from "react";
import {
  GetMenuItemDetailAPI,
  UpdateCampaignIdAPI,
} from "../../actions/Menu/Menu";
import { useSelector, useDispatch } from "react-redux";
import { MENU_ALLERGEN_FREE_TEXT } from "../../config/config";
import { Button } from "react-bootstrap";
import MenuCustomization from "./MenuCustomization.js";
import MenuItemDetailDropDown from "./MenuItemDetailDropDown";
import RightArrow from "../../assets/img/MenuDetailRight.svg";
import DressingDropdown from "./DressingDropDown";
import Decrease from "../../assets/img/Decrease.svg";
import Increase from "../../assets/img/Increase.svg";
import PackageItemList from "./PackageItemList";
import PackageCustomization from "./PackageCustomization";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import MessageDialog from "../MessageDialog";
import {
  CalculateTotalAmountOfOrder,
  CreateGoogleAnalyticsAPI,
  DecryptReduxAuth,
  getImageUrl,
} from "../../actions/General/General";
import { CircularProgress } from "@material-ui/core";
import { UpdateOrderDetailAPI } from "../../actions/Cart/Cart";
import {
  populateDefaultList,
  calculateOrderPrice,
  prepareItemDetailAndAdd,
  RemoveItemFromIngredientsList,
  getModifierOrItemPrice,
  getAddedAndRemovedItemsList,
  checkModifierMinimumLimitAchieved,
  rePopulateIngredientsFromOrderData,
  calculateOrderCalories,
  createOrderItemsData,
} from "./MenuFunctions";
import $ from "jquery";
import { MenuReducer } from "../../redux/reducers/MenuReducer";
import MessagesList from "../../config/messages.json";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { GetOrderFavoriteItemsAPI } from "../../actions/Orders/Order.js";
import { AddToFavorite, RemoveFromFavorite } from "../Cart/cartFunctions.js";
const MenuActions = MenuReducer.actions;

function MenuItemDetail({
  itemId,
  itemDetailData,
  setSelectedItem,
  isEdit = false,
  itemNo = 0,
}) {
  // CREATE DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const menuLocation = useSelector((state) => state.menu.location_info);
  const menuItemDetail = useSelector((state) => state.menu.item_detail);
  const orderDetail = useSelector((state) => state.order);
  const profile = useSelector((state) => state.profile);
  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);
  const history = useHistory();
  // CREATE LOCAL STATE
  const [name, setName] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCalories, setTotalCalories] = useState(0);
  const [orderQuantity, setOrderQuantity] = useState(1);
  const [ingredients, setIngredients] = useState({});
  const [addedIngredients, setAddedIngredients] = useState("");
  const [removedIngredients, setRemovedIngredients] = useState("");
  const [showCustomization, setShowCustomization] = useState(false);
  const [openedOptionsId, setOpenedOptionsId] = useState("");
  const [basicPrice, setBasicPrice] = useState(0);
  const [isPackage, setIsPackage] = useState(false);
  const [isPackageCompleted, setIsPackageCompleted] = useState(false);
  const [packageIngredientsList, setPackageIngredientsList] = useState({});
  const [showError, setShowError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");
  const [itemImage, setItemImage] = useState("");
  const [loader, setLoader] = useState(true);
  const [notDisplayedModifierIds, setNotDisplayedModifierIds] = useState([]);
  const [itemDetail, setItemDetail] = useState(itemDetailData);
  const [isItemReadyToAddInCart, setIsItemReadyToAddInCart] = useState(false);
  const [isNameRequired, setIsNameRequired] = useState(false);

  const [favoriteItemId, setFavoriteItemId] = useState("");
  const [favoriteItemList, setFavoriteItemList] = useState(null);

  useEffect(async () => {
    if (orderDetail?.calculate_order_data?.orderId && !auth.isGuest) {
      let favoritesList = await GetOrderFavoriteItemsAPI(
        orderDetail.calculate_order_data.orderId
      );
      setFavoriteItemList(favoritesList.data);
      console.log(favoritesList.data, itemDetail.itemUniqueId);
    }
  }, []);

  useEffect(() => {
    CreateGoogleAnalyticsAPI("ItemDetailView", {});
    if (name === "" && orderDetail.calculate_order_data.isGroupOrder) {
      setName(profile.first_name + " " + profile.last_name);
    }
  }, []);

  useEffect(async () => {
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    // GET MENU ITEM DETAIL
    let APIdata = {
      id: itemId,
      type: menuLocation.location_type,
      locationId1: menuLocation.location_id1,
    };

    if (menuLocation.location_id2 && menuLocation.location_id1 !== "") {
      APIdata.locationId2 = menuLocation.location_id2;
    }
    if (menuLocation.location_id2 && menuLocation.location_id1 === "") {
      APIdata.locationId1 = menuLocation.location_id2;
    }

    let result = await GetMenuItemDetailAPI(APIdata);
    if (result.message) {
      setShowError(true);
      setErrorType("ingredient not available");
      setShowErrorMessage(MessagesList[result.message]);
    } else {
      dispatch(
        MenuActions.UpdateItemDetail({
          itemDetail: result.data,
        })
      );
    }
    dispatch(UpdateCampaignIdAPI(""));
  }, [itemId]);

  useEffect(() => {
    if (!showError && errorType === "ingredient not available") {
      location.reload();
    }
  }, [showError]);

  const customizeOrder = (updateIngredientsList) => {
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    let isMinimumModifierValid = checkModifierMinimumLimitAchieved(
      updateIngredientsList,
      menuItemDetail.modifiers
    );
    if (isMinimumModifierValid.status) {
      // ORDER IS CUSTOMIZED
      // HIDE THE CUSTOMIZATION SCREEN BY UPDATING STATE
      setShowCustomization(false);

      // CALL FUNCTION THAT WILL GET ADDED AND REMOVED INGREDIENTS
      let { addedList, removedList } = getAddedAndRemovedItemsList(
        menuItemDetail.ingredients,
        updateIngredientsList
      );

      // UPDATE ADDED/REMOVED INGREDIENTS STATE
      setAddedIngredients(addedList);
      setRemovedIngredients(removedList);

      let isOrderReadyToPlace = checkModifierMinimumLimitAchieved(
        updateIngredientsList,
        menuItemDetail.modifiers
      );

      if (isOrderReadyToPlace.status) {
        setIsItemReadyToAddInCart(true);
      }
    } else {
      let errorMessage = isMinimumModifierValid.minimumLimitMessage;
      setShowErrorMessage(errorMessage);
      setShowError(true);
    }
  };

  // CREATE ORDER OBJECT
  const addOrder = async () => {
    let isOrderReadyToPlace = checkModifierMinimumLimitAchieved(
      isPackage ? packageIngredientsList : ingredients,
      menuItemDetail.modifiers
    );

    let isNameFieldRequired = false;
    if (orderDetail.calculate_order_data.isGroupOrder) {
      if (name === "") {
        isNameFieldRequired = true;
      }
    }
    setIsNameRequired(isNameFieldRequired);
    if (!isNameFieldRequired) {
      if (isOrderReadyToPlace.status) {
        // GET PREVIOUSLY SELECTED MENUS OF CART FROM REDUX
        let previousOrderItemsArray =
          orderDetail.calculate_order_data.hasOwnProperty("items")
            ? _.cloneDeep(orderDetail.calculate_order_data.items)
            : [];

        let orderItemsData = createOrderItemsData(
          isPackage ? packageIngredientsList : ingredients,
          menuItemDetail.ingredients,
          itemDetail,
          menuItemDetail.modifiers,
          previousOrderItemsArray,
          {
            itemIdBrink: menuItemDetail.itemIdBrink,
            menuItemId: menuItemDetail.idMenuItem
              ? menuItemDetail.idMenuItem
              : menuItemDetail.idCommonMenuItem,
            orderQuantity,
            name,
            basicPrice,
            totalPrice,
            isEdit,
            itemNo,
            modifierMethodType: menuItemDetail.modifierMethodType,
            categoryMenuItemIdBrink: menuItemDetail.categoryMenuItemIdBrink
              ? menuItemDetail.categoryMenuItemIdBrink
              : 0,
            itemphoto: menuItemDetail.itemphoto,
            categoryCustomType: menuItemDetail.categoryCustomType,
            itemCustomType: menuItemDetail.itemCustomType,
          }
        );

        let orderData = _.cloneDeep(orderDetail.calculate_order_data);
        orderData.items = orderItemsData;
        orderData.payment_info = CalculateTotalAmountOfOrder(orderData);
        dispatch(UpdateOrderDetailAPI(orderData));
        await CreateGoogleAnalyticsAPI("AddedToCart", {});
        if (isEdit) {
          setSelectedItem("update");
        } else {
          if (orderDetail.calculate_order_data.isGroupOrder)
            history.push("/cart");
          else setSelectedItem(-1);
        }
      } else {
        if (
          menuItemDetail.itemCustomType === 2 ||
          menuItemDetail.itemCustomType === 3 ||
          menuItemDetail.categoryCustomType === 7 ||
          menuItemDetail.categoryCustomType === 2
          // ||
          // menuItemDetail.categoryCustomType === 4
        ) {
          setShowCustomization(true);
        } else {
          let errorMessage = isOrderReadyToPlace.minimumLimitMessage;
          setShowErrorMessage(errorMessage);
          setShowError(true);
        }
      }
    }
  };

  const customizePackage = (ingredientsList) => {
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    setIsPackageCompleted(true);
    // ORDER IS CUSTOMIZED
    // HIDE THE CUSTOMIZATION SCREEN BY UPDATING STATE
    setShowCustomization(false);

    setPackageIngredientsList(ingredientsList);

    let isOrderReadyToPlace = checkModifierMinimumLimitAchieved(
      ingredientsList,
      menuItemDetail.modifiers
    );

    if (isOrderReadyToPlace.status) {
      setIsItemReadyToAddInCart(true);
    }
  };

  useEffect(() => {
    if (
      menuItemDetail.hasOwnProperty("price") &&
      menuItemDetail.itemIdBrink === itemId
    ) {
      setIsPackage(
        menuItemDetail.categoryCustomType === 7 ||
          menuItemDetail.categoryCustomType === 8
      );

      // Need description, categoryCustomType
      // FOR EDITING
      let categoryCustomType = menuItemDetail.categoryCustomType;

      setItemDetail({
        itemUniqueId: itemDetail.itemUniqueId
          ? itemDetail.itemUniqueId
          : uuidv4(),
        description: menuItemDetail.categoryMenuItemDescription,
        name: menuItemDetail.categoryMenuItemName
          ? menuItemDetail.categoryMenuItemName
          : menuItemDetail.name,
        priceCal: `${menuItemDetail.calories} cals`,
        seasonal: menuItemDetail.seasonal,
        categoryCustomType: categoryCustomType,
        isPackage:
          menuItemDetail.categoryCustomType === 7 ||
          menuItemDetail.categoryCustomType === 8,
      });

      let image = getImageUrl(menuItemDetail);
      setItemImage(image);
      setBasicPrice(menuItemDetail.price);
      if (!isEdit) {
        let ingredientsList = populateDefaultList(
          menuItemDetail.ingredients,
          menuItemDetail.modifiers,
          menuItemDetail.defaultProteinBrinkId
        );
        setIngredients(ingredientsList);

        setLoader(false);
      } else {
        // GET ORDER DETAIL
        let orderData = orderDetail.calculate_order_data;

        // SET QUANTITY AND NAME FROM ORDER
        setOrderQuantity(orderData.items[itemNo].quantity);
        setName(orderData.items[itemNo].notes);

        setItemDetail({
          itemUniqueId: itemDetail.itemUniqueId
            ? itemDetail.itemUniqueId
            : uuidv4(),
          description: menuItemDetail.categoryMenuItemDescription,
          name: menuItemDetail.categoryMenuItemName
            ? menuItemDetail.categoryMenuItemName
            : menuItemDetail.name,
          priceCal: `${menuItemDetail.calories} cals`,
          seasonal: menuItemDetail.seasonal,
          categoryCustomType: categoryCustomType,
          isPackage:
            menuItemDetail.categoryCustomType === 7 ||
            menuItemDetail.categoryCustomType === 8,
          idGroupOrderItems: orderData.items[itemNo].idGroupOrderItems,
          userId: orderData.items[itemNo].userId,
        });

        // POPULATE DEFAULT INGREDIENT
        let ingredientsList = populateDefaultList(
          menuItemDetail.ingredients,
          menuItemDetail.modifiers,
          menuItemDetail.defaultProteinBrinkId,
          {},
          1
        );

        // GET SELECTED ORDER INGREDIENT LIST
        let previousSelectedIngredientsList =
          orderData.items[itemNo].modifierItems;

        // REPOPULATE ALL SELECTED DATA
        ingredientsList = rePopulateIngredientsFromOrderData(
          previousSelectedIngredientsList,
          ingredientsList,
          menuItemDetail.modifiers,
          menuItemDetail.ingredients
        );
        setIngredients(ingredientsList);
        if (
          menuItemDetail.categoryCustomType === 7 ||
          menuItemDetail.categoryCustomType === 8
        )
          customizePackage(ingredientsList);
        else customizeOrder(ingredientsList);

        setLoader(false);
      }
    }
  }, [menuItemDetail]);

  useEffect(() => {
    let price = calculateOrderPrice(
      isPackage ? packageIngredientsList : ingredients,
      menuItemDetail.price
    );
    let calories = calculateOrderCalories(
      isPackage ? packageIngredientsList : ingredients,
      menuItemDetail.calories,
      menuItemDetail.ingredients
    );
    // UPDATE PRICE ACCORDING TO QUANTITY
    price *= showCustomization ? 1 : orderQuantity;
    calories *= showCustomization ? 1 : orderQuantity;

    price = price.toFixed(2);
    // UPDATE TOTAL PRICE IN STATE
    setTotalPrice(price);
    setTotalCalories(calories);
  }, [ingredients, orderQuantity, showCustomization, packageIngredientsList]);

  useEffect(() => {
    if (!showCustomization) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [showCustomization]);

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      !isPackage || (isPackage && isPackageCompleted)
        ? addOrder()
        : setShowCustomization(true);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [menuItemDetail, ingredients]);

  const AddRemoveFavorite = () => {
    let isAddToFavourite =
      favoriteItemList.filter(
        (singleFavItem) =>
          singleFavItem.itemUniqueId === itemDetail.itemUniqueId
      ).length === 0;

    if (isAddToFavourite) {
      let orderData = _.cloneDeep(orderDetail.calculate_order_data);
      let order_location_id = null;
      if (orderData.mixtLocationId) {
        order_location_id = orderData.mixtLocationId;
      }
      if (
        orderData.doordashLocationId &&
        (order_location_id === null ||
          orderData.doordashLocationId < orderData.mixtLocationId)
      ) {
        order_location_id = orderData.doordashLocationId;
      }
      let orderItemDataToMakeFavorite = createOrderItemsData(
        isPackage ? packageIngredientsList : ingredients,
        menuItemDetail.ingredients,
        itemDetail,
        menuItemDetail.modifiers,
        [],
        {
          itemIdBrink: menuItemDetail.itemIdBrink,
          menuItemId: menuItemDetail.idMenuItem
            ? menuItemDetail.idMenuItem
            : menuItemDetail.idCommonMenuItem,
          orderQuantity,
          name,
          basicPrice,
          totalPrice,
          isEdit,
          itemNo,
          modifierMethodType: menuItemDetail.modifierMethodType,
          categoryMenuItemIdBrink: menuItemDetail.categoryMenuItemIdBrink
            ? menuItemDetail.categoryMenuItemIdBrink
            : 0,
          itemphoto: menuItemDetail.itemphoto,
          categoryCustomType: menuItemDetail.categoryCustomType,
          itemCustomType: menuItemDetail.itemCustomType,
        },
        false
      );
      AddToFavorite(
        orderItemDataToMakeFavorite,
        orderDetail.calculate_order_data.orderId,
        order_location_id,
        favoriteItemList,
        setFavoriteItemList,
        setFavoriteItemId,
        setLoader
      );
    } else {
      let favoriteItemIndex = -1;
      favoriteItemList.map((item, index) => {
        if (item.itemUniqueId === itemDetail.itemUniqueId) {
          favoriteItemIndex = index;
        }
      });
      RemoveFromFavorite(
        favoriteItemIndex,
        favoriteItemList,
        setFavoriteItemId,
        setFavoriteItemList,
        setLoader
      );
    }
  };

  return (
    <>
      {loader && (
        <div className="circular-progress-bar">
          <CircularProgress />
        </div>
      )}
      <div className="d-flex top-border flex-column flex-sm-row">
        <div className="menu-detail-back-icon">
          <img
            className={`cml-md-28 mt-md-4 pt-md-2 cursor-pointer back-image`}
            alt={"Back image"}
            onClick={() => {
              if (showCustomization) setShowCustomization(false);
              else isEdit ? setSelectedItem("") : setSelectedItem(0);
            }}
          />
        </div>
        {showCustomization ? (
          isPackage ? (
            <PackageCustomization
              modifiers={menuItemDetail.modifiers}
              modifiersDescription={menuItemDetail.modifiersItemDescription}
              packageIngredientsList={packageIngredientsList}
              setPackageIngredientsList={setPackageIngredientsList}
              customizePackage={customizePackage}
              itemDetail={itemDetail}
            />
          ) : (
            <MenuCustomization
              itemDetail={itemDetail}
              customizeOrder={customizeOrder}
              ingredientsList={ingredients}
              totalPrice={totalPrice}
              notDisplayedModifierIds={notDisplayedModifierIds}
              setNotDisplayedModifierIds={setNotDisplayedModifierIds}
              setIngredientsList={setIngredients}
              defaultIngredientsList={
                menuItemDetail &&
                menuItemDetail.ingredients &&
                menuItemDetail.ingredients
              }
              calories={totalCalories}
            />
          )
        ) : (
          <>
            <div className="w-100 w-sm-50 w-md-65 position-relative menu-detail-image">
              <div
                style={{
                  backgroundImage: `url(${itemImage === "" ? "" : itemImage})`,
                }}
              ></div>
            </div>
            <div className="w-100 w-sm-50 w-md-35 p-4 p-md-5">
              <div className="menu-detail-section-row">
                <h3 className="BrandenGrotesqueBold font-size-1_9 text-grey4 default-letter-spacing mb-0">
                  {/* SHOW NAME WITH OUT OUR OWN CAPITALIZATION
                  https://inv8.atlassian.net/browse/MWOP-221 */}
                  {menuItemDetail.categoryMenuItemName
                    ? menuItemDetail.categoryMenuItemName
                    : menuItemDetail.name}
                </h3>
                <p className="BrownSTDRegular font-size-0_9 text-grey4 mb-2">
                  {!isNaN(totalPrice)
                    ? isPackage
                      ? `$${totalPrice}`
                      : `$${totalPrice} - ${totalCalories} cals`
                    : ""}
                </p>
                <p className="BrownSTDRegular font-size-0_8 text-grey1 white-pre-line line-height-1_25 menu-item-description">
                  {itemDetail.seasonal && (
                    <span className="text-orange1">Seasonal - </span>
                  )}
                  {/* SHOW GUARANTY TEXT EITHER DESCRIPTION HAS OR NOT */}
                  {itemDetail.description &&
                    itemDetail.description.substring(
                      0,
                      itemDetail.description.indexOf(MENU_ALLERGEN_FREE_TEXT) >
                        -1
                        ? itemDetail.description.indexOf(
                            MENU_ALLERGEN_FREE_TEXT
                          )
                        : itemDetail.description.length
                    ) +
                      "\n" +
                      itemDetail.description.substring(
                        itemDetail.description.indexOf(
                          MENU_ALLERGEN_FREE_TEXT
                        ) > -1
                          ? itemDetail.description.indexOf(
                              MENU_ALLERGEN_FREE_TEXT
                            )
                          : itemDetail.description.length
                      )}
                  {/* // MENU_ALLERGEN_FREE_TEXT} */}
                </p>

                {itemDetail.categoryCustomType === 2 &&
                menuItemDetail.itemCustomType !== 2
                  ? ""
                  : addedIngredients && (
                      <p className="BrownSTDRegular font-size-0_8 text-grey1 text-capitalize white-pre-line">
                        <span className="text-orange1">Added - </span>
                        {addedIngredients}
                      </p>
                    )}
                {itemDetail.categoryCustomType === 2 &&
                menuItemDetail.itemCustomType !== 2
                  ? ""
                  : removedIngredients && (
                      <p className="BrownSTDRegular font-size-0_8 text-grey1 text-capitalize white-pre-line">
                        <span className="text-orange1">Removed - </span>
                        {removedIngredients}
                      </p>
                    )}
              </div>
              {itemDetail.categoryCustomType !== 2
                ? !isPackage
                  ? menuItemDetail.hasOwnProperty("modifiers") &&
                    menuItemDetail.modifiers.map((modifier) => {
                      if (
                        modifier.minimum > 0 &&
                        modifier.prefillRequired &&
                        !modifier.showItemsAsBoolean
                      )
                        return (
                          <MenuItemDetailDropDown
                            key={modifier.idItemModifiers}
                            modifier={modifier}
                            setOpenedOptionsId={setOpenedOptionsId}
                            setIngredients={setIngredients}
                            ingredients={ingredients}
                            openedOptionsId={openedOptionsId}
                          />
                        );
                    })
                  : menuItemDetail.hasOwnProperty("modifiers") && (
                      <PackageItemList
                        modifiers={menuItemDetail.modifiers}
                        packageIngredientsList={packageIngredientsList}
                        setShowCustomization={setShowCustomization}
                      />
                    )
                : ""}

              {(itemDetail.categoryCustomType === 1 ||
                itemDetail.categoryCustomType === 2 ||
                itemDetail.categoryCustomType === 4) && (
                <div
                  onClick={() => setShowCustomization(true)}
                  className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 cursor-pointer d-flex justify-content-between"
                >
                  {menuItemDetail.itemCustomType !== 2 &&
                  itemDetail.categoryCustomType === 2 &&
                  itemDetail.itemCustomType === 4 ? (
                    <div className="d-flex flex-column">
                      <span
                        className={`BrownSTDRegular font-size-0_9 ${
                          addedIngredients ? "text-grey1" : "text-orange1"
                        }`}
                      >
                        {addedIngredients ? "" : "Select"}{" "}
                        {menuItemDetail.itemCustomType === 3
                          ? "Protein, Side and Fruit"
                          : "Proteins, Ingredients and Dip"}
                      </span>
                      <span className="BrownSTDRegular font-size-0_9 text-grey1">
                        {addedIngredients ? (
                          <>
                            <span className="text-orange1">selected - </span>
                            {addedIngredients}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ) : (
                    <span className="BrownSTDRegular font-size-0_9 text-grey1">
                      customize
                    </span>
                  )}
                  <img
                    src={RightArrow}
                    alt={"Right Arrow"}
                    className={"align-self-center"}
                  />
                </div>
              )}

              <DressingDropdown
                ingredients={ingredients}
                setOpenedOptionsId={setOpenedOptionsId}
                openedOptionsId={openedOptionsId}
                setIngredients={setIngredients}
              />

              <div className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 d-flex justify-content-between">
                <span className="BrownSTDRegular font-size-0_9 text-grey1">
                  Quantity
                </span>
                <span className="d-flex align-items-center">
                  <img
                    src={Decrease}
                    className={"cursor-pointer mix-blend-mode"}
                    onClick={() =>
                      setOrderQuantity(
                        orderQuantity > 1 ? orderQuantity - 1 : orderQuantity
                      )
                    }
                    style={{ opacity: orderQuantity === 1 ? 0.5 : 1 }}
                  />
                  <span className="px-3 cpt-1 BrownSTDBold font-size-1_5 line-height-0">
                    {orderQuantity}
                  </span>
                  <img
                    className={"cursor-pointer mix-blend-mode"}
                    onClick={() => setOrderQuantity(orderQuantity + 1)}
                    src={Increase}
                  />
                </span>
              </div>

              {[3, 5, 6, 8, 9, 10].indexOf(itemDetail.categoryCustomType) ===
                -1 &&
                menuItemDetail.hasOwnProperty("modifiers") &&
                menuItemDetail.modifiers.map((modifier) => {
                  if (modifier.showItemsAsBoolean) {
                    {
                      return modifier.modifierGroupItems.map((modifierItem) => {
                        let modifierFinalPrice = getModifierOrItemPrice(
                          modifierItem.priceMethod,
                          modifierItem.price,
                          modifierItem.menuItem.price
                        );
                        return (
                          <div
                            key={modifierItem.menuItem.idMenuItem}
                            className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 d-flex justify-content-between align-items-center line-height-1"
                          >
                            <span className="BrownSTDRegular font-size-0_9 text-grey1">
                              {modifierItem.menuItem.itemIdBrink === 116
                                ? "Add " + modifierItem.menuItem.name + "?"
                                : "Add Reuseable Container?"}
                              {modifierFinalPrice > 0 && (
                                <>
                                  <span>
                                    (${modifierFinalPrice.toFixed(2)})
                                  </span>
                                </>
                              )}
                              {modifierItem.menuItem.itemIdBrink === 33308 && (
                                <p className="line-height-1 mb-0 BrownSTDRegular font-size-0_9 text-grey6 pt-1">
                                  {modifierItem.menuItem.name}
                                </p>
                              )}
                            </span>

                            <span className="d-flex align-items-center">
                              <Button
                                className={` font-size-0_9 text-grey1 bg-white py-0 px-1 me-3 cursor-pointer h-auto ${
                                  ingredients.hasOwnProperty(
                                    modifierItem.menuItem.itemIdBrink
                                  )
                                    ? "modifier-button-selected BrownSTDBold"
                                    : "modifier-button-unselected BrownSTDRegular"
                                }`}
                                type="button"
                                variant=""
                                onClick={() => {
                                  let ingredientsList = prepareItemDetailAndAdd(
                                    modifierItem,
                                    modifier,
                                    ingredients,
                                    { itemType: "container" }
                                  );
                                  setIngredients(_.cloneDeep(ingredientsList));
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className={`font-size-0_9 text-grey1 bg-white py-0 px-1 me-0 cursor-pointer h-auto ${
                                  ingredients.hasOwnProperty(
                                    modifierItem.menuItem.itemIdBrink
                                  )
                                    ? "modifier-button-unselected BrownSTDRegular"
                                    : "modifier-button-selected BrownSTDBold"
                                }`}
                                type="button"
                                variant=""
                                onClick={() => {
                                  let {
                                    currentIngredientsList,
                                    includedProteinItemId,
                                    defaultDeletedItemModifierId,
                                  } = RemoveItemFromIngredientsList(
                                    ingredients,
                                    modifierItem.menuItem.itemIdBrink,
                                    0
                                  );
                                  setIngredients(
                                    _.cloneDeep(currentIngredientsList)
                                  );
                                }}
                              >
                                No
                              </Button>
                            </span>
                          </div>
                        );
                      });
                    }
                  }
                })}
              {auth.isGuest ? (
                <></>
              ) : (
                <div className="menu-detail-section-row py-3">
                  {loader ? (
                    <CircularProgress size={24} />
                  ) : favoriteItemList ? (
                    favoriteItemList.filter(
                      (singleFavItem) =>
                        singleFavItem.itemUniqueId === itemDetail.itemUniqueId
                    ).length > 0 ? (
                      <span
                        onClick={AddRemoveFavorite}
                        className="BrownSTDRegular font-size-0_8 text-grey3 d-flex justify-content-start cursor-pointer me-3"
                      >
                        Remove from Favorites
                      </span>
                    ) : (
                      <span
                        onClick={AddRemoveFavorite}
                        className="BrownSTDRegular font-size-0_8 text-orange1 d-flex justify-content-start cursor-pointer me-3"
                      >
                        Add to Favorites
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="mt-2">
                <TextField
                  className={"menuTextField w-100"}
                  placeholder={
                    orderDetail.calculate_order_data.isGroupOrder
                      ? "Name (Required - who is this item for?)"
                      : "Name (Optional - who is this item for?)"
                  }
                  aria-label={"Name"}
                  autoComplete={"false"}
                  aria-required="true"
                  value={name}
                  maxLength={25}
                  onChange={(e) => {
                    if (e.target.value.length <= 25) setName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment:
                      name === "" ? (
                        <></>
                      ) : (
                        <InputAdornment position="start">
                          {"Name: "}
                        </InputAdornment>
                      ),
                  }}
                />
                {isNameRequired && (
                  <p className="text-orange1">Please add a name</p>
                )}
              </div>
              <div className="text-center">
                <Button
                  className="mw-100 mt-5 btn-half width-390 default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full m-auto"
                  type="button"
                  variant=""
                  onClick={() =>
                    !isPackage || (isPackage && isPackageCompleted)
                      ? addOrder()
                      : setShowCustomization(true)
                  }
                >
                  {isEdit
                    ? `Update ${
                        orderDetail.calculate_order_data.isGroupOrder &&
                        orderDetail.calculate_order_data.bOrderAsParticipant
                          ? "Cart"
                          : "Order"
                      } - $${!isNaN(totalPrice) ? totalPrice : ""}`
                    : isItemReadyToAddInCart
                    ? `Add To ${
                        orderDetail.calculate_order_data.isGroupOrder &&
                        orderDetail.calculate_order_data.bOrderAsParticipant
                          ? "Cart"
                          : "Order"
                      } - $${!isNaN(totalPrice) ? totalPrice : ""}`
                    : menuItemDetail.itemCustomType === 2
                    ? "Design Your Own"
                    : menuItemDetail.itemCustomType === 3
                    ? "Create Plate"
                    : menuItemDetail.itemCustomType === 4
                    ? `Add To ${
                        orderDetail.calculate_order_data.isGroupOrder &&
                        orderDetail.calculate_order_data.bOrderAsParticipant
                          ? "Cart"
                          : "Order"
                      } - $${!isNaN(totalPrice) ? totalPrice : ""}`
                    : menuItemDetail.categoryCustomType === 7
                    ? "Choose 6 OR More"
                    : menuItemDetail.categoryCustomType === 2
                    ? // || menuItemDetail.categoryCustomType === 4
                      "Create Your Bowl"
                    : !isPackage || (isPackage && isPackageCompleted)
                    ? `Add To ${
                        orderDetail.calculate_order_data.isGroupOrder &&
                        orderDetail.calculate_order_data.bOrderAsParticipant
                          ? "Cart"
                          : "Order"
                      } - $${!isNaN(totalPrice) ? totalPrice : ""}`
                    : "Build Package"}
                </Button>
              </div>
            </div>
          </>
        )}
        <MessageDialog
          showError={showError}
          text={showErrorMessage}
          closeDialog={setShowError}
          heading={
            errorType === "ingredient not available"
              ? MessagesList["Failed to get category menu items Title"]
              : ""
          }
        />
      </div>
    </>
  );
}

export default MenuItemDetail;
