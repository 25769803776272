import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  GetPaymentCardAPI,
  UpdatePaymentCardsList,
} from "../../actions/Card/Card";
import CardsList from "./CardsList";
import AddCardForm from "./AddCardForm";
import { CircularProgress } from "@material-ui/core";

function Payment() {
  const [addCard, setAddCard] = useState(false);
  const [cardDeleting, setCardDeleting] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();


  useEffect(async () => {
    setLoader(true);
    let response = await GetPaymentCardAPI();
    // UPDATE CARDS LIST IN REDUX
    dispatch(
      UpdatePaymentCardsList(response && response.data ? response.data : [])
    );
    setLoader(false);
  }, []);
  const showAddCard = (val) => {
    if (val === 3) setAddCard(true);
    else setAddCard(false);
  };
  const updatePaymentCard = () => {};

  return (
    <div className="mt-5 w-90 w-md-60 w-lg-30 mx-auto">
      {addCard ? (
        <AddCardForm
          updatePaymentCard={updatePaymentCard}
          setDisplayType={showAddCard}
        />
      ) : loader ? (
        <CircularProgress size={24} />
      ) : (
        <CardsList
          setSelectedCardIndex={setCardDeleting}
          selectedCardIndex={0}
          setDisplayType={showAddCard}
          allowDelete={true}
          cardDeleting={cardDeleting}
        />
      )}
    </div>
  );
}

export default Payment;
