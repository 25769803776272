import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MenuDefaultImage from "../../assets/img/MenuDefaultImage.jpg";
import { getImageUrl } from "../../actions/General/General";
import { MENU_ALLERGEN_FREE_TEXT } from "../../config/config";

function MenuSingleItem({ menuItem, setSelectedItem }) {
  let calories = menuItem.menuItem.calories;

  if (menuItem.menuItem.defaultProteinBrinkId) {
    menuItem.menuItem.modifiers.map((modifierItem) => {
      modifierItem.modifierGroupItems.map((modifierGroupItem) => {
        if (
          modifierGroupItem.menuItem.itemIdBrink ===
          menuItem.menuItem.defaultProteinBrinkId
        ) {
          calories = menuItem.menuItem.calories+modifierGroupItem.menuItem.calories;
        }
      });
    });
  }

  let image = getImageUrl(menuItem.menuItem);
  return (
    <Card
      className="singleMenuItem cmb-35 cursor-pointer"
      onClick={() => {
        setSelectedItem(menuItem.menuItem.itemIdBrink);
      }}
    >
      <CardMedia
        className=""
        image={image === "" ? "" : image}
        title={menuItem.menuItem.name}
      />
      <CardContent>
        <h3 className="BrandenGrotesqueBold font-size-1_5 text-grey4 default-letter-spacing">
          {/* {menuItem.menuItem.name} */}
          {menuItem.categoryMenuItemName}
        </h3>
        <p className="BrownSTDRegular font-size-0_8 text-grey4">
          ${menuItem.menuItem.price.toFixed(2)} - {calories} cals
        </p>
        <p className="BrownSTDRegular font-size-0_7 text-grey1 first_letter">
          {menuItem.menuItem.seasonal && (
            <span className="text-orange1">Seasonal - </span>
          )}
          {menuItem.categoryMenuItemDescription &&
            menuItem.categoryMenuItemDescription.substring(
              0,
              menuItem.categoryMenuItemDescription.indexOf(
                MENU_ALLERGEN_FREE_TEXT
              ) > -1
                ? menuItem.categoryMenuItemDescription.indexOf(
                    MENU_ALLERGEN_FREE_TEXT
                  )
                : menuItem.categoryMenuItemDescription.length
            )}
        </p>
      </CardContent>
    </Card>
  );
}

export default MenuSingleItem;
