import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import DownArrow from "../../assets/img/MenuDetailDown.svg";
import {
  getModifierOrItemPrice,
  prepareItemDetailAndAdd,
} from "./MenuFunctions";
import { DEFAULT_PROTEIN_KEY } from "../../config/config";

function MenuItemDetailDropDown({
  modifier,
  openedOptionsId,
  setOpenedOptionsId,
  ingredients,
  setIngredients,
}) {
  const [ingredient, setIngredient] = useState({});

  useEffect(() => {
    setIngredient(
      ingredients.hasOwnProperty(DEFAULT_PROTEIN_KEY)
        ? ingredients[DEFAULT_PROTEIN_KEY]
        : {}
    );
  }, [ingredients]);

  return (
    <div>
      <div
        onClick={(e) => {
          setOpenedOptionsId(modifier.idItemModifiers);
        }}
        className="menu-detail-section-row text-capitalize BrownSTDRegular font-size-0_9 py-3 cursor-pointer d-flex justify-content-between"
      >
        <span
          className={`BrownSTDRegular font-size-0_9 text-grey1 ${
            ingredient.hasOwnProperty("name") ? "" : "text-orange1"
          }`}
        >
          {ingredient.name
            ? modifier.displayName.replace("Select ", "")
            : modifier.displayName}
          {ingredient && ingredient.name
            ? (ingredient.price) > 0
              ? ": " + ingredient.name + " - $" + ingredient.price.toFixed(2)
              : ": " + ingredient.name
            : ""}
        </span>
        <img
          src={DownArrow}
          alt={"Down Arrow"}
          className={"align-self-center"}
        />
      </div>

      {openedOptionsId === modifier.idItemModifiers && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenedOptionsId("");
          }}
        >
          <div className="dropdown position-absolute bg-white">
            {modifier.modifierGroupItems.map((modifierItem) => {
              let price = getModifierOrItemPrice(
                modifierItem.priceMethod,
                modifierItem.price,
                modifierItem.menuItem.price
              );
              return (
                <li
                  className="p-3 cursor-pointer  "
                  key={modifierItem.idModifierGroupItems}
                  onClick={() => {
                    let modifierData = _.cloneDeep(modifier);
                    delete modifierData["modifierGroupItems"];
                    let ingredientsArray = prepareItemDetailAndAdd(
                      modifierItem,
                      modifierData,
                      ingredients,
                      { itemType: "included_protein" }
                    );
                    setIngredients(_.cloneDeep(ingredientsArray));
                    setOpenedOptionsId("");
                  }}
                >
                  {price > 0
                    ? modifierItem.menuItem.name + " - $" + price.toFixed(2)
                    : modifierItem.menuItem.name}
                </li>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default MenuItemDetailDropDown;
