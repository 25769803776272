import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { GetOrderDetailAPI } from "../../actions/Orders/Order";
import LocationDetail from "../Cart/LocationDetail";
import OrderDetail from "../Cart/OrderDetail";
import OrderPrice from "../Cart/OrderPrice";
import $ from "jquery";
import ReorderPreviousOrder from "../Cart/ReorderPreviousOrder";
import MessageDialog from "../MessageDialog";

function ViewOrderDetail({ orderId }) {
  // CREATE LOCAL STATE
  const [orderDetail, setOrderDetail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [orderFlow] = useState("Reorder");
  const [displayContentType, setDisplayContentType] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");

  const closeErrorMessage = async () => {
    setErrorType("");
    setErrorMessage("");
    setErrorTitle("");
  };

  useEffect(async () => {
    $("html, body").animate(
      {
        scrollTop: $("html").offset().top,
      },
      500
    );
    // GET PREVIOUS ORDERS LIST WITH PAGE
    setLoader(true);
    const ordersListData = await GetOrderDetailAPI(orderId);
    if (!ordersListData.generalError) {
      setOrderDetail(ordersListData.data);
    }
    setLoader(false);
  }, []);

  return (
    <div>
      {!loader && orderDetail !== null ? (
        <div>
          <div className="d-flex flex-column flex-md-row justify-content-center">
            <div
              className={`${
                orderFlow === "GroupAdmin" || orderFlow === "Normal"
                  ? "width-390"
                  : "cart_order_detail"
              }  mx-auto mx-md-0`}
            >
              <LocationDetail
                orderLocation={
                  orderDetail.orderType === "pickup"
                    ? orderDetail.orderItemsJson.pickup_location_info
                    : orderDetail.orderItemsJson.delivery_info
                }
                orderType={orderDetail.orderType}
                flow={orderFlow}
                orderDate={orderDetail.orderDate}
                timezone={orderDetail.locationTimeZone}
                otherData={{
                  destinationEnum: orderDetail.destinationEnum,
                  deliveryId: orderDetail.deliveryId,
                  status: orderDetail.status,
                }}
              />
              <OrderDetail
                hasUtensils={orderDetail.orderItemsJson.hasUtensils}
                itemsList={orderDetail.orderItemsJson.items}
                locationId={orderDetail.mixtLocationId}
                orderId={orderDetail.brinkOrderId}
                flow={orderFlow}
              />
              <OrderPrice
                pricingData={JSON.parse(
                  orderDetail.orderItemsJson.calc_resp_info
                )}
                discounts={orderDetail.orderItemsJson.discounts}
                tipAmount={orderDetail.orderItemsJson.payment_info.tipAmount}
                flow={orderFlow}
                isGroupOrder={
                  orderDetail.isGroupOrder ? orderDetail.isGroupOrder : false
                }
                displayContentType={displayContentType}
                setDisplayContentType={setDisplayContentType}
                setErrorType={setErrorType}
                setErrorTitle={setErrorTitle}
                setErrorMessage={setErrorMessage}
              />
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress size={24} />
      )}

      {displayContentType === "reorder" && (
        <ReorderPreviousOrder
          setDisplayContentType={setDisplayContentType}
          items={orderDetail.orderItemsJson.items}
          orderType={orderDetail.orderType}
          locationId={
            orderDetail.orderType === "pickup"
              ? orderDetail.mixtLocationId
              : orderDetail.orderItemsJson.delivery_info.idUserDeliveryAddress
          }
        />
      )}
      <MessageDialog
        showError={errorType ? true : false}
        text={errorMessage}
        closeDialog={closeErrorMessage}
        heading={errorTitle}
      />
    </div>
  );
}

export default ViewOrderDetail;
