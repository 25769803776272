import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDraggable } from "react-use-draggable-scroll";
import { useHistory } from "react-router-dom";
import edit_location from "../../assets/img/edit_location.svg";
import GroupInfo from "../../assets/img/GroupInfo.png";
import InviteLink from "./InviteLink";
import { CircularProgress } from "@material-ui/core";
import { leaveOrderGroup } from "../Cart/cartFunctions";
import { ResetCartDetailAPI } from "../../actions/Cart/Cart";
import DecisionDialog from "../DecisionDialog";
import MessagesList from "../../config/messages.json";

function MenuCategories({
  selectedMenu,
  setSelectedMenu,
  subMenuRef,
  setIsGroupLinkDisplayed = null,
}) {
  // GET MENU DETAILS FROM REDUX STATE
  const menuList = useSelector((state) => state.menu.menu_list);
  const menu = useSelector((state) => state.menu.location_info);
  const profile = useSelector((state) => state.profile);
  const group_info = useSelector((state) => state.order.group_basic_detail);

  const [loader, setLoader] = useState(false);
  const [showLeaveModel, setShowLeaveModel] = useState(false);
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE
  const history = useHistory();
  const dispatch = useDispatch();

  // CREATE REF FOR DRAGGABLE
  const draggableRef = useRef();
  const { events } = useDraggable(draggableRef);

  const onPress = (categoryId) => {
    setSelectedMenu({
      categoryId: "cat_" + categoryId,
      isCategoryChanged: true,
    });
  };

  useEffect(() => {
    if (
      (selectedMenu.categoryId === undefined ||
        selectedMenu.categoryId === 0) &&
      menuList.length > 0
    ) {
      let selectedMenuId = menuList[0].idMenuCategory;
      setSelectedMenu({
        categoryId: "cat_" + selectedMenuId,
        isCategoryChanged: true,
      });
    }
  }, [selectedMenu, menuList]);

  const LeaveOrder = async (val) => {
    setShowLeaveModel(val);
    setLoader(true);
    await leaveOrderGroup();
    await ResetCartDetailAPI(dispatch);
    history.push("/dashboard");
  };

  return (
    <div className="menu-category" ref={subMenuRef}>
      {group_info.hasOwnProperty("user_id") &&
      group_info.user_id !== profile.user_id ? (
        <div className="d-flex justify-content-center align-items-center text-uppercase BrandenGrotesqueBold font-size-1 text-brown2">
          <span>{group_info.user_name}'s group order</span>
          <img
            className="w-1 ms-2 cursor-pointer"
            onClick={() => setIsGroupLinkDisplayed(true)}
            src={GroupInfo}
          />
          <span
            onClick={async () => {
              setShowLeaveModel(true);
            }}
            className="BrownSTDRegular font-size-0_8 text-grey11 ms-3 cmt-2 cursor-pointer"
          >
            {loader ? <CircularProgress size={24} /> : "Leave"}
          </span>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center mt-2 mt-sm-0">
          {group_info.hasOwnProperty("user_id") && (
            <div className="BrandenGrotesqueBold font-size-1 text-center text-uppercase mb-2 d-flex align-items-center justify-content-center">
              <span className="line-height-1">
                {group_info.user_name}'s Group Order
              </span>
              <img
                className="w-7 ms-3 cursor-pointer"
                onClick={() => setIsGroupLinkDisplayed(true)}
                src={GroupInfo}
              />
            </div>
          )}
          <p
            className={`BrownSTDRegular font-size-0_9 text-center mb-0 ${
              !group_info.hasOwnProperty("user_id") ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (!group_info.hasOwnProperty("user_id"))
                history.push("/locations");
            }}
          >
            {menu.order_type === "pickup" ? "Pick Up from" : "Deliver to"}
          </p>
          <p
            className={`d-flex BrandenGrotesqueBold font-size-1 text-center default-letter-spacing text-uppercase ${
              menu.order_type !== "pickup" ? "mb-0" : ""
            } ${!group_info.hasOwnProperty("user_id") ? "cursor-pointer" : ""}`}
            onClick={() => {
              if (!group_info.hasOwnProperty("user_id"))
                history.push("/locations");
            }}
          >
            {menu.menuShowAddress}
            {!group_info.hasOwnProperty("user_id") && (
              <img
                onClick={() => {
                  history.push("/locations");
                }}
                src={edit_location}
                alt={"Down Arrow"}
                className={"align-self-center cursor-pointer"}
              />
            )}
          </p>
          {menu.order_type !== "pickup" ? (
            <p className="BrownSTDRegular font-size-0_8 text-center mb-2 cursor-pointer text-grey3">
              Minimum Delivery Time - {parseInt(menu.estimatedTimeMinutes)}{" "}
              Minutes
            </p>
          ) : (
            <></>
          )}
          <InviteLink />
        </div>
      )}
      <nav className="navbar pb-0">
        <div className="w-100 d-flex justify-content-center">
          <div
            id="main-menu"
            className="d-flex mx-auto overflow-x-scroll scrollbar-hide px-2"
            {...events}
            ref={draggableRef}
          >
            {menuList.map((menuCategoryItem) => {
              const categoryId = menuCategoryItem.idMenuCategory
                ? menuCategoryItem.idMenuCategory
                : "catering";
              return (
                <span
                  key={categoryId}
                  onClick={(e) => onPress(categoryId)}
                  className={`menu-item BrandenGrotesqueBold font-size-1 me-3 pe-2 me-md-5 py-2 default-letter-spacing cursor-pointer text-uppercase ${
                    selectedMenu.categoryId === "cat_" + categoryId
                      ? "selected"
                      : "text-orange1"
                  }`}
                >
                  {menuCategoryItem.categoryName}
                </span>
              );
            })}
          </div>
        </div>
      </nav>
      {showLeaveModel && (
        <DecisionDialog
          showError={showLeaveModel}
          text={MessagesList["Leave Group Order"]}
          noOption={setShowLeaveModel}
          yesOption={LeaveOrder}
          heading={MessagesList["Leave Group Order Title"]}
          yesButtonText="Leave Group Order"
        />
      )}
    </div>
  );
}

export default MenuCategories;
