import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import LeftArrow from "../../assets/img/LeftArrow.jpg";
import RightArrow from "../../assets/img/RightArrow.jpg";
import CustomIngredient from "../../assets/img/CustomIngredient.jpg";
import CancelCustomIngredient from "../../assets/img/CancelCustomIngredient.jpg";
import { getImageUrl } from "../../actions/General/General";
import "react-multi-carousel/lib/styles.css";
import { MENU_ITEM_TYPE } from "../../config/config";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1200, min: 480 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 3,
  },
};

function MenuCarouselList({ addedId, ingredients, RemoveFromIngredientsList }) {
  const [goToSlideNo, setGoToSlideState] = useState(-1);
  const [isSlideUpdated, setIsSlideUpdated] = useState(false);

  useEffect(() => {
    if (goToSlideNo !== -1) document.getElementById("goToSlide").click();
  }, [goToSlideNo]);

  const moveToSpecificSlide = (slideNo) => {
    if (slideNo !== goToSlideNo && !isSlideUpdated) {
      setGoToSlideState(slideNo);
      setIsSlideUpdated(true);
    }
  };

  useEffect(() => {
    if (isSlideUpdated) setIsSlideUpdated(false);
  }, [isSlideUpdated]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group align-items-center d-none d-md-flex">
        <img
          onClick={() => previous()}
          className="cursor-pointer position-absolute mix-blend-mode"
          src={LeftArrow}
          alt={"Left Arrow"}
        />
        <img
          onClick={() => next()}
          className="cursor-pointer position-absolute mix-blend-mode"
          src={RightArrow}
          alt={"Right Arrow"}
        />
        <img
          id="goToSlide"
          onClick={() => goToSlide(goToSlideNo)}
          className="cursor-pointer position-absolute mix-blend-mode d-none"
          src={""}
          alt={""}
        />
      </div>
    );
  };
  let ingredientsArray = Object.values(ingredients);
  ingredientsArray = _.sortBy(ingredientsArray, [
    "position"
  ]);
  ingredientsArray = ingredientsArray.reverse();
  ingredientsArray = _.sortBy(ingredientsArray, [
    "isIncludedProtein"
  ]);
  ingredientsArray = ingredientsArray.reverse();
  // console.log("ingredientsArray", ingredientsArray);
  return (
    <Carousel
      responsive={responsive}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      arrows={false}
      className={ingredientsArray.length === 0 ? "customize-carousel" : ""}
    >
      {ingredientsArray.map((ingredient, index) => {
        if (ingredient.itemIdBrink === addedId) {
          let noOfActiveItems = document.getElementsByClassName(
            "react-multi-carousel-item--active"
          );
          if (index + noOfActiveItems.length > ingredientsArray.length) {
            index = ingredientsArray.length - noOfActiveItems.length - 1;
          }

          if (index < 0) {
            index = 0;
          }

          moveToSpecificSlide(index);
        }
        let image = getImageUrl(ingredient);
        if (
          ingredient.hasOwnProperty("name") &&
          ingredient.itemType !== MENU_ITEM_TYPE["container"]
        )
          return (
            <div
              className="d-flex flex-column text-center px-3"
              key={ingredient.itemIdBrink}
              id={"id" + ingredient.itemIdBrink}
            >
              <div className="position-relative customizeItemImage mix-blend-mode">
                <img
                  onClick={() => {
                    RemoveFromIngredientsList(
                      ingredient.itemIdBrink,
                      ingredient.isIncludedProtein
                    );
                  }}
                  src={CancelCustomIngredient}
                  className={
                    "position-absolute remove-ingredient cursor-pointer rounded-full"
                  }
                />
                <img
                  src={image === "" ? "" : image}
                  style={{ visibility: image === "" ? "hidden" : "visible" }}
                />
                {ingredient.count > 1 && (
                  <span className="text-brown2 BrownSTDBold font-size-1_2 ingredient-counter">
                    {ingredient.count}
                  </span>
                )}
              </div>
              <span className="BrownSTDRegular font-size-0_8 text-black line-height-1_25 pt-2 text-capitalize">
                {ingredient.name}
              </span>
              {ingredient.hasOwnProperty("isIncludedProtein") &&
                ingredient.isIncludedProtein > 0 && (
                  <div className="BrownSTDRegular font-size-0_8 text-black text-capitalize">
                    (included protein)
                  </div>
                )}
            </div>
          );
      })}
    </Carousel>
  );
}

export default MenuCarouselList;
