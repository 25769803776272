import moment from "moment-timezone";
import {
  UpdateGroupOrderBasicDetailAPI,
  UpdateOrderDetailAPI,
} from "../../actions/Cart/Cart";
import {
  CalculateTotalAmountOfOrder,
  CreateCartDetailAPI,
  CreateNewOrderDataAPI,
  getBusinessAndCutOffTiming,
  getTimeAndClosedStatus,
} from "../../actions/General/General";
import { ClosestLocationDeliveryApi } from "../../actions/Locations/Locations";
import { SetLocationForMenuAPI } from "../../actions/Menu/Menu";
import { createOrderData } from "../Menu/MenuFunctions";
import store from "../../redux/store";

const createAllSlotsOfSelectedLocation = (
  selectedDate,
  selectedLocation,
  addPrepTimeMinutes,
  locationType
) => {
  let slotTime = 15 * 60;
  let calendarDate = selectedDate;
  let timezone = selectedLocation.timezone
    ? selectedLocation.timezone
    : selectedLocation.timeZone;

  let exceptionTimes = selectedLocation.locationExceptions;
  let totalDaysSlots = 0;
  let allSlots = {};
  let totalSlots = 0;
  while (totalSlots === 0) {
    calendarDate = calendarDate * 1000;
    // GET CURRENT DAY NUMBER OF WEEK
    let startTime = 0,
      endTime = 0,
      businessTimings,
      cutoffTimings;

    if (locationType === "pickup") {
      let currentDay = moment.tz(calendarDate, timezone).day();
      // GET BUSINESS AND CUT OFF TIMING OF SELECTED LOCATION
      let mainTimings = getBusinessAndCutOffTiming(
        locationType,
        selectedLocation,
        currentDay
      );
      businessTimings = mainTimings.businessTimings;
      cutoffTimings = mainTimings.cutoffTimings;
      let Timings = getTimeAndClosedStatus(
        locationType,
        businessTimings,
        cutoffTimings,
        timezone,
        calendarDate,
        exceptionTimes
      );
      startTime = Timings.startTime;
      endTime = Timings.endTime;
    } else {
      startTime = moment
        .tz(
          moment.tz(calendarDate, timezone).format("YYYY-MM-DD") + " 10:30",
          timezone
        )
        .unix();
      endTime = moment
        .tz(
          moment.tz(calendarDate, timezone).format("YYYY-MM-DD") + " 21:00",
          timezone
        )
        .unix();
    }

    if (startTime === endTime) startTime = 0;

    if (startTime !== 0) {
      let slots = [];
      let currentTime = moment.tz(timezone).unix();

      // ADD PREPARATION TIME
      if (addPrepTimeMinutes > 0) {
        if (currentTime > startTime) {
          currentTime += addPrepTimeMinutes * 60;
        } else {
          currentTime = startTime + addPrepTimeMinutes * 60;
        }
      }

      if (startTime > currentTime) {
        slots[0] = startTime;
      }
      // LOOP FROM START TIME TO END TIME AND ADD ALL SLOTS TO SLOTS ARRAY
      while (startTime < endTime) {
        // ADD 30 MINUTES SLOTS TIME
        startTime = startTime + slotTime;

        // IF START TIME IS GREATER THAN END TIME, ADD END TIME IN SLOTS ARRAY OTHERWISE ADD UPDATED START TIME
        if (startTime > currentTime) {
          if (startTime < endTime) slots.push(startTime);
          else slots.push(endTime);
        }
      }

      // CREATE KEY FOR SLOTS
      let dateFormat = moment
        .tz(calendarDate, timezone)
        .format("dddd, MMMM Do YYYY");

      let tag = totalDaysSlots === 0 ? "Selected Day" : "Next Day";
      if (moment.tz(timezone).format("dddd, MMMM Do YYYY") === dateFormat)
        tag = "Today";
      if (
        moment.tz(timezone).add(1, "day").format("dddd, MMMM Do YYYY") ===
        dateFormat
      )
        tag = "Tomorrow";
      // UPDATE ALL SLOTS VARIABLE
      totalSlots = slots.length;
      if (slots.length > 0 && locationType === "delivery") {
        slots.shift();
      }
      if (slots.length > 0) allSlots[dateFormat] = { slots, tag };
    }
    totalDaysSlots++;
    calendarDate = moment.tz(calendarDate, timezone).add(1, "days").unix();
  }

  return { allSlots, timezone };
};

const getDeliveryLocationDetail = async (
  selectedLocation,
  locationForMenu,
  selectedTime
) => {
  let result = await ClosestLocationDeliveryApi({
    latitude: selectedLocation.latitude,
    longitude: selectedLocation.longitude,
    city: selectedLocation.city,
    state: selectedLocation.state,
    zipCode: selectedLocation.zipCode,
    fullAddress: selectedLocation.fullAddress,
    deliveryDateUnix: selectedTime,
  });
  if (result && result.generalError) {
    return { status: false, data: "", error: result.generalError };
  } else {
    locationForMenu.timezone = selectedLocation.timezone;
    result.data.locations.map((data, index) => {
      if (data.isDoorDashDelivery) {
        locationForMenu.locationId2 = parseInt(data.idMixtLocation);
      } else {
        locationForMenu.locationId1 = parseInt(data.idMixtLocation);
      }
      locationForMenu.estimatedTimeMinutes = data.estimatedTimeMinutes;
    });
    return { status: true, data: locationForMenu, error: "" };
  }
};

const createMenuLocationDetailObject = (
  locationType,
  selectedLocation,
  isGroupOrder
) => {
  let APIObj = {
    orderType: locationType,
    isGroupOrder,
    type: "single",
    timezone: selectedLocation.timezone
      ? selectedLocation.timezone
      : selectedLocation.timeZone,
  };
  if (locationType === "delivery") {
    let addressComponents = selectedLocation.fullAddress.split(",");
    APIObj.menuShowAddress = addressComponents[0];

    if (Boolean(selectedLocation.floorAddress)) {
      APIObj.menuShowAddress =
        APIObj.menuShowAddress + ", " + selectedLocation.floorAddress;
    }
    APIObj.guestFullAddress = selectedLocation.fullAddress;
    APIObj.locationAddress = addressComponents[0];
    APIObj.idUserDeliveryAddress = selectedLocation.idUserDeliveryAddress;
    APIObj.companyName = selectedLocation.companyName;
    APIObj.floorAddress = selectedLocation.floorAddress;
    APIObj.city = selectedLocation.city;
    APIObj.state = selectedLocation.state;
    APIObj.zipCode = selectedLocation.zipCode;
    APIObj.latitude = selectedLocation.latitude;
    APIObj.longitude = selectedLocation.longitude;
    APIObj.instructions = selectedLocation.deliveryInstructions;
    APIObj.name = selectedLocation.locationName;
    APIObj.userSelectedLocationId = selectedLocation.idUserDeliveryAddress;
    APIObj.locationId1 = null;
    APIObj.locationId2 = null;
  } else {
    APIObj.menuShowAddress = selectedLocation.streetAddress1;
    APIObj.locationAddress = [
      selectedLocation.streetAddress1,
      selectedLocation.streetAddress2,
      selectedLocation.city,
      selectedLocation.state,
      selectedLocation.zipCode,
    ]
      .filter(Boolean)
      .join(", ");

    APIObj.name = selectedLocation.name;
    APIObj.userSelectedLocationId = selectedLocation.idMixtLocation;
    APIObj.locationId1 = selectedLocation.idMixtLocation;
  }
  return APIObj;
};

const isNeededToEmptyCart = (
  currentOrderData,
  selectedLocationObject,
  isGroupOrder
) => {
  let isCartNeedToEmpty = false;
  if (currentOrderData.hasOwnProperty("orderType")) {
    if (
      currentOrderData.orderType !== selectedLocationObject.orderType ||
      currentOrderData.isGroupOrder !== isGroupOrder
    )
      isCartNeedToEmpty = true;
    else if (currentOrderData.orderType === "pickup") {
      if (
        selectedLocationObject.locationId1 !== currentOrderData.mixtLocationId
      )
        isCartNeedToEmpty = true;
    } else if (currentOrderData.orderType === "delivery") {
      if (
        !(
          selectedLocationObject.longitude ===
            currentOrderData.delivery_info.longitude &&
          selectedLocationObject.latitude ===
            currentOrderData.delivery_info.latitude
        )
      )
        isCartNeedToEmpty = true;
    }
  }
  return isCartNeedToEmpty;
};

const getFlowType = (location, parentPage) => {
  let flowType = "";
  let anyFavoriteItemSelected = location.get("id");
  let campaignSelected = location.get("cid");
  let isGroupOrder = location.get("is_group") || false;

  if (anyFavoriteItemSelected) flowType = "favorite";
  else if (campaignSelected) flowType = "campaign";
  else if (parentPage === "reorder") flowType = "reorder";
  else if (parentPage === "cart") flowType = "edit";
  else if (isGroupOrder) flowType = "group";
  else flowType = "normal";
  return flowType;
};

const completeEditCart = (orderData, selectedTime, dispatch, setActionType) => {
  let orderItem = _.cloneDeep(orderData);
  orderItem.orderDate = selectedTime;
  // UPDATE DATA IN REDUX STATE
  dispatch(UpdateOrderDetailAPI(orderItem));
  setActionType(false);
};

const addFavoriteItemToOrder = async (
  locationForMenu,
  favorite_id,
  history,
  dispatch
) => {
  let orderData = store.getState().order.calculate_order_data;
  orderData = _.cloneDeep(orderData);
  orderData.previousOrderItemsArray = orderData.items
    ? _.cloneDeep(orderData.items)
    : [];
  let completeOrderData = await CreateNewOrderDataAPI(
    favorite_id,
    orderData,
    "favorite",
    locationForMenu
  );

  if (!completeOrderData.hasOwnProperty("message")) {
    orderData.items = completeOrderData.items;
    orderData.payment_info = CalculateTotalAmountOfOrder(orderData);
    dispatch(UpdateOrderDetailAPI(orderData));
    history.push("/cart");
  } else {
    return { status: false, error: completeOrderData.message, data: "" };
  }
};

export {
  createAllSlotsOfSelectedLocation,
  getDeliveryLocationDetail,
  createMenuLocationDetailObject,
  isNeededToEmptyCart,
  getFlowType,
  completeEditCart,
  addFavoriteItemToOrder,
};
