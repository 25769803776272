import React, { useEffect, useState } from "react";
import "../../assets/css/locations.css";
import PickupLocations from "./PickupLocations";
import DeliveryLocations from "./DeliveryLocations";
import SelectTime from "./SelectTime";
import {
  CreateGoogleAnalyticsAPI,
  DecryptReduxAuth,
} from "../../actions/General/General";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DecisionDialog from "../DecisionDialog";

function Locations({
  locationType,
  updateLocations,
  actionType,
  setActionType,
  selectedLocation,
  setSelectedLocation,
  showLocations,
  setShowLocations,
}) {
  const history = useHistory();
  const order = useSelector((state) => state.order.calculate_order_data);
  let auth = useSelector((state) => state.auth.data);

  const [showCancelAlert, setShowCancelAlert] = useState(false);

  auth = DecryptReduxAuth(auth);
  const location = new URLSearchParams(window.location.search);

  let isGroupOrder = location.get("is_group") || false;
  useEffect(() => {
    CreateGoogleAnalyticsAPI("LocationView", {});
  });
  return (
    <div className={`locations_div ${showCancelAlert ? "vh-100" : ""}`}>
      {showCancelAlert && (
        <DecisionDialog
          showError={showCancelAlert}
          text={"Do you want to cancel this group order?"}
          noOption={setShowCancelAlert}
          yesOption={() => {
            setShowCancelAlert(false)
            history.push('/locations')
          }}
          heading={"Cancel Group Order"}
        />
      )}
      {actionType === "" && (
        <>
          {/* <p className={`text-center BrownSTDRegular font-size-1_2 ${isGroupOrder ? "text-orange1":"text-grey4"} mt-1 mt-md-5`}>
            {isGroupOrder ? "Choose Group Order Location" : "Choose Location"}
          </p> */}
          {isGroupOrder ? (
            <div className="d-flex justify-content-between align-items-baseline mb-4 mt-4 mt-md-5 w-80 w-sm-70 mx-auto">
              <span className="text-center BrownSTDRegular font-size-1_2 text-orange1 line-height-0">
                Choose Group Order Location
              </span>
              <span
                className="BrownSTDRegular font-size-0_8 text-grey3 cursor-pointer line-height-0"
                onClick={() => setShowCancelAlert(true)}
              >
                Cancel
              </span>
            </div>
          ) : (
            <p className="text-center BrownSTDRegular font-size-1_2 text-grey4 mt-1 mt-md-5">
              Choose Location
            </p>
          )}

          <div className="text-uppercase border-grey1 rounded-full w-80 w-sm-70 m-auto location_type d-flex align-items-stretch text-center">
            <span
              className={`BrandenGrotesqueBold px-2 w-50 break-word d-flex justify-content-center align-items-center py-1 line-height-1 ${
                locationType === "pickup" && "active"
              }`}
              onClick={() => updateLocations("pickup")}
            >
              Pick Up / <br />
              cupboard
            </span>
            <span
              className={`BrandenGrotesqueBold px-2 w-50 break-word d-flex justify-content-center align-items-center py-1 ${
                locationType === "delivery" && "active"
              }`}
              onClick={() => updateLocations("delivery")}
            >
              Delivery
            </span>
          </div>
        </>
      )}
      <div
        className={`pb-sm-4 ${actionType !== "pickTime" ? "px-4 px-sm-5" : ""}`}
      >
        {(locationType === "delivery" || locationType === "pickup") &&
          actionType === "" &&
          auth.isGuest && (
            <>
              <span
                className={`d-block d-sm-none BrandenGrotesqueBold text-uppercase text-brown1 font-size-1 ${
                  order && order.items && order.items.length > 0
                    ? "group_order_sign_in_with_cart"
                    : "group_order_sign_in"
                }`}
                onClick={() => history.push("/decider/1")}
              >
                group order
              </span>
              <span
                className="d-block d-sm-none BrandenGrotesqueBold text-uppercase text-brown1 font-size-1 locations_sign_in"
                onClick={() => history.push("/signin")}
              >
                sign in
              </span>
              <span
                className="d-block d-sm-none BrandenGrotesqueBold text-uppercase text-brown1 font-size-1 locations_sign_up"
                onClick={() => history.push("/signup")}
              >
                sign Up
              </span>
            </>
          )}
        {locationType === "pickup" && actionType === "" ? (
          <PickupLocations
            setActionType={setActionType}
            setSelectedLocation={setSelectedLocation}
            showLocations={showLocations}
            setShowLocations={setShowLocations}
          />
        ) : locationType === "delivery" && actionType === "" ? (
          <DeliveryLocations
            setActionType={setActionType}
            setSelectedLocation={setSelectedLocation}
            showLocations={showLocations}
            setShowLocations={setShowLocations}
          />
        ) : (
          <SelectTime
            setActionType={setActionType}
            locationType={locationType}
            selectedLocation={selectedLocation}
          />
        )}
      </div>
    </div>
  );
}

export default Locations;
