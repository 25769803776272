import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DecryptReduxAuth } from "../../actions/General/General";
import { useDispatch, useSelector } from "react-redux";
import { CustomFunc } from "../../actions/RSA/Rsa";
import { GuestUser } from "../../actions/Auth/Auth";
import { SetGroupOrderIdAPI } from "../../actions/Orders/Order";

function GroupLink({ searchId }) {
  const history = useHistory();
  const dispatch = useDispatch();

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);

  const createGroupCart = async () => {
    if (auth.serverPublicKey === "") {
      await CustomFunc();
      await GuestUser(dispatch);
    }

    let buff = Buffer.from(searchId, "base64");
    let groupDetail = buff.toString("utf8").split("-");

    dispatch(
      SetGroupOrderIdAPI({
        group_order_id: groupDetail[1],
        group_order_user_name: groupDetail[2],
      })
    );
    history.push("/decider");
  };
  useEffect(() => {
    createGroupCart();
  });

  return <div></div>;
}

export default GroupLink;
