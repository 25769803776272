import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetFavoriteMenuListAPI,
  DeleteFavoriteItemAPI,
} from "../../actions/Orders/Order";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import {
  getImageUrl,
  GetOrderItemsListToSeparateArrays,
} from "../../actions/General/General";
import MessageDialog from "../MessageDialog";
import ErrorSuccessDialog from "../ErrorSuccessDialog";

import CartImage from "../../assets/img/MenuDefaultImage.jpg";
import { useHistory } from "react-router-dom";
import {
  addFavoriteItemToOrder,
} from "../Locations/LocationFunction";

function FavoriteMenu() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = new URLSearchParams(window.location.search);

  const [favoritesList, setFavoritesList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [favoriteItemId, setFavoriteItemId] = useState("");
  const [historyDate, setHistoryDate] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageHeading, setMessageHeading] = useState("");
  const [locationForMenu, setLocationForMenu] = useState({});
  const [isFavoriteErrorDisplayed, setIsFavoriteErrorDisplayed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getUserFavoritesList = async () => {
    setLoader(true);
    const favoriteListData = await GetFavoriteMenuListAPI({
      date: historyDate,
    });
    if (!favoriteListData.generalError) {
      let newFavoritesList = favoritesList.concat(favoriteListData.data.items);
      setFavoritesList(newFavoritesList);
      if (favoriteListData.data.items.length === favoriteListData.data.limit)
        setHistoryDate(newFavoritesList[newFavoritesList.length - 1].createdAt);
      else setHistoryDate("");
    }
    setLoader(false);
  };

  useEffect(async () => {
    getUserFavoritesList();

  }, []);

  const handleContainerOnBottom = () => {
    // GET NEXT LIST IF USER REACHED AT BOTTOM OF DOCUMENT
    if (historyDate !== "") getUserFavoritesList();
  };

  const RemoveFromFavorites = async (favoriteItemId, key) => {
    setLoader(true);
    setFavoriteItemId(favoriteItemId);

    // DELETE FAVORITE ITEM
    const favoriteResponse = await DeleteFavoriteItemAPI(favoriteItemId);
    favoritesList.splice(key, 1);
    setMessageHeading("Favorite Item Deleted");
    setShowMessage("Favorite Item Deleted Successfully.");
    setShowMessageModal(true);

    setLoader(false);
    setFavoriteItemId("");
  };
  const order = useSelector((state) => state.order.calculate_order_data);
  
  const addItemToFavorite = async (favoriteItem) => {
    if(order &&  ((
      order.isGroupOrder) || (order.items && order.items.length>=1)))
    {
      setLoader(true);
      let favoriteItemId = location.get("id") ? location.get("id") : favoriteItem ;
      setLocationForMenu(order.mixtLocationId ? order.mixtLocationId : order.doordashLocationId)
      let favoriteResult = await addFavoriteItemToOrder(
        locationForMenu,
        favoriteItemId,
        history,
        dispatch
      );
      setLoader(false);
      if (favoriteResult && !favoriteResult.status) {
        setIsFavoriteErrorDisplayed(true);
        setShowError(true);
        setErrorMessage(favoriteResult.error);
      }
    }
    else{
    history.push("/locations?id=" + favoriteItem);
    }

  };

  return (
    <>
      <BottomScrollListener onBottom={handleContainerOnBottom} />
      <div className="pb-4 mb-4 pt-4 pt-md-0">
        {favoritesList.map((singleFavoriteItem, key) => {
          let favoriteItem = singleFavoriteItem.itemJson;
          let itemImage = getImageUrl(favoriteItem, "fullFavImageUrlDesktop");
          if (itemImage === "") {
            itemImage = getImageUrl(favoriteItem);
          }
          let {
            includedProteinsList,
            dressingsList,
            containerList,
            addedList,
            RemovedList,
          } = GetOrderItemsListToSeparateArrays([favoriteItem]);
          return (
            <div className="border mb-4 cp-19 bg-white6" key={key}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="BrandenGrotesqueBold font-size-1_2 text-brown2 w-75">
                  {favoriteItem.itemName + " (" + favoriteItem.quantity + ")"}
                </span>
                <span className="BrownSTDRegular font-size-1_1 text-grey1 cpt-3">
                  ${favoriteItem.itemTotal.toFixed(2)}
                </span>
              </div>

              <div className="d-flex">
                <div className="d-flex flex-column justify-content-between">
                  <img
                    className="car-image me-3 cart-image-border"
                    src={itemImage === "" ? CartImage : itemImage}
                  />
                  <span
                    className="BrownSTDBold font-size-0_8 text-white2 cursor-pointer"
                    onClick={() =>
                      RemoveFromFavorites(
                        singleFavoriteItem.idFavoriteOrderItem,
                        key
                      )
                    }
                  >
                    {favoriteItemId ===
                    singleFavoriteItem.idFavoriteOrderItem ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Remove"
                    )}
                  </span>
                </div>

                <div className="flex-1 d-flex flex-column justify-content-between">
                  <div>
                    {includedProteinsList[0] !== "" && (
                      <p
                        key={"included_protein"}
                        className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-3"
                      >
                        <span className="BrownSTDBold">Included Protein: </span>
                        {includedProteinsList[0]}
                      </p>
                    )}

                    {(addedList[0] !== "" || RemovedList[0] !== "") && (
                      <div className=" mb-3">
                        {addedList[0] !== "" && (
                          <p
                            key={"added"}
                            className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0"
                          >
                            <span className="BrownSTDBold">Added: </span>
                            {addedList[0]}
                          </p>
                        )}

                        {RemovedList[0] !== "" && (
                          <p
                            key={"removed"}
                            className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize"
                          >
                            <span className="BrownSTDBold">Removed: </span>
                            {RemovedList[0]}
                          </p>
                        )}
                      </div>
                    )}

                    {dressingsList.length > 0 && (
                      <div className="mb-3">
                        {dressingsList[0] &&
                          dressingsList[0].map((dressingMenuItem, index) => (
                            <div
                              key={"dressing_" + index}
                              dangerouslySetInnerHTML={{
                                __html: dressingMenuItem,
                              }}
                            />
                          ))}
                      </div>
                    )}

                    {(containerList[0] !== "" || favoriteItem.notes !== "") && (
                      <div className="mb-3">
                        {containerList[0] !== "" && (
                          <p
                            key={"container" + 0}
                            className="BrownSTDBold font-size-0_9 text-grey1 text-capitalize mb-0"
                          >
                            Add {containerList[0]}
                          </p>
                        )}

                        {favoriteItem.hasOwnProperty("notes") &&
                          favoriteItem.notes !== "" && (
                            <p className="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0">
                              <span className="BrownSTDBold">Name: </span>
                              {favoriteItem.notes}
                            </p>
                          )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full width-168 height-30 border-0"
                      onClick={() =>
                        addItemToFavorite(
                          singleFavoriteItem.idFavoriteOrderItem
                        )
                      }
                    >
                      Add to Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {loader && favoriteItemId === "" ? (
          <div className="circular-progress-bar">
            <CircularProgress size={24} />
          </div>
        ) : (
          favoritesList.length === 0 && (
            <div className="d-flex flex-column align-items-center cmt-50">
              <h2 className="BrandenGrotesqueBold font-size-1_5 text-brown1 text-uppercase">
                no favorites yet
              </h2>
              <p className="BrownSTDRegular font-size-1_1 text-grey5 mb-4 text-center w-70 cmt-25">
                Add items to your favorites from your cart or past order
              </p>
              <button
                className="text-uppercase BrandenGrotesqueBold font-size-1 text-white bg-brown1 cursor-pointer rounded-full width-168 height-30 border-0 cmt-50"
                onClick={() => history.push("/locations")}
              >
                order now
              </button>
            </div>
          )
        )}
        <MessageDialog
          heading={messageHeading}
          showError={showMessageModal}
          text={showMessage}
          closeDialog={setShowMessageModal}
        />
        <ErrorSuccessDialog
            showError={showError}
            text={errorMessage}
            closeDialog={setShowError}
          />
      </div>
    </>
  );
}

export default FavoriteMenu;
