import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment-timezone";
import {
  getTimeAndClosedStatus,
  getBusinessAndCutOffTiming,
} from "../../actions/General/General";
import {
  GetDeliveryLocationsAPI,
  RemoveUserDeliveryAddressAPI,
} from "../../actions/Locations/Locations";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import DecisionDialog from "../DecisionDialog";
import MessagesList from "../../config/messages.json";
import { useDispatch } from "react-redux";

function DeliveryLocationItem({
  location,
  setSelectedLocation,
  setActionType,
  showLocations,
}) {
  const dispatch = useDispatch();
  // SET LOCAL STATE
  const [isClosedNow, setIsClosedNow] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState(0);
  const [hasAvailableTime, setHasAvailableTime] = useState(true);
  const [deleteLocationId, setDeleteLocationId] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageTitle, setMessageTitle] = useState("false");

  const RemoveDeliveryLocation = async () => {
    await RemoveUserDeliveryAddressAPI(deleteLocationId);
    dispatch(GetDeliveryLocationsAPI());
    setMessageTitle("Location Deleted Successfully");
  };


  return (
    <div
      className={`location_item pt-4 pb-3 ${
        !showLocations ? "d-none" : ""
      } d-lg-block`}
    >
      <span className="BrandenGrotesqueBold font-size-1_1 text-capitalize text-grey4">
        {location.locationName}
      </span>
      <p className="BrownSTDRegular font-size-0_9 text-grey1">
        {[
          location.floorAddress
            ? location.fullAddress.split(",")[0] + ", " + location.floorAddress
            : location.fullAddress.split(",")[0],
          location.city,
          location.state + " " + location.zipCode,
        ].join(", ")}
      </p>
      <div className="d-flex justify-content-between w-100 text-end mt-4 pt-3">
        <span
          className="BrownSTDRegular font-size-0_8 text-grey3 cursor-pointer"
          onClick={() => {
            setDeleteLocationId(location.idUserDeliveryAddress);
            setShowMessage(true);
            setMessageTitle("Confirm Remove Location");
          }}
        >
          Remove
        </span>

        <Button
          onClick={() => {
            setActionType("pickTime");
            setSelectedLocation(location);
          }}
          variant=""
          className={`text-white bg-brown1 border-brown1 rounded-full BrandenGrotesqueBold font-size-1 default-letter-spacing text-uppercase ${
            isClosedNow ? "width-168" : "width-93"
          } height-30 line-height-1 cpy-5 px-0 btn`}
        >
          Select
        </Button>
      </div>

      <DecisionDialog
        showError={showMessage && messageTitle === "Confirm Remove Location"}
        text={MessagesList[messageTitle]}
        yesButtonText="Confirm"
        noOption={setShowMessage}
        yesOption={RemoveDeliveryLocation}
        heading={MessagesList[messageTitle + " Title"]}
      />

      <ErrorSuccessDialog
        showError={showMessage && messageTitle !== "Confirm Remove Location"}
        text={messageTitle}
        closeDialog={setShowMessage}
      />
    </div>
  );
}

export default DeliveryLocationItem;
