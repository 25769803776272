import React, { useEffect, useRef, useState } from "react";
import CloseCircle from "../../assets/img/CloseCircle.svg";
import { Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";
import { AddBarCodeAPI } from "../../actions/Auth/Auth";
import MessagesList from "../../config/messages.json";
import MessageDialog from "../MessageDialog";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

function BarCode({ setAskForCode }) {
  // CREATE LOCAL STATE
  const [promoCode, setPromoCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successMessageHeading, setSuccessMessageHeading] = useState("");
  const [loader, setLoader] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const ref = useRef({ currentTab: 0 });
  let auth = useSelector((state) => state.profile);

  const handleResize = () => {
    const currentTab = ref.current?.currentTab;
    const newTab =
      window.innerWidth > 767 ? 2 : currentTab === 0 ? 1 : currentTab;
    setSelectedTab(newTab);
    ref.current.currentTab = newTab;
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const applyPromoCode = async () => {
    setIsSubmitted(true);
    setLoader(true);

    if (promoCode === "") {
      setErrorMessage("Enter Code");
    } else {
      let result = await AddBarCodeAPI(promoCode);
      if (result.generalError) setErrorMessage(result.generalError);
      else {
        let message = MessagesList["Bar Code Points Earned"];
        message = message.replace("{{POINTS}}", result.data.points_earned);
        setSuccessMessage(message);
        setSuccessMessageHeading(MessagesList["Bar Code Points Earned Title"]);
        setShowSuccessMessage(true);
      }
    }
    setLoader(false);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {
          setAskForCode(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className={`customize_modal barcode_modal`}
      >
        <DialogContent>
          <div className="d-flex flex-column">
            <img
              src={CloseCircle}
              className={"close-confirm-reward cursor-pointer"}
              onClick={() => {
                setAskForCode(false);
              }}
            />
            <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-20">
              earn points
            </h3>
            <div className="d-md-none text-uppercase border-grey1 rounded-full w-80 w-sm-70 m-auto location_type d-flex align-items-stretch text-center">
              <span
                className={`BrandenGrotesqueBold px-2 w-50 break-word d-flex justify-content-center align-items-center py-1 line-height-1 ${
                  selectedTab === 1 && "active"
                }`}
                onClick={() => {
                  setSelectedTab(1);
                  ref.current.currentTab = 1;
                }}
              >
                My Code
              </span>
              <span
                className={`BrandenGrotesqueBold px-2 w-50 break-word d-flex justify-content-center align-items-center py-1 ${
                  selectedTab === 2 && "active"
                }`}
                onClick={() => {
                  setSelectedTab(2);
                  ref.current.currentTab = 2;
                }}
              >
                Enter Code
              </span>
            </div>
            {selectedTab === 2 ? (
              <>
                <div className={`w-100 bg-yellow1 py-4 mt-3 mb-5`}>
                  <p className="BrownSTDRegular font-size-0_9 text-grey4 text-center line-height-1_25 width-233 mx-auto">
                    Enter the bar code on your receipt to earn points on your
                    purchase
                  </p>
                </div>

                <Form.Group className="w-70 m-auto text-center">
                  <Form.Control
                    className="payment_field"
                    autoComplete={"false"}
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                      setErrorMessage("");
                      setIsSubmitted(false);
                    }}
                    isInvalid={isSubmitted && errorMessage !== ""}
                    placeholder={"Enter Barcode"}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorMessage}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="text-center">
                  <Button
                    variant=""
                    className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 bg-green3 rounded-full default-letter-spacing btn-half width-233 mt-3 mb-0`}
                    onClick={applyPromoCode}
                  >
                    {loader ? <CircularProgress size={23} /> : "Submit"}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={`w-100 pt-4 mt-3 mb-1`}>
                  <p className="BrownSTDRegular font-size-1 text-grey4 text-center line-height-1_25 width-233 mx-auto">
                    Scan your QR code at the register to earn points on your
                    purchase
                  </p>
                </div>

                <div
                  className={`mt-3 mb-1 border-white1 border m-auto p-3 rounded-half`}
                >
                  <h1 className="BrownSTDBold font-size-1_5 text-grey4 text-center line-height-1_25 width-233 mx-auto">
                    {auth ? auth.first_name + " " + auth.last_name : ""}
                  </h1>
                  <div className={`w-100 m-auto`}>
                    <QRCode
                      size={128}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={auth.user_as_qrcode}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <MessageDialog
        showError={showSuccessMessage}
        text={successMessage}
        closeDialog={setAskForCode}
        heading={successMessageHeading}
      />
    </>
  );
}

export default BarCode;
