import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "react-bootstrap";

function MessageDialog({ showError, text, closeDialog, heading = "" }) {
  return (
    <Dialog
      open={showError}
      aria-labelledby="draggable-dialog-title"
      className="customize_modal"
    >
      <DialogContent>
        {heading !== "" && <h2>{heading}</h2>}
        <DialogContentText
        // dangerouslySetInnerHTML={{
        //   __html: { text },
        // }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center p-0 ">
        <Button
          autoFocus
          className="text-grey4 CalibriBold"
          onClick={() => closeDialog(false)}
          variant=""
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
