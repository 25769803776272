import React from "react";
import { useSelector } from "react-redux";
import CartIconImg from "../../assets/img/CartIcon.svg";
import { useHistory } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { DecryptReduxAuth } from "../../actions/General/General";

function CartIcon({ isDashboard, setShowLoginModal = null }) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const groupOrderDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const profile = useSelector((state) => state.profile);
  let auth = useSelector((state) => state.auth.data);
  // DECRYPT REDUX AUTH STATE
  auth = DecryptReduxAuth(auth);
  const history = useHistory();
  // Tool-Tip text
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className="d-none d-md-flex" {...props}>
      Cart
    </Tooltip>
  );

  const showLoginPopup = () => {
    setShowLoginModal(true);
  };
  return (
    <>
      {(orderData.hasOwnProperty("orderType") && orderData.items.length > 0) ||
      (groupOrderDetail.hasOwnProperty("user_id") &&
        groupOrderDetail.user_id === profile.user_id) ? (
        <>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 100 }}
            overlay={renderTooltip}
          >
            <div
              className={`position-relative cursor-pointer ${
                isDashboard ? "ms-2 px-0 " : "px-2"
              } `}
              onClick={() => {
                if (auth.isGuest) showLoginPopup();
                else history.push("/cart");
              }}
            >
              <img
                src={CartIconImg}
                alt={"Cart Icon"}
                className={isDashboard ? "dashboard_cart" : "menu_cart"}
              />
              <span
                className={`position-absolute BrownSTDBold text-white font-size-0_9 cart_text ${
                  !isDashboard ? "pb-1" : ""
                } `}
              >
                {orderData && orderData.items ? orderData.items.length : 0}
              </span>
            </div>
          </OverlayTrigger>
          {!isDashboard && (
            <span className="BrandenGrotesqueBold text-grey4 font-size-0_7 text-uppercase checkout_header d-none d-md-block">
              Checkout
            </span>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default CartIcon;
