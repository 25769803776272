import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import MenuSingleItem from "./MenuSingleItem";
import { Card } from "react-bootstrap";

function MenuItems({
  setSelectedMenu,
  setSelectedItem,
  setSelectedItemDetail,
}) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const menuList = useSelector((state) => state.menu.menu_list);

  // CREATE LOCAL STATE
  const [noOfDisplayedItems, setNoOfDisplayedItems] = useState(4);

  const [y, setY] = useState(window.scrollY);

  const checkActiveItem = (scrollTopValue, position) => {
    let activeIdsList = [];

    let pageHeight = document.documentElement.clientHeight + scrollTopValue;
    menuList.map((menuCategoryItem) => {
      let categoryId = !menuCategoryItem.isCatering
        ? "cat_" + menuCategoryItem.idMenuCategory
        : "cat_catering";

      var element = document.getElementById(categoryId);
      if (element) {
        let elementPosition = !position
          ? element.getBoundingClientRect().top
          : element.getBoundingClientRect().top;
        elementPosition += scrollTopValue + 100;
        if (position) {
          if (pageHeight > elementPosition) {
            activeIdsList.push(categoryId);
          }
        } else if (pageHeight > elementPosition) {
          activeIdsList.push(categoryId);
        }
      }
    });
    if (activeIdsList.length > 0)
      setSelectedMenu({
        categoryId: activeIdsList[activeIdsList.length - 1],
        isCategoryChanged: false,
      });
  };

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        checkActiveItem(window.scrollY, 0);
      } else if (y < window.scrollY) {
        checkActiveItem(window.scrollY, 1);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setNoOfDisplayedItems(Math.floor((window.innerWidth - 48) / (310 + 32)));
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id={"menuItemsDiv"}
      // style={{ height: "100vh", maxHeight: "100vh", overflowY: "scroll" }}
    >
      {/* LOOP OVER ALL MENU CATEGORIES TO SHOW THERE MENU */}
      {menuList.map((menuCategoryItem) => {
        if (!menuCategoryItem.isCatering)
          return (
            <section
              id={"cat_" + menuCategoryItem.idMenuCategory}
              key={menuCategoryItem.idMenuCategory}
              className={"section mx-4 px-2"}
            >
              <p
                className={`GTSuperDisplayBold font-size-2 mx-3 py-1 mb-2 text-capitalize text-brown1`}
              >
                {menuCategoryItem.categoryName.toLowerCase()}
              </p>
              <div
                className="categoryMenuItems"
                style={{
                  gridTemplateColumns:
                    "repeat(" + noOfDisplayedItems + ", minmax(310px, 1fr))",
                }}
              >
                {/* LOOP OVER ALL MENU ITEMS OF A SINGLE CATEGORY */}
                {menuCategoryItem.categoryItems &&
                menuCategoryItem.categoryItems.length > 0 ? (
                  menuCategoryItem.categoryItems.map((menuItem) => {
                    return (
                      <MenuSingleItem
                        key={menuItem.categoryMenuItemIdBrink}
                        menuItem={menuItem}
                        setSelectedItem={setSelectedItem}
                      />
                    );
                  })
                ) : (
                  <Card className="singleMenuItem cmb-35 cursor-pointer border-0"></Card>
                )}
              </div>
            </section>
          );
        else
          return (
            <section id={"cat_catering"} key={menuCategoryItem.categoryName}>
              {menuCategoryItem.cateringItems.map((cateringItem) => {
                return (
                  <section
                    key={cateringItem.idMenuCategory}
                    id={"cat_catering_" + cateringItem.idMenuCategory}
                    className={"section mx-4 px-2"}
                  >
                    <p
                      className={`GTSuperDisplayBold font-size-2 mx-3 py-1 mb-2 text-capitalize text-brown1`}
                    >
                      Catering - {cateringItem.categoryName.toLowerCase()}
                    </p>
                    <div
                      className="categoryMenuItems"
                      style={{
                        gridTemplateColumns:
                          "repeat(" +
                          noOfDisplayedItems +
                          ", minmax(310px, 1fr))",
                      }}
                    >
                      {/* LOOP OVER ALL MENU ITEMS OF A SINGLE CATEGORY */}
                      {cateringItem.categoryItems &&
                        cateringItem.categoryItems.map((menuItem) => {
                          return (
                            <MenuSingleItem
                              key={menuItem.categoryMenuItemIdBrink}
                              menuItem={menuItem}
                              setSelectedItem={setSelectedItem}
                            />
                          );
                        })}
                    </div>
                  </section>
                );
              })}
            </section>
          );
      })}
    </div>
  );
}

export default MenuItems;
