import React from "react";
import { useSelector } from "react-redux";
import MenuIconImg from "../../assets/img/MenuIcon.svg";
import { useHistory } from "react-router-dom";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function MenuIcon({ isDashboard }) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const selectedLocationDetail = useSelector(
    (state) => state.menu.location_info
  );
  // Tool-Tip text
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Menu
    </Tooltip>
  );

  const history = useHistory();

  return (
    <>
      {selectedLocationDetail &&
        selectedLocationDetail.hasOwnProperty("order_type") &&
        selectedLocationDetail.order_type !== "" && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 100 }}
            overlay={renderTooltip}
          >
          <div
            className="position-relative cursor-pointer px-2 mt-2 BrownSTDBold font-size-0_9 text-center"
            onClick={() => {
              history.push("/menu");
            }}
          >
            {"MENU"}
          </div>
          </OverlayTrigger>
        )}
    </>
  );
}

export default MenuIcon;
