import React, { useState, useEffect } from "react";
import { FieldsValidation } from "../../actions/General/General";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment-timezone";

function DateFieldComponent({
  label,
  name,
  value,
  required = false,
  setValue,
  placeholder,
  disabled = false,
  errorMessages = {},
  setErrorMessages,
  validationType = "",
}) {
  // CREATE LOCAL STATE FOR VALUE
  const [localValue, setLocalValue] = useState(
    value === undefined || value === null ? null : value
  );

  // FUNCTION CALLED WHEN FIELD VALUE IS CHANGED
  const valueChanged = (text) => {
    // APPLY EMPTY CHECK IF REQUIRED
    if (required && text === "") {
      updateErrorMessages(label + " is required");
    } else {
      // APPLY CUSTOM VALIDATION
      if (validationType && text !== "") {
        let message = FieldsValidation(text, validationType);
        updateErrorMessages(message);
      } else {
        updateErrorMessages("");
      }
    }

    // UPDATE FIELD VALUE LOCALLY AND IN PARENT COMPONENT
    setLocalValue(text);
    setValue(text);
  };

  // UPDATE ERROR MESSAGE
  const updateErrorMessages = (error) => {
    if (validationType || required) {
      let updatedErrorMessages = { ...errorMessages, [name]: error };
      setErrorMessages(updatedErrorMessages);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk={true}
          disableFuture={true}
          className={"date_picker w-100 cmt-8 cmb-30"}
          placeholder={placeholder}
          label={label}
          name={name}
          // disableToolbar
          aria-label={label}
          aria-required="true"
          variant="inline"
          // format="MM/dd/yyyy"
          format="MM-dd-yyyy"
          margin="normal"
          disabled={disabled}
          InputProps={{ readOnly: true }}
          maxDate={moment().subtract(18, "years")}
          required={required}
          value={localValue}
          onChange={(e) => valueChanged(e)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          error={
            errorMessages[name] && errorMessages[name] !== null ? true : false
          }
          helperText={errorMessages[name]}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}
export default DateFieldComponent;
