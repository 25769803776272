import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LocationItem from "./PickUpLocationItem.js";
import Text from "../../components/GeneralComponents/Text";
import { CircularProgress } from "@material-ui/core";

function PickupLocations({
  setActionType,
  setSelectedLocation,
  showLocations,
  setShowLocations,
}) {
  //CREATE LOCAL STATE
  const [searchText, setSearchText] = useState("");
  const [locations, setLocations] = useState([]);

  // GET ALL PICK UP LOCATIONS FROM REDUX STATE
  const locationsList = useSelector((state) => state.location.pickupLocations);
  const locationsLoader = useSelector(
    (state) => state.location.pickupLocationsLoader
  );

  // UPDATE LOCATIONS LIST
  const updateLocationsList = (text) => {
    let allLocations = locationsList;
    allLocations = allLocations.filter((location) => {
      let address =
        location.streetAddress1 +
        location.streetAddress2 +
        ", " +
        location.city +
        ", " +
        location.state +
        ", " +
        location.zipCode;
      return (
        address.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
        location.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
        location.description.toLowerCase().indexOf(text.toLowerCase()) > -1
      );
    });
    setLocations(allLocations);
    setSearchText(text);
  };

  useEffect(() => {
    setLocations(locationsList);
  }, [locationsList]);
  return (
    <>
      <div
        className="mx-4 pt-2 d-flex align-items-center justify-content-between search-div"
        style={{ height: "60px" }}
      >
        <Text
          name={"floor"}
          label={"Select or search by store, city, state or zip"}
          placeholder={"Select or search by store, city, state or zip"}
          value={searchText}
          setValue={updateLocationsList}
          classes={"textFieldUpdate"}
        />
        <p
          className="view_map_button BrownSTDBold font-size-0_8 text-grey1 w-25 cmb-1 cmt-12"
          onClick={() => setShowLocations(!showLocations)}
        >
          {showLocations ? "View Map" : "View List"}
        </p>
      </div>

      <div className={`mt-4 ${!showLocations ? "d-none" : ""} d-sm-block`}>
        {locationsLoader ? (
          <div className="circular-progress-bar">
            <CircularProgress />
          </div>
        ) : locations.length === 0 ? (
          <p className="BrownSTDBold font-size-1">No Locations Found</p>
        ) : (
          locations.length > 0 &&
          locations.map((location, index) => {
            return (
              <LocationItem
                key={index}
                location={location}
                setSelectedLocation={setSelectedLocation}
                setActionType={setActionType}
                showLocations={showLocations}
              />
            );
          })
        )}
      </div>
    </>
  );
}

export default PickupLocations;
