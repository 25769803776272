import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import RewardsIcon from "../assets/img/Rewards_icon.svg";

function FirstReward({ setFirstRewardEarned }) {
  const history = useHistory();
  return (
    <Dialog
      open={true}
      onClose={() => {
        setFirstRewardEarned(false);
      }}
      aria-labelledby="draggable-dialog-title"
      className={`customize_modal confirm-reward`}
    >
      <div className="d-flex flex-column p-3">
        <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-10 mb-3">
          Mixt Rewards
        </h3>
        <h4 className="BrandenGrotesqueBold font-size-1_4 text-grey4 text-center text-uppercase cmt-0 cmb-10">
          Congrats!
        </h4>

        <span className="BrownSTDRegular font-size-0_9 text-grey4 text-center cmb-37 line-height-1_25 px-3">
          You’ve earned your first reward. Use rewards when checking out in the
          app, online or in person.
        </span>

        <div className="BrownSTDRegular font-size-0_9 text-grey4 text-center cmb-37 line-height-1_25 px-3">
          Tap the{" "}
          <img
            src={RewardsIcon}
            alt={"Reward Icon"}
            className={`w-auto`}
            style={{ verticalAlign: "bottom" }}
          />{" "}
          icon to <br />
          find your rewards any time.
        </div>
        <div className="text-center">
          <Button
            variant=""
            className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 rounded-full bg-green3 default-letter-spacing btn-half width-233 mb-0`}
            onClick={() => setFirstRewardEarned(false)}
          >
            Sounds Good
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default FirstReward;
