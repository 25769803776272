import { createSlice } from "@reduxjs/toolkit";

export const OrderReducer = createSlice({
  name: "order",
  initialState: {
    calculate_order_data: {},
    track_order_id: "",
    group_order_id: "",
    group_order_user_name: "",
    update_last_order: false,
    calculated_order: {},
    group_basic_detail: {},
  },
  reducers: {
    UpdateGroupOrderBasicDetail: (state, action) => {
      state.group_basic_detail = action.payload.group_order_basic_detail;
    },
    RemoveGroupOrderBasicDetail: (state) => {
      state.group_basic_detail = {};
    },
    UpdateCalculateOrderData: (state, action) => {
      state.calculate_order_data = action.payload.order_data;
    },
    updateCartDeliveryInstructions: (state, action) => {
      state.calculate_order_data.delivery_info.instructions =
        action.payload.deliveryInstruction;
    },
    updateCartDeliveryInstructions: (state, action) => {
      state.calculate_order_data.delivery_info.instructions =
        action.payload.deliveryInstruction;
    },
    UpdateCalculatedOrder: (state, action) => {
      state.calculated_order = action.payload;
    },
    ResetCartDetail: (state) => {
      state.calculate_order_data = {};
      state.group_basic_detail = {};
    },
    setTrackOrderId: (state, action) => {
      state.track_order_id = action.payload.track_order_id;
    },
    setGroupOrderId: (state, action) => {
      state.group_order_id = action.payload.group_order_id;
      state.group_order_user_name = action.payload.group_order_user_name;
    },
    updateLastOrder: (state, action) => {
      state.update_last_order = action.payload.update_last_order;
    },
    updateGroupOrderVisibility: (state) => {
      state.group_basic_detail.is_link_displayed = true;
    },
  },
});
