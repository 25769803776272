import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Button, Form, Row, Col } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  AddUserDeliveryAddressAPI,
  GetDeliveryLocationsAPI,
  getTimezone,
  UpdateDeliveryLocationLoader,
} from "../../actions/Locations/Locations";
import { useDispatch, useSelector } from "react-redux";
import ErrorSuccessDialog from "../ErrorSuccessDialog";
import MessagesList from "../../config/messages.json";
import { DecryptReduxAuth } from "../../actions/General/General";
var AddressParser = require("parse-google-address");

function DeliveryLocationForm({ setAddNewLocation, setActionType,setShowLocations }) {
  const auth = useSelector((state) => state.auth.data);
  let authData = DecryptReduxAuth(auth);

  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE LOCAL STATE
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [floor, setFloor] = useState("");
  const [company, setCompany] = useState("");
  const [instructions, setInstructions] = useState("");
  const [locationName, setLocationName] = useState("");
  const [tag, setTag] = useState(authData.isGuest ? "Delivery Address 1" : "");
  const [loader, setLoader] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    street: "",
    locationName: "",
    zipCode: "",
  });

  // GOOGLE PLACE AUTO COMPLETE WHEN LOCATION  SELECTED
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      // WHEN LOCATION IS SELECTED, UPDATE STREET, LATITUDE, LONGITUDE LOCAL STATES
      setStreet(place.formatted_address);
      setLongitude(place.geometry.location.lng());
      setLatitude(place.geometry.location.lat());

      place.address_components.map((component) => {
        if (component.types.indexOf("administrative_area_level_1") > -1) {
          setState(component.short_name);
        }
      });

      // PARSE ADDRESS COMPONENT AND SAVE CITY, STATE, ZIP CODE IN LOCAL STATE
      AddressParser.Parse_Reverse_GeoCode_Address(
        place.address_components,
        async (err, ParsedData) => {
          if (err) console.error(err);
          else {
            setCity(ParsedData.Locality);
            // setState(ParsedData.Area_Level_1);
            setZipCode(
              ParsedData.Postal_Code ? parseInt(ParsedData.Postal_Code) : 0
            );
          }
        }
      );
    },
    options: {
      types: ["geocode"],
    },
  });

  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);
    setFormSubmitted(true);
    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (zipCode === "") {
      errors.zipCode = "Please enter a zip code";
    }

    if (zipCode === 0 || zipCode === "0") {
      errors.zipCode = "Please enter a valid zip code";
    }

    if (street === "") {
      errors.street = "Street Address is required";
    }

    if (tag === "custom" && locationName === "") {
      errors.locationName = "Location Name is required";
    }

    if (latitude === "" && longitude === "") {
      errors.street = "Please retype street address and select an option.";
    }

    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (
      errors.street === "" &&
      errors.zipCode === "" &&
      tag !== "" &&
      (errors.locationName === "" || tag !== "custom")
    ) {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");
      let timezone = await getTimezone(latitude, longitude);
      let deliveryLocationObject = {
        fullAddress: street,
        locationName: tag === "custom" ? locationName : tag,
        floorAddress: floor,
        companyName: company,
        latitude,
        longitude,
        deliveryInstructions: instructions,
        city,
        state,
        zipCode: parseInt(zipCode),
        timeZone: timezone,
      };
      if (authData.isGuest) {
        deliveryLocationObject.idUserDeliveryAddress = -1;
        setAddNewLocation(deliveryLocationObject);
        setActionType("pickTime");
        setLoader(false);
      } else {
        let data = await AddUserDeliveryAddressAPI(deliveryLocationObject);

        // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
        if (data && data.generalError) {
          // setGeneralError(data.generalError);
          setGeneralError(
            data.generalError === "Location name already exits."
              ? MessagesList[tag + " Delivery Location Exists"]
              : data.generalError
          );
          setShowMessageDialog(true);
          // HIDE LOADER
          setLoader(false);
        } else {
          // CALL API
          dispatch(UpdateDeliveryLocationLoader(true));
          dispatch(UpdateDeliveryLocationLoader(false));
          dispatch(GetDeliveryLocationsAPI());
          // OPEN DELIVERY LOCATION LIST
          setAddNewLocation(false);
          setShowLocations(true)
        }
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  return (
    <div>
      <p className="BrownSTDRegular text-center py-4 mb-5">
        Enter Delivery Address
      </p>
      <Form>
        <Row className={"flex-column"}>
          <Col className="px-0">
            <Text
              mapRef={ref}
              autocomplete={"false"}
              name={"street"}
              label={"Street Address"}
              placeholder={"Street Address"}
              value={street}
              required={true}
              setValue={setStreet}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
            />
          </Col>
          <Col className="px-0">
            <Text
              name={"floor"}
              label={"Apt, Floor, Suite (Optional)"}
              placeholder={"Apt, Floor, Suite"}
              value={floor}
              setValue={setFloor}
            />
          </Col>
          <Col className="px-0">
            <Text
              name={"company"}
              label={"Company Name (Optional)"}
              placeholder={"Company Name"}
              value={company}
              setValue={setCompany}
            />
          </Col>
          <Col className="px-0">
            <Text
              name={"zipCode"}
              label={"Zip Code"}
              placeholder={"Zip Code"}
              value={zipCode}
              required={true}
              setValue={setZipCode}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
            />
          </Col>
          <Col className="px-0">
            <Text
              name={"instructions"}
              label={"Delivery Instructions (Optional)"}
              placeholder={"Delivery Instructions"}
              value={instructions}
              setValue={setInstructions}
            />
          </Col>
          {!authData.isGuest && (
            <>
              <Col className="px-0">
                <p className="BrandenGrotesqueMedium font-size-1_2 text-grey1">
                  Select Location Name
                </p>
                <div
                  className={`d-flex justify-content-around ${
                    formSubmitted && tag === "" ? " pb-2 mb-0" : "mb-4"
                  }`}
                >
                  <span
                    onClick={() => setTag("home")}
                    className={`tag rounded-full px-3 py-1 font-size-1 BrandenGrotesqueBold default-letter-spacing text-uppercase ${
                      tag === "home" ? "selected" : ""
                    }`}
                  >
                    Home
                  </span>
                  <span
                    onClick={() => setTag("office")}
                    className={`tag rounded-full px-3 py-1 font-size-1 BrandenGrotesqueBold default-letter-spacing text-uppercase ${
                      tag === "office" ? "selected" : ""
                    }`}
                  >
                    Office
                  </span>
                  <span
                    onClick={() => setTag("custom")}
                    className={`tag rounded-full px-3 py-1 font-size-1 BrandenGrotesqueBold default-letter-spacing text-uppercase ${
                      tag === "custom" ? "selected" : ""
                    }`}
                  >
                    Custom
                  </span>
                </div>
                {formSubmitted && tag === "" && (
                  <p className="text-orange1 font-size-0_9 BrandenGrotesqueRegular mb-3">
                    Select Location Name
                  </p>
                )}
              </Col>
              {tag === "custom" && (
                <Col className="px-0">
                  <Text
                    name={"locationName"}
                    label={"Enter Custom Location Name"}
                    placeholder={"Enter Custom Location Name"}
                    value={locationName}
                    required={true}
                    setValue={setLocationName}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
              )}
            </>
          )}
          <Button
            className="mt-5 default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full btn-half width-268 m-auto"
            type="button"
            variant=""
            onClick={submitForm}
          >
            {loader ? (
              <CircularProgress size={24} color={"inherit"} />
            ) : authData.isGuest ? (
              "Proceed"
            ) : (
              "Save"
            )}
          </Button>
        </Row>
      </Form>
      <ErrorSuccessDialog
        showError={showMessageDialog}
        text={generalError}
        closeDialog={setShowMessageDialog}
      />
    </div>
  );
}

export default DeliveryLocationForm;
