import {
  sendDeleteCallToSever,
  sendGetCallToSever,
  sendPostCallToSever,
} from "../Axios/Axios";
import { LocationReducer } from "../../redux/reducers/LocationReducer";
import store from "../../redux/store";
import { MICRO_SERVICES } from "../../config/config";
const LocationActions = LocationReducer.actions;

// GET ALL PICKUP LOCATIONS
const GetPickUpLocationsAPI = () => (dispatch) => {
  // UPDATE LOADER IN REDUX STATE
  dispatch(LocationActions.UpdatePickUpLocationsLoader());

  // GET ALL PICKUP LOCATION
  sendGetCallToSever(
    MICRO_SERVICES.LOCATIONS + "/location-details/pickup/full"
  ).then((result) => {
    // UPDATE PICKUP LOCATION IN REDUX
    dispatch(
      LocationActions.UpdatePickUpLocations({
        pickupLocations: result.data.data ? result.data.data : [],
      })
    );
  });
};

// GET ALL DELIVERY LOCATIONS
const GetDeliveryLocationsAPI = () => (dispatch) => {
  // GET PROFILE STATE DATA FROM REDUX
  const profile = store.getState().profile;
  if (profile.user_id) {
    // UPDATE LOADER IN REDUX STATE
    dispatch(LocationActions.UpdateDeliveryLocationsLoader());

    // GET ALL DELIVERY LOCATIONS
    sendGetCallToSever(
      MICRO_SERVICES.LOCATIONS + "/user-delivery-address/" + profile.user_id
    ).then((result) => {
      // UPDATE DELIVERY LOCATIONS IN REDUX
      dispatch(
        LocationActions.UpdateDeliveryLocations({
          deliveryLocations: result.data.data ? result.data.data : [],
        })
      );
    });
  }
};

// GET ALL PICKUP LOCATION BY PROVIDED LATITUDE LONGITUDE
const GetPickUpLocationsByRadiusAPI = (data) => (dispatch) => {
  // UPDATE LOADER IN REDUX STATE
  dispatch(LocationActions.UpdatePickUpLocationsLoader());

  // GET ALL PICKUP LOCATIONS IN PROVIDED LATITUDE LONGITUDE
  sendPostCallToSever(
    data,
    MICRO_SERVICES.LOCATIONS + "/location-details/full-by-radius"
  ).then((result) => {
    // UPDATE PICKUP LOCATIONS IN REDUX
    dispatch(
      LocationActions.UpdatePickUpLocations({
        pickupLocations: result.data.data ? result.data.data : [],
      })
    );
  });
};

// ADD USER DELIVERY ADDRESS
const AddUserDeliveryAddressAPI = (data) => {
  // SEND CALL TO ADD DELIVERY LOCATION FOR USER
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.LOCATIONS + "/user-delivery-address"
  ).then((result) => {
    if (result.error) {
      return { generalError: result.error };
    } else if (!result.data.success) {
      return { generalError: result.data.error };
    } else {
      return {};
    }
  });
};

// DELETE USER DELIVERY ADDRESS
const RemoveUserDeliveryAddressAPI = (location_id) => {
  // SEND CALL TO DELETE DELIVERY LOCATION FOR USER
  let url = `${MICRO_SERVICES.LOCATIONS}/user-delivery-address/${location_id}`;
  return sendDeleteCallToSever(url).then((result) => {
    return result.data.data;
  });
};

const UpdateDeliveryLocationLoader = (val) => (dispatch) => {
  dispatch(LocationActions.UpdateDeliveryLocationsLoader({ value: val }));
};

// CALL ITEM AVAILABILITY CALL
const GetItemsAvailabilityAPI = (data) => {
  sendPostCallToSever(data, MICRO_SERVICES.MENUS + "/menu/item-availability");
};

// REFRESH CLOSEST LOCATION FOR USER
const RefreshClosestLocationAPI = () => {
  const profile = store.getState().profile;
  sendGetCallToSever(
    MICRO_SERVICES.LOCATIONS +
    "/user-delivery-address/refreshClosest/" +
    profile.user_id
  );
};
// Closest Location delivery
const ClosestLocationDeliveryApi = (data) => {
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.LOCATIONS + "/identify-location/user-closest-locations"
  ).then((response) => {
    if (response.error) {
      return { generalError: response.error };
    } else if (response.data.data.message) {
      return { generalError: response.data.data.message };
    } else {
      return { data: response.data.data };
    }
  });
};

const getTimezone = async (lat, long) => {
  console.log("getTimezone called", `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`)
  var url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
  return fetch(url)
    .then((res) => res.json())
    .then((out) => {
      if (out.hasOwnProperty("timeZoneId") && out.timeZoneId)
        return out.timeZoneId;
      else return "America/Los_Angeles";
    })
    .catch((err) => {
      return "America/Los_Angeles";
    });
};

const ResetLocationAPI = () => (dispatch) => {
  dispatch(LocationActions.ResetLocationsDetail());
};

const UpdateGuestDeliveryInfo = async (delivery_info, user_id) => {
  let deliveryLocationResult = await sendGetCallToSever(
    MICRO_SERVICES.LOCATIONS + "/user-delivery-address/" + user_id
  );
  let deliveryLocations = deliveryLocationResult.data.data
    ? deliveryLocationResult.data.data
    : [];

  let locationObject = {};
  let alreadyGuestLocations = 0;
  let isLocationAlreadyExist = false;
  for (let i = 0; i < deliveryLocations.length; i++) {
    let deliveryFullAddress = deliveryLocations[i].fullAddress.split(",")[0];
    if (deliveryLocations[i].locationName.startsWith("Delivery Address ")) {
      alreadyGuestLocations++;
    }
    if (
      deliveryLocations[i].city === delivery_info.city &&
      deliveryLocations[i].state === delivery_info.state &&
      deliveryFullAddress === delivery_info.fullAddress
    ) {
      isLocationAlreadyExist = true;
     
      locationObject = {
        locationName: deliveryLocations[i].locationName,
        idUserDeliveryAddress: deliveryLocations[i].idUserDeliveryAddress,
      };
    }
  }
  if (!isLocationAlreadyExist) {
    let timezone = await getTimezone(
      delivery_info.latitude,
      delivery_info.longitude
    );
    let deliveryObject = {
      fullAddress: delivery_info.guestFullAddress,
      locationName: "Delivery Address " + (parseInt(alreadyGuestLocations) + 1),
      floorAddress: delivery_info.floorAddress,
      companyName: delivery_info.companyName,
      latitude: delivery_info.latitude,
      longitude: delivery_info.longitude,
      deliveryInstructions: delivery_info.instructions,
      city: delivery_info.city,
      state: delivery_info.state,
      zipCode: delivery_info.zipCode,
      timeZone: timezone,
    };
    await AddUserDeliveryAddressAPI(deliveryObject);
    locationObject = UpdateGuestDeliveryInfo(delivery_info, user_id);
  }
  return locationObject;
};

export {
  GetPickUpLocationsAPI,
  GetPickUpLocationsByRadiusAPI,
  AddUserDeliveryAddressAPI,
  GetDeliveryLocationsAPI,
  GetItemsAvailabilityAPI,
  RefreshClosestLocationAPI,
  UpdateDeliveryLocationLoader,
  RemoveUserDeliveryAddressAPI,
  ClosestLocationDeliveryApi,
  getTimezone,
  ResetLocationAPI,
  UpdateGuestDeliveryInfo,
};
