import { sendGetCallToSever } from "../Axios/Axios";
import { MICRO_SERVICES } from "../../config/config";

const GetAllCampaigns = () => {
  // SEND CALL TO SERVER TO GET ALL PAYMENT CARDS
  return sendGetCallToSever(MICRO_SERVICES.ORDERS + "/campaigns/home").then(
    (response) => {
      // RETURN ERROR
      if (response.error) {
        return { generalError: response.error };
      } else {
        return { data: response.data.data };
      }
    }
  );
};

export { GetAllCampaigns };
