import React, { createRef, useEffect, useState } from "react";
import MenuCategories from "./MenuCategories.js";
import MenuItems from "./MenuItems.js";
import MenuItemDetail from "./MenuItemDetail.js";
import {
  GetMenuAPI,
  EmptyDetailObject,
  GetModifierCodeForLocationAPI,
} from "../../actions/Menu/Menu";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/menu.css";
import { CircularProgress } from "@material-ui/core";
import { CreateGoogleAnalyticsAPI } from "../../actions/General/General.js";
import GroupLinkModal from "./GroupLinkModal.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

function Menu() {
  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();
  const history = useHistory();

  // CREATE REF FOR CATEGORY DIV
  const ref = createRef();

  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const campaignId = useSelector((state) => state.menu.campaign_id);
  const menuLocation = useSelector((state) => state.menu.location_info);
  const menuLoader = useSelector((state) => state.menu.loader);
  const groupOrderDetail = useSelector(
    (state) => state.order.group_basic_detail
  );
  const activeOrderDetail = useSelector(
    (state) => state.order.calculate_order_data
  );

  if (Object.keys(activeOrderDetail).length === 0) history.push("/locations");

  // CREATE LOCAL STATE
  const [selectedMenu, setSelectedMenu] = useState({
    categoryId: 0,
    isCategoryChanged: false,
  });
  const [selectedItem, setSelectedItem] = useState(
    campaignId ? parseInt(campaignId) : 0
  );
  const [selectedItemDetail, setSelectedItemDetail] = useState({});
  const [subMenuHeight, setSubMenuHeight] = useState("0px");
  const [isGroupLinkDisplayed, setIsGroupLinkDisplayed] = useState(false);

  useEffect(() => {
    setIsGroupLinkDisplayed(
      groupOrderDetail.hasOwnProperty("is_link_displayed")
        ? !groupOrderDetail.is_link_displayed
        : false
    );
  }, [groupOrderDetail.is_link_displayed]);

  useEffect(() => {
    CreateGoogleAnalyticsAPI("ViewMenu", {});
  });

  useEffect(() => {
    if (Object.keys(activeOrderDetail).length > 0) {
      let APIObj = {
        locationId1: menuLocation.location_id1,
        type: menuLocation.location_type,
      };
      if (menuLocation.location_id2 && menuLocation.location_id1 !== "") {
        APIObj.locationId2 = menuLocation.location_id2;
      }
      if (menuLocation.location_id2 && menuLocation.location_id1 === "") {
        APIObj.locationId1 = menuLocation.location_id2;
      }
      // GET MENU FROM API
      dispatch(GetMenuAPI(APIObj));
      dispatch(GetModifierCodeForLocationAPI(APIObj.locationId1));
    }
  }, []);

  useEffect(() => {
    if (selectedItem === 0) {
      // scrollToDiv();
      setSelectedMenu({
        categoryId: selectedMenu.categoryId,
        isCategoryChanged: true,
      });
    } else if (selectedItem === -1) {
      setSelectedMenu({ categoryId: 0, isCategoryChanged: false });
      setSelectedItem(0);
    }
    // GET MENU FROM API
    dispatch(EmptyDetailObject());
  }, [selectedItem]);

  useEffect(() => {
    scrollToDiv();
  }, [selectedMenu]);

  const scrollToDiv = () => {
    if (selectedMenu.categoryId !== 0 && selectedMenu.isCategoryChanged) {
      var element = document.getElementById(selectedMenu.categoryId);
      if (element) {
        var headerOffset = 250;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      setSubMenuHeight(ref.current.clientHeight + "px");
    }
  }, [ref]);

  return (
    <>
      {menuLoader && (
        <div className="circular-progress-bar">
          <CircularProgress />
        </div>
      )}
      <div style={{ paddingTop: selectedItem === 0 ? subMenuHeight : 0 }}>
        {selectedItem === 0 ? (
          <>
            <MenuCategories
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              subMenuRef={ref}
              setIsGroupLinkDisplayed={setIsGroupLinkDisplayed}
            />
            <MenuItems
              setSelectedMenu={setSelectedMenu}
              setSelectedItem={setSelectedItem}
              setSelectedItemDetail={setSelectedItemDetail}
            />
          </>
        ) : (
          <MenuItemDetail
            itemId={selectedItem}
            itemDetailData={selectedItemDetail}
            setSelectedItem={setSelectedItem}
          />
        )}
        {isGroupLinkDisplayed && (
          <GroupLinkModal setIsGroupLinkDisplayed={setIsGroupLinkDisplayed} />
        )}
      </div>
    </>
  );
}

export default Menu;
