import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PackageCustomizationGroup from "./PackageCustomizationGroup.js";
import MessageDialog from "../MessageDialog";

function PackageCustomization({
  modifiers,
  itemDetail,
  packageIngredientsList,
  customizePackage,
  modifiersDescription,
}) {
  // CREATE LOCAL STATE
  const [ingredients, setIngredients] = useState(packageIngredientsList);
  const [showError, setShowError] = useState(false);
  const [errorImg, setErrorImg] = useState(false);

  // WHEN CLICKED ON DONE BUTTON
  const packageDone = () => {
    let packageCompleted = true;
    let incompleteModifierItem = "";
    modifiers.map((modifier) => {
      if (
        !ingredients.hasOwnProperty("modifiersCounter") ||
        (modifier.minimum > 0 &&
          !ingredients.modifiersCounter.hasOwnProperty(modifier.brinkId)) ||
        (modifier.minimum > 0 &&
          ingredients.modifiersCounter[modifier.brinkId] < modifier.minimum)
      ) {
        if (incompleteModifierItem === "")
          incompleteModifierItem = "modifier_" + modifier.brinkId;
        packageCompleted = false;
        setShowError(true);
        setErrorImg(true);
      }
    });
    if (packageCompleted) customizePackage(ingredients);
    else {
      let element = document.getElementById(incompleteModifierItem);
      if (element) {
        var headerOffset = 180;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="w-100 pt-5 mt-3 mt-sm-0">
      <div
        className={`menu-customization-header ${
          itemDetail.categoryCustomType === 8 ? "package_customization" : ""
        } pt-md-3`}
      >
        <p className="BrownSTDRegular font-size-1 text-brown2 text-center pb-1 pb-md-0 pt-0 pt-md-3 mb-0">
          Select package items below
        </p>
        <div className="cpx-20 cpx-md-80 w-100">
          {/* <h2 className="BrandenGrotesqueBold font-size-1_9 text-brown2 default-letter-spacing mb-0 text-uppercase"> */}
          <h2 className="BrandenGrotesqueBold font-size-1_9 text-brown2 text-center text-md-left d-flex d-lg-block justify-content-center default-letter-spacing mb-0 text-uppercase">
            {itemDetail.name}
          </h2>
        </div>
      </div>
      <div
        className={`mb-5 pb-4 ${
          itemDetail.categoryCustomType === 8 ? "mt-md-4" : "mt-4"
        }`}
      >
        {itemDetail.categoryCustomType === 7 && (
          <span className="BrownSTDBold font-size-1 text-white3 d-flex justify-content-center px-3 mx-auto px-sm-4 mx-sm-5 flex-column mt-5 pt-4">
            *6 or more required
          </span>
        )}
        {modifiers.map((modifier) => {
          return (
            <PackageCustomizationGroup
              modifier={modifier}
              modifiersDescription={modifiersDescription}
              key={modifier.idItemModifiers}
              setIngredients={setIngredients}
              ingredients={ingredients}
              errorImg={errorImg}
              categoryCustomType={itemDetail.categoryCustomType}
            />
          );
        })}
      </div>
      <div className="w-100 position-fixed text-center customize-order-button bg-white py-4">
        <Button
          className="btn-half width-390 default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full"
          type="button"
          variant=""
          onClick={packageDone}
        >
          i'm done
        </Button>
      </div>
      <MessageDialog
        showError={showError}
        heading={"Select Required Quantity"}
        text={
          "Please select the required item quantities to finish building your package."
        }
        closeDialog={setShowError}
      />
    </div>
  );
}

export default PackageCustomization;
