import { MICRO_SERVICES } from "../../config/config";
import {
  sendGetCallToSever,
  sendPostCallToSever,
  sendDeleteCallToSever,
} from "../Axios/Axios";
import { OrderReducer } from "../../redux/reducers/OrderReducer";
const OrderActions = OrderReducer.actions;

const GetFavoriteMenuListAPI = (data) => {
  // SEND CALL TO SERVER TO GET FAVORITE MENUS
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/favorite-order-item/by-date"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const DeleteFavoriteItemAPI = (favoriteId) => {
  // SEND CALL TO SERVER TO DELETE FAVORITE ITEM
  return sendDeleteCallToSever(
    MICRO_SERVICES.ORDERS + "/favorite-order-item/" + favoriteId
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const SetFavoriteMenuListAPI = (data) => {
  // SEND CALL TO SERVER TO MARK ITEM AS FAVORITE
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/favorite-order-item"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetPreviousOrdersListAPI = (data) => {
  // SEND CALL TO SERVER TO GET PREVIOUS ORDER LIST
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/orders-history/by-date/"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};
const GetPreviousGroupOrdersListAPI = (data) => {
  // SEND CALL TO SERVER TO GET PREVIOUS ORDER LIST

  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order/by-date"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};
const fetchOrderHistoryId = (id) => {
  // SEND CALL TO SERVER TO GET PREVIOUS ORDER LIST
  return sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/orders-history/by-group-order/" + id
  ).then((response) => {
    console.log(response);
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};
const ReActivateGroupOrdersAPI = (data) => {
  // SEND CALL TO SERVER TO GET PREVIOUS ORDER LIST

  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/group-order/continue-cancel-group"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const CancelChangeOrderAPI = (data) => {
  // SEND CALL TO SERVER TO CANCEL/CHANGE ORDER
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.ORDERS + "/cancel-orders"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else if (!response.data.success) {
      return {
        generalError: response.data.message
          ? response.data.message
          : response.data.error
          ? typeof response.data.error === "string"
            ? response.data.error
            : response.data.error.message
          : "",
      };
    } else {
      return { data: response.data };
    }
  });
};

const GetOrderDetailAPI = (orderId) => {
  // SEND CALL TO SERVER TO GET ORDER DETAIL
  return sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/orders-history/" + orderId
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetOrderFavoriteItemsAPI = (orderId) => {
  // SEND CALL TO SERVER TO GET ORDER FAVORITE ITEMS
  return sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/favorite-order-item/order/" + orderId
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetFavoriteItemDetailAPI = (favoriteId) => {
  // SEND CALL TO SERVER TO GET FAVORITE ITEM DETAIL
  return sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/favorite-order-item/" + favoriteId
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetOrderTrackDetailAPI = (trackId) => {
  // SEND CALL TO SERVER TO GET FAVORITE ITEM DETAIL
  return sendPostCallToSever(
    { id: trackId },
    MICRO_SERVICES.DOORDASH + "/dasher-info/details"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetOrderTrackLatestDetailAPI = (trackId) => {
  // SEND CALL TO SERVER TO GET FAVORITE ITEM DETAIL
  return sendPostCallToSever(
    { id: trackId },
    MICRO_SERVICES.DOORDASH + "/dasher-info/last-status"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetLastOrderStatusAPI = () => {
  // SEND CALL TO SERVER TO GET Last Order DETAIL
  return sendGetCallToSever(
    MICRO_SERVICES.ORDERS + "/orders-history/last-active-order"
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      return { data: response.data.data };
    }
  });
};

const GetOrderEncodedIdAPI = (order) => {
  // SEND CALL TO SERVER TO GET Last Order DETAIL
  return sendPostCallToSever(
    order,
    MICRO_SERVICES.ORDERS + "/menu-order/encoded-id"
  ).then((response) => {
    // RETURN ERROR
    if (!response || response.error) {
      return {};
    } else {
      return { data: response.data };
    }
  });
};

const SetTrackOrderIdAPI = (id) => (dispatch) => {
  dispatch(OrderActions.setTrackOrderId({ track_order_id: id }));
};

const SetGroupOrderIdAPI = (data) => (dispatch) => {
  dispatch(OrderActions.setGroupOrderId(data));
};

const UpdateLastOrderAPI = (value) => (dispatch) => {
  dispatch(OrderActions.updateLastOrder({ update_last_order: value }));
};

export {
  SetFavoriteMenuListAPI,
  GetFavoriteMenuListAPI,
  GetPreviousOrdersListAPI,
  GetPreviousGroupOrdersListAPI,
  CancelChangeOrderAPI,
  GetOrderDetailAPI,
  GetOrderFavoriteItemsAPI,
  DeleteFavoriteItemAPI,
  GetFavoriteItemDetailAPI,
  GetOrderTrackDetailAPI,
  GetLastOrderStatusAPI,
  GetOrderTrackLatestDetailAPI,
  SetTrackOrderIdAPI,
  GetOrderEncodedIdAPI,
  UpdateLastOrderAPI,
  SetGroupOrderIdAPI,
  ReActivateGroupOrdersAPI,
  fetchOrderHistoryId,
};
