import React, { useState } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Logo from "../../components/GeneralComponents/Logo";
import { CUSTOM_VALIDATIONS } from "../../config/config";
import { useHistory } from "react-router-dom";
import { ResetPasswordAPI } from "../../actions/Auth/Auth";
import { CircularProgress } from "@material-ui/core";

function ResetPasswordForm() {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE LOCAL STATE
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    email: "",
  });
  const [generalError, setGeneralError] = useState("");
  const [loader, setLoader] = useState(false);

  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);

    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (email === "") {
      errors.email = "Email is required";
    }
    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (errors.email === "") {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");

      // CALL API
      let data = ResetPasswordAPI({ email });
      data = await Promise.resolve(data);
      // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
      if (data && data.generalError) {
        setGeneralError(data.generalError);
        // HIDE LOADER
        setLoader(false);
      } else {
        // SHOW EMAIL SENT TEXT
        setIsEmailSent(true);
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  // OPEN OTHER FORM
  const openOtherForm = (page) => {
    history.push(page);
  };

  return (
    <Row className="mb-5">
      <Col className="width-326 px-0">
        <Logo />
        <h3
          className={
            "text-center text-uppercase default-letter-spacing BrandenGrotesqueBold font-size-1_7 cmt-50 cmb-25"
          }
          as="h3"
        >
          {isEmailSent ? "Email Sent" : "Reset Password"}
        </h3>
        {generalError && <Alert variant={"danger"}>{generalError}</Alert>}
        <p className="BrownSTDRegular font-size-1 text-grey1 mb-5 text-center">
          {isEmailSent
            ? "You should receive an email to reset your account password."
            : "Enter your email below and we’ll send you a link to reset your password."}
        </p>
        {isEmailSent ? (
          <Button
            className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto mt-5"
            type="button"
            variant=""
            onClick={() => openOtherForm("/signin")}
          >
            Sign in
          </Button>
        ) : (
          <Form>
            <Row className={"flex-column"}>
              <Col className="px-0">
                <Text
                  validationType={CUSTOM_VALIDATIONS.email}
                  name={"email"}
                  label={"Email"}
                  placeholder={"Enter Email"}
                  value={email}
                  required={true}
                  setValue={setEmail}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                />
              </Col>

              <Row className={"justify-content-between align-items-end"}>
                <Button
                  variant={""}
                  onClick={() => openOtherForm("/signin")}
                  className={
                    "btn-half default-letter-spacing btn-custom BrandenGrotesqueBold border-brown2 bg-white text-brown2 text-uppercase rounded-full font-size-1_1"
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant={""}
                  onClick={submitForm}
                  className={
                    "btn-half default-letter-spacing btn-custom BrandenGrotesqueBold border-brown2 bg-brown2 text-white text-uppercase rounded-full font-size-1_1"
                  }
                >
                  {loader ? (
                    <CircularProgress size={24} color={"inherit"} />
                  ) : (
                    "Reset"
                  )}
                </Button>
              </Row>
            </Row>
          </Form>
        )}
      </Col>
    </Row>
  );
}
export default ResetPasswordForm;
