import React, { useState, useEffect } from "react";
import { FieldsValidation } from "../../actions/General/General";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Form } from "react-bootstrap";

function PhoneFieldComponent({
  label,
  name,
  value,
  required = false,
  setValue,
  placeholder,
  countryCode,
  setCountryCode,
  disabled = false,
  errorMessages = {},
  setErrorMessages,
  validationType = "",
}) {
  // CREATE LOCAL STATE FOR VALUE
  // const [localValue, setLocalValue] = useState(
  //   value === undefined || value === null ? "" : value
  // );

  const [localValue_phone, setLocalValue_phone] = useState(
    value === undefined || value === null ? "" : value
  );
  const [localValue_code, setLocalValue_code] = useState(
    countryCode === undefined || countryCode === null || countryCode === ""
      ? "1"
      : countryCode
  );

  // FUNCTION CALLED WHEN FIELD VALUE IS CHANGED
  const valueChanged = (text, country_code) => {
    // APPLY EMPTY CHECK IF REQUIRED
    if (required && text === "") {
      updateErrorMessages(label + " is required");
    } else {
      // APPLY CUSTOM VALIDATION
      if (validationType && text !== "") {
        let message = FieldsValidation(text, validationType);
        updateErrorMessages(message);
      } else {
        updateErrorMessages("");
      }
    }

    // UPDATE FIELD VALUE LOCALLY AND IN PARENT COMPONENT
    // setLocalValue(text);
    setLocalValue_phone(text);
    setLocalValue_code(country_code.dialCode);
    setValue(text);
    setCountryCode(country_code.dialCode);
  };

  // UPDATE ERROR MESSAGE
  const updateErrorMessages = (error) => {
    if (validationType || required) {
      let updatedErrorMessages = { ...errorMessages, [name]: error };
      setErrorMessages(updatedErrorMessages);
    }
  };

  useEffect(() => {
    setLocalValue_phone(value);
    setLocalValue_code(
      countryCode === undefined || countryCode === null || countryCode === ""
        ? "1"
        : countryCode
    );
  }, [value, countryCode]);

  return (
    <>
      <Form.Group className={"w-100 cmb-30"}>
        <PhoneInput
          autoFormat={true}
          placeholder={placeholder}
          label={label}
          specialLabel={label}
          name={name}
          aria-label={label}
          aria-required="true"
          countryCodeEditable={false}
          country={'us'}
          disabled={disabled}
          required={required}
          value={localValue_code + localValue_phone}
          onChange={(value, countryDetail) => {
            value = value.substring(countryDetail.dialCode.length);
            valueChanged(value, countryDetail);
          }}
          error={
            errorMessages[name] && errorMessages[name] !== "" ? true : false
          }
        />
        {errorMessages[name] && (
          <>
            <Form.Control.Feedback className="d-block" type="invalid">
              {errorMessages[name]}
            </Form.Control.Feedback>
          </>
        )}
      </Form.Group>
    </>
  );
}
export default PhoneFieldComponent;
