import { sendPostCallToSever, sendGetCallToSever } from "../Axios/Axios";
import { MenuReducer } from "../../redux/reducers/MenuReducer";
import { MICRO_SERVICES } from "../../config/config";
const MenuActions = MenuReducer.actions;

const SetLocationForMenuAPI = (data) => (dispatch) => {
  // ADD SELECTED LOCATION DETAIL IN REDUX STATE FOR ORDER
  dispatch(MenuActions.AddLocation(data));
};

const UpdateCampaignIdAPI = (id) => (dispatch) => {
  // ADD SELECTED LOCATION DETAIL IN REDUX STATE FOR ORDER
  dispatch(MenuActions.UpdateCampaignId({ campaignId: id }));
};

const GetMenuAPI = (data) => (dispatch) => {
  if (!data.locationId1) {
    data.locationId1 = data.locationId2;
    delete data.locationId2;
  }
  // UPLOADER TO TRUE
  dispatch(
    MenuActions.UpdateMenuLoader({
      loader: true,
    })
  );
  // GET MENU LIST FROM API
  sendPostCallToSever(data, MICRO_SERVICES.MENUS + "/menu").then((result) => {
    // UPDATE MENU LIST IN REDUX STATE
    dispatch(
      MenuActions.UpdateMenuCategories({
        menuList: result.data.data ? result.data.data : [],
      })
    );
  });
};

const GetMenuItemDetailAPI = (data) => {
  if (!data.locationId1) {
    data.locationId1 = data.locationId2;
    delete data.locationId2;
  }
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.MENUS + "/menu/menu-item-details"
  ).then((result) => {
    if (!result.data.success) {
      return { data: {}, message: result.data.message };
    }
    else return { data: result.data.data ? result.data.data : {}, message: "" };
  });
};

const GetMenuItemDetailForReorderAPI = (data) => {
  return sendPostCallToSever(
    data,
    MICRO_SERVICES.MENUS + "/menu/menu-item-details"
  ).then((result) => {
    return result.data.data
      ? result.data.data
      : { generalError: result.data.message };
  });
};

const GetModifierCodeForLocationAPI = (location_id) => (dispatch) => {
  sendGetCallToSever(
    MICRO_SERVICES.MENUS + "/modifier-codes/" + location_id
  ).then((result) => {
    dispatch(
      MenuActions.UpdateDressingDetail({
        dressingDetail: result.data.data ? result.data.data : [],
      })
    );
  });
};

const EmptyDetailObject = () => (dispatch) => {
  dispatch(
    MenuActions.UpdateItemDetail({
      itemDetail: {},
    })
  );
};

export {
  SetLocationForMenuAPI,
  GetMenuAPI,
  GetMenuItemDetailAPI,
  EmptyDetailObject,
  GetModifierCodeForLocationAPI,
  GetMenuItemDetailForReorderAPI,
  UpdateCampaignIdAPI,
};
