import { createSlice } from "@reduxjs/toolkit";
import {
  EncryptReduxAuth,
  DecryptReduxAuth,
} from "../../actions/General/General";

export const AuthReducer = createSlice({
  name: "auth",
  initialState: {
    data: EncryptReduxAuth({
      defaultAccessToken: process.env.REACT_APP_DEFAULT_ACCESS_TOKEN,
      publicKey: "",
      privateKey: "",
      clientId: "",
      clientSecret: "",
      serverPublicKey: "",
      serverPrivateKey: "",
      accessToken: "",
      businessKey: "",
      isGuest: true,
      hasGuestOrder: false,
    }),
  },
  reducers: {
    setStartUpData: (state, action) => {
      let data = DecryptReduxAuth(state.data);
      data = { ...data, ...action.payload };
      data = EncryptReduxAuth(data);
      state.data = data;
    },
    verifyUser: (state, action) => {
      let data = DecryptReduxAuth(state.data);
      data = { ...data, ...action.payload };
      data = EncryptReduxAuth(data);
      state.data = data;
    },
    updateGuestOrderStatus: (state, action) => {
      let data = DecryptReduxAuth(state.data);
      data = { ...data, ...action.payload };
      data = EncryptReduxAuth(data);
      state.data = data;
    },
    Login: (state, action) => {
      let data = DecryptReduxAuth(state.data);
      data = { ...data, ...action.payload };
      data = EncryptReduxAuth(data);
      state.data = data;
    },
    Logout: (state) => {
      state.data = EncryptReduxAuth({
        defaultAccessToken: process.env.REACT_APP_DEFAULT_ACCESS_TOKEN,
        publicKey: "",
        privateKey: "",
        clientId: "",
        clientSecret: "",
        serverPublicKey: "",
        serverPrivateKey: "",
        accessToken: "",
        businessKey: "",
        isGuest: true,
      });
    },
  },
});
