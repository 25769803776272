import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import Text from "../../components/GeneralComponents/Text";
import Date from "../../components/GeneralComponents/Date";
import Phone from "../../components/GeneralComponents/Phone";
import CheckBox from "../../components/GeneralComponents/CheckBox";
import Logo from "../../components/GeneralComponents/Logo";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_VALIDATIONS } from "../../config/config";
import { useHistory } from "react-router-dom";
import {
  GuestUser,
  RegisterAPI,
  SendTheOTP,
  VerifyTheOTP,
} from "../../actions/Auth/Auth";
import { CircularProgress } from "@material-ui/core";
import { phone } from "phone";
import {
  CreateGoogleAnalyticsAPI,
  DecryptReduxAuth,
} from "../../actions/General/General";
import { CustomFunc } from "../../actions/RSA/Rsa";
import MessageDialog from "../MessageDialog";

function SignUpForm({ isGroup }) {
  // CREATE HISTORY OBJECT TO MAINTAIN HISTORY AND MOVE TO NEW URL
  const history = useHistory();

  // CREATE  DISPATCH OBJECT TO SAVE DATA IN REDUX
  const dispatch = useDispatch();

  // CREATE LOCAL STATE
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDay, setBirthDay] = useState(null);
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [countryCode, setCountryCode] = useState("");
  const [otp, setOtp] = useState("");
  const [otpResponse, setOtpResponse] = useState(null);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [loader, setLoader] = useState(false);

  let auth = useSelector((state) => state.auth.data);
  auth = DecryptReduxAuth(auth);
  const createGuestLogin = async () => {
    if (auth.serverPublicKey === "") {
      await CustomFunc();
      await GuestUser(dispatch);
    }
  };
  useEffect(() => {
    createGuestLogin();
  }, []);

  // CREATE METHOD THAT CALL ON FORM SUBMIT
  const submitForm = async () => {
    // START LOADER ON BUTTON
    setLoader(true);
    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (firstName === "") {
      errors.firstName = "First Name is required";
    }
    if (lastName === "") {
      errors.lastName = "Last Name is required";
    }
    if (email === "") {
      errors.email = "Email is required";
    }
    if (password === "") {
      errors.password = "Password is required";
    }
    if (phoneNumber === "") {
      errors.phoneNumber = "Phone Number is required";
    } else {
      let result = phone("+" + countryCode + phoneNumber);
      if (!result.isValid) {
        errors.phoneNumber = "Phone Number is not valid";
      }
    }

    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (
      errors.firstName === "" &&
      errors.lastName === "" &&
      errors.phoneNumber === "" &&
      errors.email === "" &&
      errors.password === ""
    ) {
      // SET GENERAL ERROR TO EMPTY
      setGeneralError("");

      // CREATE REGISTER OBJECT
      let registerObject = {
        email,
        password,
        password_confirmation: password,
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        privacy_policy: true,
        terms_and_conditions: true,
      };

      if (birthDay) {
        registerObject.birthday = birthDay;
      }
      // CALL API
      let data = dispatch(RegisterAPI(registerObject, countryCode));
      data = await Promise.resolve(data);
      // CHECK IF API RETURN ANY ERROR, IF ERROR RETURNED SET ERROR IN GENERAL STATE
      if (data && data.generalError) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setGeneralError(data.generalError);
        // HIDE LOADER
        setLoader(false);
        setShowOtpForm(false);
      } else {
        CreateGoogleAnalyticsAPI("SignedUp", {});
        // REDIRECT TO DASHBOARD
        openOtherForm(
          isGroup
            ? data.route + "/1"
            : data.route === "/cart"
            ? data.route
            : "/reward"
        );
      }
    } else {
      // HIDE LOADER
      setLoader(false);
    }
  };

  // Call send OTP api here
  const sendOTP = async () => {
    // CHECK REQUIRED FIELDS OF FORM
    let errors = { ...errorMessages };
    if (firstName === "") {
      errors.firstName = "First Name is required";
    }
    if (lastName === "") {
      errors.lastName = "Last Name is required";
    }
    if (email === "") {
      errors.email = "Email is required";
    }
    if (password === "") {
      errors.password = "Password is required";
    }
    if (phoneNumber === "") {
      errors.phoneNumber = "Phone Number is required";
    } else {
      let result = phone("+" + countryCode + phoneNumber);
      if (!result.isValid) {
        errors.phoneNumber = "Phone Number is not valid";
      }
    }

    // UPDATE ERROR MESSAGE
    setErrorMessages(errors);

    // IF THERE ARE NO ERROR SUBMIT THE FORM
    if (
      errors.firstName === "" &&
      errors.lastName === "" &&
      errors.phoneNumber === "" &&
      errors.email === "" &&
      errors.password === ""
    ) {
      // SET GENERAL ERROR TO EMPTY
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
      setGeneralError("");
      setShowOtpForm(true);
      let otpData = await SendTheOTP("+" + countryCode + phoneNumber);
      
      setOtpResponse(otpData);
    }
  };

  const verifyOtp = async () => {
    let result = await VerifyTheOTP("+" + countryCode + phoneNumber, otp);
    console.log("result", result);
    if (result.generalError) {
      setOtpResponse({ generalError: result.generalError });
    } else {
      setOtpResponse(null);
      submitForm();
    }
  };

  // OPEN OTHER FROM
  const openOtherForm = (page) => {
    history.push(page);
  };

  return (
    <Row className="mb-5">
      <Col className="width-326 px-0">
        <Logo />
        {!showOtpForm && (
          <>
            <h3
              className={`text-center text-uppercase default-letter-spacing BrandenGrotesqueBold ${
                isGroup ? "font-size-1_1" : "font-size-1_7"
              } cmt-50 cmb-10`}
              as="h3"
            >
              Sign Up {isGroup ? "to place a group order" : ""}
            </h3>
            <Col className="px-0 text-center mb-4">
              <p className="BrandenGrotesqueRegular text-grey1 font-size-1_1">
                Have an account?{" "}
                <button
                  onClick={() =>
                    openOtherForm(isGroup ? "/signin/1" : "/signin")
                  }
                  variant=""
                  className="border-0 bg-transparent BrandenGrotesqueBold font-size-1_1 text-green1 text-uppercase default-letter-spacing"
                >
                  Sign In
                </button>
              </p>
            </Col>
            {generalError && <Alert variant={"danger"}>{generalError}</Alert>}
            <p className="BrandenGrotesqueRegular font-size-0_9 text-grey1 mb-4">
              Fields with a * are required
            </p>

            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row className={"flex-column"}>
                <Col className="px-0">
                  <Text
                    name={"firstName"}
                    label={"First Name"}
                    placeholder={"Enter First Name"}
                    value={firstName}
                    required={true}
                    setValue={setFirstName}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
                <Col className="px-0">
                  <Text
                    name={"lastName"}
                    label={"Last Name"}
                    placeholder={"Enter Last Name"}
                    value={lastName}
                    required={true}
                    setValue={setLastName}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
                <Col className="px-0">
                  <Text
                    validationType={CUSTOM_VALIDATIONS.email}
                    name={"email"}
                    label={"Email"}
                    placeholder={"Enter Email"}
                    value={email}
                    required={true}
                    setValue={setEmail}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
                <Col className="px-0">
                  <Phone
                    name={"phoneNumber"}
                    label={"Mobile Phone Number"}
                    placeholder={"Enter Mobile Phone Number"}
                    value={phoneNumber}
                    required={true}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    setValue={setPhoneNumber}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
                <Col className="px-0 position-relative d-flex">
                  <Date
                    name={"birthDay"}
                    label={"Birthday"}
                    placeholder={"Enter Birthday"}
                    value={birthDay}
                    setValue={setBirthDay}
                  />
                </Col>
                <Col className="px-0">
                  <Text
                    validationType={CUSTOM_VALIDATIONS.password}
                    name={"password"}
                    label={"Password"}
                    placeholder={"Enter Password"}
                    value={password}
                    type={"password"}
                    required={true}
                    setValue={setPassword}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>
                <Col className="px-0 d-flex align-items-center mt-2 mb-3">
                  <CheckBox
                    name={"terms"}
                    label={""}
                    value={terms}
                    required={true}
                    setValue={setTerms}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                  <p className="mb-0 BrandenGrotesqueRegular font-size-0_9">
                    I agree to the{" "}
                    <a
                      href={"https://www.mixt.com/terms-of-use"}
                      className="signup-page-links text-capitalize text-orange1"
                    >
                      terms & conditions
                    </a>
                  </p>
                </Col>
                <Col className="px-0 d-flex align-items-center mb-5">
                  <CheckBox
                    name={"privacy"}
                    label={""}
                    value={privacy}
                    required={true}
                    setValue={setPrivacy}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                  <p className="mb-0 BrandenGrotesqueRegular font-size-0_9">
                    I agree to the{" "}
                    <a
                      href={"https://www.mixt.com/privacy-policy"}
                      className="signup-page-links text-capitalize text-orange1"
                    >
                      privacy policy
                    </a>
                    {" & "}
                    <a
                      href={"https://www.mixt.com/privacy-policy/#ccpa"}
                      className="signup-page-links text-capitalize text-orange1"
                    >
                      california privacy rights
                    </a>
                  </p>
                </Col>
                <Col className="px-0 d-flex align-items-center mb-5">
                  <p className="mb-0 BrandenGrotesqueRegular font-size-0_8 text-grey9">
                    By providing your cell phone number you agree to receive a
                    one time SMS text message from MG Restaurants, Inc. ("Mixt")
                    regarding your account. Standard message and data rates may
                    apply. For help or support, contact our customer service at{" "}
                    <a
                      className="mb-0 BrandenGrotesqueRegular text-grey9 cursor-pointer"
                      href="mailto:hello@mixt.com"
                    >
                      hello@mixt.com
                    </a>
                    . Your cell phone number will be shared with{" "}
                    <a
                      href="https://www.twilio.com/legal/privacy"
                      className="signup-page-links text-capitalize text-orange1"
                    >
                      Twilio
                    </a>
                    .
                  </p>
                </Col>
                <Button
                  className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto"
                  type="submit"
                  variant=""
                  disabled={terms && privacy ? false : true}
                  onClick={() => sendOTP()}
                >
                  {loader ? (
                    <CircularProgress size={24} color={"inherit"} />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </Row>
            </Form>
          </>
        )}
        {showOtpForm && (
          <>
            <h3
              className={`text-center text-uppercase default-letter-spacing BrandenGrotesqueBold ${
                isGroup ? "font-size-1_1" : "font-size-1_7"
              } cmt-50 cmb-30`}
              as="h3"
            >
              Phone Verification
            </h3>
            <img
              className={`cml-md-150 cursor-pointer back-image`}
              alt={"Back image"}
              onClick={() => setShowOtpForm(false)}
            />
            <Form
              className="otp_view"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {otpResponse &&
                otpResponse.generalError &&
                otpResponse.generalError !==
                  "Invalid phone number type. Please use a mobile number." && (
                  <Alert
                    className="px-0 bg-white border-0 BrandenGrotesqueMedium font-size-1 text-center"
                    variant={"danger"}
                  >
                    Invalid Verification Code. Please try again.
                  </Alert>
                )}
              {otpResponse && !otpResponse.generalError && (
                <Alert
                  className="px-0 bg-white border-0 BrandenGrotesqueMedium font-size-1 text-center"
                  variant={"success"}
                >
                  Please verify your phone number by entering the SMS code you
                  just received.
                </Alert>
              )}
              <Row className={"flex-column"}>
                <Col className="px-0">
                  <Text
                    name={"otp"}
                    label={"Enter Verification Code"}
                    placeholder={"Enter Verification Code"}
                    value={otp}
                    required={true}
                    setValue={setOtp}
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                  />
                </Col>

                <Button
                  className="default-letter-spacing text-center text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 font-size-1_1 text-white rounded-full width-320 m-auto"
                  type="submit"
                  variant=""
                  disabled={terms && privacy ? false : true}
                  onClick={() => verifyOtp()}
                >
                  {loader ? (
                    <CircularProgress size={24} color={"inherit"} />
                  ) : (
                    "Verify"
                  )}
                </Button>
                <Col className="px-0 text-center">
                  <Button
                    onClick={() => sendOTP()}
                    variant=""
                    className={`BrownSTDRegular bg-white border-0 font-size-1_1 text-green1 cmt-45 cmb-60 forgot-password`}
                  >
                    Resend Code
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}

        {otpResponse &&
          otpResponse.generalError &&
          otpResponse.generalError ===
            "Invalid phone number type. Please use a mobile number." && (
            <MessageDialog
              showError={true}
              text={"Please enter a valid mobile phone number."}
              closeDialog={() => {
                setOtpResponse(null);
                setShowOtpForm(false);
              }}
              heading={"Invalid Phone Number"}
            />
          )}
      </Col>
    </Row>
  );
}
export default SignUpForm;
