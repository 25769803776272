import React, { useState, useEffect } from "react";
import { FieldsValidation } from "../../actions/General/General";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";

function CheckBoxComponent({
  label,
  name,
  value,
  required = false,
  setValue,
  errorMessages = {},
  setErrorMessages,
  validationType = "",
}) {
  // CREATE LOCAL STATE FOR VALUE
  const [localValue, setLocalValue] = useState(
    value === undefined || value === null ? "" : value
  );

  // FUNCTION CALLED WHEN FIELD VALUE IS CHANGED
  const valueChanged = (val) => {
    // UPDATE FIELD VALUE LOCALLY AND IN PARENT COMPONENT
    setLocalValue(val);
    setValue(val);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <>
      <FormControlLabel
        className={"checkboxField"}
        control={
          <Checkbox
            onChange={(e) => valueChanged(e.target.checked)}
            checked={localValue}
            checkedIcon={<DoneIcon />}
            value={!localValue}
            disableRipple={true}
          />
        }
        label={label}
      />
    </>
  );
}
export default CheckBoxComponent;
